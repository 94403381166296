import { buildApiUrl } from '@ss/helpers/buildApiUrl';
import httpService from './httpService';
const list = (data = {}) => {
    const apiUrl = buildApiUrl('/systemnotices');
    return httpService.request('get', apiUrl, data);
};
const details = (id, data = {}) => {
    const apiUrl = buildApiUrl(`/systemnotices/${id}`);
    return httpService.request('get', apiUrl, data);
};
const store = (data, headers) => {
    const apiUrl = buildApiUrl('/systemnotices/create');
    return httpService.post(apiUrl, data, headers);
};
const update = (id, data, headers) => {
    const apiUrl = buildApiUrl(`/systemnotices/update/${id}`);
    return httpService.post(apiUrl, data, headers);
};
const deleteSystemNotice = (id, data, headers) => {
    const apiUrl = buildApiUrl(`/systemnotices/delete/${id}`);
    return httpService.post(apiUrl, data, headers);
};
const getSystemNotices = (type) => {
    // No necessary parameters
    const apiUrl = buildApiUrl(`/systemnotices/active/${type}`);
    return httpService.request('get', apiUrl);
};
export default {
    list,
    details,
    store,
    update,
    delete: deleteSystemNotice,
    getSystemNotices,
};
