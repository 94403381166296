import React from 'react';
import { findLinkGroupByTitle } from '@ss/helpers';
import PricingForm from '../../Forms/PricingForm';
import Tab from '../../shared/Tab';
import Sidebar from '../Sidebar';
class Pricing extends PricingForm {
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            tab: 'regular',
            linkGroup: findLinkGroupByTitle('Settings'),
        };
    }
    doSubmit = async (callback) => {
        this.setState({ submitting: true });
        const response = await this.submitFormData();
        if (response.status === 200) {
            callback();
        }
        else {
            throw new Error('Unable to submit');
        }
        this.setState({ submitting: false });
    };
    changeTab = (tab) => {
        this.setState({ tab });
    };
    render() {
        const { tab, submitting, linkGroup } = this.state;
        return (React.createElement("main", { className: "dashboard admin" },
            React.createElement("div", { className: "sidebar-wrapper" },
                React.createElement(Sidebar, { ...this.props, iconClass: "fas fa-id-card", linkGroup: linkGroup })),
            React.createElement("div", { className: "content" },
                React.createElement("div", { className: "admin-pricing" },
                    React.createElement("div", { className: "header" },
                        React.createElement("span", { className: "theme-h2 title" }, "Pricing and Rate for our Marketplace"),
                        React.createElement("button", { type: "button", className: "theme-btn blue medium save-btn", disabled: submitting, onClick: this.handleSubmit },
                            submitting && React.createElement("i", { className: "fas fa-spinner fa-spin" }),
                            submitting ? ' Saving...' : 'Save')),
                    React.createElement("div", { className: "pricing-form form" },
                        React.createElement("div", { className: "divider" }),
                        React.createElement("div", { className: "tabs" },
                            React.createElement(Tab, { active: tab === 'regular' ? true : false, onClick: () => this.changeTab('regular'), title: 'Rates Regular Booking' }),
                            React.createElement(Tab, { active: tab === 'bulk' ? true : false, onClick: () => this.changeTab('bulk'), title: 'Rates Bulk Booking' })),
                        this.renderForm(tab),
                        React.createElement("div", { className: "divider" }),
                        React.createElement("div", { className: "align_center" },
                            React.createElement("button", { type: "button", className: "theme-btn blue large save-btn", disabled: submitting, onClick: this.handleSubmit },
                                submitting && React.createElement("i", { className: "fas fa-spinner fa-spin" }),
                                submitting ? ' Saving...' : 'Save')))))));
    }
}
export default Pricing;
