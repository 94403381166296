import React from 'react';
import { validatePassword } from '@ss/helpers';
class PasswordWithRules extends React.Component {
    state = {
        view: false,
    };
    toggleView = () => {
        this.setState((prevState) => ({ ...prevState, view: !prevState.view }));
    };
    render() {
        const { name, label, error, value, ...rest } = this.props;
        const { view } = this.state;
        const { hasLowercase, hasUppercase, hasSpecialCharacters, hasNumber, isLengthy, } = validatePassword(value);
        const type = view ? 'text' : 'password';
        const classNames = ['form-field', name, 'password'];
        return (React.createElement("div", { className: "form-field" },
            React.createElement("div", { className: classNames.join(' ') },
                React.createElement("input", { ...rest, name: name, id: name, value: value, type: type, className: value ? 'active' : 'inactive' }),
                React.createElement("label", { className: "", htmlFor: name },
                    label,
                    React.createElement("span", { className: "required" }, "\u00A0*")),
                React.createElement("div", { className: "password-toggle", onClick: this.toggleView },
                    React.createElement("i", { className: "far fa-eye" })),
                error && (React.createElement("span", { className: "error", role: "alert" },
                    React.createElement("strong", null, error)))),
            React.createElement("div", { className: "form-field-info" },
                React.createElement("p", null, "Password must contain the following:"),
                React.createElement("ul", null,
                    React.createElement("li", null,
                        React.createElement("p", null,
                            React.createElement("i", { className: isLengthy ? 'far fa-check' : 'far fa-times-circle' }),
                            "\u00A0Over 8 Characters")),
                    React.createElement("li", null,
                        React.createElement("p", null,
                            React.createElement("i", { className: hasUppercase ? 'far fa-check' : 'far fa-times-circle' }),
                            "\u00A0Uppercase Character")),
                    React.createElement("li", null,
                        React.createElement("p", null,
                            React.createElement("i", { className: hasLowercase ? 'far fa-check' : 'far fa-times-circle' }),
                            "\u00A0Lowercase Character")),
                    React.createElement("li", null,
                        React.createElement("p", null,
                            React.createElement("i", { className: hasNumber ? 'far fa-check' : 'far fa-times-circle' }),
                            "\u00A0Number")),
                    React.createElement("li", null,
                        React.createElement("p", null,
                            React.createElement("i", { className: hasSpecialCharacters
                                    ? 'far fa-check'
                                    : 'far fa-times-circle' }),
                            "\u00A0Special Character"))))));
    }
}
export default PasswordWithRules;
