import 'react-app-polyfill/ie11';
import React from 'react';
import { render } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { persistor, store } from './reducers/store';
import App from './components/App';
import Toaster from './components/shared/Toaster';
import { initializeBootstrap, initializeDevice, initializeMoment, } from './initializers';
import { QueryClient, QueryClientProvider } from 'react-query';
import { QueryClientProviderWithErrorReporting, RollbarErrorReporting, } from './components/shared/ErrorReporting';
initializeBootstrap();
initializeDevice();
initializeMoment();
const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
        },
    },
});
render(React.createElement(RollbarErrorReporting, null,
    React.createElement(QueryClientProviderWithErrorReporting, null,
        React.createElement(BrowserRouter, null,
            React.createElement(QueryClientProvider, { client: queryClient },
                React.createElement(Provider, { store: store },
                    React.createElement(PersistGate, { loading: null, persistor: persistor },
                        React.createElement(App, null),
                        React.createElement(Toaster, null))))))), document.querySelector('#app'));
