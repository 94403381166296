import { authService, familyService, stripeService } from '@ss/services';
import { debounce } from 'lodash-es';
import moment from 'moment-timezone';
import React from 'react';
import { connect } from 'react-redux';
import { Elements, StripeProvider } from 'react-stripe-elements';
import { toast } from 'react-toastify';
import { balanceActions } from '../../../actions/balanceActions';
import { stripeKey } from '../../../config';
import { membershipRenewalFee } from '../../../data';
import { CouponChecker, Modal } from '../../shared';
import MemberCancel from '../../shared/MemberCancel';
import NewCardForm from './NewCardForm';
import PayBalanceForm from './PayBalanceForm';

const fontsObj = [
  {
    cssSrc: 'https://fonts.googleapis.com/css?family=Open+Sans&display=swap',
  },
];

class BillingSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      loading: true,
      renew: false,
      showRenewMembership: false,
      piid: null,
      creating: true,
      family_id: null,
      profileFamily: {},
      personalInfo: {},
      transactions: [],
      balance: '0',
      showTransactions: false,
      hasStripeUser: false,
      changingPaymentInfo: false,
      showPayBalance: false,
      payingBalance: false,
      subscription: {},
      showingCancelMembership: false,
      cancelLoading: false,
      coupon: false,
      renewalFee: membershipRenewalFee,
      actualFee: membershipRenewalFee,
    };
  }
  componentDidMount = () => {
    this.getFamilyDetails();
    this.getTransactions();
    this.getStripeCustomer();
    this.getSubscription();
  };
  getFamilyDetails = async () => {
    try {
      const { family_id, id } = this.props.auth.user.personal_info;
      const response = await familyService.get(family_id, id);
      if (response.status === 200) {
        const { pets, children, profileFamily, personalInfo } =
          response.data.data;
        this.setState({
          profileFamily,
          pets,
          children,
          personalInfo,
        });
      }
    } catch (e) {
      console.log(e);
    }
  };
  getTransactions = async () => {
    try {
      const { family_id } = this.props.auth.user.personal_info;
      const response = await this.props.getTransactions(family_id);
    } catch (e) {
      console.log(e);
    }
  };
  getStripeCustomer = async () => {
    try {
      const { id } = this.props.auth.user;
      const response = await stripeService.get(id);
      if (response.status === 200) {
        const { user, stripeUser } = response.data.data;
        this.setState({
          stripeUser,
          user,
          hasStripeUser: true,
          changingPaymentInfo: false,
        });
      }
    } catch (e) {
      console.log(e);
    }
  };
  getSubscription = async () => {
    try {
      const { id } = this.props.auth.user;
      const response = await authService.getSubscription(id);
      if (response.status === 200) {
        const { subscription } = response.data.data;
        this.setState({
          subscription,
        });
      }
    } catch (e) {
      console.log(e);
    }
  };
  toggleTransactions = () => {
    this.setState({
      showTransactions: !this.state.showTransactions,
    });
  };
  toggleShowBalance = () => {
    this.setState({
      showPayBalance: !this.state.showPayBalance,
    });
  };
  changePaymentInfo = () => {
    this.setState({
      changingPaymentInfo: !this.state.changingPaymentInfo,
    });
  };
  requestSubscriptionCancellation = async (cancel_reason) => {
    const { id } = this.props.auth.user;
    this.setState({
      cancelLoading: true,
    });
    try {
      const response = await familyService.requestCancellation(id, {
        cancel_reason,
      });
      if (response.status === 200) {
        toast.success(
          'Hooray! Your request has been submitted! An admin will process your request shortly.'
        );
      } else {
        toast.error('Uh oh! Looks like something went wrong!');
      }

      this.setState(
        {
          showingCancelMembership: false,
          cancelLoading: false,
        },
        () => {
          window.location.reload();
        }
      );
    } catch (e) {
      console.log(e);
      toast.error('Uh oh! Looks like something went wrong!');
      this.setState({
        showingCancelMembership: false,
        cancelLoading: false,
      });
    }
  };
  removeRequestSubscriptionCancellation = async () => {
    const { id } = this.props.auth.user;
    try {
      const response = await familyService.removeRequestCancellation(id);
      if (response.status === 200) {
        toast.success(
          'Hooray! You removed your request to cancel your membership has been submitted!'
        );
      } else {
        toast.error('Uh oh! Looks like something went wrong!');
      }

      this.setState(
        {
          showingCancelMembership: false,
        },
        () => {
          window.location.reload();
        }
      );
    } catch (e) {
      console.log(e);
      toast.error('Uh oh! Looks like something went wrong!');
      this.setState({
        showingCancelMembership: false,
      });
    }
  };
  onPaymentMethodSaved = (paymentMethod) => {
    var reload = debounce(() => {
      window.location.reload();
    }, 500);
    reload();
  };
  onSuccessfulPayment = () => {
    this.setState(
      {
        payingBalance: false,
        showPayBalance: false,
      },
      this.getTransactions
    );
  };
  applyCouponCode = (coupon) => {
    const { type, amount } = coupon;
    const { renewalFee } = this.state;
    let actualFee = renewalFee;
    if (type === 'percentage') {
      actualFee = renewalFee - renewalFee * amount;
    } else if (type === 'amount') {
      actualFee = renewalFee - amount;
    }
    this.setState({
      coupon,
      actualFee,
    });
  };
  renewMembership = async () => {
    this.setState({ renew: true });
    const { id } = this.props.auth.user;
    const paymentData = {
      amount: this.state.actualFee,
      type: 'subscription',
    };
    if (this.state.coupon !== false) {
      paymentData['coupon'] = this.state.coupon;
    }
    const response = await stripeService.charge(id, paymentData);
    if (response.status === 200 && response.data.payment_accepted == true) {
      toast.success(`Hooray! You have renewed your membership!`);
      this.setState({ renew: false, showRenewMembership: false });
      this.getSubscription();
    } else {
      toast.error(
        'Your card was declined. Please check your information and try again.'
      );
      this.setState({ renew: false });
    }
  };
  toggleCancelMembership = () => {
    this.setState({
      showingCancelMembership: !this.state.showingCancelMembership,
    });
  };
  render() {
    const {
      data,
      profileFamily,
      showTransactions,
      hasStripeUser,
      changingPaymentInfo,
      showPayBalance,
      subscription,
      showRenewMembership,
      renew,
      showingCancelMembership,
      renewalFee,
      actualFee,
      coupon,
    } = this.state;
    const { user } = this.props.auth;
    const { balance, transactions } = this.props.balance;

    return (
      <main className="account profile settings billing">
        <div className="profile-body">
          <h4 className="section-label">Billing Settings</h4>
          <div className="billing-section account-status">
            <h2>Account Status</h2>
            <p
              className={`balance ${
                parseFloat(balance) < 0 ? 'owed' : 'credit'
              }`}
            >
              {parseFloat(balance) < 0 ? '-' : ''}${Math.abs(balance)}&nbsp;
              {parseFloat(balance) > 0 ? 'credit available' : 'balance due'}
            </p>
            <a className="view" href="#" onClick={this.toggleTransactions}>
              {!!showTransactions ? 'Hide' : 'View'} credit/balance history
            </a>
            {!!showTransactions && (
              <table>
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Amount</th>
                    <th>Notes</th>
                  </tr>
                </thead>
                <tbody>
                  {transactions.map((transaction) => (
                    <tr key={`transaction-${transaction.id}`}>
                      <td>
                        {moment(transaction.created_at).format(
                          'MMM Do, YYYY h:mma'
                        )}
                      </td>
                      <td className="right">
                        {parseFloat(transaction.amount) < 0 ? '-' : ''}$
                        {Math.abs(transaction.amount)}
                      </td>
                      <td>{transaction.notes}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
            <br />
            {parseFloat(balance) < 0 && !!hasStripeUser && (
              <button
                className={`theme-btn primary-green`}
                onClick={this.toggleShowBalance}
              >
                <i className="far fa-check-circle"></i>&nbsp;Pay Now
              </button>
            )}
            {parseFloat(balance) < 0 && !hasStripeUser && (
              <p className="add-card-prompt">
                <a href="#" onClick={this.changePaymentInfo}>
                  Add a card
                </a>{' '}
                to pay your balance.
              </p>
            )}
            <Modal
              title="Pay Balance"
              isOpen={showPayBalance}
              closeModal={this.toggleShowBalance}
              secondaryButton={'Close'}
            >
              <div className="family-pay-balance">
                <StripeProvider apiKey={stripeKey}>
                  <Elements fonts={fontsObj}>
                    <PayBalanceForm
                      balance={balance}
                      hasStripeUser={hasStripeUser}
                      onSuccessfulPayment={this.onSuccessfulPayment}
                    />
                  </Elements>
                </StripeProvider>
              </div>
            </Modal>
          </div>
          <div className="billing-section card-on-file">
            <h2>Card On File</h2>
            {hasStripeUser && !changingPaymentInfo && (
              <div className="payment-info-row">
                <div className="icon">
                  {user.card_brand === 'visa' && (
                    <i className="fab fa-cc-visa"></i>
                  )}
                  {user.card_brand === 'mastercard' && (
                    <i className="fab fa-cc-mastercard"></i>
                  )}
                  {user.card_brand === 'discover' && (
                    <i className="fab fa-cc-discover"></i>
                  )}
                  {user.card_brand === 'amex' && (
                    <i className="fab fa-cc-amex"></i>
                  )}
                  {user.card_brand !== 'visa' &&
                    user.card_brand !== 'mastercard' &&
                    user.card_brand !== 'discover' &&
                    user.card_brand !== 'amex' && (
                      <i className="far fa-credit-card"></i>
                    )}
                </div>
                <div className="last-four">
                  <p>....&nbsp;{user.card_last_four}</p>
                </div>
                <div className="btn-wrapper">
                  <button
                    className="theme-btn small blue"
                    onClick={this.changePaymentInfo}
                  >
                    Change
                  </button>
                </div>
              </div>
            )}
            {!hasStripeUser && !changingPaymentInfo && (
              <button
                className="theme-btn small blue"
                onClick={this.changePaymentInfo}
              >
                Add Card
              </button>
            )}
            {((!!hasStripeUser && !!changingPaymentInfo) ||
              (!hasStripeUser && !!changingPaymentInfo)) && (
              <div className="new-card-form">
                <StripeProvider apiKey={stripeKey}>
                  <Elements fonts={fontsObj}>
                    <NewCardForm
                      onPaymentMethodSaved={this.onPaymentMethodSaved}
                    />
                  </Elements>
                </StripeProvider>
              </div>
            )}
          </div>
          <div className="billing-section renewal-status">
            <h2>Renewal Status</h2>
            {!subscription && !hasStripeUser && (
              <p className="add-card-prompt">
                <a href="#" onClick={this.changePaymentInfo}>
                  Add a card
                </a>{' '}
                to pay your balance.
              </p>
            )}
            {!subscription && hasStripeUser && (
              <button
                className={`theme-btn primary-green`}
                onClick={() =>
                  this.setState({
                    showRenewMembership: !showRenewMembership,
                  })
                }
              >
                <i className="far fa-check-circle"></i>
                &nbsp;Renew Membership
              </button>
            )}
            {subscription &&
              subscription.ends_at === null &&
              subscription.renews_at !== null && (
                <React.Fragment>
                  <div>
                    Renews on:{' '}
                    {moment(subscription.renews_at).format('MMMM Do, YYYY')}
                  </div>
                  <br />
                </React.Fragment>
              )}
            {subscription &&
              subscription.ends_at !== null &&
              subscription.renews_at === null && (
                <React.Fragment>
                  <div>
                    Ends on:{' '}
                    {moment(subscription.ends_at).format('MMMM Do, YYYY')}
                  </div>
                  <br />
                </React.Fragment>
              )}
            {subscription && (
              <div className="cancellation-btn">
                {subscription.ends_at === null && (
                  <button
                    onClick={this.toggleCancelMembership}
                    className="theme-btn small slate-blue"
                  >
                    Cancel Subscription
                  </button>
                )}
                {!!user.requested_subscription_cancellation && (
                  <div>
                    <p>
                      Your subscription cancellation request is being processed.
                    </p>
                    <button
                      onClick={this.removeRequestSubscriptionCancellation}
                      className="theme-btn small slate-blue"
                    >
                      Remove Subscription Cancellation Request
                    </button>
                  </div>
                )}
              </div>
            )}
          </div>
          <Modal
            title="Renew Membership"
            isOpen={showRenewMembership}
            closeModal={() =>
              this.setState({
                showRenewMembership: !showRenewMembership,
              })
            }
            secondaryButton={'Close'}
          >
            <div className="family-pay-balance">
              <StripeProvider apiKey={stripeKey}>
                <Elements fonts={fontsObj}>
                  <div>
                    <CouponChecker
                      className="subscription"
                      type="subscription"
                      applyCouponCode={this.applyCouponCode}
                    />
                    <div className="payment-info-wrapper">
                      <h3>Payment information</h3>
                      <div className="line-item">
                        <p>Membership Fee</p>
                        <p>${parseFloat(renewalFee).toFixed(2)}</p>
                      </div>
                      {coupon !== false && (
                        <div className="line-item">
                          <p>Coupon</p>
                          <p>
                            {coupon.type === 'percentage'
                              ? coupon.amount * 100 + '%'
                              : '$' + coupon.amount}{' '}
                            off
                          </p>
                        </div>
                      )}
                      {coupon !== false && (
                        <div className="line-item">
                          <p>Charged Today</p>
                          <p>${parseFloat(actualFee).toFixed(2)}</p>
                        </div>
                      )}
                    </div>
                    <div className="payment-info-row">
                      <div className="icon">
                        {user.card_brand === 'visa' && (
                          <i className="fab fa-cc-visa"></i>
                        )}
                        {user.card_brand === 'mastercard' && (
                          <i className="fab fa-cc-mastercard"></i>
                        )}
                        {user.card_brand === 'discover' && (
                          <i className="fab fa-cc-discover"></i>
                        )}
                        {user.card_brand === 'amex' && (
                          <i className="fab fa-cc-amex"></i>
                        )}
                        {user.card_brand !== 'visa' &&
                          user.card_brand !== 'mastercard' &&
                          user.card_brand !== 'discover' &&
                          user.card_brand !== 'amex' && (
                            <i className="far fa-credit-card"></i>
                          )}
                      </div>
                      <div className="last-four">
                        <p>
                          ....&nbsp;
                          {user.card_last_four}
                        </p>
                      </div>
                    </div>
                    <div className="form-field button submit">
                      <button
                        type="submit"
                        className="theme-btn primary-green"
                        disabled={this.state.renew}
                        onClick={() => this.renewMembership()}
                      >
                        <i className="far fa-check-circle"></i>
                        {this.state.renew && (
                          <i className="fas fa-spinner fa-spin"></i>
                        )}
                        Renew
                      </button>
                    </div>
                  </div>
                </Elements>
              </StripeProvider>
            </div>
          </Modal>
          {!!showingCancelMembership && (
            <MemberCancel
              cancelMembership={this.requestSubscriptionCancellation}
              toggleCancelMembership={this.toggleCancelMembership}
            />
          )}
        </div>
      </main>
    );
  }
}

function mapState(state) {
  return {
    auth: state.auth,
    balance: state.balance,
  };
}

const actionCreators = {
  getTransactions: balanceActions.getTransactions,
};

export default connect(mapState, actionCreators)(BillingSettings);
