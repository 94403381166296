import { buildApiUrl } from '../helpers/buildApiUrl';
import httpService from './httpService';
const get = (id, piid = null) => {
    let apiUrl = buildApiUrl(`/personalInfo/details/${id}`);
    if (piid !== null) {
        apiUrl += `/${piid}`;
    }
    return httpService.get(apiUrl);
};
const store = (data, headers, type = 'primary') => {
    const apiUrl = buildApiUrl(`/personalInfo/store/${type}`);
    return httpService.post(apiUrl, data, headers);
};
const update = (id, data, headers) => {
    const apiUrl = buildApiUrl(`/personalInfo/update/${id}`);
    return httpService.post(apiUrl, data, headers);
};
const deleteMember = (id, data, headers) => {
    const apiUrl = buildApiUrl(`/personalInfo/delete/${id}`);
    return httpService.delete(apiUrl, {}, headers);
};
export default {
    get,
    update,
    store,
    delete: deleteMember,
};
