import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';

import { authActions } from '../../../actions/authActions';
import SitterAvailabilityForm from '../../Forms/SitterAvailabilityForm';
import sitterService from '../../../services/sitterService';
import { FormStepTracker } from '../../shared';
import trackerSteps from './trackerSteps.json';
import { mapAuthState } from '../../../helpers/mapAuthState';

class Availability extends SitterAvailabilityForm {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      availability: [],
      loading: true,
      numMonths: 3,
      months: [moment(), moment().add(1, 'months'), moment().add(2, 'months')],
      id: null,
      dirty: false,
    };
  }
  componentDidMount = () => {
    if (this.props.windowWidth >= 1200 && this.state.numMonths != 3) {
      this.setState({
        numMonths: 3,
        months: [
          moment(),
          moment().add(1, 'months'),
          moment().add(2, 'months'),
        ],
      });
    } else if (this.props.windowWidth < 1200 && this.state.numMonths != 1) {
      this.setState({
        numMonths: 1,
        months: [moment()],
      });
    }

    window.onbeforeunload = () => {
      const { dirty } = this.state;
      return dirty
        ? 'Changes will be LOST if you navigate away from the page. Are you sure you want to proceed?'
        : null;
    };
  };
  componentWillUnmount = () => {
    window.onbeforeunload = () => {
      return null;
    };
  };
  componentDidUpdate = (prevProps, prevState) => {
    const prevData = prevState.data;
    const { data } = this.state;
    if (this.props.windowWidth != prevProps.windowWidth) {
      if (this.props.windowWidth >= 1200 && this.state.numMonths != 3) {
        this.setState({
          numMonths: 3,
          months: [
            moment(),
            moment().add(1, 'months'),
            moment().add(2, 'months'),
          ],
        });
      } else if (this.props.windowWidth < 1200 && this.state.numMonths != 1) {
        this.setState({
          numMonths: 1,
          months: [moment()],
        });
      }
    }

    if (
      data['recurring_start_time'] !== null &&
      prevData['recurring_start_time'] !== data['recurring_start_time']
    ) {
      this.filterEndTimes();
    }
  };
  doSubmit = async (callback) => {
    try {
      await this.submitFormData(callback);
    } catch (e) {
      console.log(e);
      callback();
    }
  };
  postSubmit = async () => {
    try {
      const apiData = { ...this.state.data };
      const { id } = this.props.auth.user.personal_info;

      const response = await this.props.updateUser(
        `/profileSitter/update/${id}`,
        apiData
      );

      if (response.status === 200) {
        this.advanceStep();
      }
    } catch (e) {
      console.log(e);
    }
  };
  advanceStep = async (e) => {
    if (!!e) {
      e.preventDefault();
    }
    try {
      const { id } = this.props.auth.user;
      const response = await this.props.updateUserStep(
        'sitter-availability',
        id
      );
    } catch (e) {
      console.log(e);
    }
  };
  render() {
    const { data } = this.state;
    const { user } = this.props.auth;

    return (
      <div className="registration sitter step-6 availability">
        <div className="form" style={{ maxWidth: 1200 }}>
          <div className="inner">
            <FormStepTracker steps={trackerSteps} currentStep={3} />
            <div className="form-header">
              <h2>Set your schedule</h2>
              {this.renderForm(false)}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const actionCreators = {
  updateUserStep: authActions.updateUserStep,
  updateUser: authActions.updateUser,
};

export default connect(mapAuthState, actionCreators)(Availability);
