import { useRollbar } from '@rollbar/react';
import { useCallback } from 'react';
export function useReportError() {
    const rollbar = useRollbar(); // eslint-disable-line @typescript-eslint/no-unsafe-call
    return useCallback((message, error) => {
        if (process.env.NODE_ENV === 'production') {
            rollbar.error(message, error);
        }
        else {
            console.error({ message, error }); // eslint-disable-line no-console,@typescript-eslint/no-unsafe-assignment
        }
    }, [rollbar]);
}
