import React from 'react';
class SystemNotices extends React.Component {
    state = {
        closedNotices: [],
    };
    componentDidMount() {
        const rawClosedNotices = localStorage.getItem('closedNotices');
        if (rawClosedNotices) {
            const closedNotices = rawClosedNotices
                .split(',')
                .map((notice) => Number(notice));
            this.setState({
                closedNotices,
            });
        }
    }
    getClassName = (type) => {
        return [type, 'notice'].join(' ');
    };
    closeSystemNotice = (id) => {
        this.setState((prevState) => {
            const { closedNotices } = prevState;
            closedNotices.push(id);
            localStorage.setItem('closedNotices', closedNotices.join(','));
            return {
                ...prevState,
                closedNotices,
            };
        });
    };
    render() {
        const { closedNotices } = this.state;
        if (closedNotices.length === this.props.systemNotices.length) {
            return React.createElement("div", null);
        }
        return (React.createElement("div", { className: "notice-container" }, this.props.systemNotices.map((notice) => {
            if (closedNotices.includes(notice.id) === false) {
                return (React.createElement("div", { className: this.getClassName(notice.status), key: `notice-${notice.id}` },
                    React.createElement("a", { href: "#", className: "close-notice", onClick: () => {
                            this.closeSystemNotice(notice.id);
                        } },
                        React.createElement("i", { className: "far fa-times-circle" })),
                    React.createElement("h3", { className: "info" },
                        React.createElement("i", { className: "fas fa-exclamation-circle" }),
                        " Important Notice"),
                    React.createElement("h2", { className: "title" }, notice.name),
                    React.createElement("p", null,
                        " ",
                        notice.notice)));
            }
        })));
    }
}
export default SystemNotices;
