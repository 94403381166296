import moment from 'moment-timezone';
const displayAge = (dob, yearsSuffix = 'Years Old', monthsSuffix = 'Months Old') => {
    if (!!dob) {
        const childDob = moment(dob, 'YYYY-MM-DD');
        const age = moment().diff(childDob, 'years');
        if (age > 0) {
            return `${age} ${yearsSuffix}`;
        }
        else {
            const months = moment().diff(childDob, 'months');
            return `${months} ${monthsSuffix}`;
        }
    }
    return 'N/A';
};
export default displayAge;
