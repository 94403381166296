export default [
    { name: '15 min', id: 15 },
    { name: '30 min', id: 30 },
    { name: '45 min', id: 45 },
    { name: '1 hour', id: 60 },
    { name: '1h 15m', id: 75 },
    { name: '1h 30m', id: 90 },
    { name: '1h 45m', id: 105 },
    { name: '2 hours', id: 120 },
];
