import { GridColumnMenuCheckboxFilter, GridColumnMenuFilter, } from '@progress/kendo-react-grid';
import { get } from 'lodash-es';
import moment from 'moment-timezone';
import React from 'react';
import { Link } from 'react-router-dom';
export const ColumnMenu = (props) => {
    return (React.createElement("div", null,
        React.createElement(GridColumnMenuFilter, { ...props, expanded: true })));
};
export const ColumnMenuCheckboxFilter = (props) => {
    return (React.createElement("div", null,
        React.createElement(GridColumnMenuCheckboxFilter, { ...props, data: props.sitters, expanded: true })));
};
export const ManageCell = (props) => {
    const id = get(props.dataItem, props.field);
    return (React.createElement("td", { className: "manage-cell" },
        React.createElement(Link, { className: "theme-btn blue small", to: `/appointments/view/${id}` }, "Manage")));
};
export const DateTimeCell = (props) => {
    const rawDate = props.dataItem[props.field];
    let date;
    if (props.field === 'date') {
        date = moment(rawDate, 'YYYY-MM-DD').format('M/D/YYYY');
    }
    if (props.field === 'start_time') {
        date = moment(rawDate, 'HH:mm:ss').format('h:mm a');
    }
    if (props.field === 'end_time') {
        date = moment(rawDate, 'HH:mm:ss').format('h:mm a');
    }
    if (props.field === 'cancelled_at') {
        date = moment(rawDate).format('M/D [@] h:mm a');
    }
    return React.createElement("td", { className: "manage-cell" }, date);
};
export const BooleanCell = (props) => {
    const value = props.dataItem[props.field];
    const display = Number.isNaN(Number.parseFloat(value)) === false ? 'Yes' : 'No';
    return React.createElement("td", { className: "manage-cell" }, display);
};
