export default [
    {
        name: 'Coral',
        class: 'primary-coral',
    },
    {
        name: 'Green',
        class: 'primary-green',
    },
    {
        name: 'Blue',
        class: 'brand-blue',
    },
    {
        name: 'Purple',
        class: 'primary-purple',
    },
    {
        name: 'Red',
        class: 'color-nov',
    },
    {
        name: 'Alt Red',
        class: 'color-alt-red',
    },
];
