import axios from 'axios';
import { toast } from 'react-toastify';
import { authActions } from '../actions/authActions';
import { baseUrl } from '../config';
axios.defaults.headers['X-Requested-With'] = 'XMLHttpRequest';
const instance = axios.create({
    baseURL: baseUrl,
});
instance.interceptors.response.use(null, (error) => {
    const expectedError = error.response &&
        error.response.status >= 400 &&
        error.response.status < 500;
    if (!expectedError) {
        toast.error('An unexpected error occurred.');
    }
    // unauthorized
    if (error.response && error.response.status === 401) {
        // logging person out
        toast.dismiss();
        toast.error('Unauthorized');
        authActions.logout();
        window.open('/login', '_self');
    }
    // dealing with laravel errors
    if (error.response && error.response.status === 422) {
        // all laravel responses
        const errors = error.response.data.errors;
        for (const errorGroup of Object.values(errors)) {
            for (const errorMessage of errorGroup) {
                toast.error(errorMessage);
            }
        }
    }
    if (error.response && error.response.status === 400) {
        console.log(error.response.data.message);
    }
    return Promise.reject(error);
});
const setToken = (token) => {
    instance.defaults.headers.Authorization = 'Bearer ' + token;
};
const clearToken = () => {
    if (instance.defaults.headers.Authorization) {
        delete instance.defaults.headers.Authorization;
    }
};
function request(method, url, data, headers) {
    if (method === 'get') {
        return instance.request({
            method,
            url,
            data,
            params: data,
            headers,
        });
    }
    return instance.request({
        method,
        url,
        data,
        headers,
    });
}
function getReq(url, params, headers) {
    return request('get', url, params, headers);
}
function postReq(url, params, headers) {
    return request('post', url, params, headers);
}
function putReq(url, params, headers) {
    return request('put', url, params, headers);
}
function deleteReq(url, params, headers) {
    return request('delete', url, params, headers);
}
export default {
    request,
    setToken,
    clearToken,
    get: getReq,
    post: postReq,
    put: putReq,
    delete: deleteReq,
};
