import React, { useEffect } from 'react';
const Page = (props) => {
    const { menuOpen, detailOpen, id, header, children, footer } = props;
    useEffect(() => {
        document.body.classList.toggle('menu-open', menuOpen ?? false);
        document.body.classList.toggle('appointment-open', detailOpen ?? false);
    }, [menuOpen, detailOpen]);
    return (React.createElement("div", { id: id, className: "section" },
        header,
        children,
        footer));
};
export default Page;
