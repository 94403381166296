import { buildApiUrl } from '../helpers/buildApiUrl';
import httpService from './httpService';
const getNotifications = async () => {
    const response = await httpService.get(buildApiUrl('/notifications'));
    return response.data.data.notifications;
};
const ignoreNotification = async (id) => {
    await httpService.delete(buildApiUrl(`/notifications/delete/${id}`));
};
const ignoreAllNotifications = async () => {
    await httpService.post(buildApiUrl('/notifications/clear'));
};
export default {
    getNotifications,
    ignoreNotification,
    ignoreAllNotifications,
};
