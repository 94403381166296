import Joi from 'joi';
import React from 'react';
import { joiWordCountHelper } from '../../helpers';
import httpService from '../../services/httpService';
import sitterService from '../../services/sitterService';
import Form from '../shared/Form';
class SitterPublicProfileForm extends Form {
    schema = Joi.object({
        avatar_filename: Joi.alternatives(Joi.string(), Joi.object())
            .required()
            .label('Avatar'),
        profile_color: Joi.string()
            .optional()
            .allow(null)
            .allow('')
            .label('Profile Color'),
        intro: Joi.string()
            .custom((value, helpers) => {
            return joiWordCountHelper(value, helpers, 50);
        }, 'custom validation')
            .required()
            .messages({
            'length.exceeds': 'Your Intro cannot be more than 50 words.',
        })
            .label('Intro'),
        bio: Joi.string()
            .custom((value, helpers) => {
            return joiWordCountHelper(value, helpers, 200);
        }, 'custom validation')
            .required()
            .messages({
            'length.exceeds': 'Your Bio cannot be more than 200 words.',
        })
            .label('Bio'),
        vehicle_year: Joi.string().required().label('Vehicle Year'),
        vehicle_make: Joi.string().required().label('Vehicle Make'),
        vehicle_model: Joi.string().required().label('Vehicle Model'),
        vehicle_seatbelts: Joi.number().required().label('Vehicle Seatbelts'),
        selected_age_ranges: Joi.array().required().label('Selected Age Ranges'),
        selected_activities: Joi.array().optional().label('Selected Activities'),
        languages: Joi.array().optional().label('Conversational Languages'),
    });
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            data: {
                profile_color: 'primary-coral',
                avatar_filename: '',
                intro: '',
                bio: '',
                selected_age_ranges: [],
                selected_activities: [],
                vehicle_make: '',
                vehicle_model: '',
                vehicle_year: '',
                vehicle_seatbelts: '',
                languages: [],
            },
            acceptedAgeRanges: [],
            activityOptions: [],
            availableLanguages: [],
            errors: {},
            user: null,
        };
    }
    getAcceptedAgeRanges = async () => {
        try {
            const response = await httpService.get('/api/acceptedAgeRanges');
            if (response.status === 200) {
                const acceptedAgeRanges = response.data.data.acceptedAgeRanges.map((info) => ({
                    ...info,
                    value: info.id,
                    label: info.name,
                }));
                this.setState({ acceptedAgeRanges });
            }
        }
        catch (error) {
            console.log(error);
        }
    };
    getActivityOptions = async () => {
        try {
            const response = await httpService.get('/api/activityOptions');
            if (response.status === 200) {
                const activityOptions = response.data.data.activityOptions.map((info) => ({
                    ...info,
                    value: info.id,
                    label: info.name,
                }));
                this.setState({ activityOptions });
            }
        }
        catch (error) {
            console.log(error);
        }
    };
    getAvailableLanguages = async () => {
        const response = await httpService.get('/api/languages');
        if (response.status === 200) {
            const languages = response.data.data.languages.map((info) => ({
                ...info,
                value: info.id,
                label: info.language,
            }));
            this.setState({
                availableLanguages: languages,
            });
        }
    };
    handleProfileColorChange = (colorClass) => {
        this.setState((prevState) => ({
            ...prevState,
            data: {
                ...prevState.data,
                profile_color: colorClass,
            },
        }));
    };
    submitFormData = async () => {
        try {
            const apiData = { ...this.state.data };
            const { id } = this.state;
            const data = new FormData();
            const headers = {
                headers: { 'content-type': 'multipart/form-data' },
            };
            data.append('_method', 'post');
            for (const key of Object.keys(apiData)) {
                if (typeof apiData[key] === 'object') {
                    if (key === 'avatar_filename') {
                        if (apiData[key] !== null) {
                            data.append(key, apiData[key], 'image.jpg');
                        }
                    }
                    else {
                        data.append(key, JSON.stringify(apiData[key]));
                    }
                }
                else {
                    data.append(key, apiData[key]);
                }
            }
            return await sitterService.update(id, data, headers);
        }
        catch (error) {
            return error;
        }
    };
    renderForm = () => {
        const { acceptedAgeRanges, activityOptions, availableLanguages } = this.state;
        return (React.createElement("div", { className: "sitter-public-profile-form" },
            this.renderInput('intro', 'Introduce yourself to our families.', 'text', true, { wordCount: 50 }),
            this.renderTextarea('bio', 'Write a short bio about yourself and child care history.', true, { wordCount: 200 }),
            this.renderCheckbox('selected_age_ranges', 'Ages of kids I can work with?', acceptedAgeRanges),
            this.renderCheckbox('selected_activities', 'Description of Skills and Activities', activityOptions),
            React.createElement("hr", { className: "divider" }),
            this.renderInput('vehicle_year', 'Vehicle Year', 'number', true),
            this.renderInput('vehicle_make', 'Vehicle Make', 'text', true),
            this.renderInput('vehicle_model', 'Vehicle Model', 'text', true),
            this.renderInput('vehicle_seatbelts', 'Number of back seats with seatbelts', 'number', true),
            React.createElement("hr", { className: "divider" }),
            this.renderCheckbox('languages', 'In addition to English, I can also speak conversational:', availableLanguages)));
    };
}
export default SitterPublicProfileForm;
