import { buildApiUrl } from '../helpers/buildApiUrl';
import httpService from './httpService';
const get = (id) => {
    const apiUrl = buildApiUrl(`/profileChild/${id}`);
    return httpService.get(apiUrl);
};
const store = (data, headers) => {
    const apiUrl = buildApiUrl('/profileChild/store');
    return httpService.post(apiUrl, data, headers);
};
const update = (id, data, headers) => {
    const apiUrl = buildApiUrl(`/profileChild/update/${id}`);
    return httpService.post(apiUrl, data, headers);
};
const deleteChild = (id, data, headers) => {
    const apiUrl = buildApiUrl(`/profileChild/delete/${id}`);
    return httpService.delete(apiUrl, {}, headers);
};
export default {
    get,
    store,
    update,
    delete: deleteChild,
};
