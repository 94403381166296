import moment from 'moment-timezone';
import React from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { mapAuthState } from '../../../helpers/mapAuthState';
import sitterService from '../../../services/sitterService';
import SitterAvailabilityForm from '../../Forms/SitterAvailabilityForm';
class ProviderAvailability extends SitterAvailabilityForm {
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            availability: [],
            loading: true,
            numMonths: 3,
            months: [moment(), moment().add(1, 'months'), moment().add(2, 'months')],
            id: null,
            dirty: false,
        };
    }
    handleBeforeUnload = () => {
        const { dirty } = this.state;
        return dirty
            ? 'Changes will be LOST if you navigate away from the page. Are you sure you want to proceed?'
            : null;
    };
    componentDidMount = () => {
        this.getSitter();
        if (this.props.windowWidth >= 1200 && this.state.numMonths !== 3) {
            this.setState({
                numMonths: 3,
                months: [
                    moment(),
                    moment().add(1, 'months'),
                    moment().add(2, 'months'),
                ],
            });
        }
        else if (this.props.windowWidth < 1200 && this.state.numMonths !== 1) {
            this.setState({
                numMonths: 1,
                months: [moment()],
            });
        }
        window.addEventListener('beforeunload', this.handleBeforeUnload);
    };
    componentWillUnmount = () => {
        window.removeEventListener('beforeunload', this.handleBeforeUnload);
    };
    componentDidUpdate = (prevProps, prevState) => {
        const prevData = prevState.data;
        const { data, numMonths } = this.state;
        if (this.props.windowWidth !== prevProps.windowWidth) {
            if (this.props.windowWidth >= 1200 && numMonths !== 3) {
                this.setState({
                    numMonths: 3,
                    months: [
                        moment(),
                        moment().add(1, 'months'),
                        moment().add(2, 'months'),
                    ],
                });
            }
            else if (this.props.windowWidth < 1200 && numMonths !== 1) {
                this.setState({
                    numMonths: 1,
                    months: [moment()],
                });
            }
        }
        if (data.recurring_start_time !== null &&
            prevData.recurring_start_time !== data.recurring_start_time) {
            this.filterEndTimes();
        }
    };
    getSitter = async () => {
        try {
            const { id } = this.props.match.params;
            const response = await sitterService.get(id);
            this.formatData(response.data.data);
        }
        catch (error) {
            console.log(error);
        }
    };
    formatData = ({ availability, personalInfo, profileSitter, }) => {
        const { data } = this.state;
        const timeslotAvailability = [];
        // checking if they have their profiles fully filled out
        const required = [];
        if (personalInfo.DOB === null) {
            required.push('Date of Birth in Account Settings');
        }
        if (personalInfo.address1 === null) {
            required.push('Private Profile in My Profile');
        }
        if (profileSitter.bio === null) {
            required.push('Public Profile in My Profile');
        }
        if (required.length > 0) {
            toast.error('This sitter still needs to fill out their: ' + required.join(', '));
        }
        // set up timeslots
        for (const value of availability) {
            if (timeslotAvailability.length > 0) {
                let foundIndex = -1;
                for (const [i] of timeslotAvailability.entries()) {
                    if (timeslotAvailability[i]?.date === value.date) {
                        foundIndex = i;
                    }
                }
                if (foundIndex !== -1) {
                    timeslotAvailability[foundIndex] = {
                        ...timeslotAvailability[foundIndex],
                        timeslots: [
                            ...timeslotAvailability[foundIndex].timeslots,
                            {
                                start_time: value.start_time,
                                end_time: value.end_time,
                            },
                        ],
                    };
                }
                else {
                    timeslotAvailability.push({
                        ...value,
                        timeslots: [
                            {
                                start_time: value.start_time,
                                end_time: value.end_time,
                            },
                        ],
                    });
                }
            }
            else {
                timeslotAvailability.push({
                    ...value,
                    timeslots: [
                        {
                            start_time: value.start_time,
                            end_time: value.end_time,
                        },
                    ],
                });
            }
        }
        data.sitterAvailability = timeslotAvailability;
        data.availability_notes = profileSitter.availability_notes;
        this.setState({
            data,
            id: personalInfo.id,
        });
    };
    doSubmit = async (callback) => {
        try {
            await this.submitFormData();
        }
        catch (error) {
            console.log(error);
            callback();
        }
    };
    render() {
        return (React.createElement("main", { className: "account profile availability" },
            React.createElement("div", { className: "profile-body" },
                React.createElement("h2", null, "Availability Schedule"),
                this.renderForm())));
    }
}
export default connect(mapAuthState)(ProviderAvailability);
