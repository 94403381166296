import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { balanceActions } from '../../actions/balanceActions';
import authService from '../../services/authService';
import { publicSiteUrl } from '@ss/config';

class SelectFlow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      //
    };
  }
  componentDidMount() {
    this.getBalance();
    this.checkSub();
  }
  componentDidUpdate(prevProps, prevState) {
    const prevBalance = prevProps.balance.balance;
    const { balance } = this.props.balance;
    if (parseFloat(balance) < 0) {
      toast.error(
        `Uh oh! Looks like you have an outstanding balance of $${parseFloat(
          Math.abs(balance)
        )}. You must pay this before you are allowed to book appointments.`
      );
      this.props.history.push('/settings/billing');
    }
  }
  getBalance = async () => {
    try {
      const { family_id } = this.props.auth.user.personal_info;
      const response = await this.props.getBalance(family_id);
    } catch (e) {
      console.log(e);
    }
  };
  checkSub = async () => {
    try {
      const { id } = this.props.auth.user;
      const response = await authService.getSubscription(id);
      if (response.status === 200) {
        let { subscription } = response.data.data;
        if (!subscription) {
          toast.error(
            `Uh oh! Looks like your membership has expired. You must pay this before you are allowed to book appointments.`
          );
          this.props.history.push('/settings/billing');
        }
      }
    } catch (e) {
      console.log(e);
    }
  };
  render() {
    return (
      <main className="booking select-flow">
        <div className="booking-component">
          <div className="booking-component-inner">
            <div className="booking-header">
              <div className="inner">
                <h3 className="theme-h3">Select Type of Care</h3>
              </div>
            </div>
            <div className="booking-body">
              <div className="select-flow-wrapper">
                <div className="flow">
                  <i className="fas fa-calendar-star"></i>
                  <h2>Casual Care</h2>
                  <p>
                    Casual Care is best suited for Members looking for care on 1 or more dates, on an as-needed basis. Members may submit Casual requests with as little as a few hours of notice, or up to 60 days in advance!
                    <br />
                    (Example: Alternate coverage for normal care, date nights, overnights, and occasional daytime assistance. For a full list of services, visit <a href={publicSiteUrl} target="_blank">{publicSiteUrl}</a>).
                  </p>
                  <Link
                    to="/appointments/book"
                    className="theme-btn slate-blue"
                  >
                    Select
                  </Link>
                  <p className="small">
                    All appointments require payment to the provider for the Full Amount scheduled + confirmed online. In the event a member does not utilize the full amount of time, providers are still owed for the time scheduled. All appointments require a 3-hour minimum. The Full Amount Owed policy extends to all appointments booked on our platform. 
                  </p>
                </div>
                <div className="flow">
                  <i className="fas fa-calendar-alt"></i>
                  <h2>Consistent Care</h2>
                  <p>Consistent Care is best suited for members who want to create a custom schedule, for an upcoming 6-8 week period, with as few sitters as possible. Our concierge team works alongside our providers to cover each request, as efficiently as possible.</p>
                  <p>Please note:</p>
                  <p>We highly suggest completing a complimentary consultation prior to submitting a request.</p>
                  <p>We do not facilitate full time requests, or nanny requests, of any kind!</p>
                  <p>There is a required *minimum* lead time of 2-3 weeks, from the date of submission.</p>
                  <p>All requests may be subject to waitlists.</p>
                  <p>Visit <a href={`${publicSiteUrl}/consistent-care`} target="_blank" rel="noreferrer">{publicSiteUrl}/consistent-care</a> for more information!</p>
                  <Link to="/program/book" className="theme-btn slate-blue">
                    Select
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}

function mapState(state) {
  return {
    auth: state.auth,
    balance: state.balance,
  };
}

const actionCreators = {
  getBalance: balanceActions.getBalance,
};

export default connect(mapState, actionCreators)(SelectFlow);
