import feeService from '@ss/services/feeService';
import Joi from 'joi';
import React from 'react';
import { Form } from '../shared';
class PricingForm extends Form {
    schema = Joi.object({
        signup_fee: Joi.number().required().precision(2).label('Sign Up Fee'),
        regular: Joi.number().required().precision(2).label('Regular Fee'),
        regular_last_minute: Joi.number()
            .required()
            .precision(2)
            .label('Regular (last minute)'),
        regular_rush: Joi.number().required().precision(2).label('Regular (rush)'),
        overnight: Joi.number().required().precision(2).label('Overnight'),
        overnight_last_minute: Joi.number()
            .required()
            .precision(2)
            .label('Overnight (last minute)'),
        overnight_rush: Joi.number()
            .required()
            .precision(2)
            .label('Overnight (rush)'),
        additional_holiday_charge: Joi.number()
            .required()
            .precision(2)
            .label('Additional Holiday Charge'),
        errand_running: Joi.number()
            .required()
            .precision(2)
            .label('Errand Running'),
        share_regular: Joi.number().required().precision(2).label('Share Regular'),
        share_last_minute: Joi.number()
            .required()
            .precision(2)
            .label('Share (last minute)'),
        share_rush: Joi.number().required().precision(2).label('Share (rush)'),
        local_partnership_6_14_sitters: Joi.number()
            .required()
            .precision(2)
            .label('Local Partnership (6-14 Sitters)'),
        local_partnership_15_20_sitters: Joi.number()
            .required()
            .precision(2)
            .label('Local Partnership (15-20 Sitters)'),
        out_of_town_guest: Joi.number()
            .required()
            .precision(2)
            .label('Out of Town Guest'),
        out_of_town_guest_sit: Joi.number()
            .required()
            .precision(2)
            .label('Out of Town Guest Sit'),
        mileage_rate: Joi.number()
            .required()
            .precision(2)
            .label('Mileage Rate Per Mile'),
        mileage_min: Joi.number().required().precision(2).label('Mileage Minimum'),
        one_child_per_hour: Joi.number()
            .required()
            .precision(2)
            .label('1 Child - Rate/Hr'),
        two_three_children_per_hour: Joi.number()
            .required()
            .precision(2)
            .label('2-3 Children - Rate/Hr'),
        four_plus_children_per_hour: Joi.number()
            .required()
            .precision(2)
            .label('4+ Children - Rate/Hr'),
        surge_pricing: Joi.number()
            .required()
            .precision(2)
            .label('Surge Pricing (Sat. Evenings)'),
        special_event: Joi.number()
            .required()
            .precision(2)
            .label('Special Event Add - Rate/Hr'),
        sick_child: Joi.number()
            .required()
            .precision(2)
            .label('Sick Child Add - Rate/Hr'),
        holiday: Joi.number()
            .required()
            .precision(2)
            .label('Holiday Add - Rate/Hr'),
        share: Joi.number().required().precision(2).label('Share Add - Rate/Hr'),
        program_sit: Joi.number()
            .required()
            .precision(2)
            .label('Program Sit - Rate/Hr'),
        local_partner_sit: Joi.number()
            .required()
            .precision(2)
            .label('Local Partner Sit - Rate/Hr'),
        normal_cancel: Joi.number().required().precision(2).label('Normal Cancel'),
        twentyfour_hour_cancel: Joi.number()
            .required()
            .precision(2)
            .label('24 Hour Cancel'),
        twelve_hour_sit: Joi.number()
            .required()
            .precision(2)
            .label('24 Hour Cancel'),
        cancel_overnight: Joi.number()
            .required()
            .precision(2)
            .label('Cancel Overnight'),
        cancel_without_calling: Joi.number()
            .required()
            .precision(2)
            .label('Cancel Without Calling'),
        two_days_one_sit: Joi.number().required().precision(2).label('2 Days'),
        three_days_one_sit: Joi.number().required().precision(2).label('3 Days'),
        four_days_one_sit: Joi.number().required().precision(2).label('4 Days'),
        five_days_one_sit: Joi.number().required().precision(2).label('5 Days'),
        two_days_two_sits: Joi.number().required().precision(2).label('2 Days'),
        three_days_two_sits: Joi.number().required().precision(2).label('3 Days'),
        four_days_two_sits: Joi.number().required().precision(2).label('4 Days'),
        five_days_two_sits: Joi.number().required().precision(2).label('5 Days'),
    });
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            data: {
                signup_fee: '0.00',
                regular: '0.00',
                regular_last_minute: '0.00',
                regular_rush: '0.00',
                overnight: '0.00',
                overnight_last_minute: '0.00',
                overnight_rush: '0.00',
                additional_holiday_charge: '0.00',
                errand_running: '0.00',
                share_regular: '0.00',
                share_last_minute: '0.00',
                share_rush: '0.00',
                local_partnership_6_14_sitters: '0.00',
                local_partnership_15_20_sitters: '0.00',
                out_of_town_guest: '0.00',
                mileage_rate: '0.00',
                mileage_min: '0.00',
                one_child_per_hour: '0.00',
                two_three_children_per_hour: '0.00',
                four_plus_children_per_hour: '0.00',
                surge_pricing: '0.00',
                special_event: '0.00',
                sick_child: '0.00',
                holiday: '0.00',
                share: '0.00',
                program_sit: '0.00',
                local_partner_sit: '0.00',
                out_of_town_guest_sit: '0.00',
                twelve_hour_sit: '0.00',
                normal_cancel: '0.00',
                twentyfour_hour_cancel: '0.00',
                cancel_overnight: '0.00',
                cancel_without_calling: '0.00',
                two_days_one_sit: '0.00',
                three_days_one_sit: '0.00',
                four_days_one_sit: '0.00',
                five_days_one_sit: '0.00',
                two_days_two_sits: '0.00',
                three_days_two_sits: '0.00',
                four_days_two_sits: '0.00',
                five_days_two_sits: '0.00',
            },
            errors: {},
            submitting: false,
        };
    }
    componentDidMount = () => {
        this.getPricing();
    };
    getPricing = async () => {
        try {
            const response = await feeService.get();
            if (response.status === 200) {
                const { data } = response.data;
                this.formatData(data.fees);
            }
        }
        catch (error) {
            console.log(error);
        }
    };
    formatData = (fees) => {
        const data = fees.reduce((acc, current) => {
            acc[current.slug] = current.amount;
            return acc;
        }, {});
        this.setState({ data });
    };
    submitFormData = () => {
        const { data } = this.state;
        return feeService.bulkUpdate({ fees: data });
    };
    renderForm(type) {
        return (React.createElement("div", { className: "form-group justify-space-between" },
            type === 'regular' && (React.createElement(React.Fragment, null,
                React.createElement("h3", null, "Membership Fee"),
                this.renderInput('signup_fee', 'Sign Up Fee', 'number', true, {
                    step: 0.01,
                }),
                React.createElement("h3", null, "Placement Fees"),
                this.renderInput('regular', 'Regular Fee', 'number', true),
                this.renderInput('regular_last_minute', 'Regular (last minute)', 'number', true),
                this.renderInput('regular_rush', 'Regular (rush)', 'number', true),
                this.renderInput('overnight', 'Overnight', 'number', true),
                this.renderInput('overnight_last_minute', 'Overnight (last minute)', 'number', true),
                this.renderInput('overnight_rush', 'Overnight (rush)', 'number', true),
                this.renderInput('additional_holiday_charge', 'Additional Holiday Charge', 'number', true),
                this.renderInput('errand_running', 'Errand Running', 'number', true),
                this.renderInput('share_regular', 'Share Regular', 'number', true),
                this.renderInput('share_last_minute', 'Share (last minute)', 'number', true),
                this.renderInput('share_rush', 'Share (rush)', 'number', true),
                this.renderInput('local_partnership_6_14_sitters', 'Local Partnership (6-14 Sitters)', 'number', true),
                this.renderInput('local_partnership_15_20_sitters', 'Local Partnership (15-20 Sitters)', 'number', true),
                this.renderInput('out_of_town_guest', 'Out of Town Guest', 'number', true),
                this.renderInput('mileage_rate', 'Mileage Rate Per Mile', 'number', true),
                this.renderInput('mileage_min', 'Mileage Minimum', 'number', true),
                React.createElement("h3", null, "Mileage"),
                this.renderInput('mileage_rate', 'Mileage Rate Per Mile', 'number', true),
                this.renderInput('mileage_min', 'Mileage Minimum', 'number', true),
                React.createElement("h3", null, "Sitter Rates"),
                this.renderInput('one_child_per_hour', '1 Child - Rate/Hr', 'number', true),
                this.renderInput('two_three_children_per_hour', '2-3 Children - Rate/Hr', 'number', true),
                this.renderInput('four_plus_children_per_hour', '4+ Children - Rate/Hr', 'number', true),
                this.renderInput('surge_pricing', 'Surge Pricing (Sat. Evenings)', 'number', true),
                this.renderInput('special_event', 'Special Event Add - Rate/Hr', 'number', true),
                this.renderInput('sick_child', 'Sick Child Add - Rate/Hr', 'number', true),
                this.renderInput('holiday', 'Holiday Add - Rate/Hr', 'number', true),
                this.renderInput('share', 'Share Add - Rate/Hr', 'number', true),
                this.renderInput('program_sit', 'Program Sit - Rate/Hr', 'number', true),
                this.renderInput('local_partner_sit', 'Local Partner Sit', 'number', true),
                this.renderInput('out_of_town_guest_sit', 'Out of Town Guest', 'number', true),
                this.renderInput('twelve_hour_sit', 'For 12 hour Sit', 'number', true),
                React.createElement("h3", null, "Cancellation Rates"),
                this.renderInput('normal_cancel', 'Normal Cancel', 'number', true),
                this.renderInput('twentyfour_hour_cancel', 'Mileage Mininum', 'number', true),
                this.renderInput('cancel_overnight', 'Cancel Overnight', 'number', true),
                this.renderInput('cancel_without_calling', 'Cancel Without Calling', 'number', true))),
            type === 'bulk' && (React.createElement(React.Fragment, null,
                React.createElement("h3", null, "Bulk Booking Sit Fees (Single Sit Per Day)"),
                this.renderInput('two_days_one_sit', '2 Days', 'number', true),
                this.renderInput('three_days_one_sit', '3 Days', 'number', true),
                this.renderInput('four_days_one_sit', '4 Days', 'number', true),
                this.renderInput('five_days_one_sit', '5 Days', 'number', true),
                React.createElement("h3", null, "Bulk Booking Sit Fees (2 Sits Per Day)"),
                this.renderInput('two_days_two_sits', '2 Days', 'number', true),
                this.renderInput('three_days_two_sits', '3 Days', 'number', true),
                this.renderInput('four_days_two_sits', '4 Days', 'number', true),
                this.renderInput('five_days_two_sits', '5 Days', 'number', true)))));
    }
}
export default PricingForm;
