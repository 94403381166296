import React from 'react';
import { connect } from 'react-redux';
import { Elements, StripeProvider } from 'react-stripe-elements';
import { stripeKey } from '../../../config';
import { mapAuthState } from '../../../helpers/mapAuthState';
import BulkBooking from './BulkBooking';

const fontsObj = [
  {
    cssSrc: 'https://fonts.googleapis.com/css?family=Open+Sans&display=swap',
  },
];

class BulkBookingWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      //
    };
  }
  onSubmit = () => {
    this.props.onSubmit(this.state.data);
  };
  render() {
    return (
      <main className="booking bulk-booking">
        <StripeProvider apiKey={stripeKey}>
          <Elements fonts={fontsObj}>
            <BulkBooking {...this.props} />
          </Elements>
        </StripeProvider>
      </main>
    );
  }
}

export default connect(mapAuthState)(BulkBookingWrapper);
