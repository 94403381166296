export default [
    {
        name: 'Profile',
        url: '/register/family/profile',
    },
    {
        name: 'Children',
        url: '/register/family/children',
    },
    {
        name: 'Pets',
        url: '/register/family/pets',
    },
    {
        name: 'Payments',
        url: '/register/family/payments',
    },
];
