import { buildApiUrl } from '@ss/helpers/buildApiUrl';
import httpService from './httpService';
const get = (user_id) => {
    const apiUrl = buildApiUrl(`/payment/get/${user_id}`);
    return httpService.get(apiUrl);
};
const update = (user_id, data) => {
    const apiUrl = buildApiUrl(`/payment/update/${user_id}`);
    return httpService.post(apiUrl, data);
};
const charge = (user_id, data) => {
    const apiUrl = buildApiUrl(`/payment/charge/${user_id}`);
    return httpService.post(apiUrl, data);
};
const payBalance = (user_id, data) => {
    const apiUrl = buildApiUrl(`/balance/charge/${user_id}`);
    return httpService.post(apiUrl, data);
};
export function chargeCustomer(endpoint, data) {
    return httpService.post(buildApiUrl(endpoint), data);
}
export default {
    chargeCustomer,
    get,
    update,
    charge,
    payBalance,
};
