import React from 'react';
import { findLinkGroupByTitle } from '../../../helpers';
import ReviewsGrid from '../ReviewsGrid/ReviewsGrid';
import Sidebar from '../Sidebar';
const linkGroup = findLinkGroupByTitle('Providers');
const ProviderReviews = (props) => {
    return (React.createElement("main", { className: "admin admin-listing with-sidebar appointments" },
        React.createElement("div", { className: "sidebar-wrapper" },
            React.createElement(Sidebar, { ...props, iconClass: "fas fa-id-card", linkGroup: linkGroup })),
        React.createElement("div", { className: "content" },
            React.createElement("h4", { className: "section-label" },
                React.createElement("i", { className: "fas fa-calendar-star" }),
                "\u00A0Provider Reviews"),
            React.createElement(ReviewsGrid, null))));
};
export default ProviderReviews;
