function makeAddressString({ address1, address2, city, state, zip }) {
    const address2Part = address2 ? ` ${address2}` : '';
    return `${address1}${address2Part}, ${city}, ${state} ${zip}`;
}
const displayAddress = (primaryModel, secondaryModel) => {
    // Displays the address of the given model.
    // Accepts a backup model to display as well in case the primary is null.
    if (primaryModel.address1 &&
        primaryModel.city &&
        primaryModel.state &&
        primaryModel.zip) {
        return makeAddressString(primaryModel);
    }
    if (secondaryModel) {
        return makeAddressString(secondaryModel);
    }
    return 'N/A';
};
export default displayAddress;
