import { Grid, GridColumn, } from '@progress/kendo-react-grid';
import moment from 'moment-timezone';
import React from 'react';
import { findLinkGroupByTitle } from '../../../helpers';
import sitterService from '../../../services/sitterService';
import DatePickerComponent from '../../shared/Form/Datepicker';
import Sidebar from '../Sidebar';
import { ColumnMenu, DateCell, ManageProvidersCell, TimeCell, } from './KendoComponents';
class AvailabilityGrid extends React.Component {
    state = {
        availability: [],
        result: [],
        loading: true,
        linkGroup: findLinkGroupByTitle('Providers'),
        dateFilter: null,
        dataState: {
            sort: [],
            filter: {
                logic: 'and',
                filters: [],
            },
            filterOperators: {
                text: [
                    {
                        text: 'grid.filterContainsOperator',
                        operator: 'contains',
                    },
                    { text: 'grid.filterEqOperator', operator: 'eq' },
                ],
            },
            skip: 0,
            take: 50,
            total: 0,
        },
    };
    componentDidMount = () => {
        this.getAvailability();
    };
    getAvailability = async () => {
        try {
            const { dataState, dateFilter } = this.state;
            const response = await sitterService.availability({
                skip: dataState.skip,
                take: dataState.take,
                sort: dataState.sort,
                filter: dataState.filter,
                date: dateFilter !== null
                    ? moment(dateFilter).format('YYYY-MM-DD')
                    : dateFilter,
            });
            if (response.status === 200) {
                const { availability, count } = response.data.data;
                this.setState({
                    result: availability,
                    dataState: {
                        ...dataState,
                        total: count,
                    },
                    availability,
                    loading: false,
                });
            }
        }
        catch {
            this.setState({
                loading: false,
            });
        }
    };
    pageChange = (event) => {
        this.setState({
            dataState: {
                ...this.state.dataState,
                skip: event.page.skip,
                take: event.page.take,
            },
        }, () => {
            this.getAvailability();
        });
    };
    sortChange = (event) => {
        this.setState({
            dataState: {
                ...this.state.dataState,
                sort: event.sort,
            },
        }, () => {
            this.getAvailability();
        });
    };
    filterChange = (event) => {
        this.setState({
            dataState: {
                ...this.state.dataState,
                filter: event.filter,
            },
        }, () => {
            this.getAvailability();
        });
    };
    datePickerChange = (value) => {
        this.setState({
            dateFilter: moment(value),
        }, () => {
            this.getAvailability();
        });
    };
    clearDate = () => {
        this.setState({
            dateFilter: null,
        }, () => {
            this.getAvailability();
        });
    };
    render() {
        const { loading, linkGroup, dataState, result, dateFilter } = this.state;
        if (loading) {
            return (React.createElement("main", { className: "admin admin-listing with-sidebar appointments" },
                React.createElement("div", { className: "sidebar-wrapper" },
                    React.createElement(Sidebar, { ...this.props, iconClass: "fas fa-id-card", linkGroup: linkGroup })),
                React.createElement("div", { className: "content" },
                    React.createElement("h4", { className: "section-label" }, "\u00A0Loading Availability..."))));
        }
        return (React.createElement("main", { className: "admin admin-listing with-sidebar appointments" },
            React.createElement("div", { className: "sidebar-wrapper" },
                React.createElement(Sidebar, { ...this.props, iconClass: "fas fa-id-card", linkGroup: linkGroup })),
            React.createElement("div", { className: "content" },
                React.createElement("h4", { className: "section-label" }, "\u00A0Provider Availability"),
                React.createElement("div", { className: "row align_items_center", style: { marginLeft: 5 } },
                    React.createElement("label", { style: { marginBottom: 24, marginRight: 10 }, className: "section-label" },
                        "Filter by Date:",
                        ' '),
                    React.createElement(DatePickerComponent, { onChange: this.datePickerChange, label: "Date", value: dateFilter, name: "filterDate" }),
                    dateFilter !== null && (React.createElement("span", { onClick: this.clearDate, className: "theme-btn blue small", style: {
                            marginLeft: 10,
                            marginBottom: 24,
                            cursor: 'pointer',
                        } }, "Clear"))),
                React.createElement("div", { className: "kendo-table" },
                    React.createElement(Grid, { data: result, pageable: true, sortable: true, ...dataState, onPageChange: this.pageChange, onSortChange: this.sortChange, onFilterChange: this.filterChange },
                        React.createElement(GridColumn, { title: "Date", field: "date", cell: DateCell }),
                        React.createElement(GridColumn, { title: "Start Time", field: "start_time", cell: TimeCell }),
                        React.createElement(GridColumn, { title: "End Time", field: "end_time", cell: TimeCell }),
                        React.createElement(GridColumn, { title: "First Name", field: "firstname", columnMenu: ColumnMenu }),
                        React.createElement(GridColumn, { title: "Last Name", field: "lastname", columnMenu: ColumnMenu }),
                        React.createElement(GridColumn, { title: "ID", field: "id", width: 60, columnMenu: ColumnMenu }),
                        React.createElement(GridColumn, { title: " ", field: "sitter_piid", width: 100, cell: (props) => (React.createElement(ManageProvidersCell, { ...props, ...this.props })) }))))));
    }
}
export default AvailabilityGrid;
