import $ from 'jquery';
import React from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { authActions } from '../../../actions/authActions';
import { pets } from '../../../data';
import httpService from '../../../services/httpService';
import { FormStepTracker } from '../../shared';
import Form from '../../shared/Form';
import trackerSteps from './trackerSteps';

import Joi from 'joi';
import { mapAuthState } from '../../../helpers/mapAuthState';

const checkboxOptions = [{ label: 'Yes', value: true }];

const radioOptions = [
  { label: 'Yes', value: 1 },
  { label: 'No', value: 0 },
];

const freshSchema = {
  firstname: '',
  type: '',
  other: '',
  age_years: '',
  veterinarian_info: '',
  same_veterinarian_info: false,
  notes: '',
};

class Pets extends Form {
  constructor(props) {
    super(props);
    this.state = {
      data: freshSchema,
      freshSchema,
      errors: {},
      hasPets: null,
      petsAdded: 0,
      proceed: false,
      radioOptions,
    };
  }
  schema = Joi.object({
    firstname: Joi.string().required().label('Pet Name'),
    type: Joi.string().required().label('What kind of pet do you have?'),
    other: Joi.string()
      .optional()
      .allow('')
      .allow(null)
      .label('What kind of pet do you have?'),
    age_years: Joi.number()
      .integer()
      .required()
      .label('What is the age of the pet?'),
    veterinarian_info: Joi.string()
      .optional()
      .allow(null)
      .allow('')
      .label('Veterinarian Info'),
    same_veterinarian_info: Joi.boolean()
      .truthy(1)
      .falsy(0)
      .label('Same Veterinarian Info'),
    notes: Joi.string()
      .optional()
      .allow(null)
      .allow('')
      .label('Notes and Rules'),
  });
  componentDidMount() {
    const { user } = this.props.auth;
    if (!user) {
      this.sendUserBack();
    }
  }
  componentDidUpdate(prevProps, prevState) {
    const prevData = prevState.data;
    const { data } = this.state;
    if (data['type'] === 'Other' && prevData['type'] !== 'Other') {
      this.setState({
        showOther: true,
      });
    } else if (data['type'] !== 'Other' && prevData['type'] === 'Other') {
      this.setState({
        showOther: false,
      });
    }
  }
  sendUserBack() {
    alert('You must sign up or log in to view this page.');
    window.open('/', '_self');
  }
  toggleHasPets = (val) => {
    this.setState({
      hasPets: Boolean(val),
    });
  };
  handleDefaultSubmit = (e) => {
    e.preventDefault();
    this.setState(
      {
        proceed: false,
      },
      () => {
        this.handleSubmit(e);
      }
    );
  };
  doSubmit = async (callback) => {
    let apiData = { ...this.state.data };
    const { family_id } = this.props.auth.user.personal_info;
    const { same_veterinarian_info, type, other } = apiData;
    apiData['family_id'] = family_id;

    if (type === 'Other') {
      apiData['type'] = apiData['other'];
    }

    try {
      const response = await httpService.post('/api/profilePet/store', apiData);

      if (response.status === 201 && response.data.message === 'OK') {
        if (this.state.proceed) {
          toast.success('Pet saved successfully!');
          this.advanceStep();
        } else {
          let { data, petsAdded, freshSchema } = this.state;
          petsAdded += 1;
          data = freshSchema;
          if (same_veterinarian_info) {
            data['veterinarian_info'] = apiData['veterinarian_info'];
            data['same_veterinarian_info'] = apiData['same_veterinarian_info'];
          }
          this.setState({
            data,
            petsAdded,
            showOther: false,
          });

          toast.success('Pet saved successfully!');

          $('.registration').animate({ scrollTop: 0 });

          callback();
        }
      } else {
        $('.registration').animate({ scrollTop: 0 });
        callback();
      }
    } catch (e) {
      console.log(e);
      callback();
    }
  };
  doSubmitAndAdvance = async (e) => {
    if (!!e) {
      e.preventDefault();
    }
    this.setState(
      {
        proceed: true,
      },
      this.handleSubmit
    );
  };
  checkFormDataAndAdvance = (e) => {
    if (!!e) {
      e.preventDefault();
    }
    const { data, petsAdded } = this.state;
    const { firstname, type, age_years } = data;
    if (!!petsAdded) {
      // If pets have been added, we aren't sure if they want to save & proceed, or just proceed.
      // We need to check if any of the required fields are present and make a guess
      if (!!firstname || !!type || !!age_years) {
        this.setState(
          {
            proceed: true,
          },
          this.handleSubmit
        );
      } else {
        this.advanceStep();
      }
    } else {
      // If no pets have been added, we assume they want to save & proceed.
      this.setState(
        {
          proceed: true,
        },
        this.handleSubmit
      );
    }
  };
  advanceStep = async () => {
    try {
      const { id, active } = this.props.auth.user;
      if (!!active) {
        const response = await this.props.updateUserStep('family-payments', id);
        console.log(response);
      } else {
        const response = await this.props.updateUserStep('family-pets', id);
        console.log(response);
      }
    } catch (e) {
      console.log(e);
    }
  };
  render() {
    const {
      petsAdded,
      data,
      medicalConditions,
      showOther,
      hasPets,
      radioOptions,
    } = this.state;
    return (
      <div className="registration family step-4 pets">
        <div className="form">
          <div className="inner">
            <FormStepTracker steps={trackerSteps} currentStep={2} />
            <div className="form-header">
              <h2>Add Pets</h2>
            </div>
            <div className="form-body">
              <div className={`form-field radio checkbox ${name}`}>
                <label className="" htmlFor={name}>
                  Do you have pet(s)?
                  <span className="required">&nbsp;*</span>
                </label>
                {radioOptions.map((option, idx) => (
                  <div className="radio-group" key={`has-pets-opt-${idx}`}>
                    <input
                      value={option.value}
                      name="has-pets"
                      id={`has-pets-${idx}`}
                      type="radio"
                      onChange={() => {
                        this.toggleHasPets(option.value);
                      }}
                      className={
                        hasPets === option.value ? 'active' : 'inactive'
                      }
                    />
                    <label className="" htmlFor={`has-pets-${idx}`}>
                      <span>
                        <div className="radio"></div>
                        {option.label}
                      </span>
                    </label>
                  </div>
                ))}
              </div>
            </div>
            {hasPets && (
              <form onSubmit={this.handleDefaultSubmit}>
                <div className="form-group justify-space-between">
                  <h3>Pet {petsAdded + 1}</h3>
                  {this.renderInput('firstname', 'Pet Name', 'text', true)}
                  {this.renderSelect(
                    'type',
                    'What kind of pet do you have?',
                    pets,
                    true
                  )}
                  {!!showOther && (
                    <React.Fragment>
                      {this.renderInput(
                        'other',
                        'What kind of pet do you have?',
                        'text',
                        true
                      )}
                    </React.Fragment>
                  )}
                  {this.renderInput(
                    'age_years',
                    'What is the age of the pet?',
                    'number',
                    true
                  )}
                  <div className="form-field-group field-with-checkbox">
                    {this.renderTextarea(
                      'veterinarian_info',
                      'Veterinarian Information (optional)',
                      false
                    )}
                    {this.renderBoolean(
                      'same_veterinarian_info',
                      'Check this box if all Veterinarian information is the same for all pets in your family.'
                    )}
                  </div>
                  {this.renderTextarea(
                    'notes',
                    'Notes and Rules (optional)',
                    false
                  )}
                </div>
                {this.renderButton(
                  'Add Another Pet',
                  'Adding...',
                  'theme-btn primary-green',
                  'far fa-plus-circle'
                )}
                <div className="form-field button next-step">
                  {!!this.state.submitting && (
                    <button
                      type="submit"
                      className="theme-btn blue"
                      onClick={(e) => {
                        e.preventDefault();
                      }}
                    >
                      <i className="fas fa-spinner fa-spin"></i> Adding and
                      proceeding...
                    </button>
                  )}
                  {!this.state.submitting && (
                    <button
                      type="submit"
                      className="theme-btn blue"
                      onClick={this.checkFormDataAndAdvance}
                    >
                      Next
                    </button>
                  )}
                </div>
              </form>
            )}
            {!hasPets && (
              <div className="form-field button next-step">
                <button
                  type="submit"
                  className="theme-btn blue"
                  onClick={this.advanceStep}
                >
                  Next
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const actionCreators = {
  updateUserStep: authActions.updateUserStep,
};

export default connect(mapAuthState, actionCreators)(Pets);
