import {
  GridColumnMenuCheckboxFilter,
  GridColumnMenuFilter,
} from '@progress/kendo-react-grid';
import { get } from 'lodash-es';
import moment from 'moment-timezone';
import React from 'react';
import { Link } from 'react-router-dom';

export class ColumnMenu extends React.Component {
  render() {
    return (
      <div>
        <GridColumnMenuFilter {...this.props} expanded={true} />
      </div>
    );
  }
}

export class ColumnMenuCheckboxFilter extends React.Component {
  render() {
    return (
      <div>
        <GridColumnMenuCheckboxFilter
          {...this.props}
          data={this.props.sitters}
          expanded={true}
        />
      </div>
    );
  }
}

export class DateCell extends React.Component {
  render() {
    if (this.props.dataItem[this.props.field] !== null) {
      return (
        <td>
          {moment(this.props.dataItem[this.props.field]).format('M/D/YY')}
        </td>
      );
    } else {
      return <td></td>;
    }
  }
}

export class RelationshipCell extends React.Component {
  render() {
    const field = get(this.props.dataItem, this.props.var);
    const bool = this.props.boolean;
    if (bool) {
      return <td>{!!field ? 'Yes' : 'No'}</td>;
    } else {
      return <td>{field}</td>;
    }
  }
}

export class SubscriptionCell extends React.Component {
  render() {
    const field = get(this.props.dataItem, this.props.var);
    if (field) {
      return <td>Active</td>;
    } else {
      return <td>Inactive</td>;
    }
  }
}

export class ManageCell extends React.Component {
  render() {
    const id = get(this.props.dataItem, this.props.field);
    return (
      <td className="manage-cell">
        <Link className="theme-btn blue small" to={`/families/members/${id}`}>
          Manage
        </Link>
      </td>
    );
  }
}

export class ManageMemberCell extends React.Component {
  render() {
    const id = get(this.props.dataItem, this.props.field);
    return (
      <td className="manage-cell">
        <Link
          className="theme-btn blue small"
          to={`/families/members/edit/${id}`}
        >
          Manage
        </Link>
      </td>
    );
  }
}

export class ManageChildCell extends React.Component {
  render() {
    const id = get(this.props.dataItem, this.props.field);
    return (
      <td className="manage-cell">
        <Link
          className="theme-btn blue small"
          to={`/families/children/edit/${id}`}
        >
          Manage
        </Link>
      </td>
    );
  }
}

export class ManagePetCell extends React.Component {
  render() {
    const id = get(this.props.dataItem, this.props.field);
    return (
      <td className="manage-cell">
        <Link className="theme-btn blue small" to={`/families/pets/edit/${id}`}>
          Manage
        </Link>
      </td>
    );
  }
}

export class ManageSitterCell extends React.Component {
  render() {
    const id = get(this.props.dataItem, this.props.field);
    return (
      <td className="manage-cell">
        <Link className="theme-btn blue small" to={`/providers/edit/${id}`}>
          Manage
        </Link>
      </td>
    );
  }
}

export class FavoriteSitterCell extends React.Component {
  render() {
    const id = get(this.props.dataItem, this.props.field);
    return (
      <td className="manage-cell">
        <button className="theme-btn red small" onClick={() => { this.props.onFavoriteClick(id) }}>
          Unfavorite
        </button>
      </td>
    );
  }
}

export class BlockSitterCell extends React.Component {
  render() {
    const id = get(this.props.dataItem, this.props.field);
    return (
      <td className="manage-cell">
        <button className="theme-btn red small" onClick={() => { this.props.onBlockClick(id) }}>
          Unblock
        </button>
      </td>
    );
  }
}

export class SitterActionCell extends React.Component {
  render() {
    const id = get(this.props.dataItem, this.props.field);
    const { type } = this.props;
    return (
      <td className="manage-cell">
        <button className="theme-btn red small" onClick={() => { this.props.onActionClick(id) }}>
          { type === 'block' ? 'Block' : 'Favorite' }
        </button>
      </td>
    );
  }
};

export class BooleanCell extends React.Component {
  render() {
    let bool = get(this.props.dataItem, this.props.field);
    return <td>{!!bool ? 'Yes' : 'No'}</td>;
  }
}
