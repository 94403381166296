import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment-timezone';
import { toast } from 'react-toastify';

import Calendar from '../../../shared/Appointments/Calendar';
import BookingTimes from './BookingTimes';
import { Modal } from '../../../shared';
import { displayAppointmentDate } from '../../../../helpers';
import { adminEmail } from '../../../../config';

class BookingDates extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      calendarDate: moment(),
      canDecreaseDate: false,
      showingBookingInfo: true,
    };
  }
  decreaseDate = (e) => {
    e.preventDefault();
    let calendarDate = moment(this.state.calendarDate).subtract(1, 'month');
    if (moment().isSameOrBefore(calendarDate, 'month')) {
      const isSameMonth = moment().isSame(calendarDate, 'month');
      this.setState({
        calendarDate,
        canDecreaseDate: !isSameMonth,
      });
    }
  };
  increaseDate = (e) => {
    e.preventDefault();
    let calendarDate = moment(this.state.calendarDate).add(1, 'month');
    this.setState({
      calendarDate,
      canDecreaseDate: true,
    });
  };
  toggleShowBookingInfo = () => {
    this.setState({
      showingBookingInfo: !this.state.showingBookingInfo,
    });
  };
  render() {
    const { canDecreaseDate, calendarDate, showingBookingInfo } = this.state;
    const { canAdvanceStep, appointments } = this.props;
    return (
      <div className="booking-form dates">
        <div className="calendar-wrapper">
          <a
            className={`arrow prev ${!canDecreaseDate ? 'disabled' : ''}`}
            href="#"
            onClick={this.decreaseDate}
          >
            <i className="fas fa-arrow-alt-left"></i>
          </a>
          <a className={`arrow next`} href="#" onClick={this.increaseDate}>
            <i className="fas fa-arrow-alt-right"></i>
          </a>
          <Calendar
            appointments={appointments}
            showAppointments={false}
            date={calendarDate}
            onSelectedDay={this.props.onSelectedDay}
            showDisabledDates={true}
            type="booking"
          />
        </div>
        <div className="booking-footer">
          <button
            className={`theme-btn blue ${!canAdvanceStep ? 'disabled' : ''}`}
            onClick={this.props.onAdvanceStep}
          >
            Next Step - Select Times
          </button>
        </div>
        {appointments.map((appointment, idx) => (
          <div className="booking-info" key={`appt-dates-${appointment.rid}`}>
            <div className="date">
              <h3>{moment(appointment.date).format('MMM D')}</h3>
              <h3 className="weekday">
                {moment(appointment.date).format('dddd')}
              </h3>
            </div>
            <div
              className="remove"
              onClick={() => {
                this.props.removeAppointment(idx);
              }}
            >
              <button className="theme-btn small error-red">
                <i className="fas fa-trash-alt"></i>&nbsp;Remove
              </button>
            </div>
          </div>
        ))}
      </div>
    );
  }
}

export default BookingDates;
