import Joi from 'joi';
import React from 'react';
import { booleanRadioOptions } from '../../data';
import { makeTimeIncrements } from '../../helpers';
import sitterService from '../../services/sitterService';
import Form from '../shared/Form';
const freshSchema = {
    showNotes_breakfast: 0,
    showNotes_lunch: 0,
    showNotes_dinner: 0,
    showNotes_snacks: 0,
    showNotes_bottlefeedings: 0,
    showNotes_bathroomaccidents: 0,
    showNotes_diaperchanges: 0,
    showNotes_firstaid: 0,
    food_none: 0,
    notes_breakfast: '',
    notes_lunch: '',
    notes_dinner: '',
    notes_snacks: '',
    notes_bottlefeedings: '',
    notes_bathroomaccidents: '',
    notes_diaperchanges: '',
    notes_firstaid: '',
    notes: '',
    naptime: '',
    naptime_start: '',
    naptime_end: '',
    bedtime: '',
};
class SitLogForm extends Form {
    schema = Joi.object({
        showNotes_breakfast: Joi.boolean().truthy(1).falsy(0).required(),
        notes_breakfast: Joi.string()
            .when('showNotes_breakfast', {
            is: true,
            then: Joi.required(),
            otherwise: Joi.optional().allow('').allow(null),
        })
            .label('Breakfast Notes'),
        showNotes_lunch: Joi.boolean().truthy(1).falsy(0).required(),
        notes_lunch: Joi.string()
            .when('showNotes_lunch', {
            is: true,
            then: Joi.required(),
            otherwise: Joi.optional().allow('').allow(null),
        })
            .label('Lunch Notes'),
        showNotes_dinner: Joi.boolean().truthy(1).falsy(0).required(),
        notes_dinner: Joi.string()
            .when('showNotes_dinner', {
            is: true,
            then: Joi.required(),
            otherwise: Joi.optional().allow('').allow(null),
        })
            .label('Dinner Notes'),
        showNotes_snacks: Joi.boolean().truthy(1).falsy(0).required(),
        notes_snacks: Joi.string()
            .when('showNotes_snacks', {
            is: true,
            then: Joi.required(),
            otherwise: Joi.optional().allow('').allow(null),
        })
            .label('Snack Notes'),
        food_none: Joi.boolean().truthy(1).falsy(0).required(),
        showNotes_bottlefeedings: Joi.boolean().truthy(1).falsy(0).required(),
        notes_bottlefeedings: Joi.string()
            .when('showNotes_bottlefeedings', {
            is: true,
            then: Joi.required(),
            otherwise: Joi.optional().allow('').allow(null),
        })
            .label('Bottle Feedings Notes'),
        showNotes_bathroomaccidents: Joi.boolean().truthy(1).falsy(0).required(),
        notes_bathroomaccidents: Joi.string()
            .when('showNotes_bathroomaccidents', {
            is: true,
            then: Joi.required(),
            otherwise: Joi.optional().allow('').allow(null),
        })
            .label('Bathroom Accidents Notes'),
        showNotes_diaperchanges: Joi.boolean().truthy(1).falsy(0).required(),
        notes_diaperchanges: Joi.string()
            .when('showNotes_diaperchanges', {
            is: true,
            then: Joi.required(),
            otherwise: Joi.optional().allow('').allow(null),
        })
            .label('Diaper Changes Notes'),
        showNotes_firstaid: Joi.boolean().truthy(1).falsy(0).required(),
        notes_firstaid: Joi.string()
            .when('showNotes_firstaid', {
            is: true,
            then: Joi.required(),
            otherwise: Joi.optional().allow('').allow(null),
        })
            .label('First Aid Notes'),
        bedtime: Joi.string().optional().allow(null).allow('').label('Bedtime'),
        naptime_start: Joi.string()
            .optional()
            .allow(null)
            .allow('')
            .label('Naptime Start'),
        naptime_end: Joi.string()
            .optional()
            .allow(null)
            .allow('')
            .label('Naptime End'),
        naptime: Joi.string().optional().allow(null).allow('').label('Naptime'),
        behavior_issues: Joi.string()
            .optional()
            .allow(null)
            .allow('')
            .label('Behavior Issues Notes'),
        activities: Joi.string().required().label('Activities'),
        notes: Joi.string()
            .optional()
            .allow(null)
            .allow('')
            .label('Additional Notes'),
    });
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            data: { ...freshSchema },
            appointmentData: null,
            times: makeTimeIncrements(0, 24),
        };
    }
    submitFormData = async () => {
        const data = { ...this.state.data };
        const { appointment } = this.state.appointmentData;
        const { id, start_time, end_time } = appointment;
        const sitLogActivities = [];
        if (!!data.showNotes_breakfast) {
            const activity = this.makeActivity('Food Details', 'Breakfast', data.notes_breakfast);
            sitLogActivities.push(activity);
        }
        if (!!data.showNotes_lunch) {
            const activity = this.makeActivity('Food Details', 'Lunch', data.notes_lunch);
            sitLogActivities.push(activity);
        }
        if (!!data.showNotes_dinner) {
            const activity = this.makeActivity('Food Details', 'Dinner', data.notes_dinner);
            sitLogActivities.push(activity);
        }
        if (!!data.showNotes_snacks) {
            const activity = this.makeActivity('Food Details', 'Snacks', data.notes_snacks);
            sitLogActivities.push(activity);
        }
        if (!!data.showNotes_bottlefeedings) {
            const activity = this.makeActivity('Food Details', 'Bottle Feedings', data.notes_bottlefeedings);
            sitLogActivities.push(activity);
        }
        if (!!data.behavior_issues) {
            const activity = this.makeActivity('Behavior', 'Behavioral Issues', data.behavior_issues);
            sitLogActivities.push(activity);
        }
        if (!!data.showNotes_bathroomaccidents) {
            const activity = this.makeActivity('Bathroom', 'Bathroom Accidents', data.notes_bathroomaccidents);
            sitLogActivities.push(activity);
        }
        if (!!data.showNotes_diaperchanges) {
            const activity = this.makeActivity('Bathroom', 'Diaper Changes', data.notes_diaperchanges);
            sitLogActivities.push(activity);
        }
        if (!!data.bedtime) {
            const activity = this.makeActivity('Sleep', 'Bed Time', data.bedtime);
            sitLogActivities.push(activity);
        }
        if (!!data.naptime_start) {
            const activity = this.makeActivity('Sleep', 'Nap Time', 'Nap', data.naptime_start, data.naptime_end);
            sitLogActivities.push(activity);
        }
        if (!!data.activities) {
            const activity = this.makeActivity('Activity', 'Activities', data.activities);
            sitLogActivities.push(activity);
        }
        if (!!data.showNotes_firstaid) {
            const activity = this.makeActivity('Medical', 'Medications/First Aid', data.notes_firstaid);
            sitLogActivities.push(activity);
        }
        const apiData = {
            appointment_id: id,
            sitter_notes: data.notes,
            sit_log_activities: sitLogActivities,
            start_time,
            end_time,
            submitted: 1,
        };
        return await sitterService.storeSitLog(id, apiData);
    };
    makeActivity = (type, name, description, start_time = null, end_time = null) => {
        return {
            type,
            name,
            description,
            start_time,
            end_time,
        };
    };
    renderForm = () => {
        const { data, times } = this.state;
        const { showNotes_breakfast, showNotes_lunch, showNotes_dinner, showNotes_snacks, showNotes_bottlefeedings, showNotes_bathroomaccidents, showNotes_diaperchanges, showNotes_firstaid, } = data;
        return (React.createElement("div", { className: "sitter-sit-log-form" },
            React.createElement("div", { className: "sit-log-section" }, this.renderTextarea('activities', 'Activities', true)),
            React.createElement("div", { className: "sit-log-section food-details" },
                React.createElement("p", null,
                    "Meals and Snacks",
                    React.createElement("span", { className: "required" }, "*"),
                    React.createElement("br", null),
                    React.createElement("span", { className: "small" }, "Please check any meals provided and details on what was given and how much was consumed.")),
                React.createElement("div", { className: "form-field-group" },
                    this.renderBoolean('showNotes_breakfast', 'Breakfast'),
                    !!showNotes_breakfast && (React.createElement(React.Fragment, null, this.renderTextarea('notes_breakfast', 'Notes on breakfast', true)))),
                React.createElement("div", { className: "form-field-group" },
                    this.renderBoolean('showNotes_lunch', 'Lunch'),
                    !!showNotes_lunch && (React.createElement(React.Fragment, null, this.renderTextarea('notes_lunch', 'Notes on lunch', true)))),
                React.createElement("div", { className: "form-field-group" },
                    this.renderBoolean('showNotes_dinner', 'Dinner'),
                    !!showNotes_dinner && (React.createElement(React.Fragment, null, this.renderTextarea('notes_dinner', 'Notes on dinner', true)))),
                React.createElement("div", { className: "form-field-group" },
                    this.renderBoolean('showNotes_snacks', 'Snacks'),
                    !!showNotes_snacks && (React.createElement(React.Fragment, null, this.renderTextarea('notes_snacks', 'Notes on snacks', true)))),
                React.createElement("div", { className: "form-field-group" },
                    this.renderBoolean('showNotes_bottlefeedings', 'Bottle Feedings'),
                    !!showNotes_bottlefeedings && (React.createElement(React.Fragment, null, this.renderTextarea('notes_bottlefeedings', 'Notes on bottle feedings', true)))),
                React.createElement("div", { className: "form-field-group" }, this.renderBoolean('food_none', 'None'))),
            React.createElement("div", { className: "sit-log-section" }, this.renderTextarea('behavior_issues', 'Note any behavior issues', false)),
            React.createElement("div", { className: "sit-log-section" },
                React.createElement("div", { className: "form-field-group" },
                    this.renderRadio('showNotes_bathroomaccidents', 'Bathroom Accidents', booleanRadioOptions, true),
                    !!showNotes_bathroomaccidents && (React.createElement(React.Fragment, null, this.renderTextarea('notes_bathroomaccidents', 'Notes on bathroom accidents', true))))),
            React.createElement("div", { className: "sit-log-section" },
                React.createElement("div", { className: "form-field-group" },
                    this.renderRadio('showNotes_diaperchanges', 'Diaper Changes', booleanRadioOptions, true),
                    !!showNotes_diaperchanges && (React.createElement(React.Fragment, null, this.renderTextarea('notes_diaperchanges', 'Notes on diaper changes', true))))),
            React.createElement("div", { className: "sit-log-section bedtime" }, this.renderSelect('bedtime', 'Bed Time', times, false)),
            React.createElement("div", { className: "sit-log-section naptime" },
                this.renderSelect('naptime_start', 'Nap Start Time', times, false),
                this.renderSelect('naptime_end', 'Nap End Time', times, false)),
            React.createElement("div", { className: "sit-log-section" },
                React.createElement("div", { className: "form-field-group" },
                    this.renderRadio('showNotes_firstaid', 'Any medications and/or first aid administered?', booleanRadioOptions, true),
                    !!showNotes_firstaid && (React.createElement(React.Fragment, null, this.renderTextarea('notes_firstaid', 'Notes on medication/first aid', true))))),
            React.createElement("div", { className: "sit-log-section" }, this.renderTextarea('notes', 'Any additional notes', false))));
    };
}
export default SitLogForm;
