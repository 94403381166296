import moment from 'moment-timezone';
const displayAppointmentTime = (appointment, useRequestedTime = false) => {
    const start = !!useRequestedTime
        ? appointment.requested_start_time
        : appointment.start_time;
    const end = !!useRequestedTime
        ? appointment.requested_end_time
        : appointment.end_time;
    const startTime = moment(start);
    const endTime = moment(end);
    return `${startTime.format('h:mm a')} to ${endTime.format('h:mm a')}`;
};
export default displayAppointmentTime;
