import Joi from 'joi';
import moment from 'moment-timezone';
import React from 'react';
import { Link } from 'react-router-dom';
import sitterService from '../../services/sitterService';
import Form from '../shared/Form';
class SitterAccountSettingsForm extends Form {
    schema = Joi.object({
        firstname: Joi.string().required().label('First Name'),
        lastname: Joi.string().required().label('Last Name'),
        dob_year: Joi.number().integer().min(1900).required().label('DOB - Year'),
        dob_month: Joi.number()
            .integer()
            .min(1)
            .max(12)
            .required()
            .label('DOB - Month'),
        dob_day: Joi.number()
            .integer()
            .min(1)
            .max(31)
            .required()
            .label('DOB - Day'),
    });
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            data: {
                firstname: '',
                lastname: '',
                dob_year: '',
                dob_month: '',
                dob_day: '',
            },
            user: null,
        };
    }
    submitFormData = async () => {
        const apiData = { ...this.state.data };
        const { id } = this.state;
        const { dob_day, dob_year, dob_month } = apiData;
        apiData.DOB = moment(`${dob_year}-${dob_month}-${dob_day}`, 'YYYY-M-D').format('YYYY-MM-DD');
        const data = new FormData();
        const headers = { headers: { 'content-type': 'multipart/form-data' } };
        data.append('_method', 'post');
        for (const key of Object.keys(apiData)) {
            if (typeof apiData[key] === 'object') {
                if (key === 'avatar_filename') {
                    data.append(key, apiData[key]);
                }
                else {
                    data.append(key, JSON.stringify(apiData[key]));
                }
            }
            else {
                data.append(key, apiData[key]);
            }
        }
        try {
            return await sitterService.update(id, data, headers);
        }
        catch (error) {
            return error;
        }
    };
    renderForm(showChangeEmail, showChangePassword) {
        return (React.createElement("div", { className: "account-settings-form sitter" },
            React.createElement("div", { className: "form-group" },
                this.renderInput('firstname', 'First Name', 'text', true),
                this.renderInput('lastname', 'Last Name', 'text', true),
                this.renderTextDatePicker('dob', 'Birthday', true),
                React.createElement("div", { className: "link-wrapper" },
                    !!showChangeEmail && (React.createElement(Link, { to: "/email/update", className: "theme-btn blue small" },
                        React.createElement("i", { className: "fas fa-sync" }),
                        "\u00A0Update Email")),
                    !!showChangePassword && (React.createElement(Link, { to: "/password/update", className: "theme-btn primary-green small" },
                        React.createElement("i", { className: "fas fa-sync" }),
                        "\u00A0Update Password"))))));
    }
}
export default SitterAccountSettingsForm;
