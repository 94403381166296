import { balanceConstants } from '../constants/balanceConstants';
import familyService from '../services/familyService';
const getBalance = (id) => {
    return async (dispatch) => {
        dispatch({ type: balanceConstants.BALANCE_REQUEST });
        try {
            const response = await familyService.getBalance(id);
            if (response.status === 200) {
                const { balance } = response.data.data;
                dispatch({ type: balanceConstants.BALANCE_SUCCESS, balance });
            }
            else {
                dispatch({
                    type: balanceConstants.BALANCE_FAILURE,
                    error: response,
                });
            }
            return response;
        }
        catch (error) {
            dispatch({
                type: balanceConstants.BALANCE_FAILURE,
                error,
            });
            return error;
        }
    };
};
const getTransactions = (id) => {
    return async (dispatch) => {
        dispatch({ type: balanceConstants.TRANSACTIONS_REQUEST });
        try {
            const response = await familyService.getTransactions(id);
            if (response.status === 200) {
                const { balance, transactions } = response.data.data;
                dispatch({
                    type: balanceConstants.TRANSACTIONS_SUCCESS,
                    balance,
                    transactions,
                });
            }
            else {
                dispatch({
                    type: balanceConstants.TRANSACTIONS_FAILURE,
                    error: response,
                });
            }
            return response;
        }
        catch (error) {
            dispatch({ type: balanceConstants.TRANSACTIONS_FAILURE, error });
            return error;
        }
    };
};
export const balanceActions = {
    getBalance,
    getTransactions,
};
export default balanceActions;
