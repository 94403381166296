import React from 'react';
import { connect } from 'react-redux';
import Joi from 'joi';
import { toast } from 'react-toastify';
import { authActions } from '../../actions/authActions';
import Form from './Form';
import { mapAuthState } from '../../helpers/mapAuthState';
class PasswordResetSend extends Form {
    schema = Joi.object({
        email: Joi.string().required().label('Email'),
    });
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            data: { email: '' },
            errors: {},
        };
    }
    doSubmit = async (callback) => {
        const { data } = this.state;
        try {
            const response = await this.props.sendResetPassword(data);
            if (response.status === 200) {
                toast.success('Hooray! Your password reset email has been sent.');
            }
            callback();
        }
        catch {
            callback();
        }
    };
    goBack = (event) => {
        if (event) {
            event.preventDefault();
        }
        this.props.history.goBack();
    };
    render() {
        return (React.createElement("main", { className: "password update" },
            React.createElement("div", { className: "inner" },
                React.createElement("form", { onSubmit: this.handleSubmit },
                    React.createElement("h2", null, "Reset Password"),
                    this.renderInput('email', 'Email', 'email', true),
                    this.renderButton('Send Password Reset Email', 'Sending...', 'theme-btn blue'),
                    React.createElement("div", { className: "cancel-btn" },
                        React.createElement("a", { className: "theme-btn small slate-blue", href: "#", onClick: this.goBack }, "Cancel"))))));
    }
}
const actionCreators = {
    sendResetPassword: authActions.sendResetPassword,
};
export default connect(mapAuthState, actionCreators)(PasswordResetSend);
