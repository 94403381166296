import moment from 'moment-timezone';
import React from 'react';
import { states } from '../../data';
import personalInfoService from '../../services/personalInfoService';
import { Form } from '../shared';
import Joi from 'joi';
const genderRadioOptions = [
    { label: 'Male', value: 'male' },
    { label: 'Female', value: 'female' },
];
const freshSchema = {
    email: '',
    firstname: '',
    lastname: '',
    dob_year: '',
    dob_month: '',
    dob_day: '',
    phone1: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
    gender: '',
};
class MemberForm extends Form {
    schema = Joi.object({
        email: Joi.string().required().label('Email'),
        firstname: Joi.string()
            .allow('')
            .allow(null)
            .optional()
            .label('First Name'),
        lastname: Joi.string().allow('').allow(null).optional().label('Last Name'),
        dob_year: Joi.string()
            .min(0)
            .optional()
            .allow('')
            .allow(null)
            .label('DOB - Year'),
        dob_month: Joi.string()
            .min(0)
            .max(12)
            .optional()
            .allow('')
            .allow(null)
            .label('DOB - Month'),
        dob_day: Joi.string()
            .min(0)
            .max(31)
            .optional()
            .allow('')
            .allow(null)
            .label('DOB - Day'),
        phone1: Joi.string()
            .optional()
            .allow('')
            .allow(null)
            .label('Mobile Number'),
        address1: Joi.string()
            .optional()
            .allow('')
            .allow(null)
            .label('Address (line 1)'),
        address2: Joi.string()
            .optional()
            .allow(null)
            .allow('')
            .label('Address (line 2)'),
        city: Joi.string().optional().allow('').allow(null).label('City'),
        state: Joi.string().optional().allow('').allow(null).label('State'),
        zip: Joi.string().optional().allow('').allow(null).label('ZIP code'),
        gender: Joi.string().optional().allow('').allow(null).label('Gender'),
        login_hash: Joi.string().optional().allow('').allow(null),
    });
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            data: { ...freshSchema },
            freshSchema,
            errors: {},
        };
    }
    submitFormData = async () => {
        try {
            const apiData = { ...this.state.data };
            const { dob_day, dob_year, dob_month } = apiData;
            const DOB = moment(`${dob_year}-${dob_month}-${dob_day}`, 'YYYY-M-D').format('YYYY-MM-DD');
            if (DOB === 'Invalid Date') {
                delete apiData.DOB;
            }
            else {
                apiData.DOB = DOB;
            }
            delete apiData.dob_day;
            delete apiData.dob_year;
            delete apiData.dob_month;
            const { id, creating } = this.state;
            const data = new FormData();
            const headers = {
                headers: { 'content-type': 'multipart/form-data' },
            };
            data.append('_method', 'post');
            for (const key of Object.keys(apiData)) {
                if (typeof apiData[key] === 'object') {
                    if (key === 'avatar_filename') {
                        if (apiData[key] !== null) {
                            data.append(key, apiData[key]);
                        }
                    }
                    else {
                        data.append(key, JSON.stringify(apiData[key]));
                    }
                }
                else {
                    data.append(key, apiData[key]);
                }
            }
            if (creating) {
                const { family_id } = this.state;
                data.append('family_id', String(family_id));
                data.append('type', 'parent');
                return await personalInfoService.store(data, headers, 'admin');
            }
            else {
                return await personalInfoService.update(id, data, headers);
            }
        }
        catch (error) {
            return error;
        }
    };
    renderForm = () => {
        return (React.createElement("div", { className: "admin-settings-form sitter" },
            React.createElement("div", { className: "form-group" },
                this.renderInput('email', 'Email', 'email', true),
                this.renderInput('firstname', 'First Name', 'text'),
                this.renderInput('lastname', 'Last Name', 'text'),
                this.renderTextDatePicker('dob', 'Birthday'),
                this.renderInput('phone1', 'Mobile Number', 'number'),
                this.renderInput('address1', 'Address', 'text'),
                this.renderInput('address2', 'Apt/Suite', 'text'),
                this.renderInput('city', 'City', 'text'),
                this.renderSelect('state', 'State', states),
                this.renderInput('zip', 'ZIP', 'number'),
                this.renderRadio('gender', 'Gender', genderRadioOptions))));
    };
}
export default MemberForm;
