import { Grid, GridColumn, } from '@progress/kendo-react-grid';
import { findLinkGroupByTitle } from '@ss/helpers';
import systemNoticesService from '@ss/services/systemNoticesService';
import React from 'react';
import { Link } from 'react-router-dom';
import Sidebar from '../Sidebar';
import { ColumnMenu, ManageCell } from './KendoComponents';
class AnnouncementsGrid extends React.Component {
    state = {
        systemNotices: [],
        result: [],
        loading: true,
        linkGroup: findLinkGroupByTitle('Settings'),
        dataState: {
            sort: [{ field: 'id', dir: 'asc' }],
            filter: {
                logic: 'and',
                filters: [],
            },
            filterOperators: {
                text: [
                    {
                        text: 'grid.filterContainsOperator',
                        operator: 'contains',
                    },
                    { text: 'grid.filterEqOperator', operator: 'eq' },
                ],
            },
            skip: 0,
            take: 50,
            total: 0,
        },
    };
    componentDidMount = () => {
        this.getNotices();
    };
    getNotices = async () => {
        try {
            const { dataState } = this.state;
            const response = await systemNoticesService.list({
                skip: dataState.skip,
                take: dataState.take,
                sort: dataState.sort,
                filter: dataState.filter,
            });
            if (response.status === 200) {
                const { systemNotices, count } = response.data.data;
                this.setState({
                    result: systemNotices,
                    dataState: {
                        ...dataState,
                        total: count,
                    },
                    systemNotices,
                    loading: false,
                });
            }
        }
        catch {
            this.setState({
                loading: false,
            });
        }
    };
    pageChange = (event) => {
        this.setState({
            dataState: {
                ...this.state.dataState,
                skip: event.page.skip,
                take: event.page.take,
            },
        }, () => {
            this.getNotices();
        });
    };
    sortChange = (event) => {
        this.setState({
            dataState: {
                ...this.state.dataState,
                sort: event.sort,
            },
        }, () => {
            this.getNotices();
        });
    };
    filterChange = (event) => {
        this.setState({
            dataState: {
                ...this.state.dataState,
                filter: event.filter,
                skip: 0,
            },
        }, () => {
            this.getNotices();
        });
    };
    render() {
        const { loading, linkGroup, dataState, result } = this.state;
        if (!!loading) {
            return (React.createElement("main", { className: "admin admin-listing with-sidebar appointments" },
                React.createElement("div", { className: "sidebar-wrapper" },
                    React.createElement(Sidebar, { ...this.props, iconClass: "fas fa-id-card", linkGroup: linkGroup })),
                React.createElement("div", { className: "content" },
                    React.createElement("h4", { className: "section-label" },
                        React.createElement("i", { className: "fas fa-calendar-star" }),
                        "\u00A0Loading Announcements..."))));
        }
        return (React.createElement("main", { className: "admin admin-listing with-sidebar appointments" },
            React.createElement("div", { className: "sidebar-wrapper" },
                React.createElement(Sidebar, { ...this.props, iconClass: "fas fa-id-card", linkGroup: linkGroup })),
            React.createElement("div", { className: "content" },
                React.createElement("h4", { className: "section-label" },
                    React.createElement("i", { className: "fas fa-calendar-star" }),
                    "\u00A0Manage Announcements",
                    ' ',
                    React.createElement(Link, { to: "/settings/announcements/create" },
                        React.createElement("i", { className: "fad fa-plus-circle" }))),
                React.createElement("div", { className: "kendo-table" },
                    React.createElement(Grid, { data: result, pageable: true, sortable: true, ...dataState, onPageChange: this.pageChange, onSortChange: this.sortChange, onFilterChange: this.filterChange },
                        React.createElement(GridColumn, { title: "Type", field: "type", filter: 'text', columnMenu: ColumnMenu }),
                        React.createElement(GridColumn, { title: "Status", field: "status", filter: 'text', columnMenu: ColumnMenu }),
                        React.createElement(GridColumn, { title: "Title", field: "name", filter: 'text', columnMenu: ColumnMenu }),
                        React.createElement(GridColumn, { title: " ", field: "id", width: 100, cell: (props) => React.createElement(ManageCell, { ...props, ...this.props }) }))))));
    }
}
export default AnnouncementsGrid;
