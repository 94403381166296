import { get } from 'lodash-es';
import React from 'react';
import { Link } from 'react-router-dom';

const ProgramManageBtn = (props) => {
  const id = get(props.dataItem, props.field);
  return (
    <td className="manage-cell">
      <Link className="theme-btn blue small" to={`/programs/view/${id}`}>
        Manage
      </Link>
    </td>
  );
};

export default ProgramManageBtn;
