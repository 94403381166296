import { buildApiUrl } from '../helpers/buildApiUrl';
import httpService from './httpService';
const getAppointmentsByStatus = (data = {}) => {
    const url = `/appointments/status/${data?.status ?? 'all'}`;
    return httpService.get(buildApiUrl(url), data);
};
const familyAppointments = (data) => {
    const apiUrl = buildApiUrl(`/appointments/family/${data.id}/${data.status}`);
    return httpService.get(apiUrl, data);
};
const getAppointmentsByDateRange = (data) => {
    const apiUrl = buildApiUrl(`/appointments/daterange/${data.startTime}/${data.endTime}`);
    return httpService.get(apiUrl, data);
};
const getAppointmentById = (id) => {
    const apiUrl = buildApiUrl(`/appointments/${id}`);
    return httpService.get(apiUrl);
};
const getSitLog = (id, type = 'appointment') => {
    // if (type == appointment) then id = appt ID, otherwise ID is the sit log ID.
    const apiUrl = buildApiUrl(`/sitLog/${id}/${type}`);
    return httpService.get(apiUrl);
};
const assignSitters = (id, data) => {
    const apiUrl = buildApiUrl(`/appointments/assignSitters/${id}`);
    return httpService.post(apiUrl, data);
};
const cancel = (id) => {
    const apiUrl = buildApiUrl(`/appointments/cancel/${id}`);
    return httpService.delete(apiUrl);
};
const confirmTimeChange = (id) => {
    const apiUrl = buildApiUrl(`/appointments/confirmTimeChange/${id}`);
    return httpService.post(apiUrl);
};
const rejectTimeChange = (id) => {
    const apiUrl = buildApiUrl(`/appointments/declineTimeChange/${id}`);
    return httpService.post(apiUrl);
};
const respondToSitterSignup = (sitterPiid, appointmentId, approval) => {
    const apiUrl = buildApiUrl('/appointments/sitterSignUpResponse');
    return httpService.post(apiUrl, {
        sitter_piid: sitterPiid,
        appointment_id: appointmentId,
        approval: !!approval ? '1' : '0',
    });
};
const createPendingListSitterSignup = (data) => {
    const apiUrl = buildApiUrl('/appointments/sitterSignUp');
    return httpService.post(apiUrl, data);
};
const createAppointmentV1 = (piid, data) => {
    const apiUrl = buildApiUrl(`/booking/create/${piid}`);
    return httpService.post(apiUrl, data);
};
const update = (id, data, headers) => {
    const apiUrl = buildApiUrl(`/appointments/update/${id}`);
    return httpService.post(apiUrl, data, headers);
};
const getBookings = (type, data = {}) => {
    const apiUrl = buildApiUrl(`/booking/list/${type}`);
    return httpService.get(apiUrl, data);
};
const getProgramsByType = (type, id, data = {}) => {
    const apiUrl = buildApiUrl(`/booking/programs/${type}/${id}`);
    return httpService.get(apiUrl, data);
};
const getBooking = (id, data = {}) => {
    const apiUrl = buildApiUrl(`/booking/${id}`);
    return httpService.get(apiUrl, data);
};
const updateAppointmentStatus = (id, status) => {
    const apiUrl = buildApiUrl(`/appointments/updateStatus/${id}`);
    const data = {
        status,
    };
    return httpService.post(apiUrl, data);
};
export default {
    getAppointmentsByStatus,
    familyAppointments,
    getAppointmentsByDateRange,
    get: getAppointmentById,
    assignSitters,
    cancel,
    confirmTimeChange,
    rejectTimeChange,
    create: createAppointmentV1,
    createPendingListSitterSignup,
    getSitLog,
    update,
    getBookings,
    getProgramsByType,
    getBooking,
    updateAppointmentStatus,
    respondToSitterSignup,
};
