import { buildApiUrl } from '../helpers/buildApiUrl';
import httpService from './httpService';
const getOpenCoverRequests = () => {
    const apiUrl = buildApiUrl('/coverrequests');
    return httpService.get(apiUrl);
};
const getRequestsRemaining = (sitterPiid) => {
    const apiUrl = buildApiUrl(`/coverrequests/remaining/${sitterPiid}`);
    return httpService.get(apiUrl);
};
const storeCoverRequest = (data) => {
    const apiUrl = buildApiUrl('/coverrequests/store');
    return httpService.post(apiUrl, data);
};
const acceptCoverRequest = (id, sitterPiid) => {
    const apiUrl = buildApiUrl(`/coverrequests/cover/${id}/${sitterPiid}`);
    return httpService.post(apiUrl);
};
const declineCoverRequest = (id) => {
    const apiUrl = buildApiUrl(`/coverrequests/decline/${id}`);
    return httpService.post(apiUrl);
};
const approveCoverRequest = (id) => {
    const apiUrl = buildApiUrl(`/coverrequests/fillin/accept/${id}`);
    return httpService.post(apiUrl);
};
const denyCoverRequest = (id) => {
    const apiUrl = buildApiUrl(`/coverrequests/fillin/decline/${id}`);
    return httpService.post(apiUrl);
};
const getCoverRequestsForUser = () => {
    const apiUrl = buildApiUrl('/coverrequests/user');
    return httpService.get(apiUrl);
};
export default {
    getOpenCoverRequests,
    getRequestsRemaining,
    storeCoverRequest,
    declineCoverRequest,
    acceptCoverRequest,
    approveCoverRequest,
    denyCoverRequest,
    getCoverRequestsForUser,
};
