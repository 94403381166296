import moment from 'moment-timezone';
const displayAppointmentDate = (appointment, format = 'dddd, MMMM Do, YYYY', delimiter = 'to') => {
    const startDate = moment(appointment.start_time).format('DDD');
    const endDate = moment(appointment.end_time).format('DDD');
    if (startDate === endDate) {
        // If appointments are on the same day, we display just the one day (e.g. Wednesday, May 5th, 2020)
        return moment(appointment.start_time).format(format);
    }
    else {
        // But if they are different days, we display it as "Wednesday, May 5th, 2020 to Friday, May 7th, 2020"
        const startString = moment(appointment.start_time).format(format);
        const endString = moment(appointment.end_time).format(format);
        return `${startString} ${delimiter} ${endString}`;
    }
};
export default displayAppointmentDate;
