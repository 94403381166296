import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';

import { Form } from '../../shared';
import { displayAddress } from '../../../helpers';

import { bulkBookingWeeks, states } from '../../../data';

import Radio from '../../shared/Form/Radio';
import DatePicker from '../../shared/Form/Datepicker';
import Input from '../../shared/Form/Input';
import Select from '../../shared/Form/Select';
import BooleanInput from '../../shared/Form/BooleanInput';
import ChildCheckbox from '../../shared/Form/ChildCheckbox';

import BulkAppointmentsForm from './BulkAppointmentsForm';
import BulkAppointmentsList from './BulkAppointmentsList';
import { BulkCalendar } from './BulkCalendar';
import { mapAuthState } from '../../../helpers/mapAuthState';

class BulkAppointments extends Form {
  constructor(props) {
    super(props);
    this.state = {
      currentTab: 'repeating',
      creating: false,
      editing: false,
      appointment: null,
    };
  }

  canAdvanceStep = () => {
    const { repeatingAppointments, randomAppointments } = this.props.data;
    return repeatingAppointments.length + randomAppointments.length > 0;
  };
  showCreateAppointment = () => {
    this.setState({ creating: true });
  };
  addNewAppointment = (type, apptData) => {
    this.props.addNewAppointment(type, apptData);
    this.setState({ creating: false });
  };
  showEditAppointment = (appointment) => {
    this.setState({
      editing: true,
      appointment: appointment,
    });
  };
  deleteAppointment = () => {
    if (this.state.creating) {
      // The appointment has not been added to the array yet, so we just need to close the form
      this.setState({ creating: false });
    } else {
      // We need to actually remove the edited appointment
      const appointment = { ...this.state.appointment };
      const { currentTab } = this.state;
      this.props.deleteAppointmentByRid(currentTab, appointment.rid);
      this.setState({
        editing: false,
        appointment: null,
      });
    }
  };
  saveEditedAppointment = (type, apptData) => {
    // Broadcast the changed appointment up, and close the form.
    this.props.saveEditedAppointment(type, apptData);
    this.setState({ editing: false, appointment: null });
  };
  switchToRepeatingAppts = () => {
    const { creating, editing } = this.state;
    if (!creating && !editing) this.setState({ currentTab: 'repeating' });
  };
  switchToRandomAppts = () => {
    const { creating, editing } = this.state;
    if (!creating && !editing) this.setState({ currentTab: 'random' });
  };
  render() {
    const { data, errors, currentAppointments } = this.props;
    const { currentTab, creating, editing, appointment } = this.state;
    const canAdvanceStep = this.canAdvanceStep();
    return (
      <div className="bulk-booking appointments">
        <div className="date-type-selector">
          <div
            className={`selector repeating ${
              currentTab === 'repeating' ? 'active' : ''
            }`}
            onClick={this.switchToRepeatingAppts}
          >
            <p>Repeating Days ({data['repeatingAppointments'].length})</p>
          </div>
          <div
            className={`selector random ${
              currentTab === 'random' ? 'active' : ''
            }`}
            onClick={this.switchToRandomAppts}
          >
            <p>Random Days ({data['randomAppointments'].length})</p>
          </div>
        </div>
        {!creating && !editing && (
          <div className={`bulk-appointments ${currentTab}`}>
            <BulkAppointmentsList
              appointments={data[`${currentTab}Appointments`]}
              type={currentTab}
              showEditAppointment={this.showEditAppointment}
            />
            <div className="btn-wrapper">
              <button
                onClick={this.showCreateAppointment}
                className="theme-btn primary-green tall"
              >
                {currentTab === 'repeating' && (
                  <span>
                    <i className="fas fa-repeat"></i>
                    &nbsp;Add Repeating Day
                  </span>
                )}
                {currentTab === 'random' && (
                  <span>
                    <i className="fas fa-calendar-day"></i>
                    &nbsp;Add Random Day
                  </span>
                )}
              </button>
            </div>
          </div>
        )}
        {(!!creating || !!editing) && (
          <BulkAppointmentsForm
            creating={appointment ? false : true}
            appointment={appointment}
            type={currentTab}
            data={data}
            addNewAppointment={this.addNewAppointment}
            saveEditedAppointment={this.saveEditedAppointment}
            deleteAppointment={this.deleteAppointment}
          />
        )}
        <BulkCalendar data={data} currentAppointments={currentAppointments} />
        <div className="booking-footer">
          {canAdvanceStep && (
            <button
              className={`theme-btn blue`}
              onClick={this.props.onFormComplete}
            >
              Next
            </button>
          )}
          {!canAdvanceStep && (
            <button
              className={`theme-btn blue disabled`}
              onClick={(e) => e.preventDefault()}
            >
              Next
            </button>
          )}
        </div>
      </div>
    );
  }
}

export default connect(mapAuthState)(BulkAppointments);
