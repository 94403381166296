import clsx from 'clsx';
import React from 'react';
const getRemainingWords = (value) => {
    if (value === '' || value === null) {
        return 0;
    }
    return value.split(' ').length;
};
const Textarea = (props) => {
    const { name, label, error, value, required, className = '', wordCount, tooltip, ...rest } = props;
    return (React.createElement("div", { className: clsx('form-field textarea', name, className) },
        React.createElement("textarea", { ...rest, name: name, id: name, value: value, className: value ? 'active' : 'inactive' }),
        React.createElement("label", { htmlFor: name },
            label,
            required && React.createElement("span", { className: "required" }, "\u00A0*")),
        !!wordCount && (React.createElement("span", { className: "word-count" },
            getRemainingWords(value),
            "\u00A0/\u00A0",
            wordCount,
            " words")),
        !!tooltip && (React.createElement("div", { className: "tooltip-wrapper" },
            React.createElement("div", { className: "inner" },
                React.createElement("i", { className: "fas fa-info-circle" }),
                React.createElement("div", { className: "tooltip-item" },
                    React.createElement("p", null, tooltip))))),
        error && (React.createElement("span", { className: "error", role: "alert" },
            React.createElement("strong", null, error)))));
};
export default Textarea;
