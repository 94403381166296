import moment from 'moment-timezone';
import React from 'react';
import { connect } from 'react-redux';
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  injectStripe,
} from 'react-stripe-elements';
import { toast } from 'react-toastify';
import { states } from '../../../data';
import { findFeeBySlug, upperCaseFirst } from '../../../helpers';
import familyLedgerService from '../../../services/familyLedgerService';
import stripeService from '../../../services/stripeService';
import { CouponChecker, Form, Modal } from '../../shared';
import Joi from 'joi';
import { mapAuthState } from '../../../helpers/mapAuthState';

const fieldStyles = {
  fontFamily: "'Open Sans', sans-serif",
  fontSize: '16px',
  color: '#252525',
  '::placeholder': {
    color: '#FFF',
  },
};

const cancellationReasons = [
  { name: '', id: '' },
  {
    name: 'Sick child',
    id: 'Sick child',
  },
  { name: 'Change of plans', id: 'Change of plans' },
  {
    name: 'Medical or Family Emergency/Appointment',
    id: 'Medical or Family Emergency/Appointment',
  },
  { name: 'Booked in Error / Duplicate Booking', id: 'Booked in Error / Duplicate Booking' },
  { name: 'Other', id: 'Other' },
];

class AppointmentCancel extends Form {
  schema = Joi.object({
    cancel_reason: Joi.string().required().label('Cancel Reason'),
    using_balance: Joi.boolean()
      .truthy(1)
      .falsy(0)
      .required()
      .label('Using Balance'),
    changingPaymentInfo: Joi.boolean().truthy(1).falsy(0),
    firstname: Joi.string()
      .when('changingPaymentInfo', {
        is: true,
        then: Joi.required(),
        otherwise: Joi.optional().allow('').allow(null),
      })
      .label('First Name'),
    lastname: Joi.string()
      .when('changingPaymentInfo', {
        is: true,
        then: Joi.required(),
        otherwise: Joi.optional().allow('').allow(null),
      })
      .label('Last Name'),
    address1: Joi.string()
      .when('changingPaymentInfo', {
        is: true,
        then: Joi.required(),
        otherwise: Joi.optional().allow('').allow(null),
      })
      .label('Address (line 1)'),
    address2: Joi.string()
      .optional()
      .allow('')
      .allow(null)
      .label('Address (line 2)'),
    city: Joi.string()
      .when('changingPaymentInfo', {
        is: true,
        then: Joi.required(),
        otherwise: Joi.optional().allow('').allow(null),
      })
      .label('City'),
    state: Joi.string()
      .when('changingPaymentInfo', {
        is: true,
        then: Joi.required(),
        otherwise: Joi.optional().allow('').allow(null),
      })
      .label('State'),
    zip: Joi.string()
      .when('changingPaymentInfo', {
        is: true,
        then: Joi.required(),
        otherwise: Joi.optional().allow('').allow(null),
      })
      .label('ZIP code'),
  });

  constructor(props) {
    super(props);
    this.state = {
      data: {
        cancel_reason: '',
        using_balance: 0,
        changingPaymentInfo: false,
        firstname: '',
        lastname: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
        zip: '',
      },
      errors: {},
      needsPayment: false,
      submitting: false,
      changingPaymentInfo: false,
      coupon: false,
      totalFee: 0,
      actualFee: 0,
      hourlyFee: 0,
      hourlyRate: 0,
      drivingFee: 0,
      mileageRate: 0,
      minMileageFee: 0,
      twelveHourFee: 0,
      flexFee: 0,
      duration: 0,
    };
  }
  componentDidMount() {
    const hoursUntilAppt = moment
      .duration(
        moment(this.props.appointmentData.appointment.start_time).diff(moment())
      )
      .asHours();
    const isAccepted = this.props.appointmentData.appointment.status === 'accepted';
    const needsPayment = hoursUntilAppt <= 5 && isAccepted;
    this.setState({ needsPayment, hoursUntilAppt });
    if (needsPayment) {
      this.getStripeCustomer();
      this.calculateFees();
    }
  }

  getStripeCustomer = async () => {
    try {
      const { id } = this.props.auth.user;
      const response = await stripeService.get(id);
      if (response.status === 200) {
        const { user, stripeUser } = response.data.data;
        this.setState({
          stripeUser,
          hasStripeUser: true,
        });
      }
    } catch (e) {
      console.log(e);
    }
  };
  doSubmit = async () => {
    const { needsPayment } = this.state;

    if (!!needsPayment) {
      this.submitPayment();
      // if (this.state.data.using_balance) {
      //     this.createBalance();
      // } else {
      //     this.submitPayment();
      // };
    } else {
      this.props.cancelAppointment(this.state.data.cancel_reason);
    }
  };
  submitPayment = async () => {
    let display_error = false;

    try {
      const { hasStripeUser } = this.state;
      const { changingPaymentInfo } = this.state.data;
      const { id } = this.props.auth.user;

      let amount = this.state.actualFee;

      if (this.state.data.using_balance) {
        // We only need to set the amount to something else if the user is using their balance
        // AND the balance is less than the fee. otherwise we can just charge the full fee.
        let balance = parseFloat(this.props.balance.balance);
        if (balance < amount) {
          amount = amount - balance;
        } else {
          amount = 0;
        }
      }
      const paymentData = {
        amount: amount,
        type: 'booking-cancellation',
        model_id: this.props.appointmentData.appointment.id,
      };
      if (this.state.coupon !== false) {
        paymentData['coupon'] = this.state.coupon;
      }

      if (hasStripeUser && !changingPaymentInfo) {
        const response = await stripeService.charge(id, paymentData);
        if (response.status === 200 && response.data.payment_accepted == true) {
          if (this.state.data.using_balance) {
            this.createBalance();
          } else {
            this.props.cancelAppointment(this.state.data.cancel_reason);
          }
        } else {
          toast.error(
            'Your card was declined. Please check your information and try again.'
          );
          this.setState({
            submitting: false,
          });
        }
      } else {
        const { firstname, lastname, address1, address2, city, state, zip } =
          this.state.data;
        const cardNumber = this.props.elements.getElement('cardNumber');
        let { paymentMethod, error } =
          await this.props.stripe.createPaymentMethod({
            type: 'card',
            card: cardNumber,
            billing_details: {
              name: firstname + ' ' + lastname,
              address: {
                line1: address1,
                line2: address2,
                city,
                postal_code: zip,
                state,
              },
            },
          });
        if (error) {
          this.handleStripeError(error);
          this.setState({
            submitting: false,
          });
        } else {
          const token = {
            paymentMethod: paymentMethod,
            amount: amount,
          };

          const response = await stripeService.charge(id, token);

          if (
            response.status === 200 &&
            response.data.payment_accepted == true
          ) {
            if (this.state.data.using_balance) {
              this.createBalance();
            } else {
              this.props.cancelAppointment(this.state.data.cancel_reason);
            }
          } else {
            toast.error(
              'Your card was declined. Please check your information and try again.'
            );
            this.setState({
              submitting: false,
            });
          }
        }
      }
    } catch (e) {
      console.log(e);
      this.setState({
        submitting: false,
      });
    }
  };
  handleStripeError = (errorObj) => {
    const { code } = errorObj;
    if (code === 'incomplete_number') {
      //
    }
    if (code === 'incomplete_expiry') {
      //
    }
    if (code === 'incomplete_cvc') {
      //
    }
  };
  createBalance = async () => {
    try {
      const { family_id } = this.props.auth.user.personal_info;
      const apptId = this.props.appointmentData.appointment.id;

      let balance = parseFloat(this.props.balance.balance);
      let { actualFee } = this.state;
      // Here we use the minimum of the two - if the fee is $10 and the credit is $15, we want to use the $10.
      // If the credit is $12 and the fee is $15, we would want to use the $12.
      let amount = Math.min(balance, actualFee);

      const apiData = {
        amount: -amount,
        notes: `User used $${amount} to cancel appointment #${apptId} appointments on ${moment().format(
          'MM/DD [at] hh:mm a'
        )}`,
        family_id: family_id,
      };
      const response = await familyLedgerService.store(family_id, apiData);
      if (response.status === 201) {
        this.props.cancelAppointment(this.state.data.cancel_reason);
      } else {
        toast.error('Unusual error response from the server!');
        this.setState({
          submitting: false,
        });
      }
    } catch (e) {
      console.log(e);
      toast.error('Unusual error response from the server!');
      this.setState({
        submitting: false,
      });
    }
  };
  getHourlyRate = () => {
    const { fees, appointmentData } = this.props;
    const { appointment, children } = appointmentData;
    const { start_time, end_time } = appointment;
    const weekday = moment(start_time).format('d');
    const hour = moment(end_time).format('H');
    if (Number(weekday) === 6 && Number(hour) >= 15) {
      return parseFloat(findFeeBySlug('surge_pricing', fees).amount);
    } else {
      const numChildren = children.length;
      let childFeeSlug = 'one_child_per_hour';
      if (numChildren >= 2 && numChildren <= 3)
        childFeeSlug = 'two_three_children_per_hour';
      else if (numChildren > 3) childFeeSlug = 'four_plus_children_per_hour';
      return parseFloat(findFeeBySlug(childFeeSlug, fees).amount);
    }
  };
  calculateFees = () => {
    const { appointmentData, fees } = this.props;
    const { appointment } = appointmentData;
    let {
      hourlyRate,
      hourlyFee,
      drivingFee,
      mileageRate,
      minMileageFee,
      duration,
      flexFee,
      actualFee,
    } = this.state;

    let totalFee = 0;

    if (!!fees.length) {
      if (!!appointment.overnight && !!appointment.num_nights) {
        totalFee = parseFloat(findFeeBySlug('cancel_overnight', fees).amount);
      } else {
        let {
          start_time,
          end_time,
          driving_needed,
          driving_distance,
          flex,
          flex_room,
        } = appointment;

        let startDateAndTime = moment(start_time);
        let endDateAndTime = moment(end_time);
        // Get the # of hours
        duration = moment
          .duration(endDateAndTime.diff(startDateAndTime))
          .asHours();

        hourlyRate = this.getHourlyRate();
        hourlyFee = Math.max(hourlyRate * duration, 30);
        totalFee = totalFee + hourlyFee;
        hourlyFee = parseFloat(hourlyFee).toFixed(2);
        hourlyRate = parseFloat(hourlyRate).toFixed(2);
      }
    }

    totalFee = parseFloat(totalFee).toFixed(2);
    actualFee = totalFee;

    if (this.state.coupon !== false) {
      const { type, amount } = this.state.coupon;
      if (type === 'percentage') {
        actualFee = totalFee - totalFee * amount;
      } else if (type === 'amount') {
        actualFee = totalFee - amount;
      }
    }

    this.setState({
      hourlyRate,
      hourlyFee,
      drivingFee,
      mileageRate,
      minMileageFee,
      totalFee,
      actualFee,
      duration,
      flexFee,
    });
  };
  changePaymentInfo = (e) => {
    e.preventDefault();
    const data = { ...this.state.data };
    data['changingPaymentInfo'] = !data['changingPaymentInfo'];
    this.setState({ data });
  };
  applyCouponCode = (coupon) => {
    this.setState({ coupon }, this.calculateFees);
  };
  render() {
    const { needsPayment, totalFee, actualFee, coupon, hasStripeUser } =
      this.state;
    const { changingPaymentInfo } = this.state.data;
    const { balance } = this.props.balance;
    const { user } = this.props.auth;
    return (
      <Modal
        title="Confirm Cancellation"
        isOpen={true}
        closeModal={this.props.toggleCancelAppointment}
        content={
          <div className="appointment-cancel">
            <div className="cancellation-notice">
              <p>
                Are you sure you want to cancel this appointment? The scheduling
                fee is non-refundable.
              </p>
              {!!needsPayment && (
                <p className="needs-payment">
                  Heads up! You are about to cancel an appointment within 3
                  hours of the start time. If a confirmed appointment is
                  cancelled within this time frame, members are required to pay
                  the Full Amount Owed Cancellation Fee. This fee is distributed
                  to your confirmed provider, in order to properly compensate
                  them for the inconvenience.
                  <br />
                  <br />
                  <b>
                    Based upon your confirmed appointment details, the total
                    amount owed will be ${totalFee}. If you believe this to be
                    an error
                  </b>{' '}
                  due to your cancellation not being made within 3 hours OR the
                  total amount owed not being accurate, please contact our team
                  directly (before cancelling); we will be happy to assist!
                </p>
              )}
            </div>
            <form onSubmit={this.handleSubmit}>
              {this.renderSelect(
                'cancel_reason',
                'Cancellation Reason',
                cancellationReasons,
                true
              )}
              {!!needsPayment && (
                <div className="payment-info">
                  <h2>Payment Information</h2>
                  {parseFloat(balance) > 0 && (
                    <React.Fragment>
                      {this.renderBoolean(
                        'using_balance',
                        `You have a credit of $${balance}. Would you like to use it today?`
                      )}
                    </React.Fragment>
                  )}
                  <CouponChecker
                    className="booking-cancellation"
                    type="booking-cancellation"
                    applyCouponCode={this.applyCouponCode}
                  />
                  {coupon !== false && (
                    <div className="coupon-info">
                      <p>
                        Full Amount Owed Cancellation Fee: $
                        {parseFloat(totalFee).toFixed(2)}
                      </p>
                      <p>
                        Coupon:{' '}
                        {coupon.type === 'percentage'
                          ? coupon.amount * 100 + '%'
                          : '$' + coupon.amount}{' '}
                        off
                      </p>
                      <p className="total">
                        Total Due Today: ${parseFloat(actualFee).toFixed(2)}
                      </p>
                    </div>
                  )}
                  {hasStripeUser && !changingPaymentInfo && (
                    <div className="payment-info-item">
                      <h3>
                        {user.card_brand !== null
                          ? upperCaseFirst(user.card_brand)
                          : user.card_brand}
                      </h3>
                      <p>....&nbsp;{user.card_last_four}</p>
                      <div className="btn-wrapper">
                        <button
                          className="theme-btn small blue"
                          onClick={this.changePaymentInfo}
                        >
                          Change
                        </button>
                      </div>
                    </div>
                  )}
                  {(!hasStripeUser || !!changingPaymentInfo) && (
                    <div className="payment-fields">
                      <div className="payment-billing-address">
                        {this.renderInput(
                          'firstname',
                          'First Name',
                          'text',
                          true
                        )}
                        {this.renderInput(
                          'lastname',
                          'Last Name',
                          'text',
                          true
                        )}
                        {this.renderInput('address1', 'Address', 'text', true)}
                        {this.renderInput('address2', 'Apt/Suite', 'text')}
                        {this.renderInput('city', 'City', 'text', true)}
                        {this.renderSelect('state', 'State', states, true)}
                        {this.renderInput('zip', 'ZIP', 'number', true)}
                      </div>
                      <div className="payment-field card-number">
                        <CardNumberElement
                          className="card-number"
                          style={{
                            base: fieldStyles,
                          }}
                        />
                        <label>
                          Card Number
                          <span className="required">&nbsp;*</span>
                        </label>
                      </div>
                      <div className="payment-field card-expiry">
                        <CardExpiryElement
                          className="card-expiry"
                          placeholder="Expiration"
                          style={{
                            base: fieldStyles,
                          }}
                        />
                        <label>
                          Expiration Date
                          <span className="required">&nbsp;*</span>
                        </label>
                      </div>
                      <div className="payment-field card-cvc-num">
                        <CardCvcElement
                          className="card-cvc-num"
                          style={{
                            base: fieldStyles,
                          }}
                        />
                        <label>
                          CSV
                          <span className="required">&nbsp;*</span>
                        </label>
                      </div>
                      {!!hasStripeUser && (
                        <div
                          className="btn-wrapper"
                          style={{
                            marginTop: '16px',
                          }}
                        >
                          <button
                            className="theme-btn small blue"
                            onClick={this.changePaymentInfo}
                          >
                            Use Default Card
                          </button>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              )}
              {this.renderButton(
                'Cancel Appointment',
                'Cancelling...',
                'theme-btn blue'
              )}
            </form>
          </div>
        }
        secondaryButton={'Close'}
      />
    );
  }
}

export default connect(mapAuthState)(injectStripe(AppointmentCancel));
