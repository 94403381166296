import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import SitterPrivateProfileForm from '../../Forms/SitterPrivateProfileForm';
import sitterService from '../../../services/sitterService';
import { each } from 'lodash-es';
import { mapAuthState } from '../../../helpers/mapAuthState';

class PrivateProfile extends SitterPrivateProfileForm {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      loading: true,
      id: null,
    };
  }
  componentDidMount = () => {
    this.getMedicalConditions();
    this.getSitter();
  };
  getSitter = async () => {
    try {
      const { id } = this.props.auth.user.personal_info;
      const response = await sitterService.get(id);
      this.formatData(response.data.data);
    } catch (e) {
      console.log(e);
    }
  };
  formatData = ({
    acceptedAgeRanges,
    languages,
    personalInfo,
    profileSitter,
    selectedActivities,
    medicalInfo,
  }) => {
    const { data } = this.state;

    const {
      firstname,
      lastname,
      address1,
      address2,
      city,
      state,
      zip,
      gender,
      phone1,
      sms_optin,
    } = personalInfo;

    const {
      allergy_info,
      emergency_contact_name,
      emergency_contact_phone,
      marital_status,
      smoke,
      pets,
      own_children,
    } = profileSitter;

    data['address1'] = address1;
    data['address2'] = address2;
    data['city'] = city;
    data['state'] = state;
    data['zip'] = zip;
    data['gender'] = gender;
    data['phone1'] = phone1;

    data['allergy_info'] = allergy_info;
    data['emergency_contact_name'] = emergency_contact_name;
    data['emergency_contact_phone'] = emergency_contact_phone;
    data['sms_optin'] = sms_optin;
    data['marital_status'] = marital_status;
    data['smoke'] = smoke;
    data['own_children'] = own_children;
    data['pets'] = pets;

    const selected_medical_conditions = each(medicalInfo, (info) => {
      info['value'] = info.id;
      info['label'] = info.name;
    });

    data['selected_medical_conditions'] = selected_medical_conditions;

    this.setState({
      data,
      medicalInfo,
      personalInfo,
      profileSitter,
      id: personalInfo.id,
    });
  };
  doSubmit = async (callback) => {
    try {
      const response = await this.submitFormData();
      callback();
      if (response.status === 200) {
        toast.success('Hooray! Your changes are saved.');
      }
    } catch (e) {
      console.log(e);
      callback();
    }
  };
  render() {
    const { data } = this.state;
    const { user } = this.props.auth;

    return (
      <main className="account profile sitter-private">
        <form onSubmit={this.handleSubmit}>
          <div
            className={`profile-header profile-color gradient ${
              !!user.personal_info.profile_color
                ? user.personal_info.profile_color
                : 'primary-coral'
            }`}
          >
            <div className="inner">
              <div className="avatar">
                <div className="avatar-wrapper">
                  {!!user.personal_info.avatar_filename && (
                    <img src={user.personal_info.avatar_filename} />
                  )}
                  {!user.personal_info.avatar_filename && (
                    <i className="far fa-camera"></i>
                  )}
                </div>
              </div>
              <h2 className="theme-h2">
                {user.personal_info.firstname}&nbsp;
                {user.personal_info.lastname}
              </h2>
            </div>
          </div>
          <div className="profile-body">
            <h2>Private Profile</h2>
            {this.renderForm()}
            {this.renderButton(
              'Save Private Profile',
              'Saving...',
              'theme-btn blue'
            )}
            <div className="cancel-btn">
              <Link className="theme-btn small slate-blue" to="/profile">
                Cancel
              </Link>
            </div>
          </div>
        </form>
      </main>
    );
  }
}

export default connect(mapAuthState)(PrivateProfile);
