import React from 'react';
import { Link } from 'react-router-dom';

class PublicLanding extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <main className="public-landing">
        <h2>SmartSitter</h2>
        <p>Please log in or register to view the site.</p>
        <p>
          <Link to="/login">Log In</Link>
        </p>
        <p>
          <Link to="/register/sitter">Sitter Register</Link>
        </p>
        <p>
          <Link to="/register/family">Family Register</Link>
        </p>
      </main>
    );
  }
}

export default PublicLanding;
