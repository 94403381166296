export default [
    {
        id: 1,
        slug: 'sitter-signup',
        step: 'Sitter Signup',
    },
    {
        id: 2,
        slug: 'sitter-application-questions',
        step: 'Sitter Application Questions',
    },
    {
        id: 3,
        slug: 'sitter-application-pending',
        step: 'Sitter Application Pending',
    },
    {
        id: 4,
        slug: 'sitter-application-approved',
        step: 'Sitter Application Approved',
    },
    {
        id: 5,
        slug: 'sitter-private-profile',
        step: 'Sitter Private Profile',
    },
    {
        id: 6,
        slug: 'sitter-public-profile',
        step: 'Sitter Public Profile',
    },
    {
        id: 7,
        slug: 'sitter-availability',
        step: 'Sitter Availability',
    },
    {
        id: 16,
        slug: 'sitter-onboarding',
        step: 'Sitter Onboarding',
    },
    {
        id: 17,
        slug: 'sitter-awaiting-admin-approval',
        step: 'Sitter Awaiting Admin Approval',
    },
    {
        id: 8,
        slug: 'sitter-application-finished',
        step: 'Sitter Application Finished',
    },
    {
        id: 9,
        slug: 'sitter-application-denied',
        step: 'Sitter Application Denied',
    },
    {
        id: 10,
        slug: 'family-signup',
        step: 'Family Signup',
    },
    {
        id: 11,
        slug: 'family-profile',
        step: 'Family Profile',
    },
    {
        id: 12,
        slug: 'family-children',
        step: 'Family Children',
    },
    {
        id: 13,
        slug: 'family-pets',
        step: 'Family Pets',
    },
    {
        id: 14,
        slug: 'family-payments',
        step: 'Family Payments',
    },
    {
        id: 15,
        slug: 'family-application-finished',
        step: 'Family Application Finished',
    },
];
