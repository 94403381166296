import { find, findIndex } from 'lodash-es';
import moment from 'moment-timezone';
import React from 'react';
import {
  bookingFreshSchema as freshSchema,
  booleanRadioOptions as radioOptions,
  daysOfWeek,
  wiggleOptions,
} from '../../../data';
import { makeTimeIncrements, upperCaseFirst } from '../../../helpers';
import Checkbox from '../../shared/Form/Checkbox';
import DatePicker from '../../shared/Form/Datepicker';
import Input from '../../shared/Form/Input';
import NumNights from '../../shared/Form/NumNights';
import Radio from '../../shared/Form/Radio';
import Select from '../../shared/Form/Select';
import Textarea from '../../shared/Form/Textarea';

class BulkAppointmentsForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        weekday: '',
        repeatingOptions: [],
        ...freshSchema,
        start_time: '09:00',
        end_time: '12:00',
      },
      minDate: null,
      maxDate: null,

      showWiggleTime: false,
      showOvernight: false,
      showDrivingDistance: false,
      showDelete: false,

      showRepeatingOptions: false,
      repeatingOptions: [],

      times: makeTimeIncrements(0),
      startTimes: makeTimeIncrements(0, 21),
      endTimes: makeTimeIncrements(12, 21),
    };
  }
  componentDidMount() {
    // here we need to calculate minDate and maxDate for when type = random
    let numWeeks = this.props.data.program_num_weeks;
    let startDate = moment(this.props.data.program_start_date);
    let minDate = moment(startDate).startOf('week').toDate();
    let maxDate = moment(startDate)
      .add(numWeeks - 1, 'weeks')
      .endOf('week')
      .toDate();

    this.setState({
      minDate,
      maxDate,
    });

    if (this.props.creating) {
      // new - do nothing
    } else {
      // we need to get the appointment prop to merge it into data
      const data = { ...this.props.appointment };
      this.setState({ data }, () => {
        this.makeRepeatingOptions(false);
      });
    }
  }
  componentDidUpdate(prevProps, prevState) {
    const prevData = prevState.data;
    const data = { ...this.state.data };

    // basically, if we dont add the "creating" check, it will fire the instant the new data loads into the form
    // so we have 2 conditions here, one for when creating, one for when not. the idea is we need to detect changes
    // only when the user changes the input, not when the form itself loads the data and changes it.
    let condition1 =
      data['weekday'] !== '' &&
      data['weekday'] !== prevData['weekday'] &&
      !!this.props.creating;
    let condition2 =
      data['weekday'] !== '' &&
      prevData['weekday'] !== '' &&
      data['weekday'] !== prevData['weekday'] &&
      !this.props.creating;
    if (condition1 || condition2) {
      this.makeRepeatingOptions(true);
    }

    if (
      data['overnight'] !== null &&
      data['overnight'] !== prevData['overnight']
    ) {
      // we need to filter end times based on the value of overnight (no filter when overnight = true)
      // responding to overnight change
      const { overnight } = data;
      let newEndTimes = this.createNewEndTimes(data['start_time'], overnight);
      let timeStillExists = find(newEndTimes, (time) => {
        return time.value == data['end_time'];
      });
      if (!timeStillExists) {
        data['end_time'] = newEndTimes[0].value;
      }
      (data['num_nights'] = data['overnight'] ? 1 : null),
        this.setState({
          endTimes: newEndTimes,
          showOvernight: !!data['overnight'],
          data,
        });
    }

    if (
      data['start_time'] !== null &&
      prevData['start_time'] !== data['start_time']
    ) {
      // we need to filter end times based on the value of overnight (no filter when overnight = true)
      // responding to start time change
      let newEndTimes = this.createNewEndTimes(
        data['start_time'],
        data['overnight']
      );
      let timeStillExists = find(newEndTimes, (time) => {
        return time.value == data['end_time'];
      });
      if (!timeStillExists) {
        data['end_time'] = newEndTimes[0].value;
      }
      this.setState({
        endTimes: newEndTimes,
        data,
      });
    }

    if (data['flex'] === 1 && prevData['flex'] !== 1) {
      const { data } = this.state;
      data['flex_room'] = `${wiggleOptions[0].id}`; // num to string conversion hack
      this.setState({
        showWiggleTime: true,
        data,
      });
    } else if (data['flex'] === 0 && prevData['flex'] !== 0) {
      const { data } = this.state;
      data['flex_room'] = null;
      this.setState({
        showWiggleTime: false,
        data,
      });
    }

    // If driving is true, show the driving_distance field
    if (
      data['driving_needed'] === 1 &&
      prevData['driving_needed'] !== data['driving_needed']
    ) {
      this.setState({
        showDrivingDistance: true,
      });
    } else if (
      data['driving_needed'] === 0 &&
      prevData['driving_needed'] !== data['driving_needed']
    ) {
      this.setState({
        showDrivingDistance: false,
      });
    }
  }
  createNewEndTimes = (start_time, overnight) => {
    let newEndTimes = [...this.state.times];
    newEndTimes.splice(0, 1);
    if (!overnight) {
      const idx = findIndex(newEndTimes, (time) => {
        return time.value == start_time;
      });
      const length = idx + 12;
      if (length === newEndTimes.length) {
        // if they select the very last time slot (11:45pm), then there are 0 options left
        // So we force there to be only 1 item
        newEndTimes.splice(0, length - 1);
      } else {
        newEndTimes.splice(0, length);
      }
    }
    return newEndTimes;
  };
  makeRepeatingOptions = (overwrite) => {
    // this function is used to both generate the options for repeat appts and also set them once they've changed
    // we use it to generate when editing an appointment, and we use it to generate and set when creating a new appointment
    // the distinction is bc we need to load the user's previously selected repeating options on edit, but not on create
    const data = { ...this.state.data };
    let weekday = data['weekday'];
    let numWeeks = this.props.data.program_num_weeks;
    let startDate = moment(this.props.data.program_start_date);
    const repeatingOptions = [];
    const selectedOptions = [];
    // here we use "i" to keep track of the "week #" of the repeating appt.
    // so if the appt repeats for 8 weeks, we keep track of the week via array of week numbers ([ 1, 3, 4, 5, 8 ])
    for (var i = 1; i <= numWeeks; i++) {
      repeatingOptions.push({
        label: moment(startDate)
          .add(i - 1, 'weeks')
          .startOf('week')
          .add(weekday, 'days')
          .format('dddd, MMMM Do'),
        value: i,
      });
      selectedOptions.push(`${i}`);
    }

    if (overwrite) {
      // we need to make sure to reset repeatingOptions here since the dates have most likely changed
      data['repeatingOptions'] = selectedOptions;
    }
    this.setState({
      repeatingOptions,
      data,
    });
  };
  handleChange = (name, value, type = 'default') => {
    const data = { ...this.state.data };
    if (type === 'number') {
      data[name] = Number(value);
    } else {
      data[name] = value;
    }
    this.setState({ data });
  };
  saveAppointment = () => {
    if (this.props.creating) {
      const { data } = this.props;
      const appointment = { ...this.state.data };
      let length =
        data['randomAppointments'].length +
        data['repeatingAppointments'].length +
        1;
      // we add length before and after just to make sure the chance 2 appts end up with the same rid is as low as possible
      appointment['rid'] =
        length + Math.round(Math.random() * 10000000000) + length;
      this.props.addNewAppointment(this.props.type, appointment);
    } else {
      // we need to replace the old appointment with the new one.
      const appointment = { ...this.state.data };
      this.props.saveEditedAppointment(this.props.type, appointment);
    }
  };
  isAppointmentFinished = () => {
    const { data } = this.state;
    const { type } = this.props;
    if (type === 'repeating') {
      // check required fields for repeating
      if (data['weekday'] === '' || data['repeatingOptions'].length === 0)
        return false;
    } else {
      // check required fields for date
      if (!data['date']) return false;
    }
    if (!data['start_time'] || !data['end_time']) return false;
    if (!!data['overnight']) {
      // if overnight checked, check required fields for overnight
      if (!data['num_nights']) return false;
    }
    if (!!data['flex']) {
      // if flex checked, check required fields for flex
      if (!data['flex_room']) return false;
    }
    if (!!data['driving_needed']) {
      // if driving_needed checked, check required fields for driving_needed
      if (!data['driving_distance']) return false;
    }
    // all fields are valid
    return true;
  };
  handleCheckboxChange = ({ currentTarget: input }) => {
    // we can hard code this name to repeatingOptions since there are no other checkboxes
    // just something to keep in mind.
    const data = { ...this.state.data };
    let list = data['repeatingOptions'];
    if (input.checked) {
      // if (list.indexOf(input.value) === -1) {
      //add to array
      if (list === undefined) {
        list = [input.value];
      } else {
        list.push(input.value);
      }
    } else {
      //remove from array
      list = list.filter(function (item) {
        return item !== input.value;
      });
    }
    data['repeatingOptions'] = list;
    this.setState({ data });
  };
  toggleRepeatingOptions = () => {
    this.setState({
      showRepeatingOptions: !this.state.showRepeatingOptions,
    });
  };
  toggleDeleteAppointment = () => {
    this.setState({
      showDelete: !this.state.showDelete,
    });
  };
  render() {
    const { type, creating } = this.props;
    const {
      data,
      minDate,
      maxDate,
      startTimes,
      endTimes,
      showWiggleTime,
      showOvernight,
      showDrivingDistance,
      showRepeatingOptions,
      repeatingOptions,
      showDelete,
    } = this.state;

    // the form fields expect an errors object. even if we wont use it, we need to pass something in, soo.
    const errors = {};

    let endTimeDisabled = !data['start_time'];
    let isAppointmentFinished = this.isAppointmentFinished();

    return (
      <div
        className={`bulk-appointment-form ${type} ${
          creating ? 'creating' : 'editing'
        }`}
      >
        <h3>Add {type} Appointment</h3>
        <div className="bulk-appointment-form-wrapper">
          <div className="form-group times">
            {type == 'repeating' && (
              <Select
                className="weekday"
                name={`bulk-apt-${type}-weekday`}
                label="Day"
                options={daysOfWeek}
                value={data['weekday']}
                error={errors['weekday']}
                onChange={(e) => {
                  this.handleChange('weekday', e.target.value, 'number');
                }}
                required={true}
              />
            )}
            {type == 'random' && (
              <DatePicker
                className="date"
                name={`bulk-booking-random-date`}
                label={`Date`}
                value={data['date']}
                error={errors['date']}
                required={true}
                dateFormat={'MM/d/yy'}
                onChange={(date) => {
                  this.handleChange('date', date);
                }}
                minDate={minDate}
                maxDate={maxDate}
              />
            )}
            <Select
              className="start_time"
              name={`bulk-apt-${type}-start_time`}
              label="Start"
              options={startTimes}
              value={data['start_time']}
              error={errors['start_time']}
              onChange={(e) => {
                this.handleChange('start_time', e.target.value);
              }}
              required={true}
            />
            <Select
              className="end_time"
              name={`bulk-apt-${type}-end_time`}
              label="End"
              options={endTimes}
              value={data['end_time']}
              error={errors['end_time']}
              onChange={(e) => {
                this.handleChange('end_time', e.target.value);
              }}
              required={true}
              disabled={endTimeDisabled}
            />
          </div>
          <div className="form-group flex">
            <Radio
              className="flex"
              name={`bulk-apt-${type}-flex`}
              label="Wiggle Room:"
              options={radioOptions}
              value={data['flex']}
              error={errors['flex']}
              onChange={(e) => {
                this.handleChange('flex', e.target.value, 'number');
              }}
              required={true}
            />
            {!!showWiggleTime && (
              <Select
                className="flex_room"
                name={`bulk-apt-${type}-flex_room`}
                label="Time"
                options={wiggleOptions}
                value={data['flex_room']}
                error={errors['flex_room']}
                onChange={(e) => {
                  this.handleChange('flex_room', e.target.value, 'number');
                }}
                required={true}
              />
            )}
          </div>
          <div className="form-group notes">
            {type === 'repeating' && (
              <Textarea
                className="notes"
                name={`bulk-apt-${type}-notes`}
                label={
                  !!data['weekday']
                    ? `General routine and details for ${moment(
                        data['weekday'],
                        'd'
                      ).format('dddd')}`
                    : 'General routine and details'
                }
                value={data['notes']}
                error={errors['notes']}
                onChange={(e) => {
                  this.handleChange('notes', e.target.value);
                }}
                required={false}
              />
            )}
            {type === 'random' && (
              <Textarea
                className="notes"
                name={`bulk-apt-${type}-notes`}
                label="Notes for this appointment"
                value={data['notes']}
                error={errors['notes']}
                onChange={(e) => {
                  this.handleChange('notes', e.target.value);
                }}
                required={false}
              />
            )}
          </div>
          <div className="form-group overnight">
            <Radio
              className="overnight"
              name={`bulk-apt-${type}-overnight`}
              label="Overnight:"
              options={radioOptions}
              value={data['overnight']}
              error={errors['overnight']}
              onChange={(e) => {
                this.handleChange('overnight', e.target.value, 'number');
              }}
              required={true}
            />
            {!!showOvernight && (
              <NumNights
                className="num_nights"
                name={`bulk-apt-${type}-num_nights`}
                label="Nights:"
                value={data['num_nights']}
                onChange={(label, value) => {
                  this.handleChange('num_nights', value, 'number');
                }}
                required={true}
              />
            )}
          </div>
          <div className="form-group">
            <Radio
              className="driving_needed"
              name={`bulk-apt-${type}-driving_needed`}
              label="Will this appointment require driving kids?"
              options={radioOptions}
              value={data['driving_needed']}
              error={errors['driving_needed']}
              onChange={(e) => {
                this.handleChange('driving_needed', e.target.value, 'number');
              }}
              required={true}
            />
            {!!showDrivingDistance && (
              <Input
                className="driving_distance"
                name={`bulk-apt-${type}-driving_distance`}
                label="Approx. how many miles required?"
                type="number"
                value={data['driving_distance']}
                error={errors['driving_distance']}
                onChange={(e) => {
                  this.handleChange('driving_distance', e.target.value);
                }}
                required={true}
              />
            )}
          </div>
          {type === 'repeating' && data['weekday'] !== '' && (
            <div
              className={`repeating-options ${
                !!showRepeatingOptions ? 'open' : ''
              }`}
            >
              <button
                onClick={this.toggleRepeatingOptions}
                className="toggle-repeat-btn"
              >
                <i className="fas fa-sliders-v-square"></i>
                &nbsp;&nbsp;&nbsp;Repeating Options
              </button>
              {!!showRepeatingOptions && (
                <div className="inner">
                  <p>
                    Our Consistent Care option is designed to provide coverage
                    for your family with a limited number of sitters, while
                    still allowing flexibility in your recurring schedule. You
                    can choose to proceed with all recurring dates or remove any
                    days that may not be needed. If any dates are removed, your
                    scheduling fee total will be updated to reflect the days
                    that are not needed.
                  </p>
                  <Checkbox
                    className="repeatingOptions"
                    name={`bulk-apt-${data['weekday']}-${type}-repeatingOptions`}
                    label="Repeating Options"
                    value={data['repeatingOptions']}
                    error={errors['repeatingOptions']}
                    options={repeatingOptions}
                    onChange={this.handleCheckboxChange}
                    required={true}
                  />
                </div>
              )}
            </div>
          )}
          <div className="btn-wrapper">
            {!!isAppointmentFinished && (
              <button
                className="theme-btn primary-green tall"
                onClick={this.saveAppointment}
              >
                {type === 'repeating' && (
                  <span>
                    <i className="fas fa-repeat"></i>
                    &nbsp;Save Repeating Day
                  </span>
                )}
                {type === 'random' && (
                  <span>
                    <i className="fas fa-calendar-day"></i>
                    &nbsp;Save Random Day
                  </span>
                )}
              </button>
            )}
            {!isAppointmentFinished && (
              <button className="theme-btn primary-green tall disabled">
                {type === 'repeating' && (
                  <span>
                    <i className="fas fa-repeat"></i>
                    &nbsp;Save Repeating Day
                  </span>
                )}
                {type === 'random' && (
                  <span>
                    <i className="fas fa-calendar-day"></i>
                    &nbsp;Save Random Day
                  </span>
                )}
              </button>
            )}
            <br />
            <br />
            {!showDelete && (
              <button
                className="theme-btn small error-red"
                onClick={this.toggleDeleteAppointment}
              >
                <i className="fas fa-trash-alt"></i>&nbsp;Delete
              </button>
            )}
            {showDelete && (
              <div className="appointment-delete-notice">
                <p>
                  <b>Delete {upperCaseFirst(type)} Day</b>
                  <br />
                  Are you sure you want to delete? The booking date will be
                  removed and all settings for this appointment will be deleted
                  and lost.
                </p>
                <button
                  className="theme-btn error-red"
                  onClick={this.props.deleteAppointment}
                >
                  <i className="fas fa-trash-alt"></i>
                  &nbsp;Delete
                </button>
                <br />
                <button
                  className="theme-btn small slate-blue"
                  onClick={this.toggleDeleteAppointment}
                >
                  Cancel
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default BulkAppointmentsForm;
