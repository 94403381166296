import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import ChildForm from '../../Forms/ChildForm';
import childService from '../../../services/childService';
import { ProfileColorPicker, Modal } from '../../shared';
import { displayProfileColor } from '../../../helpers';
import { each } from 'lodash-es';
import { mapAuthState } from '../../../helpers/mapAuthState';

const AvatarDisplayComponent = (props) => {
  return (
    <div className="avatar">
      <div className="avatar-wrapper">
        {!!props.value && <img src={props.value} />}
        {!props.value && <i className="far fa-camera"></i>}
      </div>
    </div>
  );
};

class ChildProfile extends ChildForm {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      loading: true,
      id: null,
      creating: true,
      family_id: null,
      showRemoveChild: false,
      removingChild: false,
    };
  }
  componentDidMount = () => {
    this.getMedicalConditions();

    const { id } = this.props.match.params;
    if (id !== 'new' && Number(id) == id) {
      // abusing a quirk of JS where "11" == 11 to ensure the ID is a number
      this.getChild(id);
    } else {
      this.setState({
        data: { ...this.state.freshSchema },
        creating: true,
        family_id: this.props.auth.user.personal_info.family_id,
      });
    }
  };
  getChild = async (id) => {
    try {
      const response = await childService.get(id);
      if (response.status === 200) {
        const { data } = response.data;
        this.formatData(data);
      }
    } catch (e) {
      console.log(e);
    }
  };
  formatData = ({ medicalInfo, personalInfo, profileChild }) => {
    const {
      DOB,
      avatar_filename,
      firstname,
      lastname,
      unborn,
      family_id,
      profile_color,
    } = personalInfo;
    const { about, allergy_info, pediatrician_info, notes } = profileChild;

    const { data } = this.state;

    const selected_medical_conditions = each(medicalInfo, (info) => {
      info['value'] = info.id;
      info['label'] = info.name;
    });

    data['firstname'] = firstname;
    data['lastname'] = lastname;
    data['avatar_filename'] = avatar_filename;
    data['unborn'] = unborn;

    const dobPieces = DOB.split('-');
    if (dobPieces.length === 3) {
      // We assume format "YYYY-MM-DD"
      // so after splittig, it should be: [ "YYYY", "MM", "DD" ]
      data['dob_year'] = dobPieces[0];
      data['dob_month'] = dobPieces[1];
      data['dob_day'] = dobPieces[2];
    }

    data['about'] = about;
    data['allergy_info'] = allergy_info;
    data['notes'] = notes;
    data['profile_color'] =
      profile_color !== null ? profile_color : 'primary-coral';
    data['pediatrician_info'] = pediatrician_info;

    data['selected_medical_conditions'] = selected_medical_conditions;

    this.setState({
      data,
      creating: false,
      medicalInfo,
      personalInfo,
      profileChild,
      id: profileChild.id,
      family_id,
    });
  };
  toggleRemoveChild = (e) => {
    if (!!e) e.preventDefault();
    this.setState({
      showRemoveChild: !this.state.showRemoveChild,
    });
  };
  doRemoveChild = async (e) => {
    e.preventDefault();
    try {
      const { id } = this.props.match.params;
      if (id !== 'new' && Number(id) == id) {
        const response = await childService.delete(id);
        if (response.status === 204) {
          toast.success('This child has been removed from your account.');
          this.props.history.push('/profile');
        } else if (response.status === 200) {
          const { message } = response.data;
          toast.error(message);
          this.setState({
            showRemoveChild: false,
          });
        }
      }
    } catch (e) {
      console.log(e);
    }
  };
  handleProfileColorChange = (colorClass) => {
    const { data } = this.state;
    data['profile_color'] = colorClass;
    this.setState({ data });
  };
  doSubmit = async (callback) => {
    const response = await this.submitFormData();
    callback();
    if (response.status === 200 || response.status === 201) {
      toast.success('Hooray! Your changes are saved.');
      if (this.state.creating) {
        const { data } = response.data;
        const { id } = data.profileChild;
        this.formatData(data);
        this.setState(
          {
            creating: false,
          },
          () => {
            this.props.history.replace(`/profile/child/${id}`);
          }
        );
      }
    }
  };
  render() {
    const { data, creating, removingChild, showRemoveChild } = this.state;

    return (
      <main className="account profile child">
        <form onSubmit={this.handleSubmit}>
          <div
            className={`profile-header ${displayProfileColor(
              data.profile_color,
              'gradient'
            )}`}
          >
            <div className="inner">
              {this.renderFile(
                'avatar_filename',
                'Upload Your Public Profile Image',
                'image/jpg, image/png',
                false,
                AvatarDisplayComponent
              )}
              <h2 className="theme-h2">
                {data.firstname}&nbsp;{data.lastname}
              </h2>
            </div>
          </div>
          <div className="profile-body">
            <h2>Child Profile</h2>
            <ProfileColorPicker
              value={data.profile_color}
              onProfileColorChange={this.handleProfileColorChange}
            />
            {this.renderForm()}
            {this.renderButton(
              'Save Child Details',
              'Saving...',
              'theme-btn blue'
            )}
            <div className="cancel-btn">
              {!creating && (
                <button
                  className="theme-btn small error-red"
                  onClick={this.toggleRemoveChild}
                >
                  Delete
                </button>
              )}
              <Link className="theme-btn small slate-blue" to="/profile">
                Cancel
              </Link>
            </div>
          </div>
        </form>
        <Modal
          title="Confirm Deletion"
          isOpen={showRemoveChild}
          closeModal={this.toggleRemoveChild}
          content={
            'Are you sure you want to remove this child from your account?'
          }
          primaryButton={removingChild ? ' Removing' : 'Remove'}
          secondaryButton={'Close'}
          handleClick={this.doRemoveChild}
        />
      </main>
    );
  }
}

export default connect(mapAuthState)(ChildProfile);
