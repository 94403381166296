import { GridColumnMenuCheckboxFilter, GridColumnMenuFilter, } from '@progress/kendo-react-grid';
import { displayAppointmentDuration, displayAppointmentTime, slugify, upperCaseFirst, } from '@ss/helpers';
import clsx from 'clsx';
import { filter, get } from 'lodash-es';
import moment from 'moment-timezone';
import React from 'react';
import { Link } from 'react-router-dom';
export const ColumnMenu = (props) => {
    return (React.createElement("div", null,
        React.createElement(GridColumnMenuFilter, { ...props, expanded: true })));
};
export const ColumnMenuCheckboxFilter = (props) => {
    return (React.createElement("div", null,
        React.createElement(GridColumnMenuCheckboxFilter, { ...props, data: props.sitters, expanded: true })));
};
export const TimeTickerCell = (props) => {
    const createdDate = moment(props.dataItem[props.field]);
    const today = moment(new Date());
    const days = Math.round(moment.duration(today.diff(createdDate)).asDays());
    if (days > 1) {
        return React.createElement("td", null,
            days,
            " days ago");
    }
    const hours = Math.round(moment.duration(today.diff(createdDate)).asHours());
    if (hours > 1) {
        return React.createElement("td", null,
            hours,
            " hours ago");
    }
    return React.createElement("td", null, "<1 hour ago");
};
export const DateCell = (props) => {
    const date = props.dataItem[props.field];
    return React.createElement("td", null, date && moment(date).format('MMM Do'));
};
export const CancelledCell = (props) => {
    const date = props.dataItem[props.field];
    return (React.createElement("td", null,
        date && moment(date).format('MMMM Do @ h:mm a'),
        !date && 'No Date Found'));
};
export const TimeCell = (props) => {
    return (React.createElement("td", null,
        displayAppointmentTime(props.dataItem),
        " (",
        displayAppointmentDuration(props.dataItem),
        ")"));
};
export const ManageCell = (props) => {
    const id = get(props.dataItem, props.field);
    return (React.createElement("td", { className: "manage-cell" },
        React.createElement(Link, { className: "theme-btn blue small", to: `/appointments/view/${id}` }, "Manage")));
};
export const ManageBookingsCell = (props) => {
    const family_id = get(props.dataItem, props.field);
    const id = props.dataItem.id;
    return (React.createElement("td", { className: "manage-cell" },
        React.createElement(Link, { className: "theme-btn blue small", to: `/families/appointments/book/${family_id}/${id}` }, "Manage")));
};
export const StatusCell = (props) => {
    let status = props.dataItem[props.field];
    // We do this because there are some internal system statuses that aren't really "readable", so we translate
    // them into something a little more understandable to the admin.
    if (status === 'program_pending') {
        status = 'program-pending';
    }
    if (status === 'submitted-timed') {
        status = 'auto-assigning';
    }
    if (status === 'pending-admin') {
        status = 'admin-priority';
    }
    let icon = 'fas fa-exclamation-circle';
    if (status === 'accepted' || status === 'completed') {
        icon = 'fas fa-check-circle';
    }
    if (status === 'cancelled') {
        icon = 'fas fa-times-circle';
    }
    if (status === 'time change requested') {
        icon = 'fas fa-clock';
    }
    if (status === 'pending-cover') {
        // this user-circle is hollow, i feel like it's fitting for "pending person"
        icon = 'far fa-user-circle';
    }
    if (status === 'cover-acceptance-needed') {
        // this user-circle is solid
        icon = 'fas fa-user-circle';
    }
    return (React.createElement("td", { className: `status-cell ${slugify(status)}` },
        React.createElement("div", { className: "tooltip-wrapper" },
            React.createElement("div", { className: "inner" },
                React.createElement("i", { className: icon }),
                React.createElement("div", { className: "tooltip-item" },
                    React.createElement("p", null, status
                        .split('-')
                        .map((str) => upperCaseFirst(str))
                        .join(' ')))))));
};
export const BooleanCell = (props) => {
    const bool = props.dataItem[props.field];
    return React.createElement("td", null, bool ? 'Yes' : 'No');
};
export const FamilyCell = (props) => {
    const family = props.dataItem[props.var];
    const avatar = family.avatar_filename;
    const name = family.name;
    return (React.createElement("td", { className: "avatar-cell family" },
        React.createElement("span", null,
            React.createElement("div", { className: "avatar" },
                React.createElement("div", { className: "avatar-wrapper" },
                    !!avatar && React.createElement("img", { src: avatar }),
                    !avatar && React.createElement("i", { className: "far fa-camera" }))),
            name)));
};
export const SittersCell = (props) => {
    const sitters = props.dataItem[props.field];
    const rejectedSitters = props.dataItem.rejected_sitters;
    const sitterPreferences = !!props.sitterPreferences
        ? props.sitterPreferences
        : [];
    const apptStatus = props.dataItem.status;
    if (apptStatus === 'pending-list' || apptStatus === 'pending-list-approval' || apptStatus === 'pending-admin') {
        let message = 'Admin Priority (Pending)';
        if (apptStatus === 'pending-list') {
            message = 'Admin Priority (On Pending List)';
        }
        ;
        if (apptStatus === 'pending-list-approval') {
            message = 'Admin Priority (Needs Approval)';
        }
        ;
        return (React.createElement("td", { className: "avatar-cell sitter" }, message));
    }
    ;
    if (!!sitters && sitters.length > 0) {
        return (React.createElement("td", { className: "avatar-cell sitter" }, sitters.map((sitter) => {
            const avatar = sitter.avatar_filename;
            const name = sitter.firstname + ' ' + sitter.lastname;
            return (React.createElement("span", { key: `sitter-${sitter.id}` },
                React.createElement("div", { className: "avatar" },
                    React.createElement("div", { className: "avatar-wrapper" },
                        avatar && React.createElement("img", { src: avatar }),
                        !avatar && React.createElement("i", { className: "far fa-camera" }))),
                React.createElement("div", { className: "avatar-content" },
                    name,
                    apptStatus === 'submitted-timed' && (React.createElement(React.Fragment, null,
                        React.createElement("br", null),
                        React.createElement("span", { className: "auto-assigning" },
                            "Auto-assigning (",
                            rejectedSitters.length,
                            " of 3)"))),
                    sitterPreferences.map((sitterPreference) => (React.createElement(React.Fragment, { key: `sitter-preference-${sitterPreference.id}` },
                        React.createElement("span", null, sitterPreference.sitter_piid === sitter.id &&
                            !!sitterPreference.favorite && (React.createElement("i", { className: "fas fa-star" }))),
                        React.createElement("span", null, sitterPreference.sitter_piid === sitter.id &&
                            !!sitterPreference.interested && (React.createElement("i", { className: "fas fa-save" })))))))));
        })));
    }
    return (React.createElement("td", { className: "avatar-cell sitter" },
        React.createElement("span", null, "Pending")));
};
export const SitterNameCell = (props) => {
    const sitter = props.dataItem;
    const avatar = sitter.avatar_filename;
    const name = `${sitter.firstname} ${sitter.lastname}`;
    return (React.createElement("td", { className: "avatar-cell sitter" },
        React.createElement("span", null,
            React.createElement("div", { className: "avatar" },
                React.createElement("div", { className: "avatar-wrapper" },
                    avatar && React.createElement("img", { src: avatar }),
                    !avatar && React.createElement("i", { className: "far fa-camera" }))),
            name)));
};
export const AvailabilityCell = (props) => {
    const data = props.dataItem[props.field];
    const availability = data ? data.split(';') : [];
    return (React.createElement("td", { className: "availability-cell" },
        availability.length === 0 && React.createElement("span", null, "No Availability"),
        availability.map((slot, i) => (React.createElement("div", { className: "availability-slot active", key: `availability-slot-${i}` },
            React.createElement("p", null, slot.split('::')[0]),
            React.createElement("p", { className: "small" },
                "(",
                slot.split('::')[1],
                ")"))))));
};
export const AssignCell = (props) => {
    const piid = props.dataItem[props.field];
    const { appointment } = props;
    const { sitters } = appointment;
    const isAssigned = filter(sitters, (sitter) => {
        return sitter.id === piid;
    }).length > 0;
    return (React.createElement("td", { className: "assign-cell" },
        React.createElement("a", { href: "#", onClick: (e) => {
                e.preventDefault();
                if (!isAssigned) {
                    props.onAssign(piid);
                }
            }, className: clsx('theme-btn blue small', isAssigned ? 'disabled' : '') }, isAssigned ? 'Assigned' : 'Assign')));
};
