import Joi from 'joi';
import React from 'react';
import { Link } from 'react-router-dom';
import { states } from '../../data';
import familyService from '../../services/familyService';
import Form from '../shared/Form';
const radioOptions = [
    { label: 'Yes', value: 1 },
    { label: 'No', value: 0 },
];
const sitterRadioOptions = [
    { label: 'Any', value: 'any' },
    { label: 'Female', value: 'female' },
];
class FamilyAccountSettingsForm extends Form {
    schema = Joi.object({
        avatar_filename: Joi.alternatives(Joi.string(), Joi.object())
            .required()
            .messages({
            'alternatives.any': 'Avatar is required',
            'alternatives.all': 'Avatar is required',
            'alternatives.one': 'Avatar is required',
            'alternatives.types': 'Avatar is required',
            'alternatives.match': 'Avatar is required',
            'any.required': 'Avatar is required',
        })
            .label('Avatar'),
        secondary_email: Joi.string()
            .optional()
            .allow(null)
            .allow('')
            .label('Secondary Email'),
        profile_color: Joi.string()
            .optional()
            .allow(null)
            .allow('')
            .label('Profile Color'),
        firstname: Joi.string().required().label('First Name'),
        lastname: Joi.string().required().label('Last Name'),
        address1: Joi.string().required().label('Address (line 1)'),
        address2: Joi.string()
            .optional()
            .allow(null)
            .allow('')
            .label('Address (line 2)'),
        city: Joi.string().required().label('City'),
        state: Joi.string().required().label('State'),
        zip: Joi.string().required().label('ZIP code'),
        phone1: Joi.string().required().label('Mobile Number'),
        phone2: Joi.string().required().label('Secondary Number (Work)'),
        employer: Joi.string()
            .optional()
            .allow(null)
            .allow('')
            .label('Place of Employment'),
        sexual_criminal_offense: Joi.boolean()
            .truthy(1)
            .falsy(0)
            .required()
            .label('Sexual / Criminal Offense'),
        smoke: Joi.boolean()
            .truthy(1)
            .falsy(0)
            .required()
            .label('Household Smokers'),
        selected_activities: Joi.array().optional().label('Selected Activities'),
        house_rules: Joi.string()
            .optional()
            .allow(null)
            .allow('')
            .label('House Rules'),
        sitter_preference: Joi.string()
            .required()
            .label('Sitter Preference'),
    });
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            data: {
                avatar_filename: null,
                profile_color: 'primary-coral',
                firstname: '',
                lastname: '',
                address1: '',
                address2: '',
                city: '',
                state: '',
                zip: '',
                phone1: '',
                phone2: '',
                employer: '',
                sexual_criminal_offense: '',
                smoke: '',
                selected_activities: [],
                secondary_email: '',
                house_rules: '',
                sitter_preference: '',
            },
        };
    }
    submitFormData = async () => {
        const apiData = { ...this.state.data };
        const { family_id, piid } = this.state;
        apiData.family_id = family_id;
        apiData.piid = piid;
        const data = new FormData();
        const headers = { headers: { 'content-type': 'multipart/form-data' } };
        data.append('_method', 'post');
        for (const key of Object.keys(apiData)) {
            if (typeof apiData[key] === 'object') {
                if (key === 'avatar_filename') {
                    if (apiData[key] !== null) {
                        data.append(key, apiData[key]);
                    }
                }
                else {
                    data.append(key, JSON.stringify(apiData[key]));
                }
            }
            else {
                data.append(key, apiData[key]);
            }
        }
        try {
            return await familyService.update(family_id, data, headers);
        }
        catch (error) {
            return error;
        }
    };
    renderForm(showChangeEmail, showChangePassword, activityOptions) {
        return (React.createElement("div", { className: "account-settings-form family" },
            React.createElement("div", { className: "form-group" },
                React.createElement("h3", null, "Secondary Contact Info"),
                React.createElement("p", null, "The secondary email will receive all emails sent out by the system involving Bookings. Everything else will only go to the primary account holder."),
                this.renderInput('secondary_email', 'Secondary Email', 'text', false)),
            React.createElement("div", { className: "form-group" },
                React.createElement("h3", null, "Your Information"),
                this.renderInput('firstname', 'First Name', 'text', true),
                this.renderInput('lastname', 'Last Name', 'text', true),
                React.createElement("div", { className: "link-wrapper" },
                    !!showChangeEmail && (React.createElement(Link, { to: "/email/update", className: "theme-btn blue small" },
                        React.createElement("i", { className: "fas fa-sync" }),
                        "\u00A0Update Email")),
                    !!showChangePassword && (React.createElement(Link, { to: "/password/update", className: "theme-btn primary-green small" },
                        React.createElement("i", { className: "fas fa-sync" }),
                        "\u00A0Update Password")))),
            React.createElement("div", { className: "form-group" },
                React.createElement("h3", null, "Family Information"),
                this.renderInput('house_rules', 'House Rules', 'text', true),
                React.createElement("br", null),
                this.renderRadio('sitter_preference', 'Do you have a sitter preference?', sitterRadioOptions, true)),
            React.createElement("div", { className: "form-group" },
                React.createElement("h3", null, "Contact and Address Information"),
                this.renderInput('phone1', 'Mobile Phone', 'number', true),
                this.renderInput('phone2', 'Secondary Phone (Work)', 'number', true),
                this.renderInput('address1', 'Address', 'text', true),
                this.renderInput('address2', 'Apt/Suite', 'text'),
                this.renderInput('city', 'City', 'text', true),
                this.renderSelect('state', 'State', states, true),
                this.renderInput('zip', 'ZIP', 'number', true)),
            React.createElement("div", { className: "form-group" },
                React.createElement("h3", null, "History Information"),
                this.renderInput('employer', 'Place of Employment (optional)'),
                this.renderRadio('sexual_criminal_offense', 'Has anyone in the home been convicted of any sexual or criminal offenses?', radioOptions, true),
                this.renderRadio('smoke', 'Does anyone in your household smoke?', radioOptions, true)),
            React.createElement("div", { className: "form-group" },
                React.createElement("h3", null, "Requested Sitter Activities"),
                this.renderCheckbox('selected_activities', 'Description of Skills and Activities', activityOptions))));
    }
}
export default FamilyAccountSettingsForm;
