import { map, debounce, each, sortBy } from 'lodash-es';
import moment from 'moment-timezone';
import React from 'react';
import { Link, Prompt } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  bookingFreshSchema as freshSchema,
  booleanRadioOptions as radioOptions,
  wiggleOptions,
} from '../../../data';
import {
  displayAddress,
  findLinkGroupByTitle,
  makeTimeIncrements,
  slugify,
  upperCaseFirst,
} from '../../../helpers';
import appointmentService from '../../../services/appointmentService';
import familyService from '../../../services/familyService';
import sitterService from '../../../services/sitterService';
import { Avatar, Modal } from '../../shared';
import Calendar from '../../shared/Appointments/Calendar';
import AsyncSelect from '../../shared/Form/AsyncSelect';
import BooleanInput from '../../shared/Form/BooleanInput';
import ChildCheckbox from '../../shared/Form/ChildCheckbox';
import Input from '../../shared/Form/Input';
import NumNights from '../../shared/Form/NumNights';
import Radio from '../../shared/Form/Radio';
import Select from '../../shared/Form/Select';
import Textarea from '../../shared/Form/Textarea';
import Sidebar from '../Sidebar';
import ProgramBookingRow from './ProgramBookingRow';
import ProgramCommunications from './ProgramCommunications';

const bulkEditFieldOptions = [
  { name: '', id: null },
  { name: 'Start Time', id: 'bulk_edit_start_time' },
  { name: 'End Time', id: 'bulk_edit_end_time' },
  { name: 'Wiggle Room Needed', id: 'bulk_edit_flex' },
  { name: 'Wiggle Time', id: 'bulk_edit_flex_room' },
  { name: 'Driving Needed', id: 'bulk_edit_driving_needed' },
  { name: 'Driving Distance', id: 'bulk_edit_driving_distance' },
  { name: 'Overnight', id: 'bulk_edit_overnight' },
  { name: 'Number of Nights', id: 'bulk_edit_num_nights' },
  { name: 'Children', id: 'bulk_edit_selected_children' },
  { name: 'Provider', id: 'bulk_edit_selected_sitters' },
];

class ProgramBooking extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        booking_appointments: [],
        deleted_ids: [],

        // These are for the "default appointment settings"
        program_start_time: '',
        program_end_time: '',
        program_flex: 0,
        program_flex_room: null,
        program_overnight: 0,
        program_num_nights: null,
        program_driving_needed: 0,
        program_driving_distance: '',
        program_all_children: 1,
        program_selected_children: [],
        program_selected_sitters: null,
        // These are the default appointment settings, but are not changeable by the user.
        program_address1: '',
        program_address2: '',
        program_city: '',
        program_state: '',
        program_zip: '',
        sitter_notes: '',
        admin_notes: '',

        // These are used for bulk edit
        bulk_edit_field: '',

        bulk_edit_start_time: '',
        bulk_edit_end_time: '',
        bulk_edit_flex: 0,
        bulk_edit_flex_room: null,
        bulk_edit_overnight: 0,
        bulk_edit_num_nights: null,
        bulk_edit_driving_needed: 0,
        bulk_edit_driving_distance: '',
        bulk_edit_all_children: 1,
        bulk_edit_selected_children: [],
        bulk_edit_selected_sitters: null,
      },
      freshSchema: {
        ...freshSchema,
      },

      currentTab: 'schedule',

      suppressNotifications: true,
      errors: {},
      children: [],
      profileFamily: {},
      booking: false,
      owner: {},
      pets: [],
      sitters: [],
      appointments: [],
      activities: [],

      loading: true,
      linkGroup: findLinkGroupByTitle('Programs'),
      times: makeTimeIncrements(0),

      calendarDate: moment(),
      canDecreaseDate: false,
      months: [moment(), moment().add(1, 'months')],
      today: moment(),

      randomAppointments: [],
      repeatingAppointments: [],
      programRepeatingDays: [],

      sitterSignups: [],
      commSitters: [],

      showOvernight: false,
      showDrivingDistance: false,
      showWiggleTime: false,

      programStartDate: false,
      programEndDate: false,

      editingAppointments: [],

      showBulkEditModal: false,

      newProgramStatus: false,
    };
  }
  componentDidMount = () => {
    // this.getFamily();
    if (!!this.props.match.params.program_id) {
      this.getProgramBooking();
    } else if (!!this.props.match.params.family_id) {
      this.getFamily();
    } else {
      // This shouldn't happen
    }

    window.onbeforeunload = () => {
      const { dirty } = this.state;
      return dirty
        ? 'Changes will be LOST if you navigate away from the page. Are you sure you want to proceed?'
        : null;
    };
  };
  componentWillUnmount = () => {
    window.onbeforeunload = () => {
      return null;
    };
  };
  componentDidUpdate = (prevProps, prevState) => {
    let prevData = prevState.data;
    const data = { ...this.state.data };

    // If driving is true, show the driving_distance field
    if (
      data['program_driving_needed'] === 1 &&
      prevData['program_driving_needed'] !== data['program_driving_needed']
    ) {
      this.setState({
        showDrivingDistance: true,
      });
    } else if (
      data['program_driving_needed'] === 0 &&
      prevData['program_driving_needed'] !== data['program_driving_needed']
    ) {
      this.setState({
        showDrivingDistance: false,
      });
    }

    // If driving is true, show the driving_distance field
    if (
      data['program_overnight'] === 1 &&
      prevData['program_overnight'] !== data['program_overnight']
    ) {
      data['program_num_nights'] = 1;
      this.setState({
        data,
        showOvernight: true,
      });
    } else if (
      data['program_overnight'] === 0 &&
      prevData['program_overnight'] !== data['program_overnight']
    ) {
      data['program_num_nights'] = null;
      this.setState({
        data,
        showOvernight: false,
      });
    }

    if (data['program_flex'] === 1 && prevData['program_flex'] !== 1) {
      data['program_flex_room'] = `${wiggleOptions[0].id}`;
      this.setState({
        data,
        showWiggleTime: true,
      });
    } else if (data['program_flex'] === 0 && prevData['program_flex'] !== 0) {
      data['program_flex_room'] = null;
      this.setState({
        showWiggleTime: false,
      });
    }
  };
  getFamily = async () => {
    try {
      const { family_id } = this.props.match.params;
      const response = await familyService.get(family_id);
      if (response.status === 200) {
        const { data } = this.state;
        const { profileFamily, parents, children, pets, owner } =
          response.data.data;
        data['program_selected_children'] = map(children, (child) => {
          return `${child.id}`;
        });
        data['program_address1'] = owner.address1;
        data['program_address2'] = owner.address2;
        data['program_city'] = owner.city;
        data['program_state'] = owner.state;
        data['program_zip'] = owner.zip;
        this.setState({
          family: profileFamily,
          parents,
          pets,
          children,
          owner,
          data,
          loading: false,
          suppressNotifications: true,
          newProgramStatus: 'new',
          booking: {
            status: 'creating',
            id: Math.round(Math.random() * 10000000),
          },
        });
      }
    } catch (e) {
      console.log(e);
      this.setState({
        loading: false,
      });
    }
  };
  getProgramBooking = async () => {
    try {
      const { program_id, family_id } = this.props.match.params;
      let { data } = this.state;

      let idToLookup = !!program_id ? program_id : this.state.booking.id;

      if (!!idToLookup) {
        const response = await appointmentService.getBooking(idToLookup);
        if (response.status === 200) {
          const {
            bookingAppointments,
            family,
            children,
            booking,
            owner,
            sitterSignups,
            commSitters,
            activities,
          } = response.data.data;
          each(bookingAppointments, (appointment) => {
            appointment['date'] = moment(appointment.start_time).toDate();
            appointment['start_time'] = moment(appointment.start_time).format(
              'HH:mm'
            );
            appointment['end_time'] = moment(appointment.end_time).format(
              'HH:mm'
            );
            appointment['all_children'] =
              appointment.children.length === children.length ? 1 : 0;
            appointment['selected_children'] = map(
              appointment.children,
              (child) => {
                return `${child.id}`;
              }
            );
            appointment['driving_distance'] = !!appointment.driving_distance
              ? appointment.driving_distance
              : '';
            appointment['selected_sitters'] =
              appointment.sitters.length === 0
                ? null
                : map(appointment.sitters, (sitter) => {
                    return {
                      ...sitter,
                      value: sitter.id,
                      label: sitter.firstname + ' ' + sitter.lastname,
                      name: sitter.firstname + ' ' + sitter.lastname,
                    };
                  })[0];
            delete appointment['children'];
            delete appointment['sitters'];
            appointment['valid'] = this.validateAppointment(appointment);
          });
          data['booking_appointments'] = sortBy(bookingAppointments, [
            'date',
            'start_time',
          ]);
          data['program_selected_children'] = data[
            'bulk_edit_selected_children'
          ] = map(children, (child) => {
            return `${child.id}`;
          });
          data['program_address1'] = owner.address1;
          data['program_address2'] = owner.address2;
          data['program_city'] = owner.city;
          data['program_state'] = owner.state;
          data['program_zip'] = owner.zip;
          data['sitter_notes'] = !!booking.sitter_notes
            ? booking.sitter_notes
            : '';
          data['admin_notes'] = !!booking.admin_notes
            ? booking.admin_notes
            : '';
          const sitters = map(sitterSignups, (sitter) => {
            return {
              ...sitter,
              id: sitter.sitter_piid,
              name: sitter.firstname + ' ' + sitter.lastname,
            };
          });
          const sittersComm = map(commSitters, (sitter) => {
            return {
              ...sitter,
              id: sitter.id,
              name: sitter.firstname + ' ' + sitter.lastname,
            };
          });
          this.setState(
            {
              data,
              loading: false,
              family,
              children,
              booking,
              owner,
              activities,
              sitterSignups: sitters,
              commSitters: sittersComm,
              suppressNotifications: true,
            },
            this.calculateProgramStats
          );
        }
      } else {
        toast.error('Improper ID');
      }
    } catch (e) {
      console.log(e);
    }
  };
  calculateProgramStats = () => {
    const { booking_appointments } = this.state.data;

    if (booking_appointments.length > 0) {
      // I feel like the date checking should be more complicated than this, but I guess
      // Lodash's sort is smarter than I give it credit for and is properly filtering dates
      // If this ever proves to not be the case, I'm leaving this function in just in case so it's easier to tweak.
      let sortedAppts = sortBy(booking_appointments, ['date', 'start_time']);
      let programStartDate = moment(sortedAppts[0].date);
      let programEndDate = moment(sortedAppts[sortedAppts.length - 1].date);

      // Try to filter random/recurring appointments
      const randomAppointments = [];
      const repeatingAppointments = [];

      // This is used on the backend to generate tags - this data comes from the calculateProgramStats function
      const programRepeatingDays = [];

      each(booking_appointments, (appointment) => {
        let isRepeating = false;
        let appointmentWeekday = moment(appointment.date).format('d');

        // We only want to calculate the type of appointment once the data/start/end are entered
        if (
          !!appointment.date &&
          !!appointment.start_time &&
          !!appointment.end_time
        ) {
          each(booking_appointments, (apptToCheck) => {
            // we don't want to check the same appt against itself
            if (appointment.id !== apptToCheck.id) {
              let apptToCheckWeekday = moment(apptToCheck.date).format('d');
              if (
                appointment.start_time === apptToCheck.start_time &&
                appointment.end_time === apptToCheck.end_time &&
                appointmentWeekday === apptToCheckWeekday
              ) {
                isRepeating = true;
              }
            }
          });
          if (isRepeating) {
            if (programRepeatingDays.indexOf(appointmentWeekday) === -1)
              programRepeatingDays.push(appointmentWeekday);
            let appointmentDateString = `${moment(
              appointmentWeekday,
              'd'
            ).format('dddd')} ${moment(appointment.start_time, 'HH:mm').format(
              'h:mma'
            )}-${moment(appointment.end_time, 'HH:mm').format('h:mma')}`;
            if (repeatingAppointments.indexOf(appointmentDateString) === -1) {
              repeatingAppointments.push(appointmentDateString);
            }
          } else {
            let appointmentDateString = `${moment(appointment.date).format(
              'MMMM Do'
            )} ${moment(appointment.start_time, 'HH:mm').format(
              'h:mma'
            )}-${moment(appointment.end_time, 'HH:mm').format('h:mma')}`;
            if (randomAppointments.indexOf(appointmentDateString) === -1) {
              randomAppointments.push(appointmentDateString);
            }
          }
          appointment.type = isRepeating ? 'repeating' : 'random';
        }
      });

      this.setState({
        programStartDate,
        programEndDate,
        randomAppointments,
        repeatingAppointments,
        programRepeatingDays,
      });
    }
  };
  onSelectedDay = (currentDate) => {
    this.addNewAppointment(currentDate);
  };
  addNewAppointment = (currentDate = null) => {
    const data = { ...this.state.data };
    const freshSchema = { ...this.state.freshSchema };
    const appointments = [...data['booking_appointments']];

    // we assign it a random id here so that we can reference that instead of its index
    // using its index in the array causes problems with splicing
    freshSchema.id =
      appointments.length +
      Math.round(Math.random() * 10000000) +
      appointments.length;

    if (!!currentDate) {
      freshSchema.date = currentDate.toDate();
    }

    // Editable via "Default Appointment Settings" panel
    freshSchema.start_time = data.program_start_time;
    freshSchema.end_time = data.program_end_time;
    freshSchema.flex = data.program_flex;
    freshSchema.flex_room = data.program_flex_room;
    freshSchema.driving_needed = data.program_driving_needed;
    freshSchema.driving_distance = data.program_driving_distance;
    freshSchema.overnight = data.program_overnight;
    freshSchema.num_nights = data.program_num_nights;
    freshSchema.all_children = data.program_all_children;
    freshSchema.selected_children = data.program_selected_children;
    freshSchema.selected_sitters = data.program_selected_sitters;

    // Hard-coded data pulled from family owner's profile
    freshSchema.address1 = data.program_address1;
    freshSchema.address2 = data.program_address2;
    freshSchema.city = data.program_city;
    freshSchema.state = data.program_state;
    freshSchema.zip = data.program_zip;

    freshSchema.valid = this.validateAppointment(freshSchema);

    appointments.push(freshSchema);
    data['booking_appointments'] = sortBy(appointments, ['date', 'start_time']);

    this.setState(
      {
        data,
        dirty: true,
      },
      this.calculateProgramStats
    );
  };
  onAppointmentDataChange = (idx, apptData) => {
    const data = { ...this.state.data };
    data['booking_appointments'][idx] = {
      ...apptData,
      valid: this.validateAppointment(apptData),
    };
    this.setState({ data }, this.calculateProgramStats);
  };
  validateAppointment = (appointment) => {
    if (!appointment.date || !appointment.start_time || !appointment.end_time) {
      return false;
    }
    if (!!appointment.start_time && !!appointment.end_time) {
      // If start time and end time are present, we need to validate them
      // Appointments must be 3 hours. If overnight is selected, we don't do any validation
      // Otherwise we check if it's 3 hours long at least.
      if (!appointment.overnight) {
        const startTime = moment(appointment.start_time, 'HH:mm').add(
          3,
          'hours'
        );
        const endTime = moment(appointment.end_time, 'HH:mm');
        if (startTime.isAfter(endTime)) {
          // Appointment length must be 3 hours if overnight is not selected
          return false;
        }
      }
    }
    if (!!appointment.overnight) {
      if (!appointment.num_nights) {
        return false;
      }
    }
    if (!!appointment.driving_needed) {
      if (!appointment.driving_distance) {
        return false;
      }
    }
    if (!!appointment.flex) {
      if (!appointment.flex_room) {
        return false;
      }
    }
    if (appointment.selected_children.length === 0) {
      return false;
    }
    return true;
  };
  removeAppointment = (idx) => {
    const { data } = this.state;
    const { booking_appointments, deleted_ids } = data;
    const removedAppt = booking_appointments.splice(idx, 1);
    data['booking_appointments'] = booking_appointments;
    let dirty = booking_appointments.length > 0;

    // We have to manually track which appointments are removed, so we know to delete them on the backend
    const { id } = removedAppt[0];
    deleted_ids.push(id);
    data['deleted_ids'] = deleted_ids;
    console.log(id, removedAppt);
    this.setState(
      {
        data,
        dirty,
      },
      this.calculateProgramStats
    );
  };
  loadOldMonths = () => {
    if (this.state.canDecreaseDate) {
      let firstMonth = this.state.months[0];
      let earliestMonth = moment(firstMonth).subtract(2, 'months');
      let months = [earliestMonth, moment(firstMonth).subtract(1, 'months')];
      if (earliestMonth.isSameOrBefore(this.state.today)) {
        this.setState({
          months,
          canDecreaseDate: false,
        });
      } else {
        this.setState({
          months,
          canDecreaseDate: true,
        });
      }
    }
  };
  loadMoreMonths = () => {
    let lastMonth = this.state.months[this.state.months.length - 1];
    let months = [
      moment(lastMonth).add(1, 'months'),
      moment(lastMonth).add(2, 'months'),
    ];
    this.setState({
      months,
      canDecreaseDate: true,
    });
  };
  openForSitters = () => {
    this.setState(
      {
        suppressNotifications: false,
        newProgramStatus: 'assigning',
      },
      this.saveAppointments
    );
  };
  submitProposal = () => {
    this.setState(
      {
        suppressNotifications: false,
        newProgramStatus: 'proposal-sent',
      },
      this.saveAppointments
    );
  };
  cancelProgram = () => {
    this.setState(
      {
        suppressNotifications: false,
        newProgramStatus: 'cancelled',
      },
      this.saveAppointments
    );
  };
  saveAppointments = () => {
    this.setState({
      submitting: true,
    });
    let anyInvalid = false;
    each(this.state.data.booking_appointments, (appointment) => {
      if (!appointment.valid) anyInvalid = true;
    });
    if (!this.state.data.booking_appointments.length) {
      toast.error(
        'Uh oh! There must be appointments before you can save this program.'
      );
      this.setState({
        newProgramStatus: false,
        submitting: false,
      });
    } else if (anyInvalid) {
      toast.error(
        'Uh oh! Some appointments are not valid. Please double-check and try again.'
      );
      this.setState({
        newProgramStatus: false,
        submitting: false,
      });
    } else {
      this.doSubmit();
    }
  };
  doSubmit = async () => {
    try {
      const piid = this.state.owner.id;
      const { program_id } = this.props.match.params;
      let rawAppointments = [...this.state.data.booking_appointments];
      const { newProgramStatus, suppressNotifications, programRepeatingDays } =
        this.state;
      const { sitter_notes, admin_notes, deleted_ids } = this.state.data;
      let appointments = [];
      each(rawAppointments, (rawAppointment) => {
        let appointment = { ...rawAppointment };
        let date = moment(appointment['date']).format('YYYY-MM-DD');
        appointment['start_time'] = moment(
          `${date} ${appointment['start_time']}`,
          'YYYY-MM-DD HH:mm:ss'
        ).format();
        appointment['end_time'] = !!appointment.overnight
          ? moment(
              `${moment(date, 'YYYY-MM-DD')
                .add(appointment['num_nights'], 'days')
                .format('YYYY-MM-DD')} ${appointment['end_time']}`,
              'YYYY-MM-DD HH:mm:ss'
            ).format()
          : moment(
              `${date} ${appointment['end_time']}`,
              'YYYY-MM-DD HH:mm:ss'
            ).format();
        delete appointment['all_children'];
        delete appointment['date'];
        appointment['selected_children'] = appointment['selected_children'];
        appointment['selected_sitters'] =
          appointment['selected_sitters'] !== undefined &&
          appointment['selected_sitters'] !== null
            ? [appointment['selected_sitters'].id]
            : [];
        appointments.push(appointment);
      });
      const apiData = {
        booking_appointments: appointments,
        booking_id: !!program_id ? program_id : null,
        type: 'program',
        suppress: suppressNotifications, // notifications should NOT go out UNLESS the program request is being officially started by an admin
        sitter_notes: sitter_notes,
        admin_notes: admin_notes,
        deleted_ids: deleted_ids,
        repeating_days: programRepeatingDays.sort(),
        refresh_tags: 1,
      };
      if (!!newProgramStatus) {
        // newProgramStatus has a default value of false. if it's not false, we pass it through in the request
        apiData['status'] = newProgramStatus;
      }
      const response = await appointmentService.create(piid, apiData);
      if (response.status === 200) {
        if (!!this.props.match.params.family_id) {
          const { booking } = response.data.data;
          this.setState(
            {
              dirty: false,
              booking,
            },
            () => {
              this.props.history.replace(`/programs/view/${booking.id}`);
              this.getProgramBooking();
              toast.success(
                'Hooray! Your Program has been saved! Refreshing data...'
              );
            }
          );
        } else {
          toast.success(
            'Hooray! Your Program has been saved! Refreshing data...'
          );
          this.getProgramBooking();
        }
      }
      this.setState({
        submitting: false,
      });
    } catch (e) {
      console.log(e);
      this.setState({
        submitting: false,
      });
    }
  };
  debounceLoadSitters = debounce((search, callback) => {
    if (!!search) {
      this.loadSitters(search, callback);
    } else {
      callback();
    }
  }, 1000);
  loadSitters = async (search, callback) => {
    try {
      const response = await sitterService.search({ search });
      if (response.status === 200) {
        let { sitters } = response.data.data;
        sitters = each(sitters, (sitter) => {
          sitter['value'] = sitter.piid;
          sitter['id'] = sitter.piid;
          sitter['label'] =
            sitter.firstname + ' ' + upperCaseFirst(sitter.lastname);
        });
        this.setState(
          {
            sitters,
          },
          () => {
            callback(sitters);
          }
        );
      }
    } catch (e) {
      console.log(e);
      callback();
    }
  };
  handleChange = (name, value, type = 'default') => {
    const data = { ...this.state.data };
    if (type === 'number') {
      data[name] = Number(value);
    } else {
      data[name] = value;
    }
    this.setState({ data }, this.calculateProgramStats);
  };
  handleProgramPresetChildrenChange = (e) => {
    this.handleCheckboxChange(e, 'program_selected_children');
  };
  handleBulkEditChildrenChange = (e) => {
    this.handleCheckboxChange(e, 'bulk_edit_selected_children');
  };
  handleCheckboxChange = ({ currentTarget: input }, key) => {
    const data = { ...this.state.data };
    let children = [...data[key]];
    if (input.checked) {
      children.push(input.value);
    } else {
      //remove from array
      children = children.filter(function (item) {
        return item !== input.value;
      });
    }
    if (!children) children = [];
    data[key] = children;
    this.setState({ data });
  };
  toggleRemoveAppointment = (e) => {
    if (e) e.preventDefault();
    this.setState({
      showingRemoveAppt: !this.state.showingRemoveAppt,
    });
  };
  toggleEditAllAppointments = () => {
    if (
      this.state.data.booking_appointments.length ===
      this.state.editingAppointments.length
    ) {
      // All are selected - we want to unselect them all.
      this.setState({
        editingAppointments: [],
      });
    } else {
      // SOME or none are selected. We want to select them ALL.
      this.setState({
        editingAppointments: map(
          this.state.data.booking_appointments,
          (appointment) => {
            return appointment.id;
          }
        ),
      });
    }
  };
  toggleEditingAppointment = (id) => {
    const editingAppointments = [...this.state.editingAppointments];
    const idx = editingAppointments.indexOf(id);
    if (idx > -1) {
      editingAppointments.splice(idx, 1);
    } else {
      editingAppointments.push(id);
    }
    this.setState({ editingAppointments });
  };
  toggleBulkEditModal = () => {
    this.setState({
      showBulkEditModal: !this.state.showBulkEditModal,
    });
  };
  applyBulkEdit = () => {
    const { editingAppointments } = this.state;
    const editKey = this.state.data.bulk_edit_field;
    if (!!editKey) {
      const valueKey = editKey.replace('bulk_edit_', '');
      const value = this.state.data[editKey];
      const data = { ...this.state.data };
      const appointments = [...data['booking_appointments']];
      const { children } = this.state;
      each(appointments, (appointment) => {
        if (editingAppointments.indexOf(appointment.id) > -1) {
          appointment[valueKey] = value;
          if (valueKey === 'selected_children') {
            if (value.length === children.length)
              appointment['all_children'] = 1;
            else appointment['all_children'] = 0;
          }
        }
      });
      data['booking_appointments'] = appointments;
      this.setState(
        {
          data,
          showBulkEditModal: false,
        },
        () => {
          toast.success(
            `${editingAppointments.length} appointment${
              editingAppointments.length > 1 ? 's have' : ' has'
            } been modified.`
          );
          this.calculateProgramStats();
        }
      );
    }
  };
  setCurrentTab = (tab) => {
    this.setState({ currentTab: tab });
  };
  renderActivities = () => {
    const elements = [];
    const { activities } = this.state;
    activities.map((activity) => {
      let element = (
        <span key={`program-activitiy-${activity.id}`}>
          {activity.name}
          <br />
        </span>
      );
      elements.push(element);
    });
    return <p>{elements.length > 0 ? elements : 'None'}</p>;
  };
  render() {
    const { props } = this;
    const {
      loading,
      linkGroup,
      children,
      family,
      owner,
      sitters,
      data,
      times,
      canDecreaseDate,
      calendarDate,
      months,
      appointments,
      dirty,
      booking,
      programStartDate,
      programEndDate,
      randomAppointments,
      repeatingAppointments,
      showOvernight,
      showWiggleTime,
      showDrivingDistance,
      showBulkEditModal,
      editingAppointments,
      currentTab,
      sitterSignups,
      commSitters,
    } = this.state;
    const { booking_appointments, program_all_children, bulk_edit_field } =
      data;

    const { program_id } = this.props.match.params;

    // this is for displaying on the calendar
    let consolidatedAppointments = [...appointments, ...booking_appointments];
    let errors = {};

    // Eventually this needs to be based on the program status
    const isAssigningSitters = false;

    const allAppointmentsSelected =
      editingAppointments.length === booking_appointments.length ? 1 : 0;

    if (!!loading) {
      return (
        <main className="admin admin-detail with-sidebar admin-program-booking">
          <div className="sidebar-wrapper">
            <Sidebar
              {...props}
              iconClass="fas fa-id-card"
              linkGroup={linkGroup}
            />
          </div>
          <div className="content">
            <div className="main-panel">
              <div className="appt-people">
                <div className="admin-avatar-section family">
                  <h4 className="program-title section-label">
                    Loading Program Booking Details....
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </main>
      );
    }

    return (
      <main className="admin admin-detail with-sidebar admin-program-booking">
        <Prompt
          when={dirty}
          message="Changes will be LOST if you navigate away from the page. Are you sure you want to proceed?"
        />
        <div className="sidebar-wrapper">
          <Sidebar
            {...props}
            iconClass="fas fa-id-card"
            linkGroup={linkGroup}
          />
        </div>
        <div className="content">
          <div className="main-panel">
            <div className="appt-people">
              <div className="admin-avatar-section family">
                <h4 className="program-title section-label">
                  Program Booking
                  {program_id ? ' #' + program_id : 's'}
                </h4>
                <div className="wrapper">
                  <Avatar
                    imageSrc={family.avatar_filename}
                    profileColor={owner.profile_color}
                  />
                  <div className="info">
                    <h1>
                      <Link to={`/families/members/${family.id}`}>
                        {family.name}
                      </Link>
                    </h1>
                    <p>{displayAddress(owner)}</p>
                  </div>
                </div>
              </div>
              <div className="program-pill-wrapper">
                {programStartDate !== false && (
                  <div className="program-pill">
                    <h3>{programStartDate.format('MMM Do')}</h3>
                    <p>Start Date</p>
                  </div>
                )}
                {programEndDate !== false && (
                  <div className="program-pill">
                    <h3>{programEndDate.format('MMM Do')}</h3>
                    <p>End Date</p>
                  </div>
                )}
                <div className="program-pill">
                  <h3>{booking_appointments.length}</h3>
                  <p>Total Appointments</p>
                </div>
              </div>
            </div>
            <div className="admin-program-booking-tabs">
              <div className="tabs-header">
                <div
                  className={`tab-item ${
                    currentTab === 'schedule' ? 'active' : ''
                  }`}
                  onClick={() => {
                    this.setCurrentTab('schedule');
                  }}
                >
                  <p>Schedule</p>
                </div>
                <div
                  className={`tab-item ${
                    currentTab === 'family' ? 'active' : ''
                  }`}
                  onClick={() => {
                    this.setCurrentTab('family');
                  }}
                >
                  <p>Client Communications</p>
                </div>
                <div
                  className={`tab-item ${
                    currentTab === 'sitter' ? 'active' : ''
                  }`}
                  onClick={() => {
                    this.setCurrentTab('sitter');
                  }}
                >
                  <p>Sitter Communications</p>
                </div>
              </div>
              <div className="tabs-body">
                {currentTab === 'schedule' && (
                  <div
                    className="admin-booking-form"
                    onSubmit={this.handleSubmit}
                  >
                    {sitterSignups.length > 0 && (
                      <div className="sitter-signups">
                        <h4 className="signup-table-title">Sitter Signups</h4>
                        <div className="signup-table">
                          <div className="signup-row header">
                            <div className="signup-col">
                              <p>Name</p>
                            </div>
                            <div className="signup-col">
                              <p>Dates Unavailable</p>
                            </div>
                            <div className="signup-col">
                              <p>Notes</p>
                            </div>
                          </div>
                          {sitterSignups.map((sitter) => (
                            <div
                              className="signup-row"
                              key={`sitter-signup-${sitter.id}`}
                            >
                              <div className="signup-col">
                                <p>
                                  {sitter.firstname}
                                  &nbsp;
                                  {sitter.lastname}
                                </p>
                              </div>
                              <div className="signup-col">
                                <p>
                                  {!!sitter.dates
                                    ? sitter.dates
                                        .split(', ')
                                        .map((time) =>
                                          moment(time).format('MMM D')
                                        )
                                        .join(', ')
                                    : 'None'}
                                </p>
                              </div>
                              <div className="signup-col">
                                <p>{sitter.notes}</p>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                    <div className="calendar-view">
                      <div className="calendar-wrapper">
                        <button
                          className={`arrow prev ${
                            !canDecreaseDate ? 'disabled' : ''
                          }`}
                          onClick={this.loadOldMonths}
                        >
                          <i className="fas fa-arrow-alt-left"></i>
                        </button>
                        <button
                          className={`arrow next`}
                          onClick={this.loadMoreMonths}
                        >
                          <i className="fas fa-arrow-alt-right"></i>
                        </button>
                        <div className="calendars">
                          {months.map((month, i) => {
                            return (
                              <Calendar
                                key={i}
                                appointments={consolidatedAppointments}
                                showAppointments={false}
                                date={month}
                                onSelectedDay={this.onSelectedDay}
                                type="booking"
                              />
                            );
                          })}
                        </div>
                      </div>
                    </div>
                    <div className="default-appointment-settings">
                      <h4 className="title">Default Appointment Settings</h4>
                      <div className="appointment-row">
                        <div className="appointment-main-row">
                          <div className="form-field-wrapper start_time">
                            <Select
                              className="program_start_time"
                              name={`program-preset-program_start_time`}
                              label="Start Time"
                              options={times}
                              value={data['program_start_time']}
                              error={errors['program_start_time']}
                              onChange={(e) => {
                                this.handleChange(
                                  'program_start_time',
                                  e.target.value
                                );
                              }}
                              required={true}
                            />
                          </div>
                          <div className="form-field-wrapper end_time">
                            <Select
                              className="program_end_time"
                              name={`program-preset-program_end_time`}
                              label="End Time"
                              options={times}
                              value={data['program_end_time']}
                              error={errors['program_end_time']}
                              onChange={(e) => {
                                this.handleChange(
                                  'program_end_time',
                                  e.target.value
                                );
                              }}
                              required={true}
                            />
                          </div>
                          <div className="form-field-wrapper overnight">
                            <Radio
                              className="program_overnight"
                              name={`program-preset-program_overnight`}
                              label="Overnight:"
                              options={radioOptions}
                              value={data['program_overnight']}
                              error={errors['program_overnight']}
                              onChange={(e) => {
                                this.handleChange(
                                  'program_overnight',
                                  e.target.value,
                                  'number'
                                );
                              }}
                              required={true}
                            />
                            {!!showOvernight && (
                              <NumNights
                                className="program_num_nights"
                                name={`program-preset-program_num_nights`}
                                label="Nights:"
                                value={data['program_num_nights']}
                                onChange={(label, value) => {
                                  this.handleChange(
                                    'program_num_nights',
                                    value,
                                    'number'
                                  );
                                }}
                                required={true}
                              />
                            )}
                          </div>
                          <div className="form-field-wrapper driving">
                            <Radio
                              className="driving_needed"
                              name={`program-preset-program_driving_needed`}
                              label="Driving?"
                              options={radioOptions}
                              value={data['program_driving_needed']}
                              error={errors['program_driving_needed']}
                              onChange={(e) => {
                                this.handleChange(
                                  'program_driving_needed',
                                  e.target.value,
                                  'number'
                                );
                              }}
                              required={true}
                            />
                            {!!showDrivingDistance && (
                              <Input
                                className="driving_distance"
                                name={`program-preset-program_driving_distance`}
                                label="Miles"
                                type="number"
                                value={data['program_driving_distance']}
                                error={errors['program_driving_distance']}
                                onChange={(e) => {
                                  this.handleChange(
                                    'program_driving_distance',
                                    e.target.value
                                  );
                                }}
                                required={true}
                              />
                            )}
                          </div>
                          <div className="form-field-wrapper flex">
                            <Radio
                              className="flex"
                              name={`program-preset-program_flex`}
                              label="Wiggle Room?"
                              options={radioOptions}
                              value={data['program_flex']}
                              error={errors['program_flex']}
                              onChange={(e) => {
                                this.handleChange(
                                  'program_flex',
                                  e.target.value,
                                  'number'
                                );
                              }}
                              required={true}
                            />
                            {!!showWiggleTime && (
                              <Select
                                className="flex_room"
                                name={`program-preset-program_flex_room`}
                                label="Time"
                                options={wiggleOptions}
                                value={data['program_flex_room']}
                                error={errors['program_flex_room']}
                                onChange={(e) => {
                                  this.handleChange(
                                    'program_flex_room',
                                    e.target.value,
                                    'number'
                                  );
                                }}
                                required={true}
                              />
                            )}
                          </div>
                          <div className="form-field-wrapper children">
                            <Radio
                              className="program_all_children"
                              name={`program-preset-program_all_children`}
                              label="All children?"
                              options={radioOptions}
                              value={data['program_all_children']}
                              error={errors['program_all_children']}
                              onChange={(e) => {
                                this.handleChange(
                                  'program_all_children',
                                  e.target.value,
                                  'number'
                                );
                              }}
                              required={true}
                            />
                            {!program_all_children && (
                              <ChildCheckbox
                                className="program_selected_children"
                                name={`program-preset-program_selected_children`}
                                label="Children"
                                options={children}
                                value={data['program_selected_children']}
                                error={errors['program_selected_children']}
                                onChange={
                                  this.handleProgramPresetChildrenChange
                                }
                                required={true}
                              />
                            )}
                          </div>
                          <div className="form-field-wrapper provider">
                            <AsyncSelect
                              value={data['program_selected_sitters']}
                              error={errors['program_selected_sitters']}
                              label={'Provider'}
                              name={`program-preset-program_selected_sitters`}
                              loadOptions={this.debounceLoadSitters}
                              onChange={(name, value) => {
                                this.handleChange(
                                  'program_selected_sitters',
                                  value
                                );
                              }}
                              required={isAssigningSitters}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="bulk-edit">
                      {editingAppointments.length > 0 && (
                        <button
                          className="theme-btn small slate-blue"
                          onClick={this.toggleBulkEditModal}
                        >
                          Bulk Edit
                        </button>
                      )}
                      {editingAppointments.length === 0 && (
                        <button
                          className="theme-btn small slate-blue disabled"
                          disabled
                        >
                          Bulk Edit
                        </button>
                      )}
                      <Modal
                        title="Bulk Edit"
                        isOpen={showBulkEditModal}
                        closeModal={this.toggleBulkEditModal}
                        content={
                          <div className="bulk-edit-modal">
                            <div className="bulk-edit-row">
                              <Select
                                className="bulk_edit_field"
                                name={`program-bulk-edit-bulk_edit_field`}
                                label="Field"
                                options={bulkEditFieldOptions}
                                value={data['bulk_edit_field']}
                                error={errors['bulk_edit_field']}
                                onChange={(e) => {
                                  this.handleChange(
                                    'bulk_edit_field',
                                    e.target.value
                                  );
                                }}
                                required={true}
                              />
                            </div>
                            <div className="bulk-edit-row">
                              {bulk_edit_field === 'bulk_edit_start_time' && (
                                <Select
                                  className="bulk_edit_start_time"
                                  name={`program-bulk-edit-bulk_edit_start_time`}
                                  label="Start Time"
                                  options={times}
                                  value={data['bulk_edit_start_time']}
                                  error={errors['bulk_edit_start_time']}
                                  onChange={(e) => {
                                    this.handleChange(
                                      'bulk_edit_start_time',
                                      e.target.value
                                    );
                                  }}
                                  required={true}
                                />
                              )}
                              {bulk_edit_field === 'bulk_edit_end_time' && (
                                <Select
                                  className="bulk_edit_end_time"
                                  name={`program-bulk-edit-bulk_edit_end_time`}
                                  label="End Time"
                                  options={times}
                                  value={data['bulk_edit_end_time']}
                                  error={errors['bulk_edit_end_time']}
                                  onChange={(e) => {
                                    this.handleChange(
                                      'bulk_edit_end_time',
                                      e.target.value
                                    );
                                  }}
                                  required={true}
                                />
                              )}
                              {bulk_edit_field === 'bulk_edit_flex' && (
                                <Radio
                                  className="bulk_edit_flex"
                                  name={`program-bulk-edit-bulk_edit_flex`}
                                  label="Wiggle Room?"
                                  options={radioOptions}
                                  value={data['bulk_edit_flex']}
                                  error={errors['bulk_edit_flex']}
                                  onChange={(e) => {
                                    this.handleChange(
                                      'bulk_edit_flex',
                                      e.target.value,
                                      'number'
                                    );
                                  }}
                                  required={true}
                                />
                              )}
                              {bulk_edit_field === 'bulk_edit_flex_room' && (
                                <Select
                                  className="flex_room"
                                  name={`program-bulk-edit-bulk_edit_flex_room`}
                                  label="Time"
                                  options={wiggleOptions}
                                  value={data['bulk_edit_flex_room']}
                                  error={errors['bulk_edit_flex_room']}
                                  onChange={(e) => {
                                    this.handleChange(
                                      'bulk_edit_flex_room',
                                      e.target.value,
                                      'number'
                                    );
                                  }}
                                  required={true}
                                />
                              )}
                              {bulk_edit_field ===
                                'bulk_edit_driving_needed' && (
                                <Radio
                                  className="bulk_edit_driving_needed"
                                  name={`program-bulk-edit-bulk_edit_driving_needed`}
                                  label="Driving?"
                                  options={radioOptions}
                                  value={data['bulk_edit_driving_needed']}
                                  error={errors['bulk_edit_driving_needed']}
                                  onChange={(e) => {
                                    this.handleChange(
                                      'bulk_edit_driving_needed',
                                      e.target.value,
                                      'number'
                                    );
                                  }}
                                  required={true}
                                />
                              )}
                              {bulk_edit_field ===
                                'bulk_edit_driving_distance' && (
                                <Input
                                  className="bulk_edit_driving_distance"
                                  name={`program-bulk-edit-bulk_edit_driving_distance`}
                                  label="Miles"
                                  type="number"
                                  value={data['bulk_edit_driving_distance']}
                                  error={errors['bulk_edit_driving_distance']}
                                  onChange={(e) => {
                                    this.handleChange(
                                      'bulk_edit_driving_distance',
                                      e.target.value
                                    );
                                  }}
                                  required={true}
                                />
                              )}
                              {bulk_edit_field === 'bulk_edit_overnight' && (
                                <Radio
                                  className="bulk_edit_overnight"
                                  name={`program-bulk-edit-bulk_edit_overnight`}
                                  label="Overnight:"
                                  options={radioOptions}
                                  value={data['bulk_edit_overnight']}
                                  error={errors['bulk_edit_overnight']}
                                  onChange={(e) => {
                                    this.handleChange(
                                      'bulk_edit_overnight',
                                      e.target.value,
                                      'number'
                                    );
                                  }}
                                  required={true}
                                />
                              )}
                              {bulk_edit_field === 'bulk_edit_num_nights' && (
                                <NumNights
                                  className="bulk_edit_num_nights"
                                  name={`program-bulk-edit-bulk_edit_num_nights`}
                                  label="Nights:"
                                  value={data['bulk_edit_num_nights']}
                                  onChange={(label, value) => {
                                    this.handleChange(
                                      'bulk_edit_num_nights',
                                      value,
                                      'number'
                                    );
                                  }}
                                  required={true}
                                />
                              )}
                              {bulk_edit_field ===
                                'bulk_edit_selected_children' && (
                                <ChildCheckbox
                                  className="bulk_edit_selected_children"
                                  name={`bulk_edit_selected_children`}
                                  label="Children"
                                  options={children}
                                  value={data['bulk_edit_selected_children']}
                                  error={errors['bulk_edit_selected_children']}
                                  onChange={this.handleBulkEditChildrenChange}
                                  required={true}
                                />
                              )}
                              {bulk_edit_field ===
                                'bulk_edit_selected_sitters' && (
                                <AsyncSelect
                                  value={data['bulk_edit_selected_sitters']}
                                  label={'Provider'}
                                  name={`bulk_edit_selected_sitters`}
                                  loadOptions={this.debounceLoadSitters}
                                  onChange={(name, value) => {
                                    this.handleChange(
                                      'bulk_edit_selected_sitters',
                                      value
                                    );
                                  }}
                                  required={true}
                                />
                              )}
                            </div>
                          </div>
                        }
                        primaryButton={'Apply'}
                        secondaryButton={'Close'}
                        handleClick={this.applyBulkEdit}
                      />
                      <BooleanInput
                        className="bulk_edit_select_all"
                        name={`bulk_edit_select_all`}
                        label="Select All"
                        type="text"
                        value={allAppointmentsSelected}
                        error={errors['bulk_edit_select_all']}
                        onChange={this.toggleEditAllAppointments}
                      />
                    </div>
                    <div className="appointment-fields">
                      <h4>Appointments</h4>
                      <div className="appointment-rows">
                        {booking_appointments.map((appointment, idx) => (
                          <ProgramBookingRow
                            key={`appt-${appointment.id}`}
                            appointment={appointment}
                            editingAppointment={
                              editingAppointments.indexOf(appointment.id) > -1
                            }
                            toggleEditingAppointment={
                              this.toggleEditingAppointment
                            }
                            idx={idx}
                            children={children}
                            sitters={sitters}
                            times={times}
                            onAppointmentDataChange={
                              this.onAppointmentDataChange
                            }
                            removeAppointment={this.removeAppointment}
                          />
                        ))}
                      </div>
                    </div>
                    <div className="btn-wrapper">
                      <button
                        onClick={() => {
                          this.addNewAppointment();
                        }}
                        className="theme-btn small slate-blue"
                      >
                        Add Day
                      </button>
                    </div>
                    <div className="submit-wrapper">
                      <div className="form-field button submit"></div>
                    </div>
                  </div>
                )}
                {currentTab === 'family' && (
                  <ProgramCommunications type="family" bookingId={booking.id} />
                )}
                {currentTab === 'sitter' && (
                  <ProgramCommunications
                    type="sitter"
                    bookingId={booking.id}
                    sitters={commSitters}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="sidebar-right">
            <div className="sidebar-section status">
              <h4>Status</h4>
              <p className={`program-status-pill ${slugify(booking.status)}`}>
                {booking.status === 'assigning' ||
                booking.status === 'assigning-pending'
                  ? 'Open'
                  : booking.status
                      .split('-')
                      .map((str) => upperCaseFirst(str))
                      .join(' ')}
              </p>
              <div className="sidebar-actions">
                {booking.status === 'new' && (
                  <button
                    type="submit"
                    className="theme-btn blue auto-width"
                    disabled={this.state.submitting}
                    onClick={this.openForSitters}
                  >
                    {this.state.submitting && (
                      <i className="fas fa-spinner fa-spin"></i>
                    )}
                    {!this.state.submitting && <i className="fas fa-save"></i>}
                    {this.state.submitting
                      ? 'Activating...'
                      : 'Activate for Signups'}
                  </button>
                )}
                {booking.status === 'assigning' && (
                  <button
                    type="submit"
                    className="theme-btn blue auto-width"
                    disabled={this.state.submitting}
                    onClick={this.submitProposal}
                  >
                    {this.state.submitting && (
                      <i className="fas fa-spinner fa-spin"></i>
                    )}
                    {!this.state.submitting && <i className="fas fa-check"></i>}
                    {this.state.submitting
                      ? 'Submitting...'
                      : 'Submit Proposal'}
                  </button>
                )}
                {booking.status === 'rejected' && (
                  <button
                    type="submit"
                    className="theme-btn blue auto-width"
                    disabled={this.state.submitting}
                    onClick={this.submitProposal}
                  >
                    {this.state.submitting && (
                      <i className="fas fa-spinner fa-spin"></i>
                    )}
                    {!this.state.submitting && <i className="fas fa-check"></i>}
                    {this.state.submitting
                      ? 'Submitting...'
                      : 'Re-Submit Proposal'}
                  </button>
                )}
                {booking.status !== 'cancelled' && (
                  <button
                    type="submit"
                    className="theme-btn primary-green auto-width"
                    disabled={this.state.submitting}
                    onClick={this.saveAppointments}
                  >
                    {this.state.submitting && (
                      <i className="fas fa-spinner fa-spin"></i>
                    )}
                    {!this.state.submitting && <i className="fas fa-save"></i>}
                    {this.state.submitting ? 'Saving...' : 'Save'}
                  </button>
                )}
                {booking.status !== 'cancelled' && (
                  <button
                    type="submit"
                    className="theme-btn error-red small auto-width"
                    disabled={this.state.submitting}
                    onClick={this.cancelProgram}
                  >
                    {this.state.submitting && (
                      <i className="fas fa-spinner fa-spin"></i>
                    )}
                    {!this.state.submitting && <i className="fas fa-times"></i>}
                    {this.state.submitting ? 'Cancelling...' : 'Cancel Program'}
                  </button>
                )}
              </div>
            </div>
            {booking.status === 'cancelled' && (
              <div className="sidebar-section">
                <h4>Cancel Reason</h4>
                <p>
                  {!!booking.cancel_reason ? booking.cancel_reason : 'None'}
                </p>
              </div>
            )}

            {repeatingAppointments.length > 0 && (
              <div className="sidebar-section">
                <h4>Recurring Days</h4>
                {repeatingAppointments.map((apptString, idx) => (
                  <p key={`repeating-appt-${idx}`}>{apptString}</p>
                ))}
              </div>
            )}
            {randomAppointments.length > 0 && (
              <div className="sidebar-section">
                <h4>Additional Days</h4>
                {randomAppointments.map((apptString, idx) => (
                  <p key={`random-appt-${idx}`}>{apptString}</p>
                ))}
              </div>
            )}
            <div className="sidebar-section">
              <h4>Tags</h4>
              <div className="tags-wrapper">
                {booking.status === 'creating' && (
                  <p>Tags will be created when the Program is saved</p>
                )}
                {!!booking.tags &&
                  booking.tags.map((tag) => (
                    <div className="tag solid" key={`tag-${tag.id}`}>
                      <p>{tag.tag.name}</p>
                    </div>
                  ))}
              </div>
            </div>
            <div className="sidebar-section">
              <h4>Selected Activities</h4>
              {this.renderActivities()}
            </div>
            <div className="sidebar-section">
              <h4>Additional Services Requested</h4>
              <p>
                {!!booking.additional_services
                  ? booking.additional_services
                  : 'None'}
              </p>
            </div>
            <div className="sidebar-section">
              <h4>House Rules</h4>
              <p>{!!family.house_rules ? family.house_rules : 'None'}</p>
            </div>
            <div className="sidebar-section">
              <h4>Family Sitter Preference</h4>
              <p>
                {!!family.sitter_preference
                  ? upperCaseFirst(family.sitter_preference)
                  : 'None'}
              </p>
            </div>
            <div className="sidebar-section">
              <h4>Admin Notes to Provider</h4>
              <p>
                {!!family.admin_family_notes
                  ? family.admin_family_notes
                  : 'None'}
              </p>
            </div>
            <div className="sidebar-section">
              <h4>Requested Sitter</h4>
              <p>
                {!!booking.requested_sitter ? booking.requested_sitter : 'None'}
              </p>
            </div>
            <div className="sidebar-section">
              <h4>Admin-Only Notes</h4>
              <Textarea
                className="admin_notes"
                name={`admin_notes`}
                label={'Admin-Only Notes'}
                value={data['admin_notes']}
                error={errors['admin_notes']}
                onChange={(e) => {
                  this.handleChange('admin_notes', e.target.value);
                }}
                required={true}
              />
            </div>
            <div className="sidebar-section">
              <h4>Program Notes</h4>
              <p>{!!booking.notes ? booking.notes : 'None'}</p>
            </div>
            <div className="sidebar-section">
              <h4>Program Notes to Sitters</h4>
              <Textarea
                className="sitter_notes"
                name={`sitter_notes`}
                label={'Message'}
                value={data['sitter_notes']}
                error={errors['sitter_notes']}
                onChange={(e) => {
                  this.handleChange('sitter_notes', e.target.value);
                }}
                required={true}
              />
            </div>
          </div>
        </div>
      </main>
    );
  }
}

export default ProgramBooking;
