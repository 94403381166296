import { Grid, GridColumn } from '@progress/kendo-react-grid';
import React from 'react';
import { Link } from 'react-router-dom';
import { findLinkGroupByTitle } from '../../../helpers';
import familyService from '../../../services/familyService';
import Sidebar from '../Sidebar';
import {
  ColumnMenu,
  DateCell,
  ManageCell,
  RelationshipCell,
  SubscriptionCell,
} from './KendoComponents';

class FamiliesGrid extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      profileFamily: [],
      result: [],
      loading: true,
      linkGroup: findLinkGroupByTitle('Families'),
      dataState: {
        sort: [{ field: 'name', dir: 'asc' }],
        filter: {
          logic: 'and',
          filters: [],
        },
        filterOperators: {
          text: [
            {
              text: 'grid.filterContainsOperator',
              operator: 'contains',
            },
            { text: 'grid.filterEqOperator', operator: 'eq' },
          ],
        },
        skip: 0,
        take: 50,
        total: 0,
      },
    };
  }
  componentDidMount = () => {
    this.getFamilies();
  };
  getFamilies = async () => {
    try {
      const { dataState } = this.state;

      const response = await familyService.list({
        skip: dataState.skip,
        take: dataState.take,
        sort: dataState.sort,
        filter: dataState.filter,
      });
      if (response.status === 200) {
        const { profileFamily, count } = response.data.data;
        this.setState({
          result: profileFamily,
          dataState: {
            ...dataState,
            total: count,
          },
          profileFamily,
          loading: false,
        });
      }
    } catch (e) {
      console.log(e);
      this.setState({
        loading: false,
      });
    }
  };
  pageChange = (event) => {
    this.setState(
      {
        dataState: {
          ...this.state.dataState,
          skip: event.page.skip,
          take: event.page.take,
        },
      },
      () => {
        this.getFamilies();
      }
    );
  };
  sortChange = (event) => {
    this.setState(
      {
        dataState: {
          ...this.state.dataState,
          sort: event.sort,
        },
      },
      () => {
        this.getFamilies();
      }
    );
  };
  filterChange = (event) => {
    this.setState(
      {
        dataState: {
          ...this.state.dataState,
          filter: event.filter,
          skip: 0,
        },
      },
      () => {
        this.getFamilies();
      }
    );
  };
  render() {
    const { loading, linkGroup, dataState, result } = this.state;

    if (!!loading) {
      return (
        <main className="admin admin-listing with-sidebar appointments">
          <div className="sidebar-wrapper">
            <Sidebar
              {...this.props}
              iconClass="fas fa-id-card"
              linkGroup={linkGroup}
            />
          </div>
          <div className="content">
            <h4 className="section-label">
              <i className="fas fa-calendar-star"></i>
              &nbsp;Loading Families...
            </h4>
          </div>
        </main>
      );
    }

    return (
      <main className="admin admin-listing with-sidebar appointments">
        <div className="sidebar-wrapper">
          <Sidebar
            {...this.props}
            iconClass="fas fa-id-card"
            linkGroup={linkGroup}
          />
        </div>
        <div className="content">
          <h4 className="section-label">
            <i className="fas fa-calendar-star"></i>&nbsp;Manage Families{' '}
            <Link to={`/families/create`}>
              <i className="fad fa-plus-circle"></i>
            </Link>
          </h4>
          <div className="kendo-table">
            <Grid
              data={result}
              pageable={true}
              sortable={true}
              {...dataState}
              onRowClick={this.handleRowClick}
              onPageChange={this.pageChange}
              onSortChange={this.sortChange}
              onFilterChange={this.filterChange}
            >
              <GridColumn
                title="Family"
                field="lastname"
                filter={'text'}
                columnMenu={ColumnMenu}
              />
              <GridColumn
                title="First Name"
                field="firstname"
                columnMenu={ColumnMenu}
                cell={(props) => (
                  <RelationshipCell
                    {...props}
                    {...this.props}
                    var="owner.firstname"
                  />
                )}
              />
              <GridColumn
                title="Email"
                field="email"
                columnMenu={ColumnMenu}
                cell={(props) => (
                  <RelationshipCell
                    {...props}
                    {...this.props}
                    var="owner.user.email"
                  />
                )}
              />
              <GridColumn
                title="Phone"
                field="phone1"
                columnMenu={ColumnMenu}
                cell={(props) => (
                  <RelationshipCell
                    {...props}
                    {...this.props}
                    var="owner.phone1"
                  />
                )}
              />
              <GridColumn
                title="Subscription"
                width={120}
                field="stripe_id"
                cell={(props) => (
                  <SubscriptionCell
                    {...props}
                    {...this.props}
                    var="stripe_id"
                  />
                )}
              />
              <GridColumn
                title="Renews At"
                field="renews_at"
                width={100}
                cell={DateCell}
              />
              <GridColumn
                title="Ends At"
                field="ends_at"
                width={100}
                cell={DateCell}
              />
              <GridColumn
                title="Cancel Request"
                width={80}
                field="requested_subscription_cancellation"
                columnMenu={ColumnMenu}
                cell={(props) => (
                  <RelationshipCell
                    {...props}
                    {...this.props}
                    var="owner.user.requested_subscription_cancellation"
                    boolean={true}
                  />
                )}
              />
              <GridColumn
                title=" "
                field="id"
                width={100}
                cell={(props) => <ManageCell {...props} {...this.props} />}
              />
            </Grid>
          </div>
        </div>
      </main>
    );
  }
}

export default FamiliesGrid;
