import { recaptchaSiteKey } from '@ss/config';
import { optimizeImage } from '@ss/helpers/image';
import React, { Component } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { toast } from 'react-toastify';
import AsyncSelect from './AsyncSelect';
import BooleanInput from './BooleanInput';
import Checkbox from './Checkbox';
import ChildCheckbox from './ChildCheckbox';
import DatePicker from './Datepicker';
import FileInput from './FileInput';
import Input from './Input';
import PasswordWithRules from './PasswordWithRules';
import Radio from './Radio';
import Select from './Select';
import SelectSearch from './SelectSearch';
import TextDatePicker from './TextDatePicker';
import Textarea from './Textarea';
const span_form_style = {
    marginTop: '7px',
};
class Form extends Component {
    state = {
        data: {},
        errors: {},
        submitting: false,
    };
    schema;
    handleDeleteClick;
    doSubmit;
    validate = () => {
        const options = { abortEarly: false, convert: true };
        const { error } = this.schema.validate(this.state.data, options);
        if (!error) {
            return null;
        }
        const errors = {};
        for (const item of error.details) {
            errors[item.path[0]] = item.message;
        }
        return errors;
    };
    handleSubmit = (event, callback) => {
        if (event) {
            event.preventDefault();
        }
        this.setState({
            submitting: true,
        });
        const errors = this.validate();
        // need to figure out what I want to do here
        if (errors) {
            toast.error('Oops! Looks like a few fields need some attention.');
            this.setState({ errors, submitting: false });
            return;
        }
        this.doSubmit(callback ? callback : this.postSubmit);
    };
    postSubmit = () => {
        this.setState({
            submitting: false,
        });
    };
    handleChange = ({ currentTarget: input, }) => {
        const errors = { ...this.state.errors };
        delete errors[input.name];
        const data = { ...this.state.data };
        let value = input.value;
        if (input.type === 'number') {
            // strip out all non-numeric and decimal characters
            value = value.replace(/[^\d.-]/g, '');
        }
        data[input.name] = value;
        this.setState({ data, errors });
    };
    handleRadioChange = ({ currentTarget: input, }) => {
        const errors = { ...this.state.errors };
        delete errors[input.name];
        const data = { ...this.state.data };
        // this is a brute force attempt to convert the raw "1" or "0" value from the input
        // into an actual true/false or 1/0
        // if all else fails, we just set the raw value
        const value = input.value;
        switch (value) {
            case '0':
            case '1':
                data[input.name] = Number(value);
                break;
            case 'true':
                data[input.name] = true;
                break;
            case 'false':
                data[input.name] = false;
                break;
            default:
                data[input.name] = value;
                break;
        }
        this.setState({ data, errors });
    };
    handleBooleanChange = ({ currentTarget: input, }) => {
        const data = { ...this.state.data };
        data[input.name] = data[input.name] === 1 ? 0 : 1;
        this.setState({ data });
    };
    handleCheckboxChange = ({ currentTarget: input, }) => {
        const data = { ...this.state.data };
        let list = data[input.name] ?? [];
        if (input.checked) {
            list.push(input.value);
        }
        else {
            list = list.filter((item) => item !== input.value);
        }
        data[input.name] = list;
        this.setState({ data });
    };
    handleDatePickerChange = (date, name) => {
        const data = { ...this.state.data };
        data[name] = date;
        this.setState({ data });
    };
    handleFileChange = async ({ currentTarget: input, }) => {
        const errors = { ...this.state.errors };
        const file = input.files[0];
        const data = { ...this.state.data };
        if (!file) {
            data[input.name] = null;
            this.setState({ data, errors });
            return;
        }
        try {
            const optimizedFile = await optimizeImage(file);
            data[input.name] = optimizedFile;
            this.setState({ data, errors });
        }
        catch {
            errors[input.name] = 'Failed to process image. Please try again.';
            this.setState({ errors, data });
        }
    };
    handleSelectSearchChange = (value, name) => {
        const data = { ...this.state.data };
        data[name] = value ?? [];
        this.setState({ data });
    };
    handleAsyncSelectChange = (name, value) => {
        const data = { ...this.state.data };
        data[name] = value;
        this.setState({ data });
    };
    renderButton(label, activeLabel, className = 'btn btn-primary', iconClassName = null, disabled = false) {
        return (React.createElement("div", { className: "form-field button submit" },
            React.createElement("button", { type: "submit", className: className, disabled: disabled || this.state.submitting },
                iconClassName && !this.state.submitting && (React.createElement("i", { className: iconClassName })),
                this.state.submitting && React.createElement("i", { className: "fas fa-spinner fa-spin" }),
                this.state.submitting ? activeLabel : label)));
    }
    renderDeleteButton(label, className = 'btn btn-danger') {
        return (React.createElement("div", { className: "form-field button delete" },
            React.createElement("div", { className: "col-md-6 offset-md-4" },
                React.createElement("button", { type: "reset", onClick: this.handleDeleteClick, className: className }, label))));
    }
    renderCheckbox(name, label, options) {
        const { data, errors } = this.state;
        return (React.createElement(Checkbox, { name: name, label: label, options: options, value: data[name], onChange: this.handleCheckboxChange, error: errors[name] }));
    }
    renderChildCheckbox(name, label, options, required) {
        const { data, errors } = this.state;
        return (React.createElement(ChildCheckbox, { name: name, label: label, options: options, value: data[name], onChange: this.handleCheckboxChange, error: errors[name], required: required }));
    }
    renderRadio(name, label, options, required, disabled = false) {
        const { data, errors } = this.state;
        return (React.createElement(Radio, { name: name, label: label, options: options, onChange: this.handleRadioChange, required: required, value: data[name], error: errors[name], disabled: disabled }));
    }
    renderDatePicker(name, label, required = false, dateFormat = 'yyyy-MM-dd', disabled = false, minDate = null) {
        const { data, errors } = this.state;
        return (React.createElement(DatePicker, { name: name, label: label, value: data[name], error: errors[name], required: required, dateFormat: dateFormat, onChange: this.handleDatePickerChange, disabled: disabled, minDate: minDate }));
    }
    renderTextDatePicker(name, label, required = false) {
        const { data, errors } = this.state;
        const yearName = `${name}_year`;
        const monthName = `${name}_month`;
        const dayName = `${name}_day`;
        return (React.createElement(TextDatePicker, { yearName: yearName, monthName: monthName, dayName: dayName, label: label, yearVal: data[yearName], monthVal: data[monthName], dayVal: data[dayName], errors: errors, onChange: this.handleChange, required: required }));
    }
    renderFile(name, label, mimeTypes, required, DisplayComponent) {
        const { data, errors } = this.state;
        return (React.createElement(FileInput, { name: name, label: label, onChange: this.handleFileChange, error: errors[name], accept: mimeTypes, value: data[name], required: required, DisplayComponent: DisplayComponent }));
    }
    renderInput(name, label, type = 'text', required = false, optionalParams = {}) {
        const { data, errors } = this.state;
        // React really does NOT like null values when passed to input type=text. So if data[name] is null, set it to an empty string instead.
        // If this causes issues later, we can change this.
        data[name] = data[name] ?? '';
        const { wordCount, disabled, step, onFocus, onChange } = optionalParams;
        return (React.createElement(Input, { type: type, name: name, value: data[name], label: label, onChange: onChange ?? this.handleChange, error: errors[name], disabled: disabled, required: required, onFocus: onFocus, step: step, wordCount: wordCount }));
    }
    renderSelect(name, label, options, required = false, optionalParams = {}) {
        const { data, errors } = this.state;
        const { defaults, disabled } = optionalParams;
        // As with the Input, React does NOT like null values when assigned to inputs. So if the input is null, we check for defaults - if they exist,
        // we assign the value to the default. If no defaults are present, we use the first option instead.
        if (data[name] === null) {
            if (defaults) {
                data[name] = defaults.id;
            }
            else if (options.length > 0) {
                data[name] = options[0].id;
            }
            else {
                data[name] = '';
            }
        }
        return (React.createElement(Select, { name: name, value: data[name], label: label, options: options, onChange: this.handleChange, required: required, error: errors[name], defaults: defaults, disabled: disabled }));
    }
    renderSelectSearch(name, label, options, required = false, optionalParams = {}) {
        const { data, errors } = this.state;
        const { disabled, tooltip } = optionalParams;
        return (React.createElement(SelectSearch, { name: name, value: data[name], error: errors[name], label: label, required: required, disabled: disabled, tooltip: tooltip, onChange: (value) => {
                this.handleSelectSearchChange(value, name);
            }, options: options }));
    }
    renderAsyncSelect(name, label, loadOptions, required) {
        const { data, errors } = this.state;
        return (React.createElement(AsyncSelect, { name: name, value: data[name], error: errors[name], label: label, loadOptions: loadOptions, onChange: this.handleAsyncSelectChange, required: required }));
    }
    renderTextarea(name, label, required = false, optionalParams = {}) {
        const { data, errors } = this.state;
        // Same deal here with null input values
        data[name] = data[name] ?? '';
        const { wordCount, disabled, tooltip } = optionalParams;
        return (React.createElement(Textarea, { name: name, value: data[name], label: label, onChange: this.handleChange, error: errors[name], disabled: disabled, required: required, tooltip: tooltip, wordCount: wordCount }));
    }
    renderPasswordWithRules(name, label) {
        const { data, errors } = this.state;
        // React really does NOT like null values when passed to input type=text. So if data[name] is null, set it to an empty string instead.
        // If this causes issues later, we can change this.
        data[name] = data[name] ?? '';
        return (React.createElement(PasswordWithRules, { name: name, value: data[name], label: label, onChange: this.handleChange, error: errors[name] }));
    }
    renderRecaptcha(callback) {
        return (React.createElement("div", { className: "form-field recaptcha" },
            React.createElement(ReCAPTCHA, { sitekey: recaptchaSiteKey, onChange: callback })));
    }
    renderBoolean(name, label) {
        const { data, errors } = this.state;
        // Same deal here with null input values
        data[name] = data[name] ?? 0;
        return (React.createElement(BooleanInput, { name: name, value: data[name], label: label, onChange: this.handleBooleanChange, error: errors[name] }));
    }
    renderSpan(name, label) {
        const { data } = this.state;
        return (React.createElement("div", { className: "form-field" },
            React.createElement("span", { className: "col-md-4 col-form-label text-md-right" },
                label,
                ":"),
            React.createElement("div", { className: "col-md-6", style: span_form_style },
                React.createElement("span", null, data[name]))));
    }
}
export default Form;
