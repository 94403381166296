import { each } from 'lodash-es';
import React from 'react';
import { connect } from 'react-redux';
import { authActions } from '../../../actions/authActions';
import { adminEmail, adminPhone } from '../../../config';
import { states } from '../../../data';
import { joiPasswordRulesHelper, updateRegisterStep } from '../../../helpers';
import httpService from '../../../services/httpService';
import { FormStepTracker } from '../../shared';
import Form from '../../shared/Form';
import trackerSteps from './trackerSteps';
import Joi from 'joi';
import { mapAuthState } from '../../../helpers/mapAuthState';

const radioOptions = [
  { label: 'Yes', value: 1 },
  { label: 'No', value: 0 },
];

const sitterRadioOptions = [
  { label: 'Any', value: 'any' },
  { label: 'Female', value: 'female' },
];

class Profile extends Form {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        firstname: null,
        lastname: null,
        email: null,
        password: null,
        address1: null,
        address2: null,
        city: null,
        state: null,
        zip: null,
        phone1: null,
        phone2: null,
        employer: null,
        sexual_criminal_offense: null,
        smoke: null,
        house_rules: null,
      },
      activityOptions: [],
      errors: {},
      user: null,
    };
  }
  schema = Joi.object({
    firstname: Joi.string().required().label('First Name'),
    lastname: Joi.string().required().label('Last Name'),
    email: Joi.string().required().label('Email'),
    password: Joi.string()
      .custom(joiPasswordRulesHelper, 'custom validation')
      .required()
      .messages({
        'password.lowercase':
          'Password must contain at least 1 lowercase character.',
        'password.uppercase':
          'Password must contain at least 1 uppercase character.',
        'password.number': 'Password must contain at least 1 number',
        'password.special_character':
          'Password must contain at least 1 special character.',
        'password.length': 'Password must be at least 8 characters long.',
      })
      .label('Password'),
    address1: Joi.string().required().label('Address (line 1)'),
    address2: Joi.string()
      .optional()
      .allow(null)
      .allow('')
      .label('Address (line 2)'),
    city: Joi.string().required().label('City'),
    state: Joi.string().required().label('State'),
    zip: Joi.string().required().label('ZIP code'),
    phone1: Joi.string().required().label('Mobile Number'),
    phone2: Joi.string().required().label('Secondary Number (Work)'),
    employer: Joi.string()
      .optional()
      .allow(null)
      .allow('')
      .label('Place of Employment'),
    house_rules: Joi.string()
      .optional()
      .allow(null)
      .allow('')
      .label('House Rules'),
    sitter_preference: Joi.string()
      .required()
      .label('House Rules'),
    sexual_criminal_offense: Joi.boolean()
      .truthy(1)
      .falsy(0)
      .required()
      .label('Sexual / Criminal Offense'),
    smoke: Joi.boolean()
      .truthy(1)
      .falsy(0)
      .required()
      .label('Household Smokers'),
    selected_activities: Joi.array().optional().label('Selected Activities'),
  });
  componentDidMount() {
    this.verifyHash();
    this.getActivityOptions();
  }
  getActivityOptions = async () => {
    try {
      const response = await httpService.get('/api/activityOptions');
      console.log(response.status);
      if (response.status === 200) {
        let { activityOptions } = response.data.data;
        activityOptions = each(activityOptions, (info) => {
          info['value'] = info.id;
          info['label'] = info.name;
        });
        this.setState({ activityOptions });
      }
    } catch (e) {
      console.log(e);
    }
  };
  verifyHash = async () => {
    const { user } = this.props.auth;
    const params = new URLSearchParams(window.location.search);
    let loginHash = params.get('login_hash');
    let email = params.get('email');

    if ((!!email && !!loginHash) || !!user) {
      if (!!user) {
        loginHash = user.login_hash;
        email = user.email;
      } else {
        email = email.replace(' ', '+');
      }

      const data = {
        email,
        login_hash: loginHash,
      };
      const response = await httpService.post('/api/verify/email', data);
      console.log(response);
      if (response.status === 200) {
        const { user } = response.data.data;
        const { email } = user;
        const {
          firstname,
          lastname,
          phone1,
          phone2,
          address1,
          address2,
          city,
          state,
          zip,
        } = user.personal_info;
        const { data } = this.state;
        data['firstname'] = firstname;
        data['lastname'] = lastname;
        data['email'] = email;
        if (!!phone1) data['phone1'] = phone1;
        if (!!phone2) data['phone2'] = phone1;
        if (!!address1) data['address1'] = address1;
        if (!!address2) data['address2'] = address2;
        if (!!city) data['city'] = city;
        if (!!state) data['state'] = state;
        if (!!zip) data['zip'] = zip;
        this.setState({ data, user }, () => {
          this.updateStep('family-signup');
        });
      } else {
        this.sendUserBack();
      }
    } else {
      this.sendUserBack();
    }
  };
  sendUserBack = () => {
    alert(
      "You must complete the previous form before filling out your profile. Please go back and fill out the initial registration form, then click on the link in your inbox once you've received the sign-up email."
    );
    window.open('/register/family', '_self');
  };
  updateStep = async (step) => {
    const { id } = this.state.user;
    const response = await updateRegisterStep(id, step);
  };
  doSubmit = async (callback) => {
    const { data } = this.state;
    const response = await this.props.register(data, 'parent');
    console.log(response);
    callback();
  };
  render() {
    const { activityOptions } = this.state;

    return (
      <div className="registration family step-2 profile">
        <div className="form">
          <div className="inner">
            <FormStepTracker steps={trackerSteps} currentStep={0} />
            <div className="form-notice">
              <h3>Time to complete registration process</h3>
              <p>
                Most families can complete registration between 5-10 minutes
                depending on the number of children and pets. If you have any
                questions regarding the registration process, you can call{' '}
                <a href={`tel:+1${adminPhone}`} target="_blank">{adminPhone}</a> or email us at{' '}
                <a href={`mailto:${adminEmail}`} target="_blank">
                  {adminEmail}
                </a>
              </p>
            </div>
            <div className="form-header">
              <h2>Family Profile</h2>
            </div>
            <form onSubmit={this.handleSubmit}>
              <div className="form-group justify-space-between">
                {this.renderInput('firstname', 'First Name', 'text', true, {
                  disabled: true,
                })}
                {this.renderInput('lastname', 'Last Name', 'text', true, {
                  disabled: true,
                })}
                {this.renderInput('email', 'Email', 'email', true, {
                  disabled: true,
                })}
                {this.renderPasswordWithRules('password', 'Password')}
              </div>
              <div className="form-group left-align">
                <h3>Contact and Address Information</h3>
                {this.renderInput('phone1', 'Mobile Phone', 'number', true)}
                {this.renderInput(
                  'phone2',
                  'Secondary Phone (Work)',
                  'number',
                  true
                )}
                {this.renderInput('address1', 'Address', 'text', true)}
                {this.renderInput('address2', 'Apt/Suite', 'text')}
                {this.renderInput('city', 'City', 'text', true)}
                {this.renderSelect('state', 'State', states, true)}
                {this.renderInput('zip', 'ZIP', 'number', true)}
              </div>
              <div className="form-group left-align">
                <h3>History Information</h3>
                {this.renderInput('employer', 'Place of Employment (optional)')}
                {this.renderRadio(
                  'sexual_criminal_offense',
                  'Has anyone in the home been convicted of any sexual or criminal offenses?',
                  radioOptions,
                  true
                )}
                {this.renderRadio(
                  'smoke',
                  'Does anyone in your household smoke?',
                  radioOptions,
                  true
                )}
              </div>
              <div className="form-group left-align">
                <h3>Appointment Information</h3>
                {this.renderTextarea(
                  'house_rules',
                  'Enter any rules or guidelines you want sitters to be aware of for all bookings. This can be changed later.'
                )}
                {this.renderCheckbox(
                  'selected_activities',
                  'Select any skills & activities you want to prioritize for potential sitters',
                  activityOptions
                )}
                {this.renderRadio(
                  'sitter_preference',
                  'Do you have a sitter preference?',
                  sitterRadioOptions,
                  true
                )}
              </div>
              {this.renderButton('Next', 'Submitting...', 'theme-btn blue')}
            </form>
          </div>
        </div>
      </div>
    );
  }
}

const actionCreators = {
  register: authActions.register,
};

export default connect(mapAuthState, actionCreators)(Profile);
