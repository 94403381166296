import debounce from 'debounce-promise';
import React from 'react';
import { useInfiniteQuery } from 'react-query';
import { getFilteredPendingAppointmentsRequest } from '../../../services';
import { UnclaimedAppointmentsRow } from './UnclaimedAppointmentsRow';
import { useUnclaimedAppointmentFilters } from './useUnclaimedAppointmentFilters';
const debouncedFilterRequest = debounce(getFilteredPendingAppointmentsRequest, 300);
export const UnclaimedAppointmentsListing = (props) => {
    const { family, ages, zip, fromDate, toDate } = useUnclaimedAppointmentFilters();
    const { schedule } = props;
    const queryKey = [
        'unclaimedAppointments',
        family,
        ages,
        zip,
        fromDate,
        toDate,
    ];
    const query = useInfiniteQuery(queryKey, function ({ pageParam = 0 }) {
        return debouncedFilterRequest({
            pageParam,
            familyName: family,
            ages,
            zip,
            fromDate,
            toDate,
        });
    }, {
        getNextPageParam: (lastPage) => lastPage.nextCursor,
        keepPreviousData: true,
    });
    if (!query.isFetchedAfterMount) {
        return React.createElement("div", null, "Loading appointments...");
    }
    if (query.isError) {
        return (React.createElement("div", null,
            "We\u2019ve encountered a problem loading appointments.",
            ' ',
            React.createElement("button", { type: "button", className: "theme-btn small", onClick: () => query.refetch() }, "Try again")));
    }
    return (React.createElement(React.Fragment, null,
        query.data?.pages[0]?.data.length === 0 && (React.createElement("div", null, "No pending appointments to show.")),
        React.createElement("ul", { className: "unclaimed-appointments-listing" }, query.data?.pages.map((group) => group.data.map((appointment) => (React.createElement("li", { key: appointment.id },
            React.createElement(UnclaimedAppointmentsRow, { ...appointment, schedule: schedule })))))),
        query.hasNextPage && (React.createElement("div", { className: "sitters-load-more" },
            React.createElement("button", { type: "button", className: "theme-btn primary-green", onClick: () => query.fetchNextPage(), disabled: query.isFetching }, query.isFetching ? 'Loading...' : 'More Appointments')))));
};
