import React, { Component } from 'react';
import Modal from 'react-modal';
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '50%',
    },
    overlay: {
        backgroundColor: 'rgba(0,0,0,0.5)',
        zIndex: '100000',
    },
};
class ReactModal extends Component {
    render() {
        const { isOpen, title, content, primaryButton, saveButton, secondaryButton, deleteButton, closeModal, handleClick, handleDelete, children, } = this.props;
        return (React.createElement(Modal, { isOpen: isOpen, onRequestClose: closeModal, style: customStyles, ariaHideApp: false },
            React.createElement("div", { className: "react-modal-outer" },
                React.createElement("div", { className: "react-modal-inner" },
                    React.createElement("div", { className: "mod-header" },
                        React.createElement("h4", null, title ? title : 'Modal'),
                        React.createElement("button", { className: "mod-close", onClick: closeModal },
                            React.createElement("span", { "aria-hidden": "true" }, "\u00D7"))),
                    React.createElement("div", { className: "mod-body" },
                        React.createElement("div", null,
                            content,
                            children),
                        React.createElement("p", { className: "mod-buttons" },
                            primaryButton && (React.createElement("button", { className: "btn primary", onClick: handleClick }, primaryButton)),
                            saveButton && (React.createElement("button", { className: "btn primary-green", onClick: handleClick },
                                React.createElement("i", { className: "far fa-check-circle" }),
                                saveButton)),
                            React.createElement("br", null),
                            deleteButton && (React.createElement(React.Fragment, null,
                                React.createElement("br", null),
                                React.createElement("button", { className: "btn", style: {
                                        background: '#DF1924',
                                        fontSize: 13,
                                    }, onClick: handleDelete }, deleteButton),
                                React.createElement("br", null))),
                            secondaryButton && (React.createElement("button", { className: "btn secondary", onClick: closeModal }, secondaryButton))))))));
    }
}
export default ReactModal;
