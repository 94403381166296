import React from 'react';
import { Link } from 'react-router-dom';
import { displayProfileColor, firstInitial, slugify, upperCaseFirst, } from '../../../helpers';
import Avatar from '../Avatar';
import { SitterProfileActions } from './SitterProfileActions';
const SitterProfile = (props) => {
    const { data: { personalInfo, profileSitter }, className = '', referrer, omitActions, } = props;
    return (React.createElement("div", { className: `sitter-profile ${className}` },
        React.createElement("div", { className: `sitter-header ${displayProfileColor(personalInfo.profile_color, 'gradient')}` },
            referrer && (React.createElement(Link, { to: referrer, "aria-label": "Return to previous page", className: "sitter-header__back-link" },
                React.createElement("svg", { width: "35", height: "36", viewBox: "0 0 35 36", fill: "none", xmlns: "http://www.w3.org/2000/svg", focusable: false, "aria-hidden": "true" },
                    React.createElement("path", { d: "M14.8828 3.22656L16.6172 1.49219C17.3516 0.757812 18.5391 0.757812 19.2656 1.49219L34.4531 16.6719C35.1875 17.4062 35.1875 18.5938 34.4531 19.3203L19.2656 34.5078C18.5312 35.2422 17.3438 35.2422 16.6172 34.5078L14.8828 32.7734C14.1406 32.0312 14.1562 30.8203 14.9141 30.0938L24.3281 21.125H1.875C0.835938 21.125 0 20.2891 0 19.25V16.75C0 15.7109 0.835938 14.875 1.875 14.875H24.3281L14.9141 5.90625C14.1484 5.17969 14.1328 3.96875 14.8828 3.22656Z", fill: "white" })))),
            React.createElement(Avatar, { imageSrc: personalInfo.avatar_filename, profileColor: personalInfo.profile_color }),
            React.createElement("h3", null,
                upperCaseFirst(personalInfo.firstname),
                "\u00A0",
                firstInitial(personalInfo.lastname)),
            React.createElement("p", null, profileSitter.intro)),
        React.createElement("div", { className: "sitter-bio" },
            React.createElement("div", { className: "bio-section bio" },
                React.createElement("h4", { className: "section-label" },
                    React.createElement("i", { className: "fas fa-id-card" }),
                    "\u00A0About",
                    ' ',
                    personalInfo.firstname),
                React.createElement("p", null, profileSitter.bio)),
            personalInfo.accepted_age_ranges.length > 0 && (React.createElement("div", { className: "bio-section ages" },
                React.createElement("h4", { className: "section-label" },
                    React.createElement("i", { className: "fas fa-child" }),
                    "\u00A0Ages of Kids I Can Work With"),
                personalInfo.accepted_age_ranges.map((ageRange) => (React.createElement("p", { key: `sitter-age-${ageRange.id}` }, ageRange.name))))),
            React.createElement("div", { className: "bio-section vehicle" },
                React.createElement("h4", { className: "section-label" },
                    React.createElement("i", { className: "fas fa-car" }),
                    "\u00A0My Vehicle"),
                React.createElement("p", null,
                    profileSitter.vehicle_year,
                    "\u00A0/\u00A0",
                    profileSitter.vehicle_make,
                    "\u00A0/\u00A0",
                    profileSitter.vehicle_model,
                    "\u00A0/\u00A0",
                    React.createElement("b", null,
                        profileSitter.vehicle_seatbelts,
                        " seatbelts"))),
            personalInfo.languages.length > 0 && (React.createElement("div", { className: "bio-section languages" },
                React.createElement("h4", { className: "section-label" },
                    React.createElement("i", { className: "far fa-comment" }),
                    "\u00A0In addition to English, I also speak conversational:"),
                personalInfo.languages.map((language) => (React.createElement("p", { key: `sitter-language-${language.id}` }, language.language))))),
            personalInfo.selected_activities.length > 0 && (React.createElement("div", { className: "bio-section activities" },
                React.createElement("h4", { className: "section-label" },
                    React.createElement("i", { className: "fas fa-grin-alt" }),
                    "\u00A0Skills and Activities I Love"),
                personalInfo.selected_activities.map((activity) => (React.createElement("div", { className: "sitter-activity-tile", key: `sitter-activity-${activity.id}` },
                    React.createElement("div", { className: "outer" },
                        React.createElement("div", { className: `tile ${slugify(activity.name)}` },
                            React.createElement("p", null, activity.name))))))))),
        !omitActions && React.createElement(SitterProfileActions, { piid: profileSitter.piid })));
};
export default SitterProfile;
