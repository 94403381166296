import { buildApiUrl } from '../helpers/buildApiUrl';
import httpService from './httpService';
const get = (data) => {
    return httpService.get(buildApiUrl('/logs'), data);
};
const family = (id, data = {}) => {
    const apiUrl = buildApiUrl(`/logs/family/${id}`);
    return httpService.get(apiUrl, data);
};
const appointment = (id, data = {}) => {
    const apiUrl = buildApiUrl(`/logs/appointment/${id}`);
    return httpService.get(apiUrl, data);
};
const getArchive = () => {
    const apiUrl = buildApiUrl('/logArchive');
    return httpService.post(apiUrl, {}, {
        responseType: 'blob',
    });
};
export default {
    get,
    family,
    appointment,
    getArchive,
};
