import React from 'react';
import moment from 'moment-timezone';
import appointmentService from '../../../services/appointmentService';
import { each } from 'lodash-es';

export default class Calendar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedDay: null,
      appointments: [],
      today: moment(),
    };
  }

  componentDidMount() {
    if (this.props.showAppointments) {
      this.getCalendarAppointments();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    //
  }

  getCalendarAppointments = async () => {
    const response = await appointmentService.getAppointmentsByDateRange({
      startTime: this.props.date.startOf('month').format('Y-MM-DD'),
      endTime: this.props.date.endOf('month').format('Y-MM-DD'),
    });

    if (response.status === 200) {
      const { appointments } = response.data.data;
      this.setState({ appointments });
    }
  };

  daysInMonth = () => {
    return this.props.date.daysInMonth();
  };
  year = () => {
    return this.props.date.format('Y');
  };
  firstDayOfMonth = () => {
    let dateObject = this.props.date;
    let firstDay = moment(dateObject).startOf('month').format('d'); // Day of week 0...1..5...6
    return firstDay;
  };
  lastDayOfMonth = () => {
    let dateObject = this.props.date;
    let lastDay = moment(dateObject).endOf('month').format('d'); // Day of week 0...1..5...6
    return lastDay;
  };
  month = () => {
    return this.props.date.format('MMMM');
  };
  shortMonth = () => {
    return this.props.date.format('MM');
  };
  onDayClick = (e, d, appts) => {
    if (this.props.type === 'dashboard') {
      if (appts.length > 0) {
        console.log('here');
        // needs to be adjusted whenever we allow multiple on same day
        this.props.history?.push(`/appointment/${appts[0].id}`, {
          appointmentView: true,
        });
      }
    } else if (
      this.props.type === 'booking' ||
      this.props.type === 'availability' ||
      this.props.type === 'program'
    ) {
      if (
        this.props.onSelectedDay &&
        typeof this.props.onSelectedDay === 'function'
      ) {
        let currentDate = `${this.year()}-${this.shortMonth()}-${d}`;
        currentDate = moment(currentDate, 'YYYY-MM-D');
        this.props.onSelectedDay(currentDate);
      }
    } else {
      this.setState(
        {
          selectedDay: d,
        },
        () => {
          if (
            this.props.onSelectedDay &&
            typeof this.props.onSelectedDay === 'function'
          ) {
            let currentDate = `${this.year()}-${this.shortMonth()}-${
              this.state.selectedDay
            }`;
            currentDate = moment(currentDate, 'YYYY-MM-D');
            this.props.onSelectedDay(currentDate);
          }
        }
      );
    }
  };
  checkIfUnavailable = (appointments) => {
    // This is basically only ever used on the Program Booking sitter signup view
    const { unavailableAppointments, signingUp } = this.props;
    if (signingUp) {
      let match = false;
      each(unavailableAppointments, (id) => {
        each(appointments, (appointment) => {
          if (appointment.id === id) match = true;
        });
      });

      if (match) {
        return 'unavailable';
      } else {
        return 'available';
      }
    } else {
      // We only want to change the color when the user is signing up
      return '';
    }
  };
  render() {
    const { selectedDay } = this.state;
    const { showDisabledDates = false } = this.props;
    let appointments = this.state.appointments;
    if (!appointments.length) {
      if (!!this.props.appointments && this.props.appointments.length > 0) {
        appointments = this.props.appointments;
      }
    }
    //getting weekdays
    const weekdayshort = moment.weekdaysShort();
    let weekdayshortname = weekdayshort.map((day, idx) => {
      return <th key={idx}>{day}</th>;
    });

    //getting blank days at beginning
    let begginingBlanks = [];
    for (let i = 0; i < this.firstDayOfMonth(); i++) {
      begginingBlanks.push(
        <td key={i + 'blank'} className="calendar-day empty">
          {''}
        </td>
      );
    }

    //getting blank days at end
    let endBlanks = [];
    for (let i = 0; i < 6 - this.lastDayOfMonth(); i++) {
      endBlanks.push(
        <td key={i + 'blank'} className="calendar-day empty">
          {''}
        </td>
      );
    }

    //getting days in month
    let daysInMonth = [];
    const month = this.month();
    const shortMonth = this.shortMonth();
    const numericMonth = parseInt(shortMonth);
    const { today } = this.state;
    const year = this.year();
    for (let d = 1; d <= this.daysInMonth(); d++) {
      let longDay = d < 10 ? '0' + d : d;
      let rawDate = year + '-' + shortMonth + '-' + longDay;
      let momentRawDate = moment(rawDate, 'YYYY-MM-DD');
      let day_appointments = appointments.filter(function (item) {
        if (!!item.date) {
          return (
            item.date == rawDate ||
            moment(item.date).format('YYYY-MM-DD') == rawDate
          );
        } else if (!!item.start_time) {
          return moment(item.start_time).format('YYYY-MM-DD') == rawDate;
        } else if (!!item.start_date) {
          return moment(item.start_date).format('YYYY-MM-DD') == rawDate;
        }
        return false;
      });
      let currentDay =
        d == parseInt(today.format('D')) &&
        month == today.format('MMMM') &&
        year == today.format('YYYY')
          ? 'today'
          : '';
      let pastDay =
        (d < parseInt(today.format('D')) &&
          numericMonth <= parseInt(today.format('M')) &&
          parseInt(year) <= parseInt(today.format('YYYY'))) ||
        (d <= parseInt(today.format('D')) &&
          numericMonth < parseInt(today.format('M')) &&
          parseInt(year) <= parseInt(today.format('YYYY')))
          ? 'past'
          : '';
      let future = moment(today).add(60, 'days');
      let futureDay = momentRawDate.isSameOrAfter(future) ? 'future' : '';
      let selectedDayClass = d === selectedDay ? 'selected' : '';
      let classNames = ['calendar-day', currentDay, selectedDayClass];
      if (showDisabledDates) {
        classNames.push(pastDay, futureDay);
      }
      daysInMonth.push(
        <td
          key={d}
          className={classNames.join(' ')}
          onClick={(e) => {
            this.onDayClick(e, d, day_appointments);
          }}
        >
          <span className="day">{d}</span>
          {day_appointments.length > 0 &&
            this.props.type !== 'availability' &&
            this.props.type !== 'program' && (
              <i className="fas fa-calendar-star"></i>
            )}
          {day_appointments.length > 0 && this.props.type == 'availability' && (
            <i className="fas fa-check primary-green"></i>
          )}
          {day_appointments.length > 0 && this.props.type == 'program' && (
            <i
              className={`fas fa-calendar-star with-tooltip ${this.checkIfUnavailable(
                day_appointments
              )}`}
            >
              <span className="time-tooltip">
                {day_appointments.map((appointment) => (
                  <span key={`appt-time-tooltip-${appointment.id}`}>
                    {moment(appointment.start_time).format('h:mm a')} -{' '}
                    {moment(appointment.end_time).format('h:mm a')}
                  </span>
                ))}
              </span>
            </i>
          )}
        </td>
      );
    }
    var totalSlots = [...begginingBlanks, ...daysInMonth, ...endBlanks];
    let rows = [];
    let cells = [];

    totalSlots.forEach((row, i) => {
      if (i % 7 !== 0) {
        cells.push(row);
      } else {
        rows.push(cells);
        cells = [];
        cells.push(row);
      }
      if (i === totalSlots.length - 1) {
        rows.push(cells);
      }
    });

    let daysinmonth = rows.map((d, i) => {
      return <tr key={i}>{d}</tr>;
    });

    return (
      <div className="align_center calendar">
        <h5 className={this.month().toLowerCase()}>
          {this.month()}
          {' ' + this.year()}
        </h5>

        <table>
          <thead>
            <tr>{weekdayshortname}</tr>
          </thead>
          <tbody>{daysinmonth}</tbody>
        </table>
      </div>
    );
  }
}
