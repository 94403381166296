import clsx from 'clsx';
import React from 'react';
const Select = (props) => {
    const { name, label, options, defaults, value, error, required, className = '', tooltip = null, ...rest } = props;
    return (React.createElement("div", { className: clsx('form-field select', name, className) },
        React.createElement("select", { name: name, id: name, ...rest, className: value !== '' && value !== null ? 'active' : 'inactive', value: value !== null && value !== undefined ? value : '' },
            defaults?.id && defaults?.name && (React.createElement("option", { value: defaults.id }, defaults.name)),
            options.map((option) => (React.createElement("option", { key: `option-${name}-${option.id}`, value: option.id }, option.name)))),
        React.createElement("label", { htmlFor: name },
            label,
            required && React.createElement("span", { className: "required" }, "\u00A0*")),
        tooltip && (React.createElement("div", { className: "tooltip-wrapper" },
            React.createElement("div", { className: "inner" },
                React.createElement("i", { className: "fas fa-info-circle" }),
                React.createElement("div", { className: "tooltip-item" },
                    React.createElement("p", null, tooltip))))),
        error && (React.createElement("span", { className: "error", role: "alert" },
            React.createElement("strong", null, error)))));
};
export default Select;
