import React from 'react';
import { calendlyMeetingLink } from '@ss/config';

class ScheduleInterview extends React.Component {
  componentDidMount() {
    const head = document.querySelector('head');
    const script = document.createElement('script');
    script.setAttribute(
      'src',
      'https://assets.calendly.com/assets/external/widget.js'
    );
    head.append(script);
  }

  render() {
    return (
      <main className="schedule-interview-page">
        <div className="row">
          <div className="col-12">
            <div
              className="calendly-inline-widget"
              data-url={calendlyMeetingLink}
              style={{ minWidth: '320px', height: '580px' }}
            />
          </div>
        </div>
      </main>
    );
  }
}
export default ScheduleInterview;
