import { each } from 'lodash-es';
import moment from 'moment-timezone';
import React from 'react';
import BooleanInput from '../../shared/Form/BooleanInput';

class BulkCheckout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      today: moment().format('MMMM Do, YYYY'),
      estimatedSchedulingFee: 0,
      showingCostBreakdown: false,
    };
  }
  componentDidMount() {
    this.calculateTotalEstimatedFee();
  }
  calculateTotalEstimatedFee = () => {
    let estimatedSchedulingFee = 0;
    let totalAppts = 0;
    const { repeatingAppointments, randomAppointments } = this.props.data;
    each(repeatingAppointments, (appt) => {
      totalAppts += appt.repeatingOptions.length;
    });
    totalAppts += randomAppointments.length;
    estimatedSchedulingFee = 10 * totalAppts;
    this.setState({
      estimatedSchedulingFee,
    });
  };
  renderWeekOutline = () => {
    const { data } = this.props;
    let elements = [];
    for (var i = 1; i <= data['program_num_weeks']; i++) {
      let startDate = moment(data['program_start_date']).add(i - 1, 'weeks');
      let startOfWeek = startDate.startOf('week').format('MMM D');
      let endOfWeek = startDate.endOf('week').format('MMM D');
      let element = (
        <div className="week" key={`week-outline-${i}`}>
          <p>
            Week {i}: {startOfWeek}&nbsp;-&nbsp;{endOfWeek}
          </p>
        </div>
      );
      elements.push(element);
    }
    return (
      <div className="week-outline">
        <p>
          <b>Dates of Care:</b>
        </p>
        {elements}
      </div>
    );
  };
  toggleCostBreakdown = () => {
    this.setState({
      showingCostBreakdown: !this.state.showingCostBreakdown,
    });
  };
  canAdvanceStep = () => {
    const { enteringPaymentInfo } = this.props;
    if (!!enteringPaymentInfo) {
      const { data } = this.props;
      if (
        !data['firstname'] ||
        !data['lastname'] ||
        !data['address1'] ||
        !data['city'] ||
        !data['state'] ||
        !data['zip']
      ) {
        return false;
      }
      return (
        !!this.props.data.program_terms_accepted_1 &&
        !!this.props.data.program_terms_accepted_2
      );
    } else {
      return (
        !!this.props.data.program_terms_accepted_1 &&
        !!this.props.data.program_terms_accepted_2
      );
    }
  };
  render() {
    const { today, showingCostBreakdown, estimatedSchedulingFee } = this.state;
    const { data, balance, submitting, coupon, actualFee, bookingFee } =
      this.props;
    const { repeatingAppointments, randomAppointments, using_balance } = data;
    const canAdvanceStep = this.canAdvanceStep();
    return (
      <div className="bulk-booking checkout">
        <div className="checkout-section calendar-details">
          <h3>Calendar Details</h3>
          <p>
            <b>Today:</b>
            <br />
            {today}
          </p>
          {this.renderWeekOutline()}
        </div>
        <div className="checkout-section payment-information">
          <h3>Payment Information</h3>
          {this.props.PaymentSection}
        </div>
        <div className="checkout-section-cost-breakdown">
          <div className="breakdown-section">
            <div className="cost-row">
              <div className="cost-cell">
                <p>
                  <b>Booking Logistics Fee</b>
                  <br />
                  <span>
                    <em>
                      This payment is due today in order for the SmartSitter Team
                      to process your request and is non-refundable.
                    </em>
                  </span>
                </p>
              </div>
              <div className="cost-cell">
                <h3>${actualFee}</h3>
              </div>
            </div>
          </div>
          <div className="appointment-cost-breakdown">
            <button
              onClick={this.toggleCostBreakdown}
              className="toggle-cost-btn"
            >
              Cost Breakdown
            </button>
            {!!showingCostBreakdown && (
              <div className="inner">
                <div className="appt-cost">
                  <div className="cost-row">
                    <div className="cost-cell">
                      <p>
                        <b>Estimated Scheduling Fee</b>
                      </p>
                      <p>($10/appt * total appointments)</p>
                      <p>
                        <em>
                          This payment is due via Credit Card upon your
                          acceptance of Consistent Care Proposal to secure
                          coverage.
                        </em>
                      </p>
                    </div>
                    <div className="cost-cell">
                      <p className="right">
                        ${parseFloat(estimatedSchedulingFee)}
                      </p>
                    </div>
                  </div>
                  <div className="cost-row">
                    <div className="cost-cell">
                      <p>
                        <b>Estimated Sitter Compensation</b>
                      </p>
                      <p>($18/hour * hours requested)</p>
                      <p>
                        <em>
                          This payment is due at the time of departure from
                          appointments. Members pay sitters directly via cash,
                          check, Venmo, or Paypal.
                        </em>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <BooleanInput
            value={data['program_terms_accepted_1']}
            name={`program_terms_accepted_1`}
            label={
              <span className="text">
                {`I understand coverage is not guaranteed or confirmed for appointments included in this request until I receive and approve a Care Proposal from the SmartSitter Team.`}
                <span className="required">*</span>
              </span>
            }
            onChange={(e) => {
              this.props.handleChange(
                'program_terms_accepted_1',
                !data['program_terms_accepted_1']
              );
            }}
            error={null}
          />
          <BooleanInput
            value={data['program_terms_accepted_2']}
            name={`program_terms_accepted_2`}
            label={
              <span className="text">
                {`I understand all SmartSitter policies regarding communication and conduct apply to Consistent Care appointments.`}
                <span className="required">*</span>
              </span>
            }
            onChange={(e) => {
              this.props.handleChange(
                'program_terms_accepted_2',
                !data['program_terms_accepted_2']
              );
            }}
            error={null}
          />
          {!using_balance && (
            <div className="breakdown-section total">
              <div className="cost-row">
                <div className="cost-cell">
                  {coupon !== false && <p>Consistent Care Booking Fee</p>}
                  {coupon !== false && <p>Coupon</p>}
                  <p>Total Charged Today</p>
                </div>
                <div className="cost-cell">
                  {coupon !== false && <p className="right">${bookingFee}</p>}
                  {coupon !== false && (
                    <p className="right">
                      {coupon.type === 'percentage'
                        ? coupon.amount * 100 + '%'
                        : '$' + coupon.amount}{' '}
                      off
                    </p>
                  )}
                  <p className="right">${actualFee}</p>
                </div>
              </div>
            </div>
          )}
          {!!using_balance && (
            <div className="breakdown-section total">
              <div className="cost-row">
                <div className="cost-cell">
                  <p>Consistent Care Booking Fee</p>
                  {coupon !== false && <p>Coupon</p>}
                  <p>Credit Available</p>
                  <p>Total Charged Today</p>
                  <p>Remaining Balance</p>
                </div>
                <div className="cost-cell">
                  <p className="right">${bookingFee}</p>
                  {coupon !== false && (
                    <p className="right">
                      -
                      {coupon.type === 'percentage'
                        ? coupon.amount * 100 + '%'
                        : '$' + coupon.amount}{' '}
                      off
                    </p>
                  )}
                  <p className="right">${parseFloat(balance)}</p>
                  <p className="right">
                    $
                    {parseFloat(balance) >= actualFee
                      ? 0
                      : actualFee - parseFloat(balance)}
                  </p>
                  <p className="right">
                    $
                    {parseFloat(balance) <= actualFee
                      ? 0
                      : parseFloat(balance) - actualFee}
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="booking-footer">
          {canAdvanceStep && !submitting && (
            <button
              className={`theme-btn blue`}
              onClick={this.props.onFormComplete}
            >
              Pay And Submit Request
            </button>
          )}
          {canAdvanceStep && !!submitting && (
            <button
              className={`theme-btn blue`}
              onClick={(e) => e.preventDefault()}
            >
              <i className="fas fa-spinner fa-spin"></i>
              &nbsp;Submitting...
            </button>
          )}
          {!canAdvanceStep && (
            <button
              className={`theme-btn blue disabled`}
              onClick={(e) => e.preventDefault()}
            >
              Pay And Submit Request
            </button>
          )}
        </div>
      </div>
    );
  }
}

export default BulkCheckout;
