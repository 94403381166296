import React from 'react';

import Sidebar from '../Sidebar';
import { findLinkGroupByTitle } from '../../../helpers';
import ReactModal from '../../shared/ReactModal';
import { toast } from 'react-toastify';
import childService from '../../../services/childService';
import ChildForm from '../../Forms/ChildForm';
import { each } from 'lodash-es';

class ChildrenEdit extends ChildForm {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      tab: 'regular',
      saving: false,
      deleting: false,
      isOpen: false,
      linkGroup: findLinkGroupByTitle('Families'),

      loading: true,
      id: null,
      creating: false,
      family_id: null,
    };
  }

  componentDidMount = () => {
    this.getMedicalConditions();

    const { id, family_id } = this.props.match.params;
    if (id !== 'new' && Number(id) == id) {
      // abusing a quirk of JS where "11" == 11 to ensure the ID is a number
      this.getChild(id);
    } else {
      this.setState({
        data: { ...this.state.freshSchema },
        creating: true,
        family_id: family_id,
      });
    }
  };
  getChild = async (id) => {
    try {
      const response = await childService.get(id);
      if (response.status === 200) {
        const { data } = response.data;
        this.formatData(data);
      }
    } catch (e) {
      console.log(e);
    }
  };
  formatData = ({ medicalInfo, personalInfo, profileChild }) => {
    const {
      DOB,
      avatar_filename,
      firstname,
      lastname,
      unborn,
      family_id,
      profile_color,
    } = personalInfo;
    const { about, allergy_info, pediatrician_info, notes } = profileChild;

    const { data } = this.state;

    const selected_medical_conditions = each(medicalInfo, (info) => {
      info['value'] = info.id;
      info['label'] = info.name;
    });

    data['firstname'] = firstname;
    data['lastname'] = lastname;
    data['avatar_filename'] = avatar_filename;
    data['unborn'] = unborn;

    const dobPieces = DOB.split('-');
    if (dobPieces.length === 3) {
      // We assume format "YYYY-MM-DD"
      // so after splittig, it should be: [ "YYYY", "MM", "DD" ]
      data['dob_year'] = dobPieces[0];
      data['dob_month'] = dobPieces[1];
      data['dob_day'] = dobPieces[2];
    }

    data['about'] = about;
    data['allergy_info'] = allergy_info;
    data['notes'] = notes;
    data['profile_color'] = profile_color;
    data['pediatrician_info'] = pediatrician_info;

    data['selected_medical_conditions'] = selected_medical_conditions;

    this.setState({
      data,
      creating: false,
      medicalInfo,
      personalInfo,
      profileChild,
      id: profileChild.id,
      family_id,
    });
  };
  doSubmit = async (callback) => {
    this.setState({ saving: true });
    const response = await this.submitFormData();
    callback();
    if (response.status === 200 || response.status === 201) {
      toast.success('Hooray! Your changes are saved.');
      this.goBack();
    }
    this.setState({ saving: false });
  };

  closeModal = () => {
    this.setState({ isOpen: false });
  };

  goBack = (e) => {
    if (!!e) {
      e.preventDefault();
    }
    this.props.history.goBack();
  };

  deleteChild = async () => {
    const { id } = this.state;

    this.setState({ deleting: true });

    let data = new FormData();
    const headers = { headers: { 'content-type': 'multipart/form-data' } };
    data.append('_method', 'delete');

    const response = await childService.delete(id, data, headers);

    if (response.status === 204) {
      toast.success('Hooray! The family member was deleted.');
      this.goBack();
    } else {
      toast.error('Oops! Looks like something went wrong.');
    }

    this.setState({ deleting: false });
  };

  render() {
    const { tab, saving, deleting, linkGroup, isOpen, creating } = this.state;

    return (
      <main className="dashboard admin manage">
        <div className="sidebar-wrapper">
          <Sidebar
            {...this.props}
            iconClass="fas fa-id-card"
            linkGroup={linkGroup}
          />
        </div>
        <div className="content">
          <div className="providers">
            <h2>{creating === true ? 'Create' : 'Edit'} Child</h2>
            <div className="providers-form form">
              {this.renderForm(true)}
              <div className="divider"></div>
              <div className="align_center row">
                <button
                  type="button"
                  className="theme-btn blue large save-btn"
                  disabled={saving}
                  onClick={this.handleSubmit}
                >
                  {saving && <i className="fas fa-spinner fa-spin"></i>}
                  {saving ? ' Saving...' : 'Save'}
                </button>
                <button
                  type="button"
                  className="theme-btn red large save-btn"
                  disabled={deleting}
                  onClick={() => this.setState({ isOpen: true })}
                >
                  {deleting && <i className="fas fa-spinner fa-spin"></i>}
                  {deleting ? ' Deleting...' : 'Delete'}
                </button>
              </div>
            </div>
          </div>
          <ReactModal
            title="Confirm Delete"
            isOpen={isOpen}
            closeModal={this.closeModal}
            content={'Are you sure you want to delete this child?'}
            primaryButton={deleting ? ' Deleting...' : 'Delete'}
            secondaryButton={'Close'}
            handleClick={this.deleteChild}
          />
        </div>
      </main>
    );
  }
}

export default ChildrenEdit;
