const validatePassword = (password) => {
    const hasLowercase = password.match(/[a-z]/g);
    const hasUppercase = password.match(/[A-Z]/g);
    const hasSpecialCharacters = password.match(/[^\da-z]/gi);
    const hasNumber = password.match(/\d/g);
    const isLengthy = password.length >= 8;
    return {
        hasLowercase,
        hasUppercase,
        hasSpecialCharacters,
        hasNumber,
        isLengthy,
    };
};
export default validatePassword;
