import React from 'react';
import { profileColors } from '../../data';
const ProfileColorPicker = (props) => {
    const { value } = props;
    return (React.createElement("div", { className: "profile-color-picker" },
        React.createElement("label", null, "Profile Color"),
        React.createElement("div", { className: "color-wrapper" }, profileColors.map((color, idx) => (React.createElement("div", { key: `color-${idx}`, className: `color-example profile-color color ${color.class} ${value === color.class ? 'active' : ''}`, onClick: () => {
                props.onProfileColorChange(color.class);
            } },
            React.createElement("div", { className: "inner" })))))));
};
export default ProfileColorPicker;
