import Joi from 'joi';
import React from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { authActions } from '../../actions/authActions';
import { joiPasswordRulesHelper } from '../../helpers';
import { mapAuthState } from '../../helpers/mapAuthState';
import Form from './Form';
class PasswordUpdate extends Form {
    schema = Joi.object({
        current_password: Joi.string().required().label('Current Password'),
        new_password: Joi.string()
            .custom(joiPasswordRulesHelper, 'custom validation')
            .required()
            .messages({
            'password.lowercase': 'Password must contain at least 1 lowercase character.',
            'password.uppercase': 'Password must contain at least 1 uppercase character.',
            'password.number': 'Password must contain at least 1 number',
            'password.special_character': 'Password must contain at least 1 special character.',
            'password.length': 'Password must be at least 8 characters long.',
        })
            .label('New Password'),
    });
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            data: { current_password: '', new_password: '' },
            errors: {},
        };
    }
    doSubmit = async (callback) => {
        const { current_password, new_password } = this.state.data;
        const { id } = this.props.auth.user;
        try {
            const response = await this.props.changePassword(id, {
                current_password,
                new_password,
            });
            if (response.status === 200) {
                toast.success('Hooray! Your password has been updated.');
            }
            callback();
        }
        catch {
            callback();
        }
    };
    goBack = (event) => {
        if (event) {
            event.preventDefault();
        }
        this.props.history.goBack();
    };
    render() {
        return (React.createElement("main", { className: "password update" },
            React.createElement("div", { className: "inner" },
                React.createElement("form", { onSubmit: this.handleSubmit },
                    React.createElement("h2", null, "Update Password"),
                    this.renderInput('current_password', 'Current Password', 'password', true),
                    this.renderPasswordWithRules('new_password', 'Password'),
                    this.renderButton('Update Password', 'Updating...', 'theme-btn blue'),
                    React.createElement("div", { className: "cancel-btn" },
                        React.createElement("a", { className: "theme-btn small slate-blue", href: "#", onClick: this.goBack }, "Cancel"))))));
    }
}
const actionCreators = {
    changePassword: authActions.changePassword,
};
export default connect(mapAuthState, actionCreators)(PasswordUpdate);
