import Joi from 'joi';
import moment from 'moment-timezone';
import React from 'react';
import { states } from '../../data';
import sitterService from '../../services/sitterService';
import { Form } from '../shared';
const genderRadioOptions = [
    { label: 'Male', value: 'male' },
    { label: 'Female', value: 'female' },
];
const statusRadioOptions = [
    { label: 'Active', value: 'active' },
    { label: 'Inactive', value: 'inactive' },
];
class ProvidersForm extends Form {
    schema = Joi.object({
        email: Joi.string().required().label('Email'),
        firstname: Joi.string()
            .optional()
            .allow('')
            .allow(null)
            .label('First Name'),
        lastname: Joi.string().optional().allow('').allow(null).label('Last Name'),
        dob_year: Joi.string()
            .optional()
            .allow('')
            .allow(null)
            .min(0)
            .label('DOB - Year'),
        dob_month: Joi.string()
            .optional()
            .allow('')
            .allow(null)
            .min(0)
            .max(12)
            .label('DOB - Month'),
        dob_day: Joi.string()
            .optional()
            .allow('')
            .allow(null)
            .min(1)
            .max(31)
            .label('DOB - Day'),
        phone1: Joi.string()
            .optional()
            .allow('')
            .allow(null)
            .label('Mobile Number'),
        emergency_contact_name: Joi.string()
            .optional()
            .allow('')
            .allow(null)
            .label('Emergency Contact Name'),
        emergency_contact_phone: Joi.number()
            .optional()
            .allow('')
            .allow(null)
            .label('Emergency Contact Phone Number'),
        address1: Joi.string()
            .optional()
            .allow('')
            .allow(null)
            .label('Address (line 1)'),
        address2: Joi.string()
            .optional()
            .allow(null)
            .allow('')
            .label('Address (line 2)'),
        city: Joi.string().optional().allow('').allow(null).label('City'),
        state: Joi.string().optional().allow('').allow(null).label('State'),
        zip: Joi.string().optional().allow('').allow(null).label('ZIP code'),
        gender: Joi.string().optional().allow('').allow(null).label('Gender'),
        active: Joi.string().optional().allow('').allow(null).label('Active'),
        login_hash: Joi.string().optional().allow('').allow(null),
        admin_notes: Joi.string().optional().allow('').allow(null),
    });
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            data: {
                email: '',
                firstname: '',
                lastname: '',
                dob_year: '',
                dob_month: '',
                dob_day: '',
                phone1: '',
                emergency_contact_name: '',
                emergency_contact_phone: '',
                address1: '',
                address2: '',
                city: '',
                state: '',
                zip: '',
                gender: '',
                active: '',
                admin_notes: '',
            },
            errors: {},
            loading: true,
            id: null,
            profileId: null,
        };
    }
    componentDidMount = () => {
        this.getSitter();
    };
    getSitter = async () => {
        try {
            const { id } = this.props.match.params;
            const response = await sitterService.get(id);
            this.formatData(response.data.data);
        }
        catch (error) {
            console.log(error);
        }
    };
    formatData = ({ personalInfo, profileSitter, user, }) => {
        const { data } = this.state;
        const { id, firstname, lastname, address1, address2, city, state, zip, DOB, gender, phone1, } = personalInfo;
        const { email, active, login_hash } = user;
        const { id: profileId, emergency_contact_name, emergency_contact_phone, admin_notes, } = profileSitter;
        data.email = email;
        data.firstname = firstname;
        data.lastname = lastname;
        data.address1 = address1;
        data.address2 = address2;
        data.city = city;
        data.state = state;
        data.zip = zip;
        data.gender = gender;
        data.phone1 = phone1;
        data.admin_notes = admin_notes;
        data.login_hash = login_hash;
        data.emergency_contact_name = emergency_contact_name;
        data.emergency_contact_phone = emergency_contact_phone;
        data.active = active ? 'active' : 'inactive';
        if (DOB !== null) {
            const dobPieces = DOB.split('-');
            if (dobPieces.length === 3) {
                // We assume format "YYYY-MM-DD"
                // so after splitting, it should be: [ "YYYY", "MM", "DD" ]
                data.dob_year = dobPieces[0];
                data.dob_month = dobPieces[1];
                data.dob_day = dobPieces[2];
            }
        }
        this.setState({
            data,
            id,
            profileId,
        });
    };
    submitFormData = async () => {
        const apiData = { ...this.state.data };
        const { dob_day, dob_year, dob_month } = apiData;
        const { id } = this.state;
        const DOB = moment(`${dob_year}-${dob_month}-${dob_day}`, 'YYYY-M-D').format('YYYY-MM-DD');
        if (DOB === 'Invalid Date') {
            delete apiData.DOB;
        }
        else {
            apiData.DOB = DOB;
        }
        const data = new FormData();
        const headers = {
            headers: { 'content-type': 'multipart/form-data' },
        };
        data.append('_method', 'post');
        for (const key of Object.keys(apiData)) {
            if (typeof apiData[key] === 'object') {
                if (key === 'avatar_filename') {
                    if (apiData[key] !== null) {
                        data.append(key, apiData[key]);
                    }
                }
                else {
                    data.append(key, JSON.stringify(apiData[key]));
                }
            }
            else {
                data.append(key, apiData[key]);
            }
        }
        return await sitterService.update(id, data, headers);
    };
    renderForm = () => {
        return (React.createElement("div", { className: "admin-settings-form sitter" },
            React.createElement("div", { className: "form-group" },
                this.renderInput('email', 'Email', 'email', true),
                this.renderInput('firstname', 'First Name', 'text'),
                this.renderInput('lastname', 'Last Name', 'text'),
                this.renderTextDatePicker('dob', 'Birthday'),
                this.renderInput('phone1', 'Mobile Number', 'number'),
                this.renderInput('emergency_contact_name', 'Emergency Contact Full Name', 'text'),
                this.renderInput('emergency_contact_phone', 'Emergency Contact Phone Number', 'number'),
                this.renderInput('address1', 'Address', 'text'),
                this.renderInput('address2', 'Apt/Suite', 'text'),
                this.renderInput('city', 'City', 'text'),
                this.renderSelect('state', 'State', states),
                this.renderInput('zip', 'ZIP', 'number'),
                this.renderTextarea('admin_notes', 'Admin Only Notes'),
                this.renderRadio('gender', 'Gender', genderRadioOptions),
                this.renderRadio('active', 'Status', statusRadioOptions))));
    };
}
export default ProvidersForm;
