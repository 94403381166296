import React, { useMemo } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { useReportError } from '@ss/hooks/useReportError';
export const QueryClientProviderWithErrorReporting = ({ children, }) => {
    const reportError = useReportError();
    const queryClient = useMemo(() => new QueryClient({
        defaultOptions: {
            queries: {
                refetchOnWindowFocus: false,
                onError: (error) => {
                    reportError('Error fetching data', error);
                },
            },
            mutations: {
                onError: (error) => {
                    reportError('Error fetching data', error);
                },
            },
        },
    }), [reportError]);
    return (React.createElement(QueryClientProvider, { client: queryClient }, children));
};
