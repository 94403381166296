import { GridColumnMenuCheckboxFilter, GridColumnMenuFilter, } from '@progress/kendo-react-grid';
import moment from 'moment-timezone';
import React from 'react';
export const ColumnMenu = (props) => {
    return (React.createElement("div", null,
        React.createElement(GridColumnMenuFilter, { ...props, expanded: true })));
};
export const ColumnMenuCheckboxFilter = (props) => {
    return (React.createElement("div", null,
        React.createElement(GridColumnMenuCheckboxFilter, { ...props, data: props.sitters, expanded: true })));
};
export const DateCell = (props) => {
    return React.createElement("td", null, moment(props.dataItem[props.field]).format('M/D/YY h:mma'));
};
