import Joi from 'joi';
import $ from 'jquery';
import React from 'react';
import { connect } from 'react-redux';
import { injectStripe } from 'react-stripe-elements';
import { toast } from 'react-toastify';
import { mapAuthState } from '../../../helpers/mapAuthState';
import familyLedgerService from '../../../services/familyLedgerService';
import stripeService from '../../../services/stripeService';
import { CouponChecker, Form } from '../../shared';

class PayBalanceForm extends Form {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        firstname: '',
        lastname: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
        zip: '',
        balance: 0,
      },
      errors: {},
      coupon: false,
      actualFee: 0,
      user: {},
    };
  }
  schema = Joi.object({
    firstname: Joi.string().required().label('First Name'),
    lastname: Joi.string().required().label('Last Name'),
    address1: Joi.string().required().label('Address (line 1)'),
    address2: Joi.string()
      .optional()
      .allow('')
      .allow(null)
      .label('Address (line 2)'),
    city: Joi.string().required().label('City'),
    state: Joi.string().required().label('State'),
    zip: Joi.string().required().label('ZIP code'),
    balance: Joi.number().positive().required().label('ZIP code'),
  });
  componentDidMount() {
    this.syncPersonalInfo();
  }
  componentDidUpdate(prevProps, prevState) {
    const prevData = prevState.data;
    const data = { ...this.state.data };

    if (data['balance'] !== prevData['balance']) {
      let maxPayableAmount = Math.abs(parseFloat(this.props.balance));
      let payedAmount = parseFloat(data.balance !== '' ? data.balance : 0);
      data['balance'] = parseFloat(Math.min(maxPayableAmount, payedAmount));

      this.setState(
        {
          data,
          actualFee: data['balance'],
        },
        () => {
          this.updateActualFee();
        }
      );
    }
  }
  syncPersonalInfo = () => {
    const { personal_info } = this.props.auth.user;
    const { firstname, lastname, address1, address2, city, state, zip } =
      personal_info;
    const { data } = this.state;
    const { balance } = this.props;

    data['firstname'] = firstname;
    data['lastname'] = lastname;
    data['address1'] = address1;
    data['address2'] = address2;
    data['city'] = city;
    data['state'] = state;
    data['zip'] = zip;
    data['balance'] = Math.abs(parseFloat(balance));

    this.setState({
      data,
      familyId: personal_info.family_id,
      actualFee: data['balance'],
    });
  };
  doSubmit = async (callback) => {
    let display_error = false;

    try {
      const { hasStripeUser } = this.props;
      const { id } = this.props.auth.user;

      const balance = this.state.data.balance;
      let amount = balance;
      const paymentData = {
        type: 'balance-payment',
      };
      if (this.state.coupon !== false) {
        const { coupon } = this.state;
        paymentData['coupon'] = coupon;
        if (coupon.type === 'percentage') {
          amount = balance - balance * coupon.amount;
        } else if (coupon.type === 'amount') {
          amount = balance - coupon.amount;
        }
      }
      paymentData['amount'] = amount;
      const response = await stripeService.charge(id, paymentData);
      if (response.status === 200 && response.data.payment_accepted == true) {
        this.saveNewBalance(callback);
      } else {
        toast.error(
          'Your card was declined. Please check your information and try again.'
        );
        callback();
      }
    } catch (e) {
      console.log(e);
      callback();
    }
  };
  updateActualFee = () => {
    const payedAmount = this.state.data.balance;
    const { type, amount } = this.state.coupon;
    let actualFee = payedAmount;
    if (type === 'percentage') {
      actualFee = payedAmount - payedAmount * amount;
    } else if (type === 'amount') {
      actualFee = payedAmount - amount;
    }
    this.setState({
      actualFee,
    });
  };
  handleStripeError = (errorObj) => {
    const { code } = errorObj;
    if (code === 'incomplete_number') {
      //
    }
    if (code === 'incomplete_expiry') {
      //
    }
    if (code === 'incomplete_cvc') {
      //
    }
    $('.registration').animate({ scrollTop: 0 });
  };
  toggleEditBilling = () => {
    this.setState({
      editBillingAddress: !this.state.editBillingAddress,
    });
  };
  applyCouponCode = (coupon) => {
    this.setState(
      {
        coupon,
      },
      this.updateActualFee
    );
  };
  saveNewBalance = async (callback) => {
    try {
      const { data, familyId } = this.state;
      const { balance } = data;
      const apiData = {
        amount: balance,
        notes: `User paid $${balance} towards their balance`,
        family_id: familyId,
      };
      const response = await familyLedgerService.store(familyId, apiData);
      console.log(response);
      callback();
      if (response.status === 201) {
        this.props.onSuccessfulPayment();
        toast.success(
          `Hooray! You have paid $${balance} towards your balance!`
        );
      } else {
        toast.error('Unusual error response from the server!');
      }
    } catch (e) {
      console.log(e);
      toast.error('Something went wrong!');
      callback();
    }
  };
  render() {
    const { hasStripeUser, auth, balance } = this.props;
    const { user } = auth;
    const { coupon, data, actualFee } = this.state;
    return (
      <form onSubmit={this.handleSubmit} className="pay-balance-form">
        <div className="balance-coupon">
          <CouponChecker
            className="balance"
            type="balance"
            applyCouponCode={this.applyCouponCode}
          />
        </div>
        {this.renderInput('balance', 'Balance', 'number', true)}
        <div className="payment-info-wrapper">
          <h3>Payment information</h3>
          <div className="line-item">
            <p>Amount Owed</p>
            <p>${Math.abs(parseFloat(balance).toFixed(2))}</p>
          </div>
          {coupon !== false && (
            <div className="line-item">
              <p>Coupon</p>
              <p>
                {coupon.type === 'percentage'
                  ? coupon.amount * 100 + '%'
                  : '$' + coupon.amount}{' '}
                off
              </p>
            </div>
          )}
          <div className="line-item">
            <p>Charged Today</p>
            <p>${parseFloat(actualFee).toFixed(2)}</p>
          </div>
        </div>
        <div className="payment-info-row">
          <div className="icon">
            {user.card_brand === 'visa' && <i className="fab fa-cc-visa"></i>}
            {user.card_brand === 'mastercard' && (
              <i className="fab fa-cc-mastercard"></i>
            )}
            {user.card_brand === 'discover' && (
              <i className="fab fa-cc-discover"></i>
            )}
            {user.card_brand === 'amex' && <i className="fab fa-cc-amex"></i>}
            {user.card_brand !== 'visa' &&
              user.card_brand !== 'mastercard' &&
              user.card_brand !== 'discover' &&
              user.card_brand !== 'amex' && (
                <i className="far fa-credit-card"></i>
              )}
          </div>
          <div className="last-four">
            <p>....&nbsp;{user.card_last_four}</p>
          </div>
        </div>
        <div className="booking-footer">
          {this.renderButton(
            'Pay Now',
            'Paying',
            'theme-btn primary-green',
            'far fa-check-circle'
          )}
        </div>
      </form>
    );
  }
}

export default connect(mapAuthState)(injectStripe(PayBalanceForm));
