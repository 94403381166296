import React from 'react';
import Joi from 'joi';
import moment from 'moment-timezone';
import appointmentService from '../../../services/appointmentService';
import { toast } from 'react-toastify';

import { Modal, Form } from '../../shared';
import { states } from '../../../data';

class AppointmentDetailsChange extends Form {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        message: '',
      },
      errors: {},
      initialSubmit: true,
    };
  }
  schema = Joi.object({
    message: Joi.string().required().label('Message'),
  });

  componentDidUpdate(prevProps, prevState) {
    const prevData = prevState.data;
    const data = { ...this.state.data };
  }
  doSubmit = async (callback) => {
    try {
      const { id } = this.props.appointmentData.appointment;
      const apiData = {
        ...this.state.data,
        id,
        address_change: true,
      };
      const response = await appointmentService.update(id, apiData);
      callback();
      if (response.status === 200 || response.status === 201) {
        toast.success(
          'Hooray! Your details change has been submitted! Your Sitter has been notified!'
        );
        this.props.refreshAppointments();
      } else {
        toast.error('Oops, there were issues submitting your details change!');
      }
      this.props.toggleDetailsChange();
    } catch (e) {
      toast.error('Oops, there were issues submitting your details change!');
      this.props.toggleDetailsChange();
      console.log(e);
    }
  };
  render() {
    const { startTimes, endTimes, errors } = this.state;
    return (
      <div className="appointment-details-change-form-modal">
        <Modal
          title="Appointment Details Change"
          isOpen={true}
          closeModal={this.props.toggleDetailsChange}
          content={
            <div className="appointment-details-change-form-wrapper">
              <p>Enter new appointment details here:</p>
              <form
                onSubmit={this.handleSubmit}
                className="appointment-details-change-form"
              >
                {this.renderTextarea('message', 'Change Details', 'text', true)}
                {this.renderButton(
                  'Submit Details Change',
                  'Submitting...',
                  'theme-btn blue'
                )}
              </form>
            </div>
          }
          secondaryButton={'Close'}
        />
      </div>
    );
  }
}

export default AppointmentDetailsChange;
