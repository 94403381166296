export default [
    {
        name: '',
        id: null,
    },
    {
        name: 'Dog',
        id: 'Dog',
    },
    {
        name: 'Cat',
        id: 'Cat',
    },
    {
        name: 'Fish',
        id: 'Fish',
    },
    {
        name: 'Bird',
        id: 'Bird',
    },
    {
        name: 'Reptile',
        id: 'Reptile',
    },
    {
        name: 'Other',
        id: 'Other',
    },
];
