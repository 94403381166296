import { buildApiUrl } from '../helpers/buildApiUrl';
import { getNextCursor } from '../helpers/pagination';
import httpService from './httpService';
export const getFilteredPendingAppointmentsRequest = async (params) => {
    const { pageParam, fromDate, toDate, zip, familyName, ages = [], perPage = 10, } = params;
    const urlParams = new URLSearchParams({
        take: String(perPage),
        skip: String(pageParam * perPage),
    });
    if (fromDate) {
        urlParams.append('from_date', fromDate);
    }
    if (toDate) {
        urlParams.append('to_date', toDate);
    }
    if (zip) {
        urlParams.append('zip', zip);
    }
    if (ages.length > 0) {
        for (const ageId of ages) {
            urlParams.append('ages[]', ageId);
        }
    }
    if (familyName) {
        urlParams.append('family_name', familyName);
    }
    const { data: { data, meta }, } = await httpService.get(buildApiUrl('/sitter/pendingAppointments'), urlParams);
    return { data, nextCursor: getNextCursor(meta) };
};
export const getUnavailableDatesTimesRequest = async () => {
    const response = await httpService.get(buildApiUrl('/sitter/unavailableDatesTimes'));
    return response.data.data;
};
