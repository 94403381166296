import React from 'react';
class NumNights extends React.Component {
    increase = () => {
        const { name, value, onChange } = this.props;
        onChange(name, value + 1);
    };
    decrease = () => {
        const { name, value, onChange } = this.props;
        onChange(name, value > 1 ? value - 1 : value);
    };
    render() {
        const { name, label, error, value, required } = this.props;
        const classNames = [
            'form-field',
            name,
            'num-nights',
            !!value ? 'active' : 'inactive',
        ];
        const canDecrease = value > 1;
        return (React.createElement("div", { className: classNames.join(' ') },
            React.createElement("label", { htmlFor: name },
                label,
                required && React.createElement("span", { className: "required" }, "\u00A0*"),
                "\u00A0",
                value),
            canDecrease && (React.createElement("button", { className: "decrease", onClick: this.decrease },
                React.createElement("i", { className: "fas fa-minus-circle" }))),
            !canDecrease && (React.createElement("button", { className: "decrease disabled" },
                React.createElement("i", { className: "fas fa-minus-circle" }))),
            React.createElement("button", { className: "increase", onClick: this.increase },
                React.createElement("i", { className: "fas fa-plus-circle" })),
            error && (React.createElement("span", { className: "error", role: "alert" },
                React.createElement("strong", null, error)))));
    }
}
export default NumNights;
