import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment-timezone';

import appointmentService from '../../../services/appointmentService';
import { Avatar } from '../../shared';

import { displayAge, upperCaseFirst, slugify } from '../../../helpers';
import { mapAuthState } from '../../../helpers/mapAuthState';

const ProgramListingRow = (props) => {
  const { program } = props;
  const { family } = program;
  const { id } = program;

  const displayChildrenAges = (children) => {
    const childAges = [];
    if (!!children) {
      children.map((child) => {
        const childAge = displayAge(child.DOB, 'yo', 'mo');
        childAges.push(childAge);
      });
      return childAges.join(' / ');
    }
    return '';
  };

  return (
    <div className="program-listing-row">
      <div className="program-listing-col family">
        <div className="family-inner">
          <Avatar
            imageSrc={family.avatar_filename}
            profileColor={family.profile_color}
          />
          <div className="info">
            <p>
              <b>{family.name}</b>
              <br />
              {displayChildrenAges(family.children)}
            </p>
            <h4>{program.booking_appointments[0].zip}</h4>
          </div>
        </div>
      </div>
      <div className="program-listing-col dates">
        <h4>Dates</h4>
        <p>
          {moment(program.start_date, 'YYYY-MM-DD').format('MMM Do')}
          &nbsp;-&nbsp;
          {moment(program.end_date, 'YYYY-MM-DD').format('MMM Do')}
        </p>
      </div>
      <div className="program-listing-col notes">
        <h4>Notes</h4>
        <p>{program.notes}</p>
      </div>
      <div className="program-listing-col total">
        <h4>Total Appts</h4>
        <p>{program.booking_appointments.length}</p>
      </div>
      <div className="program-listing-col status">
        <h4>Status</h4>
        <p className={`program-status-pill ${slugify(program.status)}`}>
          {program.status === 'assigning' ||
          program.status === 'assigning-pending'
            ? 'Open'
            : program.status
                .split('-')
                .map((str) => upperCaseFirst(str))
                .join(' ')}
        </p>
      </div>
      <div className="program-listing-col link">
        <Link to={`/programs/${id}`} className="theme-btn blue small">
          Review
        </Link>
      </div>
    </div>
  );
};

class ProgramListing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      programs: [],
    };
  }
  componentDidMount() {
    this.getPrograms();
  }
  getPrograms = async () => {
    try {
      const { id } = this.props.auth.user.personal_info;
      const response = await appointmentService.getProgramsByType('family', id);
      if (response.status === 200) {
        const { programs } = response.data.data;
        this.setState({
          programs,
          loading: false,
        });
      } else {
        // Handle error response in some way?
        this.setState({
          loading: false,
        });
      }
    } catch (e) {
      console.log(e);
    }
  };
  render() {
    const { programs, loading } = this.state;

    if (!!loading) {
      return (
        <main className="user-programs program-listing family">
          <div className="program-inner">
            <h4 className="section-label">
              <i className="fas fa-spinner fa-spin"></i>
              &nbsp;Loading Your Consistent Care Requests...
            </h4>
          </div>
        </main>
      );
    }

    return (
      <main className="user-programs program-listing family">
        <div className="program-inner">
          <h4 className="section-label">
            <i className="fas fa-calendar-star"></i>&nbsp;Your Consistent Care
            Requests ({programs.length})
          </h4>
          {programs.length > 0 && (
            <div className="program-listing-section">
              <div className="program-listing-wrapper pending">
                {programs.map((program) => (
                  <ProgramListingRow
                    program={program}
                    type={'pending'}
                    key={`program-listing-pending-${program.id}`}
                  />
                ))}
              </div>
            </div>
          )}
        </div>
      </main>
    );
  }
}

export default connect(mapAuthState)(ProgramListing);
