import React from 'react';
import Form from '../../shared/Form';
import Joi from 'joi';
import { connect } from 'react-redux';

import httpService from '../../../services/httpService';
import { authActions } from '../../../actions/authActions';

import { FormStepTracker } from '../../shared';
import { joiWordCountHelper } from '../../../helpers';
import { publicSiteUrl } from '../../../config';
import trackerSteps from './trackerSteps.json';
import { each } from 'lodash-es';
import { mapAuthState } from '../../../helpers/mapAuthState';
import { sitterService } from '../../../services';

const AvatarDisplayComponent = (props) => {
  return (
    <div className="file-field-instructions">
      <div className="avatar">
        <div className="avatar-wrapper">
          {!!props.value && <img src={props.value} />}
          {!props.value && <i className="far fa-camera"></i>}
        </div>
      </div>
      <div className="instructions">
        <ul>
          <li>
            <p>1. Make sure the photo is recent</p>
          </li>
          <li>
            <p>2. Make sure the photo only includes yourself</p>
          </li>
          <li>
            <p>3. Make sure the lighting and quality are good</p>
          </li>
        </ul>
      </div>
    </div>
  );
};

const radioOptions = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
];

const maritalStatusOptions = [
  { label: 'Single', value: 'Single' },
  { label: 'Married', value: 'Married' },
  { label: 'Widowed', value: 'Widowed' },
  { label: 'Divorced', value: 'Divorced' },
];

class PublicProfile extends Form {
  schema = Joi.object({
    avatar_filename: Joi.alternatives(Joi.string(), Joi.object())
      .required()
      .label('Avatar'),
    intro: Joi.string()
      .custom((value, helpers) => {
        return joiWordCountHelper(value, helpers, 50);
      }, 'custom validation')
      .required()
      .messages({
        'length.exceeds': 'Your Intro cannot be more than 50 words.',
      })
      .label('Intro'),
    bio: Joi.string()
      .custom((value, helpers) => {
        return joiWordCountHelper(value, helpers, 200);
      }, 'custom validation')
      .required()
      .messages({
        'length.exceeds': 'Your Bio cannot be more than 200 words.',
      })
      .label('Bio'),
    vehicle_year: Joi.string().required().label('Vehicle Year'),
    vehicle_make: Joi.string().required().label('Vehicle Make'),
    vehicle_model: Joi.string().required().label('Vehicle Model'),
    vehicle_seatbelts: Joi.number().required().label('Vehicle Seatbelts'),
    selected_age_ranges: Joi.array()
      .required()
      .min(1)
      .messages({
        'array.min': 'You must select at least 1 age range.',
      })
      .label('Selected Age Ranges'),
    selected_activities: Joi.array()
      .required()
      .min(1)
      .messages({
        'array.min': 'You must select at least 1 skill or activity',
      })
      .label('Selected Activities'),
    accepted_terms: Joi.number()
      .integer()
      .min(1)
      .max(1)
      .required()
      .messages({
        'number.greater': 'You must accept the terms to continue.',
        'number.lesser': 'You must accept the terms to continue.',
        'number.min': 'You must accept the terms to continue.',
        'number.max': 'You must accept the terms to continue.',
      })
      .label('Accepted Terms'),
  });

  constructor(props) {
    super(props);
    this.state = {
      data: {
        avatar_filename: '',
        intro: '',
        bio: '',
        selected_age_ranges: [],
        selected_activities: [],
        vehicle_make: '',
        vehicle_model: '',
        vehicle_year: '',
        vehicle_seatbelts: '',
        accepted_terms: 0,
      },
      acceptedAgeRanges: [],
      activityOptions: [],
      errors: {},
      user: null,
      loadingData: true,
    };
  }

  componentDidMount() {
    const { user } = this.props.auth;
    if (!user) {
      this.sendUserBack();
    } else {
      this.getAcceptedAgeRanges();
      this.getActivityOptions();
      this.getSitterProfile(user.personal_info.id);
    }
  }
  getAcceptedAgeRanges = async () => {
    try {
      const response = await httpService.get('/api/acceptedAgeRanges');
      if (response.status === 200) {
        let { acceptedAgeRanges } = response.data.data;
        acceptedAgeRanges = each(acceptedAgeRanges, (info) => {
          info['value'] = info.id;
          info['label'] = info.name;
        });
        this.setState({ acceptedAgeRanges });
      }
    } catch (e) {
      console.log(e);
    }
  };
  getActivityOptions = async () => {
    try {
      const response = await httpService.get('/api/activityOptions');
      if (response.status === 200) {
        let { activityOptions } = response.data.data;
        activityOptions = each(activityOptions, (info) => {
          info['value'] = info.id;
          info['label'] = info.name;
        });
        this.setState({ activityOptions });
      }
    } catch (e) {
      console.log(e);
    }
  };

  getSitterProfile = async (id) => {
    try {
      const response = await sitterService.get(id);
      if (response.status === 200) {
        const {
          data: { profileSitter },
        } = response.data;

        console.log({ profileSitter });

        this.setState((prevState) => ({
          ...prevState,
          data: {
            ...prevState.data,
            vehicle_make: profileSitter.vehicle_make,
            vehicle_model: profileSitter.vehicle_model,
            vehicle_year: profileSitter.vehicle_year,
            vehicle_seatbelts: profileSitter.vehicle_seatbelts,
          },
          loadingData: false,
        }));
      }
    } catch (error) {
      console.error(error);
    }
  };

  sendUserBack = () => {
    alert('You must sign up or log in to view this page.');
    window.open('/', '_self');
  };
  advanceStep = async (event) => {
    if (event) {
      event.preventDefault();
    }
    try {
      const { id } = this.props.auth.user;
      const response = await this.props.updateUserStep(
        'sitter-public-profile',
        id
      );
      console.log(response);
    } catch (e) {
      console.log(e);
    }
  };
  doSubmit = async (callback) => {
    try {
      const apiData = { ...this.state.data };
      const { id } = this.props.auth.user.personal_info;
      console.log('apiData', apiData);
      let data = new FormData();
      const headers = {
        headers: { 'content-type': 'multipart/form-data' },
      };
      data.append('_method', 'post');
      for (const key of Object.keys(apiData)) {
        if (typeof apiData[key] === 'object') {
          if (key === 'avatar_filename') {
            data.append(key, apiData[key]);
          } else {
            data.append(key, JSON.stringify(apiData[key]));
          }
        } else {
          data.append(key, apiData[key]);
        }
      }

      const response = await this.props.updateUser(
        `/profileSitter/update/${id}`,
        data,
        headers
      );
      console.log(response);

      if (response.status === 200) {
        this.advanceStep();
      } else {
        callback();
      }
    } catch (error) {
      console.error(error);
      callback();
    }
  };

  render() {
    const { acceptedAgeRanges, activityOptions, data, errors, loadingData } =
      this.state;
    return (
      <div className="registration sitter step-5 public-profile">
        <div className="form">
          <div className="inner">
            <FormStepTracker steps={trackerSteps} currentStep={2} />
            <div className="form-notice">
              <h3>Important Note</h3>
              <p>
                All information collected in this section will be made publicly
                available to families. Therefore, make your best effort to
                provide accurate and clear communications. Please pay attention
                to spelling and promote yourself positively in our community.
              </p>
            </div>
            <div className="form-header">
              <h2>Public Profile</h2>
            </div>
            {loadingData && 'Loading form...'}
            {!loadingData && (
              <form onSubmit={this.handleSubmit}>
                <div className="form-group justify-space-between">
                  {this.renderFile(
                    'avatar_filename',
                    'Upload Your Public Profile Image',
                    'image/jpg, image/jpeg, image/png',
                    true,
                    AvatarDisplayComponent
                  )}
                  {this.renderInput(
                    'intro',
                    'Introduce yourself to our families.',
                    'text',
                    true,
                    { wordCount: 50 }
                  )}
                  {this.renderTextarea(
                    'bio',
                    'Write a short bio about yourself and child care history.',
                    true,
                    { wordCount: 200 }
                  )}
                  {this.renderCheckbox(
                    'selected_age_ranges',
                    'Ages of kids I can work with? *',
                    acceptedAgeRanges
                  )}
                  {this.renderCheckbox(
                    'selected_activities',
                    'Description of Skills and Activities *',
                    activityOptions
                  )}
                  <hr className="divider" />
                  {this.renderInput(
                    'vehicle_year',
                    'Vehicle Year',
                    'number',
                    true
                  )}
                  {this.renderInput(
                    'vehicle_make',
                    'Vehicle Make',
                    'text',
                    true
                  )}
                  {this.renderInput(
                    'vehicle_model',
                    'Vehicle Model',
                    'text',
                    true
                  )}
                  {this.renderInput(
                    'vehicle_seatbelts',
                    'Number of back seats with seatbelts',
                    'number',
                    true
                  )}
                  <hr className="divider" />
                  <div className={`form-field boolean checkbox accepted_terms`}>
                    <span>
                      <input
                        name="accepted_terms"
                        id="accepted_terms"
                        type="checkbox"
                        checked={data['accepted_terms'] > 0 ? true : false}
                        className="checkbox"
                        onChange={this.handleBooleanChange}
                      />
                      <label htmlFor="accepted_terms">
                        <div className="checkbox">
                          {!!data['accepted_terms'] && (
                            <i className="far fa-check"></i>
                          )}
                        </div>
                        By completing your application and profile registration
                        you are agreeing to SmartSitters{' '}
                        <a
                          href={`${publicSiteUrl}/terms-of-use-for-providers`}
                          target="_blank"
                        >
                          Terms of Service
                        </a>{' '}
                        and{' '}
                        <a
                          href={`${publicSiteUrl}/privacy-policy`}
                          target="_blank"
                        >
                          Privacy Policy
                        </a>
                        .<span className="required">&nbsp;*</span>
                      </label>
                    </span>
                    {!!errors['accepted_terms'] && (
                      <span className="error" role="alert">
                        <strong>{errors['accepted_terms']}</strong>
                      </span>
                    )}
                  </div>
                </div>
                {this.renderButton('Next', 'Submitting...', 'theme-btn blue')}
              </form>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const actionCreators = {
  updateUserStep: authActions.updateUserStep,
  updateUser: authActions.updateUser,
};

export default connect(mapAuthState, actionCreators)(PublicProfile);
