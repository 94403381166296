import { Grid, GridColumn, } from '@progress/kendo-react-grid';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { adminAppointmentReviewsRequest } from '../../../services/appointmentReviewService';
import { AppointmentLinkCell, DateTimeCell, FamilyCell, FirstTimeCell, ProviderCell, } from './KendoComponents';
const initialDataState = {
    sort: [],
    filter: {
        logic: 'and',
        filters: [],
    },
    filterOperators: {
        text: [
            {
                text: 'grid.filterContainsOperator',
                operator: 'contains',
            },
            { text: 'grid.filterEqOperator', operator: 'eq' },
        ],
    },
    skip: 0,
    take: 50,
};
const ReviewsGrid = (props) => {
    const { appointmentId, sitterPiid, familyId } = props;
    const [dataState, setDataState] = useState(initialDataState);
    const onPageChange = (event) => {
        setDataState((prevState) => ({
            ...prevState,
            skip: event.page.skip,
            take: event.page.take,
        }));
    };
    const onSortChange = (event) => {
        setDataState((prevState) => ({
            ...prevState,
            sort: event.sort,
        }));
    };
    const onFilterChange = (event) => {
        setDataState((prevState) => ({
            ...prevState,
            filter: event.filter,
        }));
    };
    const query = useQuery(['provider-reviews', dataState, appointmentId, sitterPiid, familyId], async function () {
        const response = await adminAppointmentReviewsRequest({
            skip: dataState.skip,
            take: dataState.take,
            sort: dataState.sort,
            filter: dataState.filter,
            appointment_id: appointmentId,
            sitter_piid: sitterPiid,
            family_id: familyId,
        });
        return response.data.data;
    }, {
        keepPreviousData: true,
    });
    if (query.isLoading) {
        return React.createElement("p", null, "Loading reviews...");
    }
    if (query.isError) {
        return React.createElement("p", null, "We encountered an error while loading reviews data.");
    }
    if (query.isSuccess) {
        return (React.createElement("div", { className: "kendo-table" },
            React.createElement(Grid, { ...dataState, data: query.data.reviews, total: query.data.count, pageable: true, onPageChange: onPageChange, onSortChange: onSortChange, onFilterChange: onFilterChange },
                React.createElement(GridColumn, { title: "ID", field: "id", width: 60 }),
                !sitterPiid && !appointmentId && (React.createElement(GridColumn, { title: "Provider", cell: ProviderCell, width: 120 })),
                !familyId && !appointmentId && (React.createElement(GridColumn, { title: "Family", cell: FamilyCell, width: 120 })),
                !appointmentId && (React.createElement(GridColumn, { title: "Appointment Date", width: 140, cell: DateTimeCell })),
                React.createElement(GridColumn, { title: "First Time", field: "repeated_review", width: 100, cell: FirstTimeCell }),
                React.createElement(GridColumn, { title: "Review", field: "sitter_review" }),
                React.createElement(GridColumn, { title: "Comment", field: "comments" }),
                !appointmentId && (React.createElement(GridColumn, { title: " ", width: 100, field: "booking_appointment_id", cell: AppointmentLinkCell })))));
    }
    throw new Error('Invalid state');
};
export default ReviewsGrid;
