import Joi from 'joi';
import { delay } from 'lodash-es';
import React from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { authActions } from '../../actions/authActions';
import { mapAuthState } from '../../helpers/mapAuthState';
import Form from './Form';
class EmailUpdate extends Form {
    state = {
        data: { new_email: '' },
        errors: {},
        submitting: false,
    };
    schema = Joi.object({
        new_email: Joi.string().required().label('New Email'),
    });
    doSubmit = async (callback) => {
        const { data } = this.state;
        const { id } = this.props.auth.user;
        try {
            const response = await this.props.changeEmail(id, data);
            if (response.status === 200) {
                toast.success('Hooray! Your new email is saved. You will be automatically logged out after 5 seconds.');
                delay(() => {
                    this.props.logout();
                }, 5000);
            }
        }
        catch {
            callback();
        }
    };
    goBack = (event) => {
        if (!!event) {
            event.preventDefault();
        }
        this.props.history.goBack();
    };
    render() {
        const { user } = this.props.auth;
        return (React.createElement("main", { className: "email update" },
            React.createElement("div", { className: "inner" },
                React.createElement("form", { onSubmit: this.handleSubmit },
                    React.createElement("h2", null, "Update Email"),
                    React.createElement("p", null,
                        "Please be aware that you will need to re-verify your email once changed. You will not be able to use the new email address until you verify your new email by clicking on the link in email we\u2019ll send to your old email, ",
                        user.email,
                        "."),
                    this.renderInput('new_email', 'New Email', 'email', true),
                    this.renderButton('Update Email', 'Updating...', 'theme-btn blue'),
                    React.createElement("div", { className: "cancel-btn" },
                        React.createElement("a", { className: "theme-btn small slate-blue", href: "#", onClick: this.goBack }, "Cancel"))))));
    }
}
const actionCreators = {
    changeEmail: authActions.changeEmail,
    logout: authActions.logout,
};
export default connect(mapAuthState, actionCreators)(EmailUpdate);
