import React from 'react';

import Sidebar from '../Sidebar';
import { findLinkGroupByTitle } from '../../../helpers';
import { toast } from 'react-toastify';
import FamilyEditForm from '../../Forms/FamilyEditForm';
import familyService from '../../../services/familyService';

class FamiliesEdit extends FamilyEditForm {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      tab: 'regular',
      saving: false,
      linkGroup: findLinkGroupByTitle('Families'),
      creating: false,
      loading: true,
      id: null,
    };
  }
  componentDidMount = () => {
    const { id } = this.props.match.params;
    console.log(id);
    if (!!id && Number(id) == id) {
      // abusing a quirk of JS where "11" == 11 to ensure the ID is a number
      this.getFamilyInfo(id);
    } else {
      this.setState({
        data: {
          ...this.state.freshSchema,
          creating: true,
        },
        creating: true,
        family_id: family_id,
      });
    }
  };
  getFamilyInfo = async (id) => {
    try {
      const response = await familyService.get(id);
      if (response.status === 200) {
        const { data } = response.data;
        this.formatData(data);
      }
    } catch (e) {
      console.log(e);
    }
  };
  formatData = ({ profileFamily }) => {
    const {
      name,
      smoke,
      sexual_criminal_offense,
      house_rules,
      admin_family_notes,
    } = profileFamily;
    const data = { ...this.state.data };

    data['name'] = name;
    data['smoke'] = smoke;
    data['sexual_criminal_offense'] = sexual_criminal_offense;
    data['house_rules'] = house_rules;
    data['admin_family_notes'] = admin_family_notes;

    this.setState({
      data,
      creating: false,
      id: profileFamily.id,
    });
  };

  doSubmit = async (callback) => {
    this.setState({ saving: true });
    const response = await this.submitFormData();
    callback();
    if (response.status === 200 || response.status === 201) {
      toast.success('Hooray! Your changes are saved.');
      this.goBack();
    }
    this.setState({ saving: false });
  };
  goBack = (e) => {
    if (!!e) {
      e.preventDefault();
    }
    this.props.history.goBack();
  };

  render() {
    const { creating, saving, linkGroup } = this.state;

    return (
      <main className="dashboard admin manage">
        <div className="sidebar-wrapper">
          <Sidebar
            {...this.props}
            iconClass="fas fa-id-card"
            linkGroup={linkGroup}
          />
        </div>
        <div className="content">
          <div className="providers">
            {!!creating && <h2>Create Family</h2>}
            {!creating && <h2>Edit Family</h2>}
            <div className="providers-form form">
              {this.renderForm(true)}
              <div className="divider"></div>
              <div className="align_center row">
                <button
                  type="button"
                  className="theme-btn blue large save-btn"
                  disabled={saving}
                  onClick={this.handleSubmit}
                >
                  {saving && <i className="fas fa-spinner fa-spin"></i>}
                  {saving ? ' Saving...' : 'Save'}
                </button>
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}

export default FamiliesEdit;
