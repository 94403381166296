import validatePassword from './validatePassword';
const joiPasswordRulesHelper = (value, helpers) => {
    const { hasLowercase, hasUppercase, hasSpecialCharacters, hasNumber, isLengthy, } = validatePassword(value);
    if (!hasLowercase) {
        return helpers.error('password.lowercase');
    }
    if (!hasUppercase) {
        return helpers.error('password.uppercase');
    }
    if (!hasSpecialCharacters) {
        return helpers.error('password.special_character');
    }
    if (!hasNumber) {
        return helpers.error('password.number');
    }
    if (!isLengthy) {
        return helpers.error('password.length');
    }
    return value;
};
export default joiPasswordRulesHelper;
