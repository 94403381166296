import clsx from 'clsx';
import React, { useState } from 'react';
import { getNotificationDisplayProperties } from './getNotificationDisplayProperties';
import { useNotificationActions } from './useNotificationActions';
import { parseISO, format } from 'date-fns';
import { Transition } from 'react-transition-group';
const duration = 300;
const defaultStyle = {
    transition: `opacity ${duration}ms ease-in-out`,
    opacity: 0,
};
const transitionStyles = {
    entering: { opacity: 1 },
    entered: { opacity: 1 },
    exiting: { opacity: 0 },
    exited: { opacity: 0 },
    unmounted: {},
};
export const Notification = (props) => {
    const { notification, onRequestClose } = props;
    const { execute, ignore } = useNotificationActions();
    const [isIn, setIsIn] = useState(true);
    const { title, actionLabel, theme } = getNotificationDisplayProperties(notification);
    const formattedTime = notification.created_at
        ? format(parseISO(notification.created_at), 'iiii, MMMM do hh:mm aaa')
        : null;
    const handleIgnore = () => {
        setIsIn(false);
        window.setTimeout(() => ignore(notification.id), duration);
    };
    const handleActionExecute = () => {
        execute(notification);
        onRequestClose();
    };
    return (React.createElement(Transition, { in: isIn, timeout: 5000 }, (state) => (React.createElement("div", { key: notification.id, className: clsx('notification-menu__item', theme), style: {
            ...defaultStyle,
            ...transitionStyles[state],
        } },
        title && React.createElement("h3", null, title),
        React.createElement("p", null, notification.message),
        formattedTime && (React.createElement("div", null,
            React.createElement("time", { dateTime: notification.created_at }, formattedTime))),
        React.createElement("div", { className: "notification-menu__item__actions" },
            actionLabel && (React.createElement("button", { onClick: handleActionExecute, className: clsx('theme-btn small', theme) }, actionLabel)),
            React.createElement("button", { onClick: handleIgnore, className: "notification-menu__item__ignore" }, "Ignore"))))));
};
