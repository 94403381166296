import { buildApiUrl } from '../helpers/buildApiUrl';
import httpService from './httpService';
const store = (family_id, data) => {
    const apiUrl = buildApiUrl(`/familyAccountLedger/store/${family_id}`);
    return httpService.post(apiUrl, data);
};
const update = (ledger_id, data) => {
    const apiUrl = buildApiUrl(`/familyAccountLedger/update/${ledger_id}`);
    return httpService.post(apiUrl, data);
};
export default {
    store,
    update,
};
