import React from 'react';
import { connect } from 'react-redux';

import // Form
'../../shared';
import { displayAddress } from '../../../helpers';

import { additionalServicesOptions } from '../../../data';

import Checkbox from '../../shared/Form/Checkbox';
import Input from '../../shared/Form/Input';
import Textarea from '../../shared/Form/Textarea';
import ActivitiesSelector from '../../shared/Form/ActivitiesSelector';
import { mapAuthState } from '../../../helpers/mapAuthState';

class BulkDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showAddOwnService: false,
    };
  }
  componentDidMount() {
    //
  }
  componentDidUpdate(prevProps, prevState) {
    const { data } = this.props;
    const prevData = prevProps.data;

    if (
      data['program_additional_services'].length !==
      prevData['program_additional_services'].length
    ) {
      if (
        data['program_additional_services'].indexOf(`${1000}`) > -1 &&
        !this.state.showAddOwnService
      ) {
        this.setState({ showAddOwnService: true });
      } else if (
        data['program_additional_services'].indexOf(`${1000}`) === -1 &&
        !!this.state.showAddOwnService
      ) {
        this.setState({ showAddOwnService: false });
      }
    }
  }
  handleCheckboxChange = ({ currentTarget: input }) => {
    const data = { ...this.props.data };
    let name = input.name;
    let list = [...data[name]];
    if (input.checked) {
      // add to array
      if (list === undefined) {
        list = [input.value];
      } else {
        list.push(input.value);
      }
    } else {
      //remove from array
      list = list.filter(function (item) {
        return item !== input.value;
      });
    }
    data[name] = list;
    this.props.handleChange(name, list);
  };
  canAdvanceStep = () => {
    const { data } = this.props;
    if (
      data['program_additional_services'].length > 0 &&
      data['program_additional_services'].indexOf(`${1000}`) > -1
    ) {
      if (!data['program_additional_services_notes']) return false;
    }
    return true;
  };
  render() {
    const { data, errors, activityOptions } = this.props;
    const { showAddOwnService } = this.state;
    const canAdvanceStep = this.canAdvanceStep();
    return (
      <div className="bulk-booking details">
        <div className="bulk-notice blue">
          <p>
            Tell us more! Please include any additional information about your
            family and your request. This will help us curate a proposal that
            meets your expectations.
          </p>
        </div>
        <div className="details-form">
          <div className="details-form-row">
            <Textarea
              className="program_notes"
              name={`program_notes`}
              label={'Any notes about your bulk booking request'}
              value={data['program_notes']}
              error={errors['program_notes']}
              onChange={(e) => {
                this.props.handleChange('program_notes', e.target.value);
              }}
              required={true}
            />
          </div>
          <div className="details-form-row">
            <p>
              If you don’t have a specific provider(s) you would like us to
              reach out to for your request, please select or enter any
              attributes, education, or experience that would be beneficial for
              your potential match. Please note: Every Sitter is interviewed
              and trained in house; background & driving record checked; insured
              and licensed to drive; and CPR certified!
            </p>
            <Input
              className="program_requested_sitter"
              name={`program_requested_sitter`}
              label="Add Sitter to Request"
              value={data['program_requested_sitter']}
              error={errors['program_requested_sitter']}
              type="text"
              onChange={(e) => {
                this.props.handleChange(
                  'program_requested_sitter',
                  e.target.value
                );
              }}
              required={false}
            />
          </div>
          <div className="details-form-row">
            <Checkbox
              className="program_additional_services"
              name={`program_additional_services`}
              label="Select as many additional services (or none at all) that you would like included in your family’s bulk booking request."
              value={data['program_additional_services']}
              error={errors['program_additional_services']}
              options={additionalServicesOptions}
              onChange={this.handleCheckboxChange}
              required={false}
            />
            {!!showAddOwnService && (
              <div className="additional-services-notes">
                <Textarea
                  className="program_additional_services_notes"
                  name={`program_additional_services_notes`}
                  label={'Additional Details About Potential Provider'}
                  value={data['program_additional_services_notes']}
                  error={errors['program_additional_services_notes']}
                  onChange={(e) => {
                    this.props.handleChange(
                      'program_additional_services_notes',
                      e.target.value
                    );
                  }}
                  required={true}
                />
              </div>
            )}
          </div>
          <div className="details-form-row">
            <ActivitiesSelector
              className="program_selected_activities"
              name={`program_selected_activities`}
              label="Select as many interests your child(ren) has to help our team find your perfect match!"
              options={activityOptions}
              value={data['program_selected_activities']}
              error={errors['program_selected_activities']}
              onChange={this.handleCheckboxChange}
              required={false}
            />
          </div>
        </div>
        <div className="booking-footer">
          {canAdvanceStep && (
            <button
              className={`theme-btn blue`}
              onClick={this.props.onFormComplete}
            >
              Next
            </button>
          )}
          {!canAdvanceStep && (
            <button
              className={`theme-btn blue disabled`}
              onClick={(e) => e.preventDefault()}
            >
              Next
            </button>
          )}
        </div>
      </div>
    );
  }
}

export default connect(mapAuthState)(BulkDetails);
