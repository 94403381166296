export default [
    {
        label: '6 Weeks',
        value: 6,
    },
    {
        label: '7 Weeks',
        value: 7,
    },
    {
        label: '8 Weeks',
        value: 8,
    },
];
