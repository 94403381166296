import React from 'react';
import { slugify } from '@ss/helpers';
import clsx from 'clsx';
const RadioOption = (props) => {
    const { option, value, index, onChange, name, disabled } = props;
    const checked = option.value === value;
    const htmlId = `${name}-${slugify(String(option.value))}-${index}`;
    return (React.createElement("div", { className: "radio-group" },
        React.createElement("input", { checked: checked, value: option.value, id: htmlId, type: "radio", className: checked ? 'active' : 'inactive', onChange: onChange, name: name, disabled: disabled }),
        React.createElement("label", { htmlFor: htmlId },
            React.createElement("span", null,
                React.createElement("div", { className: "radio" }),
                option.label))));
};
const Radio = (props) => {
    const { name, label, options, required, error, className, disabled } = props;
    return (React.createElement("div", { className: clsx(['form-field', name, 'radio', className]) },
        React.createElement("label", { className: "radio-title", htmlFor: name },
            label,
            required && React.createElement("span", { className: "required" }, "\u00A0*")),
        options.map((option, idx) => (React.createElement(RadioOption, { key: idx, option: option, ...props, index: idx, disabled: disabled }))),
        error && (React.createElement("span", { className: "error", role: "alert" },
            React.createElement("strong", null, error)))));
};
export default Radio;
