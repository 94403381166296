import { authConstants } from '../constants/authConstants';
import httpService from '../services/httpService';
const user = JSON.parse(localStorage.getItem('user'));
const USER_INITIAL_STATE = {
    loggedIn: false,
    loggingIn: false,
    user: null,
    error: null,
};
let initialState = user
    ? { loggedIn: true, loggingIn: false, user, error: null }
    : { ...USER_INITIAL_STATE };
if (user) {
    if (user.step !== undefined) {
        // Timestamp is a string - we need to get it and convert it to a Date object
        const authTimestamp = new Date(user.timestamp);
        // Get the current time and then subtract an hour from it (an hour is the time it takes for the token to expire)
        const currentTimestamp = new Date();
        const authExpiryTimestamp = new Date(currentTimestamp.setHours(currentTimestamp.getHours() - 1));
        // If the auth timestamp is GREATER THAN the user timestamp, then the user needs to be logged out.
        if (authExpiryTimestamp > authTimestamp) {
            localStorage.clear();
            httpService.clearToken();
            initialState = { ...USER_INITIAL_STATE };
        }
        else {
            httpService.setToken(user.access_token);
        }
    }
    else {
        localStorage.clear();
        httpService.clearToken();
        initialState = { ...USER_INITIAL_STATE };
    }
}
export function auth(state = initialState, action) {
    switch (action.type) {
        case authConstants.LOGIN_REQUEST:
            return {
                loggingIn: true,
                loggedIn: false,
                error: null,
                user: null,
            };
        case authConstants.LOGIN_SUCCESS:
        case authConstants.TOKEN_REFRESH_SUCCESS:
            return {
                loggedIn: true,
                loggingIn: false,
                user: action.user,
                error: null,
            };
        case authConstants.UPDATE_FAILURE:
        case authConstants.LOGIN_FAILURE:
            return {
                ...state,
                error: action.error,
            };
        case authConstants.TOKEN_REFRESH_REQUEST:
            return {
                ...state,
                loggingIn: true,
                loggedIn: true,
                error: null,
            };
        case authConstants.TOKEN_REFRESH_FAILURE:
            return {
                user: null,
                loggedIn: false,
                loggingIn: false,
                error: action.error,
            };
        case authConstants.LOGOUT_REQUEST:
            return {
                ...state,
                loggingIn: true,
                error: null,
            };
        case authConstants.LOGOUT_SUCCESS:
            return {
                loggingIn: false,
                loggedIn: false,
                user: null,
            };
        case authConstants.REGISTER_REQUEST:
            return {
                ...state,
                loggedIn: false,
                loggingIn: true,
            };
        case authConstants.REGISTER_SUCCESS:
            return {
                ...state,
                loggedIn: true,
                loggingIn: false,
                user: action.user,
            };
        case authConstants.REGISTER_FAILURE:
            return {
                ...state,
                loggedIn: false,
                loggingIn: false,
                error: action.error,
            };
        case authConstants.UPDATE_REQUEST:
            return {
                ...state,
                error: null,
            };
        case authConstants.UPDATE_SUCCESS:
            const nextUser = {
                ...state.user,
                ...action.user,
            };
            localStorage.setItem('user', JSON.stringify(nextUser));
            return {
                ...state,
                user: nextUser,
            };
        case authConstants.LOGOUT_FAILURE:
        default:
            return state;
    }
}
