import React from 'react';
import { Avatar } from '../shared';
import { displayAppointmentDate, displayAppointmentTime, displayAppointmentDuration, } from '../../helpers';
import { Link } from 'react-router-dom';
class AppointmentsPending extends React.Component {
    render() {
        const { appointments } = this.props;
        return (React.createElement("div", { className: "submitted-appointments-sidebar" },
            React.createElement("h4", { className: "section-label" },
                React.createElement("i", { className: "far fa-clock" }),
                "\u00A0Pending Requests (",
                appointments.length,
                ")"),
            appointments.map((appointment) => (React.createElement("div", { className: "appointment", key: `appt-pending-${appointment.id}` },
                React.createElement(Avatar, { imageSrc: appointment.family.avatar_filename }),
                React.createElement("p", { className: "title" }, displayAppointmentDate(appointment, 'MMM Do', '-')),
                React.createElement("p", null, displayAppointmentDate(appointment, 'ddd')),
                React.createElement("p", null,
                    displayAppointmentTime(appointment),
                    " (",
                    displayAppointmentDuration(appointment),
                    ")"),
                React.createElement(Link, { to: {
                        pathname: `/appointment/${appointment.id}`,
                        state: { appointmentView: true },
                    } },
                    React.createElement("i", { className: "arrow fas fa-arrow-right" })))))));
    }
}
export default AppointmentsPending;
