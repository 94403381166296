import { authConstants } from '../constants/authConstants';
import { authService, httpService } from '../services';
const login = (data = {}) => {
    return async (dispatch) => {
        dispatch({ type: authConstants.LOGIN_REQUEST, data });
        try {
            const response = await authService.login(data);
            if (response.status === 200 && response.data.message === 'OK') {
                const { user, access_token } = response.data.data;
                user.access_token = access_token;
                user.timestamp = new Date().toString();
                localStorage.setItem('user', JSON.stringify(user));
                httpService.setToken(user.access_token);
                dispatch({ type: authConstants.LOGIN_SUCCESS, user });
            }
            else {
                dispatch({ type: authConstants.LOGIN_FAILURE, error: response });
            }
            return response;
        }
        catch (error) {
            dispatch({ type: authConstants.LOGIN_FAILURE, error });
            return error;
        }
    };
};
const register = (data, type) => {
    return async (dispatch) => {
        dispatch({ type: authConstants.REGISTER_REQUEST });
        try {
            const response = await authService.register(data, type);
            if (response.status === 200) {
                let { user, access_token } = response.data.data;
                user.access_token = access_token;
                user.timestamp = new Date().toString();
                localStorage.setItem('user', JSON.stringify(user));
                httpService.setToken(user.access_token);
                dispatch({ type: authConstants.REGISTER_SUCCESS, user });
            }
            else {
                dispatch({ type: authConstants.REGISTER_FAILURE, error: data });
            }
            return response;
        }
        catch (error) {
            dispatch({ type: authConstants.REGISTER_FAILURE, error });
            return error;
        }
    };
};
const refreshToken = () => {
    return async (dispatch) => {
        dispatch({ type: authConstants.TOKEN_REFRESH_REQUEST });
        try {
            const response = await authService.refreshToken();
            if (response.status === 200 && response.data.message === 'OK') {
                let { user, access_token } = response.data.data;
                user.access_token = access_token;
                user.timestamp = new Date().toString();
                localStorage.setItem('user', JSON.stringify(user));
                httpService.setToken(user.access_token);
                dispatch({ type: authConstants.TOKEN_REFRESH_SUCCESS, user });
            }
            else {
                dispatch({
                    type: authConstants.TOKEN_REFRESH_FAILURE,
                    error: response,
                });
            }
            return response;
        }
        catch (error) {
            dispatch({ type: authConstants.TOKEN_REFRESH_FAILURE, error });
            return error;
        }
    };
};
const updateUser = (endpoint, data, headers = {}) => {
    return async (dispatch) => {
        dispatch({ type: authConstants.UPDATE_REQUEST, endpoint, data });
        try {
            const response = await authService.updateUser(endpoint, data, headers);
            if (response.status === 200 || response.status === 204) {
                const { user } = response.data.data;
                user.timestamp = new Date().toString();
                localStorage.setItem('user', JSON.stringify(user));
                dispatch({ type: authConstants.UPDATE_SUCCESS, user });
            }
            else {
                dispatch({ type: authConstants.UPDATE_FAILURE, error: data });
            }
            return response;
        }
        catch (error) {
            dispatch({ type: authConstants.UPDATE_FAILURE, error });
            return error;
        }
    };
};
const changePassword = (id, data) => {
    return async (dispatch) => {
        dispatch({ type: authConstants.PASSWORD_CHANGE_REQUEST, id, data });
        try {
            const response = await authService.changePassword(id, data);
            if (response.status === 200 || response.status === 204) {
                dispatch({ type: authConstants.PASSWORD_CHANGE_SUCCESS, response });
            }
            else {
                dispatch({
                    type: authConstants.PASSWORD_CHANGE_FAILURE,
                    error: response,
                });
            }
            return response;
        }
        catch (error) {
            dispatch({ type: authConstants.PASSWORD_CHANGE_FAILURE, error });
            return error;
        }
    };
};
const sendResetPassword = (data) => {
    // don't believe we need actions here since the user isn't logged in (nothing to store)
    return async () => {
        try {
            return await authService.sendResetPassword(data);
        }
        catch (error) {
            return error;
        }
    };
};
const resetPassword = (data) => {
    // this acts like a login request so we dispatch those actions for simplicity
    return async (dispatch) => {
        dispatch({ type: authConstants.LOGIN_REQUEST, data });
        try {
            const response = await authService.resetPassword(data);
            if (response.status === 201) {
                let { user, access_token } = response.data.data;
                user.access_token = access_token;
                user.timestamp = new Date().toString();
                localStorage.setItem('user', JSON.stringify(user));
                httpService.setToken(user.access_token);
                dispatch({ type: authConstants.LOGIN_SUCCESS, user });
            }
            else {
                dispatch({ type: authConstants.LOGIN_FAILURE, error: data });
            }
            return response;
        }
        catch (error) {
            return error;
        }
    };
};
const changeEmail = (id, data) => {
    return async (dispatch) => {
        dispatch({ type: authConstants.PASSWORD_CHANGE_REQUEST, id, data });
        try {
            const response = await authService.changeEmail(id, data);
            if (response.status === 200 || response.status === 204) {
                dispatch({ type: authConstants.PASSWORD_CHANGE_SUCCESS, response });
            }
            else {
                dispatch({
                    type: authConstants.PASSWORD_CHANGE_FAILURE,
                    error: response,
                });
            }
            return response;
        }
        catch (error) {
            dispatch({ type: authConstants.PASSWORD_CHANGE_FAILURE, error });
            return error;
        }
    };
};
const updateUserStep = (stepSlug, userId) => {
    return async (dispatch) => {
        dispatch({ type: authConstants.UPDATE_REQUEST });
        try {
            const response = await authService.updateUserStep(stepSlug, userId);
            if (response.status === 200) {
                const { user } = response.data.data;
                user.timestamp = new Date().toString();
                localStorage.setItem('user', JSON.stringify(user));
                dispatch({ type: authConstants.UPDATE_SUCCESS, user });
            }
            else {
                dispatch({ type: authConstants.UPDATE_FAILURE, error: response });
            }
            return response;
        }
        catch (error) {
            dispatch({ type: authConstants.UPDATE_FAILURE, error });
            return error;
        }
    };
};
const logout = () => {
    return (dispatch) => {
        dispatch({ type: authConstants.LOGOUT_REQUEST });
        try {
            httpService.clearToken();
            localStorage.clear();
            dispatch({ type: authConstants.LOGOUT_SUCCESS });
        }
        catch (error) {
            dispatch({ type: authConstants.LOGOUT_FAILURE, error });
            return error;
        }
    };
};
export const authActions = {
    login,
    logout,
    register,
    refreshToken,
    changePassword,
    sendResetPassword,
    resetPassword,
    changeEmail,
    updateUserStep,
    updateUser,
};
export default authActions;
