import React from 'react';
import { connect } from 'react-redux';

import Signup from './Signup';
import Profile from './Profile';
import Children from './Children';
import Pets from './Pets';
import Payment from './Payment';
import Complete from './Complete';
import { mapAuthState } from '../../../helpers/mapAuthState';

class FamilyRegistration extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 'signup',
      steps: ['signup', 'profile', 'children', 'pets', 'payment', 'complete'],
    };
  }
  componentDidMount() {
    if (!!this.props.auth.user) {
      if (!!this.props.auth.user.step) {
        this.setStepFromUser();
      } else {
        this.setStepFromUrl();
      }
    } else {
      this.setStepFromUrl();
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (!!this.props.auth.user && !!prevProps.auth.user) {
      if (this.props.auth.user.step_id !== prevProps.auth.user.step_id) {
        this.setStepFromUser();
      }
    } else if (!!this.props.auth.user && !prevProps.auth.user) {
      // user signed in
      this.setStepFromUser();
    }
  }
  setStepFromUser = () => {
    const { user } = this.props.auth;
    const { step } = user;
    let pageStep = false;
    switch (step.slug) {
      case 'family-signup':
        pageStep = 'profile';
        break;
      case 'family-profile':
        pageStep = 'children';
        break;
      case 'family-children':
        pageStep = 'pets';
        break;
      case 'family-pets':
        pageStep = 'payment';
        break;
      case 'family-payments':
        pageStep = 'complete';
        break;
      // step 15 is complete and ready to go to dashboard
    }
    if (!!pageStep) this.setState({ step: pageStep }, this.updateUrl);
  };
  setStepFromUrl = () => {
    const { step } = this.props.match.params;
    if (!!step) {
      const { steps } = this.state;
      if (steps.indexOf(step) === -1) {
        this.setState(
          {
            step: steps[0],
          },
          this.updateUrl
        );
      } else {
        this.setState({ step });
      }
    }
  };
  advanceStep = () => {
    let { step, steps } = this.state;
    let idx = steps.indexOf(step);
    if (idx < steps.length - 1) {
      idx += 1;
      step = steps[idx];
      this.setState({ step }, this.updateUrl);
    }
  };
  updateUrl = () => {
    const { step } = this.state;
    switch (step) {
      case 'signup':
        window.history.replaceState(null, null, '/register/family/signup');
        break;
      case 'profile':
        window.history.replaceState(null, null, '/register/family/profile');
        break;
      case 'children':
        window.history.replaceState(null, null, '/register/family/children');
        break;
      case 'pets':
        window.history.replaceState(null, null, '/register/family/pets');
        break;
      case 'payment':
        window.history.replaceState(null, null, '/register/family/payment');
        break;
      case 'complete':
        window.history.replaceState(null, null, '/register/family/complete');
        break;
    }
  };
  render() {
    const { step, formData } = this.state;
    const { user } = this.props.auth;
    return (
      <main className="registration family">
        {step === 'signup' && <Signup />}
        {step === 'profile' && <Profile user={user} />}
        {step === 'children' && <Children user={user} />}
        {step === 'pets' && <Pets user={user} />}
        {step === 'payment' && <Payment user={user} />}
        {step === 'complete' && <Complete user={user} />}
      </main>
    );
  }
}

export default connect(mapAuthState)(FamilyRegistration);
