import { buildApiUrl } from '../helpers/buildApiUrl';
import httpService from './httpService';
const list = (data = {}) => {
    return httpService.get(buildApiUrl('/coupons'), data);
};
const get = (id) => {
    return httpService.get(buildApiUrl(`/coupons/details/${id}`));
};
const store = (data, headers) => {
    const apiUrl = buildApiUrl('/coupons/store');
    return httpService.post(apiUrl, data, headers);
};
const update = (id, data, headers) => {
    const apiUrl = buildApiUrl(`/coupons/update/${id}`);
    return httpService.post(apiUrl, data, headers);
};
const deleteCoupon = (id, data, headers) => {
    const apiUrl = buildApiUrl(`/coupons/delete/${id}`);
    return httpService.delete(apiUrl, data, headers);
};
const check = (data, headers) => {
    const apiUrl = buildApiUrl('/coupons/check');
    return httpService.post(apiUrl, data, headers);
};
export default {
    list,
    get,
    store,
    update,
    delete: deleteCoupon,
    check,
};
