import React, { useRef } from 'react';
import FocusLock from 'react-focus-lock';
import { Notification } from './Notification';
import { Portal } from 'react-portal';
import { useNotificationActions } from './useNotificationActions';
import useOnClickOutside from 'use-onclickoutside';
import clsx from 'clsx';
export const NotificationPortal = (props) => {
    const { onRequestClose, notifications, togglerRef } = props;
    const { ignoreAll } = useNotificationActions();
    const menuRef = useRef();
    useOnClickOutside(menuRef, function (event) {
        if (togglerRef.current.contains(event.target) === false) {
            onRequestClose();
        }
    });
    const hasNotifications = notifications.length > 0;
    return (React.createElement(Portal, null,
        React.createElement(FocusLock, { className: "notifications-focus-lock", returnFocus: true },
            React.createElement("div", { className: clsx('notification-menu', {
                    'is-empty': !hasNotifications,
                }), id: "notification-menu", "aria-label": "Notifications list", ref: menuRef },
                !hasNotifications && (React.createElement("div", { className: "notification-menu__empty" },
                    React.createElement("button", { type: "button", className: "theme-btn white", onClick: onRequestClose }, "No notifications"))),
                hasNotifications && (React.createElement(React.Fragment, null,
                    React.createElement("div", { className: "notification-menu__header" },
                        React.createElement("button", { type: "button", onClick: () => ignoreAll(), className: "notification-menu__ignore-all" }, "Ignore All")),
                    React.createElement("div", { className: "notification-menu__listing" }, notifications.map((notification) => (React.createElement(Notification, { notification: notification, key: notification.id, onRequestClose: onRequestClose }))))))))));
};
