import React from 'react';
import moment from 'moment-timezone';

import CalendarHeader from './CalendarHeader';
import Calendar from './Calendar';

export default class CalendarContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      months: [
        moment(),
        moment().add(1, 'months'),
        moment().add(2, 'months'),
        moment().add(3, 'months'),
      ], //start out with 3 months
    };
  }

  loadMoreMonths = () => {
    const currentLength = this.state.months.length;

    if (currentLength < 12) {
      const months = [
        ...this.state.months,
        moment().add(currentLength, 'months'),
        moment().add(currentLength + 1, 'months'),
        moment().add(currentLength + 2, 'months'),
        moment().add(currentLength + 3, 'months'),
      ];
      this.setState({ months });
    }
  };

  handleScroll = (event) => {
    const target = event.target;

    if (target.scrollHeight - target.scrollTop === target.clientHeight) {
      this.loadMoreMonths();
    }
  };

  render() {
    const { months } = this.state;
    const { type, appointments, history } = this.props;

    return (
      <div onScroll={this.handleScroll} className="appointment-calendar">
        <CalendarHeader />
        {months.map((month, i) => {
          return (
            <Calendar
              key={i}
              showAppointments={false}
              appointments={appointments}
              history={history}
              date={month}
              type={type}
            />
          );
        })}
      </div>
    );
  }
}
