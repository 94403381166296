export function buildSearchUrl({ pathname, search, paramNameMap, }) {
    const params = new URLSearchParams();
    for (const [key, value] of Object.entries(search)) {
        const paramName = paramNameMap[key];
        if (Array.isArray(value)) {
            for (const v of value) {
                params.append(paramName, v);
            }
        }
        else {
            params.append(paramName, value);
        }
    }
    return `${pathname}?${params.toString()}`;
}
