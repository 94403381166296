import React from 'react';
import { connect } from 'react-redux';
import Joi from 'joi';
import { Form, Modal } from '.';
import { mapAuthState } from '../../helpers/mapAuthState';
class MemberCancel extends Form {
    schema = Joi.object({
        cancel_reason: Joi.string().required().label('Cancel Reason'),
    });
    constructor(props) {
        super(props);
        this.state = {
            data: {
                cancel_reason: '',
            },
            errors: {},
            submitting: false,
        };
    }
    doSubmit = async () => {
        await this.props.cancelMembership(this.state.data.cancel_reason);
        this.setState({
            submitting: false,
        });
    };
    render() {
        return (React.createElement(Modal, { title: "Confirm Cancellation", isOpen: true, closeModal: this.props.toggleCancelMembership, content: React.createElement("div", { className: "appointment-cancel" },
                React.createElement("div", { className: "cancellation-notice" },
                    React.createElement("p", null, "Are you sure you want to cancel your membership?")),
                React.createElement("form", { onSubmit: this.handleSubmit },
                    this.renderInput('cancel_reason', 'Cancellation Reason'),
                    this.renderButton('Cancel Membership', 'Cancelling...', 'theme-btn blue'))), secondaryButton: 'Close' }));
    }
}
export default connect(mapAuthState)(MemberCancel);
