import clsx from 'clsx';
import React from 'react';
import { useSelector } from 'react-redux';
import { useSitterProfileActions } from './useSitterProfileActions';
export const SitterProfileActions = (props) => {
    const familyId = useSelector((state) => state.auth.user.personal_info.family_id);
    const { piid } = props;
    const { query, previouselyHired, blocked, favorited, saved, toggleBlocked, toggleFavorited, toggleSaved, } = useSitterProfileActions({ piid, familyId });
    const handleSaveClick = () => {
        toggleSaved();
    };
    const handleBlockClick = () => {
        toggleBlocked();
    };
    const handleFavoritedClick = () => {
        toggleFavorited();
    };
    if (query.isError || query.isFetchedAfterMount === false) {
        return null;
    }
    return (React.createElement("div", { className: "sitter-profile-actions" },
        !blocked && (React.createElement("div", null,
            previouselyHired && (React.createElement("button", { type: "button", className: clsx('theme-btn', {
                    orange: favorited,
                    grey: !favorited,
                }), onClick: handleFavoritedClick },
                React.createElement("i", { className: "fas fa-star" }),
                " Favorite")),
            !previouselyHired && (React.createElement("button", { type: "button", className: "theme-btn blue", onClick: handleSaveClick },
                React.createElement("i", { className: "fas fa-save" }),
                " ",
                saved ? 'Saved' : 'Save')))),
        React.createElement("button", { type: "button", className: clsx('theme-btn red', { small: !blocked }), onClick: handleBlockClick },
            React.createElement("i", { className: "fas fa-times" }),
            " ",
            blocked ? 'Unblock' : 'Block')));
};
