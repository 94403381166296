import reactSelectStyles from '@ss/theme/reactSelectStyles';
import clsx from 'clsx';
import React, { useState } from 'react';
import ReactSelect from 'react-select';
export const CustomSelect = (props) => {
    const { options, value, onChange, id, name, label } = props;
    const [focused, setFocused] = useState(false);
    const handleChange = (nextValue) => {
        onChange(nextValue.map((option) => option.value));
    };
    const selectedValue = options.filter((option) => value.includes(option.value));
    return (React.createElement("div", { className: clsx('form-field', 'react-select', {
            active: value.length > 0,
            focus: focused,
        }) },
        React.createElement(ReactSelect, { inputId: id, value: selectedValue, name: name, options: options, isMulti: true, isClearable: true, placeholder: false, onFocus: () => setFocused(true), onBlur: () => setFocused(false), styles: reactSelectStyles, components: { IndicatorSeparator: () => null }, onChange: handleChange }),
        React.createElement("label", { htmlFor: id }, label)));
};
