import { map } from 'lodash-es';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { displayProfileColor } from '../../../helpers';
import { mapAuthState } from '../../../helpers/mapAuthState';
import sitterService from '../../../services/sitterService';
import SitterPublicProfileForm from '../../Forms/SitterPublicProfileForm';
import { ProfileColorPicker } from '../../shared';

const AvatarDisplayComponent = (props) => {
  return (
    <div className="avatar">
      <div className="avatar-wrapper">
        {!!props.value && <img src={props.value} />}
        {!props.value && <i className="far fa-camera"></i>}
      </div>
    </div>
  );
};

class PublicProfile extends SitterPublicProfileForm {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      loading: true,
      id: null,
    };
  }
  componentDidMount = () => {
    this.getAcceptedAgeRanges();
    this.getActivityOptions();
    this.getAvailableLanguages();
    this.getSitter();
  };
  getSitter = async () => {
    try {
      const { id } = this.props.auth.user.personal_info;
      const response = await sitterService.get(id);
      this.formatData(response.data.data);
    } catch (e) {
      console.log(e);
    }
  };
  formatData = ({
    acceptedAgeRanges,
    languages,
    personalInfo,
    profileSitter,
    selectedActivities,
    medicalInfo,
  }) => {
    const { data } = this.state;

    const { avatar_filename, profile_color } = personalInfo;

    const {
      vehicle_year,
      vehicle_make,
      vehicle_model,
      vehicle_seatbelts,
      intro,
      bio,
    } = profileSitter;

    data['avatar_filename'] = avatar_filename;

    data['vehicle_year'] = vehicle_year;
    data['vehicle_make'] = vehicle_make;
    data['vehicle_model'] = vehicle_model;
    data['vehicle_seatbelts'] = vehicle_seatbelts;
    data['intro'] = intro;
    data['bio'] = bio;
    data['profile_color'] =
      profile_color !== null ? profile_color : 'primary-coral';

    const selected_languages = map(languages, (lang) => {
      return lang.id + '';
    });
    const selected_activities = map(selectedActivities, (activity) => {
      return activity.id + '';
    });
    const selected_age_ranges = map(acceptedAgeRanges, (ageRange) => {
      return ageRange.id + '';
    });

    data['languages'] = selected_languages;
    data['selected_activities'] = selected_activities;
    data['selected_age_ranges'] = selected_age_ranges;

    this.setState({
      data,
      personalInfo,
      profileSitter,
      id: personalInfo.id,
    });
  };
  doSubmit = async (callback) => {
    try {
      const response = await this.submitFormData();
      callback();
      if (response.status === 200) {
        toast.success('Hooray! Your changes are saved.');
      }
    } catch (e) {
      console.log(e);
      callback();
    }
  };
  render() {
    const { data } = this.state;
    const { user } = this.props.auth;

    return (
      <main className="account profile sitter-public">
        <form onSubmit={this.handleSubmit}>
          <div
            className={`profile-header ${displayProfileColor(
              data.profile_color,
              'gradient'
            )}`}
          >
            <div className="inner">
              {this.renderFile(
                'avatar_filename',
                'Upload Your Public Profile Image',
                'image/jpg, image/png',
                false,
                AvatarDisplayComponent
              )}
              <h2 className="theme-h2">
                {user.personal_info.firstname}&nbsp;
                {user.personal_info.lastname}
              </h2>
            </div>
          </div>
          <div className="profile-body">
            <h2>Public Profile</h2>
            <ProfileColorPicker
              value={data.profile_color}
              onProfileColorChange={this.handleProfileColorChange}
            />
            {this.renderForm()}
            {this.renderButton(
              'Save Public Profile',
              'Saving...',
              'theme-btn blue'
            )}
            <div className="cancel-btn">
              <Link className="theme-btn small slate-blue" to="/profile">
                Cancel
              </Link>
            </div>
          </div>
        </form>
      </main>
    );
  }
}

export default connect(mapAuthState)(PublicProfile);
