import { buildApiUrl } from '../helpers/buildApiUrl';
import httpService from './httpService';
const get = (modelType, modelId, commType, sitterPiid = null) => {
    let baseUrl = `/communications/${modelType}/${modelId}/${commType}`;
    if (sitterPiid !== null) {
        baseUrl += `/${sitterPiid}`;
    }
    return httpService.get(buildApiUrl(baseUrl));
};
const store = (data, headers) => {
    return httpService.post(buildApiUrl('/communications/store'), data, headers);
};
export default {
    get,
    store,
};
