import clsx from 'clsx';
import React from 'react';
const BooleanInput = ({ name, label, error, value, required, onChange, className = '', }) => {
    return (React.createElement("div", { className: clsx('form-field boolean checkbox', name, className) },
        React.createElement("span", null,
            React.createElement("input", { name: name, id: name, type: "checkbox", checked: !!value, className: "checkbox", onChange: onChange }),
            React.createElement("label", { htmlFor: name },
                React.createElement("div", { className: "checkbox" }, !!value && React.createElement("i", { className: "far fa-check" })),
                label,
                required && React.createElement("span", { className: "required" }, "\u00A0*"))),
        error && (React.createElement("span", { className: "error", role: "alert" },
            React.createElement("strong", null, error)))));
};
export default BooleanInput;
