import { buildApiUrl } from '../helpers/buildApiUrl';
import httpService from './httpService';
export const listAppointmentReviews = (status) => {
    const params = status
        ? new URLSearchParams({
            status,
        })
        : '';
    const apiUrl = buildApiUrl(`/appointmentReviews?${params.toString()}`);
    return httpService.get(apiUrl);
};
export const getAppointmentReviewRequest = async (id) => {
    const response = await httpService.get(buildApiUrl(`/appointmentReviews/${id}`));
    return response.data.data;
};
export const dismissAppointmentReviews = () => {
    return httpService.post(buildApiUrl('/appointmentReviews/dismiss'));
};
export const getPendingReviewsCount = async () => {
    const response = await httpService.get(buildApiUrl('/appointmentReviews/pendingCount'));
    return response.data.data.pendingCount;
};
export const saveAppointmentReview = (id, data) => {
    return httpService.post(buildApiUrl(`/appointmentReviews/update/${id}`), data);
};
export const adminAppointmentReviewsRequest = (data) => {
    const apiUrl = buildApiUrl('/appointmentReviews/admin');
    return httpService.request('get', apiUrl, data);
};
