import { process } from '@progress/kendo-data-query';
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import moment from 'moment-timezone';
import React from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { findLinkGroupByTitle } from '../../../helpers';
import appointmentService from '../../../services/appointmentService';
import familyLedgerService from '../../../services/familyLedgerService';
import familyService from '../../../services/familyService';
import logService from '../../../services/logService';
import { Modal } from '../../shared';
import {
  CancelledCell,
  ManageCell,
  SittersCell,
  StatusCell,
  TimeCell,
} from '../Appointments/KendoComponents';
import ReviewsGrid from '../ReviewsGrid/ReviewsGrid';
import Sidebar from '../Sidebar';
import {
  BooleanCell,
  ColumnMenu,
  DateCell,
  ManageChildCell,
  ManageMemberCell,
  ManagePetCell,
  ManageSitterCell,
  FavoriteSitterCell,
  BlockSitterCell,
} from './KendoComponents';

const INITIAL_APPOINTMENT_DATA_STATE = {
  sort: [{ field: 'start_time', dir: 'asc' }],
  filter: {
    logic: 'and',
    filters: [],
  },
  filterOperators: {
    text: [
      { text: 'grid.filterContainsOperator', operator: 'contains' },
      { text: 'grid.filterEqOperator', operator: 'eq' },
    ],
  },
  skip: 0,
  take: 25,
  total: 0,
};

const INITIAL_ACTIVITY_DATA_STATE = {
  sort: [{ field: 'id', dir: 'desc' }],
  filter: {
    logic: 'and',
    filters: [],
  },
  filterOperators: {
    text: [
      { text: 'grid.filterContainsOperator', operator: 'contains' },
      { text: 'grid.filterEqOperator', operator: 'eq' },
    ],
  },
  skip: 0,
  take: 25,
  total: 0,
};

class MembersGrid extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      profileFamily: null,
      parents: [],
      children: [],
      pets: [],
      family: [],
      resultParents: [],
      appointments: [],
      loading: true,
      appointmentLoading: true,
      activityLoading: true,
      appointmentStatus: 'all',
      linkGroup: findLinkGroupByTitle('Families'),
      dataState: {
        sort: [{ field: 'name', dir: 'asc' }],
        skip: 0,
        take: 10,
      },
      appointmentDataState: {
        ...INITIAL_APPOINTMENT_DATA_STATE,
      },
      activityDataState: {
        ...INITIAL_ACTIVITY_DATA_STATE,
      },
      filter: {
        logic: 'and',
        filters: [],
      },
      transactions: [],
      balance: '0.00',
      newBalance: '0',
      balanceNotes: '',
      showManageBalance: false,
      balanceMode: 'credit',
      addingBalance: false,
      showTransactions: false,
      subscription: null,
      subscriptionActive: false,
      activity: [],
      sitterPreferences: [],
    };
  }
  componentDidMount = () => {
    this.getFamily();
    this.getTransactions();
    this.getAppointments();
    this.getActivity();
    this.getSitterPreferences();
  };
  getActivity = async () => {
    try {
      const { id } = this.props.match.params;
      const { activityDataState } = this.state;
      const response = await logService.family(id, {
        skip: activityDataState.skip,
        take: activityDataState.take,
        sort: activityDataState.sort,
        filter: activityDataState.filter,
      });
      if (response.status === 200) {
        const { logs, count } = response.data.data;
        this.setState({
          activity: logs,
          activityDataState: {
            ...activityDataState,
            total: count,
          },
          activityLoading: false,
        });
      }
    } catch (e) {
      console.log(e);
      this.setState({
        activityLoading: false,
      });
    }
  };
  getSitterPreferences = async () => {
    console.log('Getting preferences');
    try {
      const { id } = this.props.match.params;
      const response = await familyService.getSitterPreferences(id);
      console.log(response);
      if (response.status === 200) {
        const { sitterPreferences } = response.data.data;
        this.setState({
          sitterPreferences,
        });
      }
    } catch (e) {
      console.log(e);
      this.setState({
        activityLoading: false,
      });
    }
  };
  getAppointments = async () => {
    try {
      const { id } = this.props.match.params;
      const { appointmentStatus, appointmentDataState } = this.state;
      const response = await appointmentService.familyAppointments({
        id,
        status: appointmentStatus,
        skip: appointmentDataState.skip,
        take: appointmentDataState.take,
        sort: appointmentDataState.sort,
        filter: appointmentDataState.filter,
      });
      if (response.status === 200) {
        const { appointments, count } = response.data.data;
        this.setState({
          appointments,
          appointmentDataState: {
            ...appointmentDataState,
            total: count,
          },
          appointmentLoading: false,
        });
      }
    } catch (e) {
      console.log(e);
      this.setState({
        appointmentLoading: false,
      });
    }
  };
  getFamily = async () => {
    try {
      const { id } = this.props.match.params;
      const response = await familyService.get(id);
      if (response.status === 200) {
        const {
          profileFamily,
          parents,
          children,
          pets,
          balance,
          subscription,
          subscriptionActive,
          blockedSitters,
          favoriteSitters,
        } = response.data.data;
        this.setState({
          resultParents: process([...parents].slice(0), this.state.dataState),
          resultChildren: process([...children].slice(0), this.state.dataState),
          resultPets: process([...pets].slice(0), this.state.dataState),
          resultFavorites: process(
            [...favoriteSitters].slice(0),
            this.state.dataState
          ),
          resultBlocked: process(
            [...blockedSitters].slice(0),
            this.state.dataState
          ),
          profileFamily,
          parents,
          pets,
          children,
          loading: false,
          subscription,
          subscriptionActive,
        });
      }
    } catch (e) {
      console.log(e);
      this.setState({
        loading: false,
      });
    }
  };
  getTransactions = async () => {
    try {
      const { id } = this.props.match.params;
      const response = await familyService.getTransactions(id);
      if (response.status === 200) {
        const { balance, transactions } = response.data.data;
        this.setState({ balance, transactions });
      }
    } catch (e) {
      console.log(e);
    }
  };
  handleDataStateChange = (e) => {
    let dataState = e.data;
    this.setState({
      resultParents: process([...this.state.parents].slice(0), dataState),
      resultChildren: process([...this.state.children].slice(0), dataState),
      resultPets: process([...this.state.pets].slice(0), dataState),
      dataState: dataState,
    });
  };
  toggleShowManageBalance = () => {
    this.setState({
      showManageBalance: !this.state.showManageBalance,
    });
  };
  toggleTransactions = (e) => {
    e.preventDefault();
    this.setState({
      showTransactions: !this.state.showTransactions,
    });
  };
  setBalanceMode = (mode) => {
    this.setState({
      balanceMode: mode,
    });
  };
  onNewBalanceChange = (e) => {
    let newBalance = e.target.value;
    newBalance = newBalance.replace(/[^\d.-]/g, '');
    this.setState({
      newBalance,
    });
  };
  onNotesChange = (e) => {
    this.setState({ balanceNotes: e.target.value });
  };
  setNewBalance = async () => {
    try {
      const { profileFamily, newBalance, balanceNotes, balanceMode } =
        this.state;
      const { id } = profileFamily;
      let amount = balanceMode === 'credit' ? newBalance : -newBalance;
      const data = {
        amount,
        notes: balanceNotes,
        family_id: id,
      };
      const response = await familyLedgerService.store(id, data);
      console.log(response);
      if (response.status === 201) {
        this.setState(
          {
            newBalance: 0,
            balanceNotes: '',
            showManageBalance: false,
          },
          this.getFamily
        );
        toast.success(
          `Hooray! Your ${balanceMode} of ${newBalance} was added to the ${profileFamily.name} Family!`
        );
      } else {
        toast.error('Unusual error response from the server!');
      }
    } catch (e) {
      console.log(e);
      toast.error('Something went wrong!');
    }
  };
  onKeyDown = (e) => {
    var invalidChars = ['-', '+', 'e'];
    if (invalidChars.includes(e.key)) e.preventDefault();
  };
  setFilter = (filter) => {
    let currentFilter = !!filter ? filter : 'pending';
    this.setState(
      {
        appointmentStatus: currentFilter,
        appointmentLoading: true,
        appointmentDataState: { ...INITIAL_APPOINTMENT_DATA_STATE },
      },
      this.getAppointments
    );
  };
  pageChange = (event, type) => {
    if (type === 'appointment') {
      this.setState(
        {
          appointmentDataState: {
            ...this.state.appointmentDataState,
            skip: event.page.skip,
            take: event.page.take,
          },
        },
        () => {
          this.getAppointments();
        }
      );
    } else {
      this.setState(
        {
          activityDataState: {
            ...this.state.activityDataState,
            skip: event.page.skip,
            take: event.page.take,
          },
        },
        () => {
          this.getActivity();
        }
      );
    }
  };
  sortChange = (event, type) => {
    if (type === 'appointment') {
      this.setState(
        {
          appointmentDataState: {
            ...this.state.appointmentDataState,
            sort: event.sort,
          },
        },
        () => {
          this.getAppointments();
        }
      );
    } else {
      this.setState(
        {
          activityDataState: {
            ...this.state.activityDataState,
            sort: event.sort,
          },
        },
        () => {
          this.getActivity();
        }
      );
    }
  };
  filterChange = (event, type) => {
    if (type === 'appointment') {
      this.setState(
        {
          appointmentDataState: {
            ...INITIAL_APPOINTMENT_DATA_STATE,
            filter: event.filter,
            skip: 0,
          },
        },
        () => {
          this.getAppointments();
        }
      );
    } else {
      this.setState(
        {
          activityDataState: {
            ...INITIAL_ACTIVITY_DATA_STATE,
            filter: event.filter,
            skip: 0,
          },
        },
        () => {
          this.getActivity();
        }
      );
    }
  };
  onFavoriteClick = async (piid) => {
    try {
      const { id } = this.props.match.params;
      const response = await familyService.favoriteSitter(id, piid, {
        favorite: 0,
      });
      if (response.status === 200) {
        this.getFamily();
      }
    } catch (e) {
      console.log(e);
    }
  };
  onBlockClick = async (piid) => {
    try {
      const { id } = this.props.match.params;
      const response = await familyService.blockSitter(id, piid, {
        excluded: 0,
      });
      if (response.status === 200) {
        this.getFamily();
      }
    } catch (e) {
      console.log(e);
    }
  };
  render() {
    const {
      loading,
      profileFamily,
      parents,
      linkGroup,
      dataState,
      resultParents,
      resultChildren,
      resultPets,
      resultFavorites,
      resultBlocked,
      balance,
      showManageBalance,
      balanceMode,
      addingBalance,
      newBalance,
      balanceNotes,
      showTransactions,
      transactions,
      appointments,
      appointmentLoading,
      appointmentDataState,
      appointmentStatus,
      subscription,
      subscriptionActive,
      activityLoading,
      activity,
      activityDataState,
      sitterPreferences,
    } = this.state;
    const { id } = this.props.match.params;

    if (!!loading) {
      return (
        <main className="admin admin-listing with-sidebar appointments">
          <div className="sidebar-wrapper">
            <Sidebar
              {...this.props}
              iconClass="fas fa-id-card"
              linkGroup={linkGroup}
            />
          </div>
          <div className="content">
            <h4 className="section-label">
              <i className="fas fa-spinner fa-spin"></i>
              &nbsp;Loading Family...
            </h4>
          </div>
        </main>
      );
    }

    if (!profileFamily) {
      return (
        <main className="admin admin-listing with-sidebar appointments">
          <div className="sidebar-wrapper">
            <Sidebar
              {...this.props}
              iconClass="fas fa-id-card"
              linkGroup={linkGroup}
            />
          </div>
          <div className="content">
            <h4 className="section-label">
              <i className="fas fa-calendar-star"></i>&nbsp;Family Not Found
            </h4>
            <p>We couldn't find a family with ID {id}</p>
          </div>
        </main>
      );
    }

    return (
      <main className="admin admin-listing with-sidebar with-sidebar-right appointments">
        <div className="sidebar-wrapper">
          <Sidebar
            {...this.props}
            iconClass="fas fa-id-card"
            linkGroup={linkGroup}
          />
        </div>
        <div className="content">
          <h4 className="section-label">
            <i className="fas fa-calendar-star"></i>&nbsp;Manage{' '}
            {profileFamily.name} Family
          </h4>
          <div className="btn-wrapper">
            <Link
              to={`/families/appointments/book/${id}`}
              className="theme-btn blue"
            >
              <i className="fas fa-plus-circle"></i>&nbsp;Casual Care
            </Link>
            <Link
              to={`/families/programs/book/${id}`}
              className="theme-btn blue"
            >
              <i className="fas fa-plus-circle"></i>
              &nbsp;Consistent Care
            </Link>
          </div>
          <br />
          <h4 className="section-label">
            &nbsp;Subscription: {subscriptionActive ? 'Active' : 'Not Active'}
            {subscriptionActive && subscription.renews_at != null
              ? ' (Renewal Date: ' +
                moment(subscription.renews_at).format('MMMM Do, YYYY') +
                ')'
              : ''}
            {subscription &&
              subscription.ends_at &&
              ' (Ends On: ' +
                moment(subscription.ends_at).format('MMMM Do, YYYY') +
                ')'}
            {subscription &&
              subscription.trial_ends_at &&
              ' (Trail Ends On: ' +
                moment(subscription.trial_ends_at).format('MMMM Do, YYYY') +
                ')'}
          </h4>
          <br />
          <h4 className="section-label">
            {appointmentLoading && <i className="fas fa-spinner fa-spin"></i>}
            &nbsp;Appointments
          </h4>
          <div className="filter-btn-wrapper">
            <button
              className="theme-btn slate-blue small"
              onClick={() => {
                this.setFilter('all');
              }}
            >
              All
            </button>
            <button
              className="theme-btn slate-blue small"
              onClick={() => {
                this.setFilter('pending');
              }}
            >
              Pending
            </button>
            <button
              className="theme-btn slate-blue small"
              onClick={() => {
                this.setFilter('24hours');
              }}
            >
              Next 24 Hours
            </button>
            <button
              className="theme-btn slate-blue small"
              onClick={() => {
                this.setFilter('accepted');
              }}
            >
              Accepted
            </button>
            <button
              className="theme-btn slate-blue small"
              onClick={() => {
                this.setFilter('completed');
              }}
            >
              Completed
            </button>
            <button
              className="theme-btn slate-blue small"
              onClick={() => {
                this.setFilter('cancelled');
              }}
            >
              Cancelled
            </button>
            <button
              className="theme-btn slate-blue small"
              onClick={() => {
                this.setFilter('time change requested');
              }}
            >
              Time Change Requested
            </button>
          </div>
          <div className="kendo-table">
            <Grid
              data={appointments}
              pageable={true}
              sortable={true}
              {...appointmentDataState}
              onRowClick={this.handleRowClick}
              onPageChange={(e) => this.pageChange(e, 'appointment')}
              onSortChange={(e) => this.sortChange(e, 'appointment')}
              onFilterChange={(e) => this.filterChange(e, 'appointment')}
            >
              <GridColumn
                title="Status"
                field="status"
                sortable={false}
                cell={StatusCell}
                width={80}
              />
              <GridColumn
                title="Date"
                field="start_time"
                cell={DateCell}
                width={125}
              />
              <GridColumn
                title="Start Time"
                field="start_time"
                width={250}
                cell={TimeCell}
              />
              {appointmentStatus == 'cancelled' && (
                <GridColumn
                  title="Cancelled At"
                  field="cancelled_at"
                  cell={CancelledCell}
                  width={200}
                />
              )}
              <GridColumn
                title="Flex"
                field="flex"
                cell={BooleanCell}
                width={70}
              />
              <GridColumn
                title="Provider"
                field="sitters"
                sortable={false}
                cell={(props) => (
                  <SittersCell
                    {...props}
                    {...this.props}
                    sitterPreferences={sitterPreferences}
                  />
                )}
                columnMenu={ColumnMenu}
              />
              <GridColumn title="ID" field="id" width={60} />
              <GridColumn
                title=" "
                field="id"
                width={100}
                cell={(props) => <ManageCell {...props} {...this.props} />}
              />
            </Grid>
          </div>
          <br />
          <h4 className="section-label">
            {activityLoading && <i className="fas fa-spinner fa-spin"></i>}
            &nbsp;Activity
          </h4>
          <div className="kendo-table">
            <Grid
              data={activity}
              pageable={true}
              sortable={true}
              {...activityDataState}
              onRowClick={this.handleRowClick}
              onPageChange={(e) => this.pageChange(e, 'activity')}
              onSortChange={(e) => this.sortChange(e, 'activity')}
              onFilterChange={(e) => this.filterChange(e, 'activity')}
            >
              <GridColumn
                title="Time"
                field="created_at"
                cell={DateCell}
                width={140}
              />
              <GridColumn title="IP" field="ip" columnMenu={ColumnMenu} />
              <GridColumn title="User" field="user" columnMenu={ColumnMenu} />
              <GridColumn
                title="Activity Type"
                field="activity_type"
                columnMenu={ColumnMenu}
              />
              <GridColumn
                title="Action"
                field="action"
                columnMenu={ColumnMenu}
              />
              <GridColumn
                title="Notation"
                field="notation"
                columnMenu={ColumnMenu}
              />
              <GridColumn title="ID" field="id" width={60} />
            </Grid>
          </div>
          <br />
          <h4 className="section-label">
            &nbsp;Parents{' '}
            <Link to={`/families/members/edit/new/${id}`}>
              <i className="fad fa-plus-circle"></i>
            </Link>
          </h4>
          <div className="kendo-table">
            <Grid
              data={resultParents}
              pageable={true}
              sortable={true}
              {...dataState}
              onDataStateChange={this.handleDataStateChange}
              onRowClick={this.handleRowClick}
            >
              <GridColumn
                title="Email"
                field="user.email"
                columnMenu={ColumnMenu}
              />
              <GridColumn
                title="First Name"
                field="firstname"
                columnMenu={ColumnMenu}
              />
              <GridColumn
                title="Last Name"
                field="lastname"
                columnMenu={ColumnMenu}
              />
              <GridColumn
                title="Cancellation"
                width={150}
                field="user.requested_subscription_cancellation"
                cell={BooleanCell}
              />
              <GridColumn
                title=" "
                field="id"
                width={100}
                cell={(props) => (
                  <ManageMemberCell {...props} {...this.props} />
                )}
              />
            </Grid>
          </div>
          <br />
          <h4 className="section-label">
            &nbsp;Children{' '}
            <Link to={`/families/children/edit/new/${id}`}>
              <i className="fad fa-plus-circle"></i>
            </Link>
          </h4>
          <div className="kendo-table">
            <Grid
              data={resultChildren}
              pageable={true}
              sortable={true}
              {...dataState}
              onDataStateChange={this.handleDataStateChange}
              onRowClick={this.handleRowClick}
            >
              <GridColumn title="DOB" field="DOB" columnMenu={ColumnMenu} />
              <GridColumn
                title="First Name"
                field="firstname"
                columnMenu={ColumnMenu}
              />
              <GridColumn
                title="Last Name"
                field="lastname"
                columnMenu={ColumnMenu}
              />
              <GridColumn
                title=" "
                field="profile_child.id"
                width={100}
                cell={(props) => <ManageChildCell {...props} {...this.props} />}
              />
            </Grid>
          </div>
          <br />
          <h4 className="section-label">
            &nbsp;Pets{' '}
            <Link to={`/families/pets/edit/new/${id}`}>
              <i className="fad fa-plus-circle"></i>
            </Link>
          </h4>
          <div className="kendo-table">
            <Grid
              data={resultPets}
              pageable={true}
              sortable={true}
              {...dataState}
              onDataStateChange={this.handleDataStateChange}
              onRowClick={this.handleRowClick}
            >
              <GridColumn
                title="Type"
                field="profile_pet.type"
                columnMenu={ColumnMenu}
              />
              <GridColumn
                title="Name"
                field="firstname"
                columnMenu={ColumnMenu}
              />
              <GridColumn
                title=" "
                field="profile_pet.id"
                width={100}
                cell={(props) => <ManagePetCell {...props} {...this.props} />}
              />
            </Grid>
          </div>
          <br />
          <h4 className="section-label">
            &nbsp;Favorite Sitters{' '}
            <Link to={`/families/sitters/${id}/favorite`}>
              <i className="fad fa-plus-circle"></i>
            </Link>
          </h4>
          <div className="kendo-table">
            <Grid
              data={resultFavorites}
              pageable={true}
              sortable={true}
              {...dataState}
              onDataStateChange={this.handleDataStateChange}
              onRowClick={this.handleRowClick}
            >
              <GridColumn
                title="First Name"
                field="firstname"
                columnMenu={ColumnMenu}
              />
              <GridColumn
                title="Last Name"
                field="lastname"
                columnMenu={ColumnMenu}
              />
              <GridColumn
                title=" "
                field="id"
                cell={(props) => (
                  <FavoriteSitterCell
                    onFavoriteClick={this.onFavoriteClick}
                    {...props}
                    {...this.props}
                  />
                )}
              />
              <GridColumn
                title=" "
                field="id"
                width={100}
                cell={(props) => (
                  <ManageSitterCell {...props} {...this.props} />
                )}
              />
            </Grid>
          </div>
          <br />
          <h4 className="section-label">
            &nbsp;Blocked Sitters{' '}
            <Link to={`/families/sitters/${id}/block`}>
              <i className="fad fa-plus-circle"></i>
            </Link>
          </h4>
          <div className="kendo-table">
            <Grid
              data={resultBlocked}
              pageable={true}
              sortable={true}
              {...dataState}
              onDataStateChange={this.handleDataStateChange}
              onRowClick={this.handleRowClick}
            >
              <GridColumn
                title="First Name"
                field="firstname"
                columnMenu={ColumnMenu}
              />
              <GridColumn
                title="Last Name"
                field="lastname"
                columnMenu={ColumnMenu}
              />
              <GridColumn
                title=" "
                field="id"
                cell={(props) => (
                  <BlockSitterCell
                    onBlockClick={this.onBlockClick}
                    {...props}
                    {...this.props}
                  />
                )}
              />
              <GridColumn
                title=" "
                field="id"
                width={100}
                cell={(props) => (
                  <ManageSitterCell {...props} {...this.props} />
                )}
              />
            </Grid>
          </div>

          <br />
          <h4 className="section-label">&nbsp;Provider Reviews </h4>
          <ReviewsGrid familyId={id} />
        </div>

        <div className="sidebar-right family-detail">
          <h4>
            <i className="fas fa-user-cog"></i>&nbsp;Admin Tools
          </h4>
          <div className="sidebar-section">
            <div className="button-row">
              <Link
                to={`/families/members/${id}/edit`}
                className="theme-btn primary-green"
              >
                <i className="fas fa-edit"></i>&nbsp;Edit Family
              </Link>
            </div>
          </div>
          <div className="sidebar-section">
            <h4>House Rules</h4>
            <p>
              {!!profileFamily.house_rules ? profileFamily.house_rules : 'None'}
            </p>
          </div>
          <div className="sidebar-section">
            <h4>Admin Notes on Family to Providers</h4>
            <p>
              {!!profileFamily.admin_family_notes
                ? profileFamily.admin_family_notes
                : 'None'}
            </p>
          </div>
          <div className="sidebar-section">
            <h4>Balance</h4>
            <p className={`${parseFloat(balance) < 0 ? 'balance' : 'credit'}`}>
              <b>
                {parseFloat(balance) < 0 ? '-' : ''}${Math.abs(balance)}&nbsp;
                {parseFloat(balance) > 0 ? 'credit available' : 'balance due'}
              </b>
            </p>
            <button
              className="theme-btn slate-blue small"
              onClick={this.toggleShowManageBalance}
            >
              Manage
            </button>
            <br />
            <a className="view" href="#" onClick={this.toggleTransactions}>
              {!!showTransactions ? 'Hide' : 'View'} credit/balance history
            </a>
            <br />
            <br />
            {!!showTransactions && (
              <React.Fragment>
                {transactions.map((transaction) => (
                  <React.Fragment key={`transaction-${transaction.id}`}>
                    <p key={`transaction-${transaction.id}`}>
                      <b>
                        {moment(transaction.created_at).format('M/D/YY h:mma')}
                      </b>
                      <br />${Math.abs(transaction.amount)}&nbsp;
                      {parseFloat(transaction.amount) < 0
                        ? 'Balance'
                        : 'Credit'}
                      &nbsp;Applied
                      <br />
                      {!!transaction.notes && (
                        <span>Notes: {transaction.notes}</span>
                      )}
                    </p>
                    <br />
                  </React.Fragment>
                ))}
              </React.Fragment>
            )}
            <Modal
              title="Manage Credit/Balance"
              isOpen={showManageBalance}
              closeModal={this.toggleShowManageBalance}
              secondaryButton={'Close'}
              content={
                <div className="admin-manage-balance">
                  <div className="button-toggler">
                    <div
                      className={`option credit ${
                        balanceMode == 'credit' ? 'active' : ''
                      }`}
                      onClick={() => {
                        this.setBalanceMode('credit');
                      }}
                    >
                      <p>Credit</p>
                    </div>
                    <div
                      className={`option balance ${
                        balanceMode == 'balance' ? 'active' : ''
                      }`}
                      onClick={() => {
                        this.setBalanceMode('balance');
                      }}
                    >
                      <p>Balance</p>
                    </div>
                  </div>
                  <input
                    type="number"
                    onChange={this.onNewBalanceChange}
                    value={newBalance}
                    onKeyDown={this.onKeyDown}
                    name="new_balance"
                    step="0.01"
                  />
                  <textarea
                    placeholder="Notes"
                    name="notes"
                    rows="4"
                    onChange={this.onNotesChange}
                    value={balanceNotes}
                  ></textarea>
                  <button
                    className={`theme-btn ${
                      balanceMode == 'credit' ? 'primary-green' : 'error-red'
                    }`}
                    onClick={this.setNewBalance}
                  >
                    <i className="far fa-check-circle"></i>
                    &nbsp;Assign {balanceMode}
                  </button>
                </div>
              }
            />
          </div>
        </div>
      </main>
    );
  }
}

export default MembersGrid;
