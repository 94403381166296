import React from 'react';
import Form from '../../shared/Form';
import Joi from 'joi';
import { connect } from 'react-redux';

import httpService from '../../../services/httpService';
import { authActions } from '../../../actions/authActions';

import { FormStepTracker } from '../../shared';

import trackerSteps from './trackerSteps.json';
import { each } from 'lodash-es';
import { mapAuthState } from '../../../helpers/mapAuthState';

const radioOptions = [
  { label: 'Yes', value: 1 },
  { label: 'No', value: 0 },
];

const maritalStatusOptions = [
  { label: 'Single', value: 'Single' },
  { label: 'Married', value: 'Married' },
  { label: 'Widowed', value: 'Widowed' },
  { label: 'Divorced', value: 'Divorced' },
];

class PrivateProfile extends Form {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        emergency_contact_name: '',
        emergency_contact_phone: '',
        marital_status: '',
        pets: '',
        smoke: '',
        selected_medical_conditions: [],
        allergy_info: '',
        sms_optin: '',
      },
      medicalConditions: [],
      errors: {},
      user: null,
    };
  }
  schema = Joi.object({
    emergency_contact_name: Joi.string()
      .required()
      .label('Emergency Contact Name'),
    emergency_contact_phone: Joi.number()
      .required()
      .label('Emergency Contact Phone Number'),
    sms_optin: Joi.boolean()
      .truthy(1)
      .falsy(0)
      .required()
      .label('Texting Opt-In'),
    marital_status: Joi.string().required().label('Marital Status'),
    pets: Joi.boolean().truthy(1).falsy(0).required().label('Pets'),
    smoke: Joi.boolean()
      .truthy(1)
      .falsy(0)
      .required()
      .label('Household Smokers'),
    selected_medical_conditions: Joi.array()
      .optional()
      .label('Selected Medical Conditions'),
    allergy_info: Joi.string()
      .optional()
      .allow(null)
      .allow('')
      .label('Medical Condition Info'),
  });
  componentDidMount() {
    const { user } = this.props.auth;
    if (!user) {
      this.sendUserBack();
    } else {
      this.getMedicalConditions();
    }
  }
  getMedicalConditions = async () => {
    try {
      const response = await httpService.get('/api/medicalInfo');
      if (response.status === 200) {
        let { medical_info } = response.data.data;
        medical_info = each(medical_info, (info) => {
          info['value'] = info.id;
          info['label'] = info.name;
        });
        this.setState({
          medicalConditions: medical_info,
        });
      }
    } catch (e) {
      console.log(e);
    }
  };
  sendUserBack = () => {
    alert('You must sign up or log in to view this page.');
    window.open('/', '_self');
  };
  advanceStep = async (e) => {
    if (!!e) {
      e.preventDefault();
    }
    try {
      const { id } = this.props.auth.user;
      const response = await this.props.updateUserStep(
        'sitter-private-profile',
        id
      );
      console.log(response);
    } catch (e) {
      console.log(e);
    }
  };
  doSubmit = async (callback) => {
    try {
      const apiData = { ...this.state.data };
      const { id } = this.props.auth.user.personal_info;

      const response = await this.props.updateUser(
        `/profileSitter/update/${id}`,
        apiData
      );
      console.log(response);

      if (response.status === 200) {
        this.advanceStep();
      } else {
        callback();
      }
    } catch (e) {
      console.log(e);
      callback();
    }
  };
  render() {
    const { medicalConditions } = this.state;
    const { user } = this.props.auth;
    let active = !!user ? user.active : false;
    return (
      <div className="registration sitter step-4 private-profile">
        <div className="form">
          <div className="inner">
            <FormStepTracker steps={trackerSteps} currentStep={1} />
            {!active && (
              <div className="form-notice">
                <h3>Time to complete Your PROFILES</h3>
                <p>
                  Your application submission and information has been reviewed
                  and approved by our team. You are now ready to continue your
                  registration and need to complete your Private and Public
                  profiles. Your Private Profile will only be viewable by the
                  SmartSitter staff and will not be available to families.
                </p>
              </div>
            )}
            <div className="form-header">
              <h2>Private Profile</h2>
            </div>
            <form onSubmit={this.handleSubmit}>
              <div className="form-group justify-space-between">
                {this.renderInput(
                  'emergency_contact_name',
                  'Emergency Contact Full Name',
                  'text',
                  true
                )}
                {this.renderInput(
                  'emergency_contact_phone',
                  'Emergency Contact Phone Number',
                  'number',
                  true
                )}
                {this.renderRadio(
                  'sms_optin',
                  'Would you like to opt-in to text alerts?',
                  radioOptions,
                  true
                )}
                {this.renderRadio(
                  'marital_status',
                  'What is your marital status?',
                  maritalStatusOptions,
                  true
                )}
                {this.renderRadio(
                  'pets',
                  'Do you have any pets?',
                  radioOptions,
                  true
                )}
                {this.renderRadio('smoke', 'Do you smoke?', radioOptions, true)}
                {this.renderSelectSearch(
                  'selected_medical_conditions',
                  'Any allergies or medical conditions? (optional)',
                  medicalConditions,
                  false,
                  {
                    tooltip:
                      'Including your own allergies is incredibly important! By doing this, it allows our team a heads up for what sits you may not be applicable for, as well as, assists in an emergency.',
                  }
                )}
                {this.renderTextarea(
                  'allergy_info',
                  'Notes on Allergies and Medical Conditions (optional)',
                  false
                )}
              </div>
              {this.renderButton('Next', 'Submitting...', 'theme-btn blue')}
            </form>
          </div>
        </div>
      </div>
    );
  }
}

const actionCreators = {
  updateUserStep: authActions.updateUserStep,
  updateUser: authActions.updateUser,
};

export default connect(mapAuthState, actionCreators)(PrivateProfile);
