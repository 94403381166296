import React from 'react';
import moment from 'moment-timezone';
import BookingReviewRow from './BookingReviewRow';

class BookingReview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentAppointments: [],
      editingAppointments: [],
    };
  }
  componentDidMount() {
    //
  }
  componentDidUpdate = (prevProps, prevState) => {
    const prevAppts = prevProps.appointments;
    const { appointments, idx } = this.props;
  };
  toggleAppointmentDetails = (rid) => {
    const { currentAppointments } = this.state;
    if (currentAppointments.indexOf(rid) > -1) {
      let idx = currentAppointments.indexOf(rid);
      currentAppointments.splice(idx, 1);
    } else {
      currentAppointments.push(rid);
    }
    this.setState({
      currentAppointments,
    });
  };
  toggleEditAppointment = (rid) => {
    const { editingAppointments } = this.state;
    if (editingAppointments.indexOf(rid) > -1) {
      let idx = editingAppointments.indexOf(rid);
      editingAppointments.splice(idx, 1);
    } else {
      editingAppointments.push(rid);
    }
    this.setState({
      editingAppointments,
    });
  };
  render() {
    const { currentAppointments, editingAppointments } = this.state;
    const { appointments, children, sitters, fees, submitting } = this.props;
    const length = appointments.length;
    const canAdvance = this.props.isFormDone();
    return (
      <div className="booking-form review">
        {appointments.map((appointment, idx) => (
          <div className="booking-info" key={`appt-review-${appointment.rid}`}>
            {!!appointment.overnight && !!appointment.num_nights && (
              <div className="date">
                <h3>
                  {moment(appointment.date).format('MMM D')}
                  &nbsp;-&nbsp;
                  {moment(appointment.date)
                    .add(appointment.num_nights, 'days')
                    .format('MMM D')}
                </h3>
                <h3 className="weekday">
                  {moment(appointment.date).format('ddd')}
                  &nbsp;-&nbsp;
                  {moment(appointment.date)
                    .add(appointment.num_nights, 'days')
                    .format('ddd')}
                </h3>
              </div>
            )}
            {(!appointment.overnight || !appointment.num_nights) && (
              <div className="date">
                <h3>{moment(appointment.date).format('MMMM D')}</h3>
                <h3 className="weekday">
                  {moment(appointment.date).format('dddd')}
                </h3>
              </div>
            )}
            <div className="time">
              <p>
                {moment(appointment.start_time, 'HH:mm').format('h:mm a')}
                &nbsp;-&nbsp;
                {moment(appointment.end_time, 'HH:mm').format('h:mm a')}
              </p>
              {!!appointment.flex && (
                <p className="wiggle">
                  ({appointment.flex_room} min Wiggle Room)
                </p>
              )}
            </div>
            <div
              className="remove"
              onClick={() => {
                this.props.removeAppointment(idx);
              }}
            >
              <button className="theme-btn small error-red">
                <i className="fas fa-trash-alt"></i>&nbsp;Remove
              </button>
            </div>
            <div className="booking-review">
              <div className="inner">
                <div className="top">
                  {!!canAdvance && (
                    <h4 className="section-label">
                      <i className="fas fa-check"></i>
                      &nbsp;READY TO GO!
                    </h4>
                  )}
                  {!canAdvance && (
                    <h4 className="section-label error">
                      <i className="far fa-times"></i>
                      &nbsp;NEEDS ATTENTION!
                    </h4>
                  )}
                  {currentAppointments.indexOf(appointment.rid) === -1 && (
                    <p
                      onClick={() => {
                        this.toggleAppointmentDetails(appointment.rid);
                      }}
                    >
                      <i className="fas fa-plus"></i>
                      &nbsp;View Details
                    </p>
                  )}
                  {currentAppointments.indexOf(appointment.rid) > -1 && (
                    <p
                      onClick={() => {
                        this.toggleAppointmentDetails(appointment.rid);
                      }}
                    >
                      <i className="fas fa-minus"></i>
                      &nbsp;Hide Details
                    </p>
                  )}
                </div>
                {currentAppointments.indexOf(appointment.rid) > -1 && (
                  <BookingReviewRow
                    appointment={appointment}
                    idx={idx}
                    fees={fees}
                    children={children}
                    sitters={sitters}
                    toggleEditAppointment={this.toggleEditAppointment}
                    handleApptChange={this.props.handleApptChange}
                    editing={editingAppointments.indexOf(appointment.rid) > -1}
                  />
                )}
              </div>
            </div>
          </div>
        ))}
        {this.props.PaymentSection}
        <div className="booking-footer">
          {!!submitting && (
            <button
              className={`theme-btn blue`}
              onClick={(e) => e.preventDefault()}
            >
              <i className="fas fa-spinner fa-spin"></i>
              &nbsp;Submitting Bookings...
            </button>
          )}
          {!submitting && !canAdvance && (
            <button
              className={`theme-btn blue disabled`}
              onClick={(e) => e.preventDefault()}
            >
              Pay and Submit Bookings
            </button>
          )}
          {!submitting && !!canAdvance && (
            <button
              className={`theme-btn blue`}
              onClick={this.props.onFormComplete}
            >
              Pay and Submit Bookings
            </button>
          )}
        </div>
      </div>
    );
  }
}

export default BookingReview;
