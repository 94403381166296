import React from 'react';
import { connect } from 'react-redux';
import { authActions } from '../../../actions/authActions';
import { adminEmail, adminPhone } from '../../../config';
import { mapAuthState } from '../../../helpers/mapAuthState';
import { FormStepTracker } from '../../shared';
import trackerSteps from './trackerSteps.json';

class ApplicationSubmitted extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      //
    };
  }
  componentDidMount() {
    const { user } = this.props.auth;
    if (!user) {
      this.sendUserBack();
    }
  }
  componentDidUpdate(prevProps, prevState) {
    //
  }
  sendUserBack() {
    alert('You must sign up or log in to view this page.');
    window.open('/', '_self');
  }
  render() {
    const {
      //
    } = this.state;
    return (
      <div className="registration sitter step-3 completion-page">
        <div className="form">
          <div className="inner">
            <FormStepTracker steps={trackerSteps} currentStep={0} />
            <div className="form-header">
              <h2>Application Submitted</h2>
              <p>
                Thank you for submitting your application to join our team as a
                SmartSitter. Your application will be reviewed by our team. If
                your application is approved, we'll contact you via email.
              </p>
            </div>
            <div className="form-body">
              <p className="mini-header">Next steps</p>
              <ul>
                <li>
                  <p>
                    <span className="form-step-circle">1</span>
                    Our Team will review your application and be in touch in 1-2
                    business days.
                  </p>
                </li>
                <li>
                  <p>
                    <span className="form-step-circle">2</span>
                    If approved, a Team Member will reach out to schedule a Meet
                    & Greet Interview.
                  </p>
                </li>
                <li>
                  <p>
                    <span className="form-step-circle">3</span>
                    Our Hiring Manager will reach out if you are a good fit for
                    the team and get you started with our training!
                  </p>
                </li>
              </ul>
            </div>
            <div className="form-footer">
              <h3>Questions</h3>
              <p>
                If you have any questions, please contact us at{' '}
                <b>{adminPhone}</b> or{' '}
                <b>
                  <a href={`mailto:${adminEmail}`}>{adminEmail}</a>
                </b>
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const actionCreators = {
  updateUserStep: authActions.updateUserStep,
};

export default connect(mapAuthState, actionCreators)(ApplicationSubmitted);
