import { areIntervalsOverlapping, format, formatDistanceStrict, } from 'date-fns';
import React from 'react';
import { Link } from 'react-router-dom';
import { createInterval, displayAge } from '../../../helpers';
import { WarningText } from '../../shared/WarningText';
import { Avatar } from '../../shared';
export const UnclaimedAppointmentsRow = (props) => {
    const { schedule, start_time, end_time, family_avatar, family_name, id, location_city, pending_approval_flag, children, } = props;
    const appointmentInterval = createInterval(start_time, end_time);
    const childAges = children
        .map((child) => displayAge(child.DOB, 'yo', 'mo'))
        .join(' / ');
    const conflict = schedule.some((entry) => areIntervalsOverlapping(entry, appointmentInterval));
    return (React.createElement("div", { className: "unclaimed-appointment-row" },
        React.createElement("div", { className: "unclaimed-appointment-row__date" },
            React.createElement("div", { className: "unclaimed-appointment-row__date__day" },
                React.createElement("h3", null, format(appointmentInterval.start, 'MMMM do')),
                React.createElement("div", null, format(appointmentInterval.start, 'iiii'))),
            React.createElement("div", { className: "unclaimed-appointment-row__date__time" },
                format(appointmentInterval.start, 'h:mm aaa'),
                " to",
                ' ',
                format(appointmentInterval.end, 'h:mm aaa'),
                ' ',
                React.createElement("span", null,
                    "(",
                    formatDistanceStrict(appointmentInterval.start, appointmentInterval.end),
                    ")"))),
        React.createElement("div", { className: "unclaimed-appointment-row__details" },
            React.createElement("div", { className: "unclaimed-appointment-row__details__avatar" },
                React.createElement(Avatar, { imageSrc: family_avatar, profileColor: null, className: "sitter-col" })),
            React.createElement("div", { className: "unclaimed-appointment-row__details__inner" },
                React.createElement("dl", { className: "unclaimed-appointment-row__details__family" },
                    React.createElement("dt", { className: "sr-only" }, "Family"),
                    React.createElement("dd", null, family_name)),
                React.createElement("dl", { className: "unclaimed-appointment-row__details__location" },
                    React.createElement("dt", { className: "sr-only" }, "Location"),
                    React.createElement("dd", null, location_city)),
                React.createElement("dl", { className: "unclaimed-appointment-row__details__kids" },
                    React.createElement("dt", { className: "sr-only" }, "Kids ages"),
                    React.createElement("dd", null, childAges)))),
        React.createElement("div", { className: "unclaimed-appointment-row__action" },
            React.createElement(Link, { to: {
                    pathname: `/appointment/${id}`,
                    state: { appointmentView: true },
                } },
                React.createElement("i", { className: "fas fa-arrow-right" }))),
        pending_approval_flag === 1 && (React.createElement("div", { className: "unclaimed-appointment-row__meta" },
            React.createElement(WarningText, null, "Pending Admin Approval"))),
        pending_approval_flag !== 1 && conflict && (React.createElement("div", { className: "unclaimed-appointment-row__meta" },
            React.createElement(WarningText, null, "Conflict")))));
};
