import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { mapAuthState } from '../../../helpers/mapAuthState';
import sitterService from '../../../services/sitterService';
import SitterAccountSettingsForm from '../../Forms/SitterAccountSettingsForm';

const AvatarDisplayComponent = (props) => {
  return (
    <div className="avatar">
      <div className="avatar-wrapper">
        {!!props.value && <img src={props.value} />}
        {!props.value && <i className="far fa-camera"></i>}
      </div>
    </div>
  );
};

class AccountSettings extends SitterAccountSettingsForm {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      loading: true,
      id: null,
    };
  }
  componentDidMount = () => {
    this.getSitter();
  };
  getSitter = async () => {
    try {
      const { id } = this.props.auth.user.personal_info;
      const response = await sitterService.get(id);
      this.formatData(response.data.data);
    } catch (e) {
      console.log(e);
    }
  };
  formatData = ({
    acceptedAgeRanges,
    languages,
    personalInfo,
    profileSitter,
    selectedActivities,
    medicalInfo,
  }) => {
    const { id, firstname, lastname, DOB } = personalInfo;

    const { data } = this.state;

    data['firstname'] = firstname;
    data['lastname'] = lastname;

    if (!!DOB) {
      const dobPieces = DOB.split('-');
      if (dobPieces.length === 3) {
        // We assume format "YYYY-MM-DD"
        // so after splittig, it should be: [ "YYYY", "MM", "DD" ]
        data['dob_year'] = dobPieces[0];
        data['dob_month'] = dobPieces[1];
        data['dob_day'] = dobPieces[2];
      }
    }

    this.setState({
      data,
      personalInfo,
      profileSitter,
      id: id,
    });
  };
  doSubmit = async (callback) => {
    try {
      const response = await this.submitFormData();
      callback();
      if (response.status === 200) {
        toast.success('Hooray! Your changes are saved.');
      }
    } catch (e) {
      console.log(e);
      callback();
    }
  };
  render() {
    const { data } = this.state;

    return (
      <main className="account profile settings">
        <form onSubmit={this.handleSubmit}>
          <div className="profile-body">
            <h2>Account Settings</h2>
            {this.renderForm(true, true)}
            {this.renderButton('Save', 'Saving...', 'theme-btn blue')}
            <div className="cancel-btn">
              <Link className="theme-btn small slate-blue" to="/profile">
                Cancel
              </Link>
            </div>
          </div>
        </form>
      </main>
    );
  }
}

export default connect(mapAuthState)(AccountSettings);
