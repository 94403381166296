import { debounce, each } from 'lodash-es';
import React from 'react';
import {
  booleanRadioOptions as radioOptions,
  wiggleOptions,
} from '../../../data';
import { upperCaseFirst } from '../../../helpers';
import sitterService from '../../../services/sitterService';
import { Modal } from '../../shared';
import AsyncSelect from '../../shared/Form/AsyncSelect';
import BooleanInput from '../../shared/Form/BooleanInput';
import ChildCheckbox from '../../shared/Form/ChildCheckbox';
import DatePicker from '../../shared/Form/Datepicker';
import Input from '../../shared/Form/Input';
import NumNights from '../../shared/Form/NumNights';
import Radio from '../../shared/Form/Radio';
import Select from '../../shared/Form/Select';

class ProgramBookingRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      end_times: [],
      showWiggleTime: false,
      showDrivingDistance: false,
      showOvernight: false,
      showingRemoveAppt: false,
      showingExtraFields: false,
    };
  }
  componentDidMount = () => {
    let { showOvernight, showWiggleTime, showDrivingDistance } = this.state;
    const { appointment } = this.props;
    if (!!appointment.overnight) showOvernight = true;
    if (!!appointment.flex) showWiggleTime = true;
    if (!!appointment.driving_needed) showDrivingDistance = true;
    if (!!showOvernight || !!showWiggleTime || !!showDrivingDistance) {
      this.setState({
        showOvernight,
        showWiggleTime,
        showDrivingDistance,
        showExtraFields: !!showWiggleTime || !!showDrivingDistance,
      });
    }
  };
  componentDidUpdate = (prevProps, prevState) => {
    const prevAppointment = prevProps.appointment;
    const appointment = this.props.appointment;

    // If driving is true, show the driving_distance field
    if (
      appointment['driving_needed'] === 1 &&
      prevAppointment['driving_needed'] !== appointment['driving_needed']
    ) {
      this.setState({
        showDrivingDistance: true,
      });
    } else if (
      appointment['driving_needed'] === 0 &&
      prevAppointment['driving_needed'] !== appointment['driving_needed']
    ) {
      this.setState({
        showDrivingDistance: false,
      });
    }

    // If driving is true, show the driving_distance field
    if (
      appointment['overnight'] === 1 &&
      prevAppointment['overnight'] !== appointment['overnight']
    ) {
      this.setState(
        {
          showOvernight: true,
        },
        () => {
          this.handleChange('num_nights', 1);
        }
      );
    } else if (
      appointment['overnight'] === 0 &&
      prevAppointment['overnight'] !== appointment['overnight']
    ) {
      this.setState(
        {
          showOvernight: false,
        },
        () => {
          this.handleChange('num_nights', null);
        }
      );
    }

    if (appointment['flex'] === 1 && prevAppointment['flex'] !== 1) {
      this.setState(
        {
          showWiggleTime: true,
        },
        () => {
          this.handleChange('flex_room', `${wiggleOptions[0].id}`);
        }
      );
    } else if (appointment['flex'] === 0 && prevAppointment['flex'] !== 0) {
      this.setState(
        {
          showWiggleTime: false,
        },
        () => {
          this.handleChange('flex_room', null);
        }
      );
    }
  };
  debounceLoadSitters = debounce((search, callback) => {
    if (!!search) {
      this.loadSitters(search, callback);
    } else {
      callback();
    }
  }, 1000);
  loadSitters = async (search, callback) => {
    try {
      const response = await sitterService.search({ search });
      if (response.status === 200) {
        let { sitters } = response.data.data;
        sitters = each(sitters, (sitter) => {
          sitter['value'] = sitter.piid;
          sitter['id'] = sitter.piid;
          sitter['label'] =
            sitter.firstname + ' ' + upperCaseFirst(sitter.lastname);
        });
        this.setState(
          {
            sitters,
          },
          () => {
            callback(sitters);
          }
        );
      }
    } catch (e) {
      console.log(e);
      callback();
    }
  };
  handleChange = (name, value, type = 'default') => {
    const appointment = { ...this.props.appointment };
    const { idx } = this.props;
    if (type === 'number') {
      appointment[name] = Number(value);
    } else {
      appointment[name] = value;
    }
    this.props.onAppointmentDataChange(idx, appointment);
  };
  toggleRemoveAppointment = (e) => {
    if (e) e.preventDefault();
    this.setState({
      showingRemoveAppt: !this.state.showingRemoveAppt,
    });
  };
  removeAppointment = (e) => {
    e.preventDefault();
    this.props.removeAppointment(this.props.idx);
  };
  toggleShowExtraFields = () => {
    this.setState({ showExtraFields: !this.state.showExtraFields });
  };
  handleChildCheckboxChange = ({ currentTarget: input }) => {
    let children = [...this.props.appointment.selected_children];
    if (input.checked) {
      children.push(input.value);
    } else {
      //remove from array
      children = children.filter(function (item) {
        return item !== input.value;
      });
    }
    if (!children) children = [];
    this.handleChange('selected_children', children);
  };
  handleAsyncSelectChange = (name, value) => {
    this.handleChange('selected_sitters', value);
  };
  render() {
    const { appointment, times, children, idx, editingAppointment } =
      this.props;
    const {
      end_times,
      showWiggleTime,
      showDrivingDistance,
      showOvernight,
      showingRemoveAppt,
      showExtraFields,
    } = this.state;
    const { all_children, start_time } = appointment;
    let errors = {};

    //@TODO: ADD PROPER STEP DETECTION HERE
    let isAssigningSitters = false;

    let validStatus = !!appointment.valid ? 'valid' : 'invalid';

    return (
      <div className={`appointment-row ${validStatus}`}>
        <div className="appointment-main-row">
          <div className="form-field-wrapper editing">
            <BooleanInput
              className="bulk_edit_select"
              name={`bulk-apt-${idx}-bulk_edit_select`}
              label="Select All"
              type="text"
              value={!!editingAppointment ? 1 : 0}
              error={errors['bulk_edit_select']}
              onChange={() => {
                this.props.toggleEditingAppointment(appointment.id);
              }}
            />
          </div>
          <div className="form-field-wrapper date">
            <DatePicker
              className="date"
              name={`bulk-apt-${idx}-date`}
              label={`Date`}
              value={appointment['date']}
              error={errors['date']}
              required={true}
              dateFormat={'MM/d/yy'}
              onChange={(date) => {
                this.handleChange('date', date);
              }}
            />
          </div>
          <div className="form-field-wrapper start_time">
            <Select
              className="start_time"
              name={`bulk-apt-${idx}-start_time`}
              label="Start Time"
              options={times}
              value={appointment['start_time']}
              error={errors['start_time']}
              onChange={(e) => {
                this.handleChange('start_time', e.target.value);
              }}
              required={true}
            />
          </div>
          <div className="form-field-wrapper end_time">
            <Select
              className="end_time"
              name={`bulk-apt-${idx}-end_time`}
              label="End Time"
              options={times}
              value={appointment['end_time']}
              error={errors['end_time']}
              onChange={(e) => {
                this.handleChange('end_time', e.target.value);
              }}
              required={true}
            />
          </div>
          <div className="form-field-wrapper overnight">
            <Radio
              className="overnight"
              name={`bulk-apt-${idx}-overnight`}
              label="Overnight:"
              options={radioOptions}
              value={appointment['overnight']}
              error={errors['overnight']}
              onChange={(e) => {
                this.handleChange('overnight', e.target.value, 'number');
              }}
              required={true}
            />
            {!!showOvernight && (
              <NumNights
                className="num_nights"
                name={`bulk-apt-${idx}-num_nights`}
                label="Nights:"
                value={appointment['num_nights']}
                onChange={(label, value) => {
                  this.handleChange('num_nights', value, 'number');
                }}
                required={true}
              />
            )}
          </div>
          <div className="form-field-wrapper children">
            <Radio
              className="all_children"
              name={`bulk-apt-${idx}-all_children`}
              label="All children?"
              options={radioOptions}
              value={appointment['all_children']}
              error={errors['all_children']}
              onChange={(e) => {
                this.handleChange('all_children', e.target.value, 'number');
              }}
              required={true}
            />
            {!all_children && (
              <ChildCheckbox
                className="selected_children"
                name={`bulk-apt-${idx}-selected_children`}
                label="Children"
                options={children}
                value={appointment['selected_children']}
                error={errors['selected_children']}
                onChange={this.handleChildCheckboxChange}
                required={true}
              />
            )}
          </div>
          <div className="form-field-wrapper provider">
            <AsyncSelect
              value={appointment['selected_sitters']}
              error={errors['selected_sitters']}
              label={'Provider'}
              name={`bulk-apt-${idx}-selected_sitters`}
              loadOptions={this.debounceLoadSitters}
              onChange={this.handleAsyncSelectChange}
              required={isAssigningSitters}
            />
          </div>
          <div className="form-field-wrapper show-extra">
            <a className="show-extra-btn" onClick={this.toggleShowExtraFields}>
              <span></span>
              <span></span>
              <span></span>
            </a>
          </div>
          <div className="form-field-wrapper cancel">
            <a className="cancel-btn" onClick={this.toggleRemoveAppointment}>
              <span>X</span>
            </a>
          </div>
        </div>
        {!!showExtraFields && (
          <div className="appointment-sub-row">
            <div className="form-field-wrapper driving">
              <Radio
                className="driving_needed"
                name={`bulk-apt-${idx}-driving_needed`}
                label="Driving?"
                options={radioOptions}
                value={appointment['driving_needed']}
                error={errors['driving_needed']}
                onChange={(e) => {
                  this.handleChange('driving_needed', e.target.value, 'number');
                }}
                required={true}
              />
              {!!showDrivingDistance && (
                <Input
                  className="driving_distance"
                  name={`bulk-apt-${idx}-driving_distance`}
                  label="Miles"
                  type="number"
                  value={appointment['driving_distance']}
                  error={errors['driving_distance']}
                  onChange={(e) => {
                    this.handleChange('driving_distance', e.target.value);
                  }}
                  required={true}
                />
              )}
            </div>
            <div className="form-field-wrapper flex">
              <Radio
                className="flex"
                name={`bulk-apt-${idx}-flex`}
                label="Wiggle Room?"
                options={radioOptions}
                value={appointment['flex']}
                error={errors['flex']}
                onChange={(e) => {
                  this.handleChange('flex', e.target.value, 'number');
                }}
                required={true}
              />
              {!!showWiggleTime && (
                <Select
                  className="flex_room"
                  name={`bulk-apt-${idx}-flex_room`}
                  label="Time"
                  options={wiggleOptions}
                  value={appointment['flex_room']}
                  error={errors['flex_room']}
                  onChange={(e) => {
                    this.handleChange('flex_room', e.target.value, 'number');
                  }}
                  required={true}
                />
              )}
            </div>
          </div>
        )}
        <Modal
          title="Confirm Removal"
          isOpen={showingRemoveAppt}
          closeModal={this.toggleRemoveAppointment}
          content={
            'Are you sure you want to remove this appointment? It has not been saved yet. This action CANNOT be undone.'
          }
          primaryButton={'Remove'}
          secondaryButton={'Close'}
          handleClick={this.removeAppointment}
        />
      </div>
    );
  }
}

export default ProgramBookingRow;
