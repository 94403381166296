import { find } from 'lodash-es';
import React from 'react';
import { booleanRadioOptions, wiggleOptions } from '../../../../data';
import { makeTimeIncrements } from '../../../../helpers';
import NumNights from '../../../shared/Form/NumNights';
import Radio from '../../../shared/Form/Radio';
import Select from '../../../shared/Form/Select';
import Joi from 'joi';

class BookingTimesRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showWiggleTime: false,
      showOvernight: false,
      times: makeTimeIncrements(0),
      startTimes: makeTimeIncrements(0, 21),
      endTimes: makeTimeIncrements(0, 21),
    };
  }
  componentDidMount() {
    const { idx } = this.props;
    let newEndTimes = this.createNewEndTimes();
    this.setState(
      {
        endTimes: newEndTimes,
      },
      () => {
        this.props.handleApptChange(idx, 'end_time', newEndTimes[0].value);
      }
    );
  }
  componentDidUpdate(prevProps, prevState) {
    const { appointment, idx } = this.props;
    const prevAppt = prevProps.appointment;

    if (
      appointment['start_time'] !== null &&
      appointment['start_time'] !== prevAppt['start_time']
    ) {
      // End time options must be filtered if the start time changes (see function for end time filtering logic)
      let newEndTimes = this.createNewEndTimes();
      let timeStillExists = find(newEndTimes, (time) => {
        return time.value == appointment['end_time'];
      });
      this.setState(
        {
          endTimes: newEndTimes,
        },
        () => {
          if (!timeStillExists)
            this.props.handleApptChange(idx, 'end_time', newEndTimes[0].value);
        }
      );
    }

    if (appointment['flex'] === 1 && prevAppt['flex'] !== 1) {
      this.setState(
        {
          showWiggleTime: true,
        },
        () => {
          // Flex room defaults to 1 if flex has changed from 0 to 1
          this.props.handleApptChange(
            idx,
            'flex_room',
            wiggleOptions[0].id,
            'number'
          );
        }
      );
    } else if (appointment['flex'] === 0 && prevAppt['flex'] !== 0) {
      this.setState(
        {
          showWiggleTime: false,
        },
        () => {
          // Flex room defaults to null if flex == 0
          this.props.handleApptChange(idx, 'flex_room', null);
        }
      );
    }

    if (appointment['overnight'] === 1 && prevAppt['overnight'] !== 1) {
      // End time options must be filtered if overnight changes
      let newEndTimes = this.createNewEndTimes();
      let timeStillExists = find(newEndTimes, (time) => {
        return time.value == appointment['end_time'];
      });
      this.setState(
        {
          endTimes: newEndTimes,
          showOvernight: true,
        },
        () => {
          // If the time doesn't exist anymore, we need to force-update it
          if (!timeStillExists) {
            this.props.handleBulkApptChange(idx, {
              end_time: newEndTimes[0].value,
              num_nights: 1,
            });
          } else {
            this.props.handleApptChange(idx, 'num_nights', 1);
          }
        }
      );
    } else if (appointment['overnight'] === 0 && prevAppt['overnight'] !== 0) {
      // End time options must be filtered if overnight changes
      let newEndTimes = this.createNewEndTimes();
      let timeStillExists = find(newEndTimes, (time) => {
        return time.value == appointment['end_time'];
      });
      this.setState(
        {
          endTimes: newEndTimes,
          showOvernight: false,
        },
        () => {
          this.props.handleApptChange(idx, 'num_nights', 0);
          // If the time doesn't exist anymore, we need to force-update it
          if (!timeStillExists) {
            this.props.handleBulkApptChange(idx, {
              end_time: newEndTimes[0].value,
              num_nights: 0,
            });
          } else {
            this.props.handleApptChange(idx, 'num_nights', 0);
          }
        }
      );
    }
  }
  createNewEndTimes = () => {
    const newEndTimes = [...this.state.times];
    newEndTimes.splice(0, 1);
    const { appointment } = this.props;
    // If overnight is not selected, we need to filter out end times that are no longer applicable
    if (!appointment.overnight) {
      const idx = newEndTimes.findIndex((time) => {
        return time.value === appointment.start_time;
      });
      const length = idx + 12;
      if (length === newEndTimes.length) {
        // if they select the very last time slot (11:45pm), then there are 0 options left
        // So we force there to be only 1 item
        newEndTimes.splice(0, length - 1);
      } else {
        newEndTimes.splice(0, length);
      }
    }
    return newEndTimes;
    // If they DO have overnight selected we want the full array of times (hence no filter).
  };
  render() {
    const { startTimes, endTimes, showWiggleTime, showOvernight } = this.state;
    const { appointment, idx } = this.props;
    const endTimeDisabled = !appointment.start_time;
    let errors = {};
    return (
      <div className="booking-details">
        <div className="form-group times">
          <Select
            className="start_time"
            name={`apt-${idx}-start_time`}
            label="Start Time"
            options={startTimes}
            value={appointment['start_time']}
            error={errors['start_time']}
            onChange={(e) => {
              this.props.handleApptChange(idx, 'start_time', e.target.value);
            }}
            required={true}
          />
          <Select
            className="end_time"
            name={`apt-${idx}-end_time`}
            label="End Time"
            options={endTimes}
            value={appointment['end_time']}
            error={errors['end_time']}
            onChange={(e) => {
              this.props.handleApptChange(idx, 'end_time', e.target.value);
            }}
            required={true}
            disabled={endTimeDisabled}
          />
        </div>
        <div className="form-group flex">
          <Radio
            className="flex"
            name={`apt-${idx}-flex`}
            label="Wiggle Room:"
            options={booleanRadioOptions}
            value={appointment['flex']}
            error={errors['flex']}
            onChange={(e) => {
              this.props.handleApptChange(
                idx,
                'flex',
                e.target.value,
                'number'
              );
            }}
            required={true}
          />
          {!!showWiggleTime && (
            <Select
              className="flex_room"
              name={`apt-${idx}-flex_room`}
              label="Time"
              options={wiggleOptions}
              value={appointment['flex_room']}
              error={errors['flex_room']}
              onChange={(e) => {
                this.props.handleApptChange(
                  idx,
                  'flex_room',
                  e.target.value,
                  'number'
                );
              }}
              required={true}
            />
          )}
        </div>
        <div className="form-group overnight">
          <Radio
            className="overnight"
            name={`apt-${idx}-overnight`}
            label="Overnight:"
            options={booleanRadioOptions}
            value={appointment['overnight']}
            error={errors['overnight']}
            onChange={(e) => {
              this.props.handleApptChange(
                idx,
                'overnight',
                e.target.value,
                'number'
              );
            }}
            required={true}
          />
          {!!showOvernight && (
            <NumNights
              className="num_nights"
              name={`apt-${idx}-num_nights`}
              label="Nights:"
              value={appointment['num_nights']}
              onChange={(label, value) => {
                this.props.handleApptChange(idx, 'num_nights', value, 'number');
              }}
              required={true}
            />
          )}
        </div>
      </div>
    );
  }
}

export default BookingTimesRow;
