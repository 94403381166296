import { parse } from 'date-fns';
function getDate(date) {
    if (typeof date === 'string') {
        return parse(date, 'yyyy-MM-dd HH:mm:ss', new Date());
    }
    return date;
}
export function createInterval(start, end) {
    return {
        start: getDate(start),
        end: getDate(end),
    };
}
