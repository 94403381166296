import { displayAge, slugify, upperCaseFirst } from '@ss/helpers';
import appointmentService from '@ss/services/appointmentService';
import tagService from '@ss/services/tagService';
import { debounce } from 'lodash-es';
import moment from 'moment-timezone';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { mapAuthState } from '../../../helpers/mapAuthState';
import { Avatar } from '../../shared';
const displayChildrenAges = (children) => {
    const childAges = children.map((child) => displayAge(child.DOB, 'yo', 'mo'));
    return childAges.join(' / ');
};
const ProgramListingRow = (props) => {
    const { program, program: { id, family }, } = props;
    return (React.createElement("div", { className: "program-listing-row" },
        React.createElement("div", { className: "program-listing-col family" },
            React.createElement("div", { className: "family-inner" },
                React.createElement(Avatar, { imageSrc: family.avatar_filename, profileColor: family.profile_color }),
                React.createElement("div", { className: "info" },
                    React.createElement("p", null,
                        React.createElement("b", null, family.name),
                        React.createElement("br", null),
                        displayChildrenAges(family.children)),
                    React.createElement("h4", null, program.booking_appointments[0].zip)))),
        React.createElement("div", { className: "program-listing-col dates" },
            React.createElement("h4", null, "Dates"),
            React.createElement("p", null,
                moment(program.start_date, 'YYYY-MM-DD').format('MMM Do'),
                "\u00A0-\u00A0",
                moment(program.end_date, 'YYYY-MM-DD').format('MMM Do'))),
        React.createElement("div", { className: "program-listing-col notes" },
            React.createElement("h4", null, "Notes"),
            React.createElement("p", null, program.sitter_notes || 'None')),
        React.createElement("div", { className: "program-listing-col tags" },
            React.createElement("h4", null, "Tags"),
            React.createElement("div", { className: "tags-wrapper" }, !!program.tags &&
                program.tags.map((tag) => (React.createElement("div", { className: "tag solid", key: `tag-${tag.id}` },
                    React.createElement("p", null, tag.tag.name)))))),
        React.createElement("div", { className: "program-listing-col total" },
            React.createElement("h4", null, "Total Appts"),
            React.createElement("p", null, program.booking_appointments.length)),
        React.createElement("div", { className: "program-listing-col status" },
            React.createElement("h4", null, "Status"),
            React.createElement("p", { className: `program-status-pill ${slugify(program.status)}` }, program.status === 'assigning' ||
                program.status === 'assigning-pending'
                ? 'Open'
                : program.status
                    .split('-')
                    .map((str) => upperCaseFirst(str))
                    .join(' '))),
        React.createElement("div", { className: "program-listing-col link" },
            React.createElement(Link, { to: `/programs/${id}`, className: "theme-btn blue small" }, "Review"))));
};
class ProgramListing extends React.Component {
    debounceGetPrograms = debounce(() => {
        this.getPrograms();
    }, 1000);
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            openPrograms: [],
            pendingPrograms: [],
            tags: [],
            selectedTags: [],
        };
    }
    componentDidMount() {
        this.getPrograms();
        this.getTags();
    }
    getPrograms = async () => {
        try {
            const { id } = this.props.auth.user.personal_info;
            const data = {
                tags: this.state.selectedTags,
            };
            const response = await appointmentService.getProgramsByType('sitter', id, data);
            if (response.status === 200) {
                const { open, pending } = response.data.data;
                this.setState({
                    openPrograms: open,
                    pendingPrograms: pending,
                    loading: false,
                });
            }
            else {
                // Handle error response in some way?
                this.setState({
                    loading: false,
                });
            }
        }
        catch (error) {
            console.log(error);
        }
    };
    getTags = async () => {
        try {
            const response = await tagService.get();
            if (response.status === 200) {
                const { tags } = response.data.data;
                this.setState({
                    tags,
                });
            }
        }
        catch (error) {
            console.log(error);
        }
    };
    toggleTag = (tagId) => {
        const selectedTags = [...this.state.selectedTags];
        const idx = selectedTags.indexOf(tagId);
        if (idx === -1) {
            selectedTags.push(tagId);
        }
        else {
            selectedTags.splice(idx, 1);
        }
        this.setState({ selectedTags }, this.debounceGetPrograms);
    };
    render() {
        const { openPrograms, pendingPrograms, tags, selectedTags, loading } = this.state;
        if (!!loading) {
            return (React.createElement("main", { className: "user-programs program-listing sitter" },
                React.createElement("div", { className: "program-inner" },
                    React.createElement("h4", { className: "section-label" },
                        React.createElement("i", { className: "fas fa-spinner fa-spin" }),
                        "\u00A0Loading Available Consistent Care Requests..."))));
        }
        return (React.createElement("main", { className: "user-programs program-listing sitter" },
            React.createElement("div", { className: "program-inner" },
                React.createElement("h4", { className: "section-label" },
                    React.createElement("i", { className: "fas fa-calendar-star" }),
                    "\u00A0Available Consistent Care Requests (",
                    openPrograms.length + pendingPrograms.length,
                    ")"),
                React.createElement("div", { className: "tags-wrapper filter" },
                    React.createElement("p", null, "Filter By Tags"),
                    tags.map((tag) => (React.createElement("div", { className: `tag filter ${selectedTags.includes(tag.id) ? 'solid' : ''}`, key: `tag-${tag.id}`, onClick: () => {
                            this.toggleTag(tag.id);
                        } },
                        React.createElement("p", null, tag.name))))),
                pendingPrograms.length > 0 && (React.createElement("div", { className: "program-listing-section" },
                    React.createElement("h4", null, "Your Pending Signups"),
                    React.createElement("div", { className: "program-listing-wrapper pending" }, pendingPrograms.map((program) => (React.createElement(ProgramListingRow, { program: program, type: "pending", key: `program-listing-pending-${program.id}` })))))),
                openPrograms.length > 0 && (React.createElement("div", { className: "program-listing-section" },
                    React.createElement("h4", null, "Open Consistent Care Requests"),
                    React.createElement("div", { className: "program-listing-wrapper open" }, openPrograms.map((program) => (React.createElement(ProgramListingRow, { program: program, type: "open", key: `program-listing-open-${program.id}` })))))))));
    }
}
export default connect(mapAuthState)(ProgramListing);
