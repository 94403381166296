import React from 'react';
import { Link, useLocation } from 'react-router-dom';
const Sidebar = (props) => {
    const { iconClass, linkGroup } = props;
    const { pathname } = useLocation();
    const isActiveLink = (href) => {
        return pathname.includes(href);
    };
    return (React.createElement("div", { className: "sidebar" },
        React.createElement("div", { className: "sidebar-title" },
            React.createElement("h3", null,
                React.createElement("i", { className: iconClass }),
                "\u00A0",
                linkGroup.group_name)),
        linkGroup.group_links.map((link, idx) => (React.createElement("div", { className: `sidebar-link ${isActiveLink(link.href) ? 'active' : ''}`, key: `link-item-${idx}` },
            React.createElement(Link, { to: link.href }, link.title))))));
};
export default Sidebar;
