import React from 'react';
import moment from 'moment-timezone';

import DatePicker from '../../shared/Form/Datepicker';
import Select from '../../shared/Form/Select';

import { daysOfWeek } from '../../../data';

import { makeTimeIncrements } from '../../../helpers';

class BulkAppointmentsList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      startTimes: makeTimeIncrements(0, 21),
      endTimes: makeTimeIncrements(0, 24),
    };
  }
  componentDidMount() {
    //
  }
  componentDidUpdate(prevProps, prevState) {
    //
  }
  render() {
    const { type, appointments } = this.props;

    const { startTimes, endTimes } = this.state;

    let errors = {};

    return (
      <div className="bulk-appointments-list">
        {appointments.map((appointment, idx) => (
          <div
            className={`list-appt appt-${type}-${idx}`}
            key={`appt-${appointment.rid}`}
          >
            <div className="form-group">
              {type == 'repeating' && (
                <Select
                  className="weekday"
                  name={`bulk-apt-${type}-weekday`}
                  label="Day"
                  options={daysOfWeek}
                  value={appointment['weekday']}
                  error={errors['weekday']}
                  onChange={(e) => {
                    e.preventDefault();
                  }}
                  required={true}
                  disabled={true}
                />
              )}
              {type == 'random' && (
                <DatePicker
                  className="date"
                  name={`bulk-booking-random-date`}
                  label={`Date`}
                  value={appointment['date']}
                  error={errors['date']}
                  required={true}
                  dateFormat={'MM/d/yy'}
                  onChange={() => {}}
                  disabled={true}
                />
              )}
              <Select
                className="start_time"
                name={`bulk-apt-${type}-start_time`}
                label="Start"
                options={startTimes}
                value={appointment['start_time']}
                error={errors['start_time']}
                onChange={(e) => {
                  e.preventDefault();
                }}
                required={true}
                disabled={true}
              />
              <Select
                className="end_time"
                name={`bulk-apt-${type}-end_time`}
                label="End"
                options={endTimes}
                value={appointment['end_time']}
                error={errors['end_time']}
                onChange={(e) => {
                  e.preventDefault();
                }}
                required={true}
                disabled={true}
              />
            </div>
            <div className="appointment-options">
              <div className="options">
                <div
                  className={`option flex ${
                    !!appointment.flex ? 'active' : ''
                  }`}
                >
                  <i className="fas fa-random"></i>
                  <h4>Wiggle</h4>
                </div>
                <div
                  className={`option overnight ${
                    !!appointment.overnight ? 'active' : ''
                  }`}
                >
                  <i className="fas fa-moon-stars"></i>
                  <h4>Overnight</h4>
                </div>
                <div
                  className={`option driving_needed ${
                    !!appointment.driving_needed ? 'active' : ''
                  }`}
                >
                  <i className="fas fa-car"></i>
                  <h4>Driving</h4>
                </div>
              </div>
              <button
                className="theme-btn small slate-blue"
                onClick={() => {
                  this.props.showEditAppointment(appointment);
                }}
              >
                Edit
              </button>
            </div>
          </div>
        ))}
      </div>
    );
  }
}

export default BulkAppointmentsList;
