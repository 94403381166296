import { get } from 'lodash-es';
import moment from 'moment-timezone';
import React from 'react';
import { Link } from 'react-router-dom';
export const DateTimeCell = ({ dataItem }) => {
    return (React.createElement("td", null,
        React.createElement(Link, { to: `/appointments/view/${dataItem.booking_appointment_id}`, className: "link" }, moment(dataItem.booking_appointment.start_time).format('M/D/YY h:mm a'))));
};
export const ProviderCell = (props) => {
    const { dataItem } = props;
    return (React.createElement("td", null,
        React.createElement(Link, { to: `/providers/edit/${dataItem.sitter_piid}`, className: "link" },
            dataItem.sitter.firstname,
            " ",
            dataItem.sitter.lastname)));
};
export const FirstTimeCell = (props) => {
    const { dataItem } = props;
    return React.createElement("td", null, dataItem.repeated_review === 1 ? 'No' : 'Yes');
};
export const AppointmentLinkCell = (props) => {
    const id = get(props.dataItem, props.field);
    return (React.createElement("td", { className: "manage-cell" },
        React.createElement(Link, { className: "theme-btn blue small", to: `/appointments/view/${id}` }, "Appt.")));
};
export const FamilyCell = ({ dataItem }) => {
    return (React.createElement("td", { className: "manage-cell" },
        React.createElement(Link, { className: "link", to: `/families/members/${dataItem.family_id}` }, dataItem.family.name)));
};
