import httpService from './httpService';
import { baseUrl, apiPrefix } from '../config';
const formatUrl = (endpoint) => {
    return `${baseUrl}${apiPrefix}${endpoint}`;
};
const login = (data) => {
    // data['email'], data['password'];
    return httpService.request('post', formatUrl('/login'), data);
};
const register = (data, type) => {
    return httpService.request('post', formatUrl(`/register/${type}`), data);
};
const refreshToken = async () => {
    return httpService.request('post', formatUrl('/refreshToken'));
};
const changePassword = (id, data) => {
    return httpService.request('post', formatUrl(`/password/update/${id}`), data);
};
const sendResetPassword = (data) => {
    return httpService.request('post', formatUrl('/password/email'), data);
};
const resetPassword = async (data) => {
    return httpService.request('post', formatUrl('/password/reset'), data);
};
const changeEmail = async (id, data) => {
    return httpService.request('post', formatUrl(`/email/update/${id}`), data);
};
const updateUser = async (endpoint, data, headers) => {
    return httpService.post(formatUrl(endpoint), data, headers);
};
const sendVerifyEmail = async (data = {}) => {
    return httpService.post(formatUrl('/verifyEmail/send'), data);
};
const updateUserStep = async (slug, userId) => {
    return httpService.post(`/api/step/update/${userId}`, { slug });
};
const getSubscription = async (userId) => {
    return httpService.get(formatUrl(`/subscription/${userId}`));
};
const logout = () => {
    //
};
export default {
    login,
    logout,
    register,
    refreshToken,
    updateUserStep,
    updateUser,
    changePassword,
    sendResetPassword,
    resetPassword,
    changeEmail,
    sendVerifyEmail,
    getSubscription,
};
