import { each } from 'lodash-es';
import moment from 'moment-timezone';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Avatar, Modal, SitterProfile } from '..';
import { createInterval, displayAddress, displayAge, displayAppointmentDate, displayAppointmentDuration, displayAppointmentTime, findFeeBySlug, mapAuthState, } from '@ss/helpers';
import { feeService, getUnavailableDatesTimesRequest, sitterService, } from '@ss/services';
import appointmentService from '@ss/services/appointmentService';
import { WarningText } from '../WarningText';
import AppointmentCancelWrapper from './AppointmentCancelWrapper';
import AppointmentCoverRequest from './AppointmentCoverRequest';
import AppointmentDetailsChange from './AppointmentDetailsChange';
import AppointmentTimeChangeRequest from './AppointmentTimeChangeRequest';
import ChildrenDetails from './ChildrenDetails';
import { areIntervalsOverlapping, parseISO } from 'date-fns';
import { AppointmentClaimSitConfirmation } from './AppointmentClaimSitConfirmation';
class AppointmentDetail extends React.Component {
    state = {
        currentChild: null,
        currentSitter: null,
        confirming: false,
        declining: false,
        cancelling: false,
        showingCancelAppointment: false,
        showingRequestCover: false,
        showingTimeChange: false,
        changingTime: false,
        showingCallInfoModal: false,
        respondingToAppointment: false,
        showingDetailsChange: false,
        fees: [],
        schedule: [],
        appointmentData: null,
        claimSitConfirmationActive: false,
        actionable: false,
    };
    componentDidMount() {
        this.getFeeData();
        this.getUnavailabilitySchedule();
        this.getAppointmentData();
    }
    componentDidUpdate(prevProps) {
        if (prevProps.id !== this.props.id) {
            this.getAppointmentData();
        }
    }
    getAppointmentData = async () => {
        const id = this.props.id;
        const response = await appointmentService.get(id);
        if (response.status === 200) {
            const appointmentData = response.data.data;
            this.setState({
                appointmentData,
            }, () => {
                const { id } = this.props.auth.user;
                let actionable = false;
                const { sitters } = appointmentData;
                sitters.map((sitter) => {
                    if (sitter.user_id === id) {
                        actionable = true;
                    }
                });
                this.setState({ actionable });
            });
        }
    };
    getUnavailabilitySchedule = async () => {
        if (this.props.type === 'sitter') {
            try {
                const data = await getUnavailableDatesTimesRequest();
                const schedule = data.map(({ start, end }) => ({
                    start: parseISO(start),
                    end: parseISO(end),
                }));
                this.setState({ schedule });
            }
            catch (error) {
                console.log(error);
            }
        }
    };
    getFeeData = async () => {
        const response = await feeService.get();
        if (response.status === 200) {
            const { fees } = response.data.data;
            this.setState({
                fees,
            });
        }
    };
    displayPets(pets) {
        console.log(pets);
        return pets
            .map((pet) => `${pet.firstname} (${pet.profile_pet.type})`)
            .join(', ');
    }
    acceptAppointment = async () => {
        this.setState({
            confirming: true,
        });
        try {
            const sitterPiid = this.props.auth.user.personal_info.id;
            const appointmentId = this.state.appointmentData.appointment.id;
            const response = await sitterService.confirmAppointment(sitterPiid, appointmentId);
            if (response.status === 200 && response.data.message === 'OK') {
                toast.success('Hooray! You have accepted this appointment!');
                this.setState({
                    confirming: false,
                }, this.refreshAppointments);
            }
            else if (response.status === 200) {
                toast.error('You do not have permission to accept this appointment.');
            }
        }
        catch (error) {
            console.log(error);
        }
    };
    refreshAppointments = () => {
        this.props.refreshAppointments(true);
        this.getAppointmentData();
    };
    rejectAppointment = async () => {
        this.setState({
            declining: true,
        });
        try {
            const sitterPiid = this.props.auth.user.personal_info.id;
            const apptId = this.state.appointmentData.appointment.id;
            const response = await sitterService.declineAppointment(sitterPiid, apptId);
            if (response.status === 200 && response.data.message === 'OK') {
                toast.success('This appointment request has been rejected.');
                this.setState({
                    declining: false,
                }, () => {
                    this.refreshAppointments();
                    this.closeDetail();
                });
            }
            else if (response.status === 200) {
                toast.error('You do not have permission to reject this appointment.');
            }
        }
        catch (error) {
            console.log(error);
        }
    };
    toggleTimeChange = () => {
        this.setState((prevState) => ({
            ...prevState,
            showingTimeChange: !prevState.showingTimeChange,
        }));
    };
    acceptTimeChange = async () => {
        this.setState({
            confirming: true,
        });
        try {
            const appointmentId = this.state.appointmentData.appointment.id;
            const response = await appointmentService.confirmTimeChange(appointmentId);
            if (response.status === 200) {
                this.refreshAppointments();
                toast.success('Hooray! You have confirmed the time change request! The Family has been notified.');
            }
        }
        catch (error) {
            console.log(error);
        }
    };
    rejectTimeChange = async () => {
        this.setState({
            declining: true,
        });
        try {
            const appointmentId = this.state.appointmentData.appointment.id;
            const response = await appointmentService.rejectTimeChange(appointmentId);
            if (response.status === 200) {
                this.refreshAppointments();
                toast.success('The time change request has been rejected. The Family has been notified.');
            }
        }
        catch (error) {
            console.log(error);
        }
    };
    getHourlyRate = () => {
        let fee;
        const { fees, appointmentData } = this.state;
        if (fees.length === 0) {
            // 12 is the single child rate and better to display the wrong thing than nothing at all, right?
            // this should only show while the fees are loading (and then once they're loaded it will update properly)
            return 12;
        }
        const { appointment, children } = appointmentData;
        const { date, start_time } = appointment;
        const startDateAndTime = moment(`${date} ${start_time}`, 'YYYY-MM-DD HH:mm');
        const weekday = startDateAndTime.format('d');
        const hour = startDateAndTime.format('H');
        if (Number(weekday) === 6 && Number(hour) >= 15) {
            fee = findFeeBySlug('surge_pricing', fees);
        }
        else {
            const numChildren = children.length;
            let childFeeSlug = 'one_child_per_hour';
            if (numChildren >= 2 && numChildren <= 3) {
                childFeeSlug = 'two_three_children_per_hour';
            }
            else if (numChildren > 3) {
                childFeeSlug = 'four_plus_children_per_hour';
            }
            fee = findFeeBySlug(childFeeSlug, fees);
        }
        return typeof fee.amount === 'string'
            ? Number.parseFloat(fee.amount)
            : fee.amount;
    };
    toggleCancelAppointment = () => {
        this.setState((prevState) => ({
            ...prevState,
            showingCancelAppointment: !prevState.showingCancelAppointment,
        }));
    };
    toggleRequestCover = () => {
        this.setState((prevState) => ({
            ...prevState,
            showingRequestCover: !prevState.showingRequestCover,
        }));
    };
    cancelAppointment = async () => {
        this.setState({
            cancelling: true,
        });
        try {
            const appointmentId = this.state.appointmentData.appointment.id;
            const response = await appointmentService.cancel(appointmentId);
            if (response.status === 200) {
                toast.success('Appointment has been cancelled.');
                this.setState({
                    cancelling: false,
                    showingCancelAppointment: false,
                }, () => {
                    this.closeDetail();
                });
            }
        }
        catch (error) {
            console.log(error);
        }
    };
    getChildAge(dob) {
        if (dob !== '0000-00-00') {
            const childDob = moment(dob, 'YYYY-MM-DD');
            const age = moment().diff(childDob, 'years');
            return `${age} Years Old`;
        }
        return 'Birthday TBD';
    }
    toggleHealthy = () => {
        alert('//@TODO');
    };
    toggleSick = () => {
        alert('//@TODO');
    };
    displayChildMedicalInfo(child) {
        const selectedConditions = [];
        each(child.medical_conditions, (condition) => {
            selectedConditions.push(condition.name);
        });
        if (selectedConditions.length > 1) {
            return selectedConditions.join(', ');
        }
        else if (selectedConditions.length === 1) {
            return selectedConditions[0];
        }
        return 'None';
    }
    setChild(child) {
        this.setState({
            currentChild: child,
        });
    }
    showSitter = (sitter) => {
        this.setState({ currentSitter: sitter });
    };
    closeSitter = () => {
        this.setState({ currentSitter: null });
    };
    onCloseBtnClick = (event) => {
        event.preventDefault();
        this.closeDetail();
    };
    closeDetail = () => {
        this.setChild(null);
        this.props.onRequestClose();
    };
    getHoursUntilAppointment = () => {
        if (this.state.appointmentData) {
            return moment
                .duration(moment(this.state.appointmentData.appointment.start_time).diff(moment()))
                .asHours();
        }
        return 0;
    };
    makeTelephoneNumber = (phoneNumber) => {
        // phone numbers can be in a few annoying formats:
        // 111-111-1111, or 1111111111.0 (because old system sucks), etc. so we parse it here
        if (!phoneNumber) {
            return '#';
        }
        phoneNumber = phoneNumber.replace(/\D/g, '');
        phoneNumber = `${Number.parseInt(phoneNumber, 10)}`;
        if (phoneNumber.length === 10) {
            phoneNumber = `1${phoneNumber}`;
        }
        return `tel:+${phoneNumber}`;
    };
    toggleCallInfoModal = () => {
        this.setState((prevState) => ({
            ...prevState,
            showingCallInfoModal: !prevState.showingCallInfoModal,
        }));
    };
    onCoverRequestSuccess = () => {
        this.setState({
            showingRequestCover: false,
        });
        toast.success('Your Sub Sitter Request has been submitted.');
        this.closeDetail();
    };
    acceptCoverRequest = () => {
        const { acceptCoverRequest, id } = this.props;
        this.setState({
            confirming: true,
            respondingToAppointment: true,
        }, () => {
            if (acceptCoverRequest) {
                acceptCoverRequest(id);
            }
        });
    };
    toggleDetailsChange = () => {
        this.setState((prevState) => ({
            ...prevState,
            showingDetailsChange: !prevState.showingDetailsChange,
        }));
    };
    render() {
        const { type, auth } = this.props;
        const { currentChild, confirming, declining, fees, appointmentData, showingCancelAppointment, showingRequestCover, currentSitter, showingTimeChange, schedule, showingCallInfoModal, showingDetailsChange, claimSitConfirmationActive, 
        // The sitter must have the ability to take an action on the appt (being assigned to it in some way)
        actionable, } = this.state;
        const respondingToAppointment = confirming || declining;
        const respondingToTimeChange = confirming || declining;
        const hoursUntilAppointment = this.getHoursUntilAppointment();
        const appointmentClasses = ['appointment-detail-container'];
        appointmentClasses.push(type);
        if (!appointmentData) {
            return React.createElement("div", { className: appointmentClasses.join(' ') }, "Loading...");
        }
        const isUnclaimedSit = type === 'sitter' &&
            ['pending-list', 'pending-list-approval'].includes(appointmentData.appointment.status);
        const isPendingApproval = isUnclaimedSit && !!appointmentData.appointment.pending_approval_flag;
        if (appointmentData) {
            if (type === 'sitter' &&
                appointmentData.appointment.status === 'time change requested') {
                appointmentClasses.push('with-time-change');
            }
            if (type === 'sitter' && !!actionable &&
                (appointmentData.appointment.status === 'submitted' ||
                    appointmentData.appointment.status === 'submitted-timed')) {
                appointmentClasses.push('with-appt-actions');
            }
            if (type === 'sitter' && isUnclaimedSit && !isPendingApproval) {
                appointmentClasses.push('with-appt-actions');
            }
        }
        const appointmentInterval = createInterval(appointmentData.appointment.start_time, appointmentData.appointment.end_time);
        const conflict = schedule.some((entry) => areIntervalsOverlapping(entry, appointmentInterval));
        return (React.createElement("div", { className: appointmentClasses.join(' ') },
            React.createElement("div", { className: "close-detail" },
                React.createElement("button", { type: "button", onClick: this.onCloseBtnClick, className: "plain-link" },
                    React.createElement("i", { className: "fas fa-arrow-right" }))),
            appointmentData && (React.createElement("div", { className: `appointment-detail-content ${currentChild ? 'child-open' : ''}` },
                React.createElement(Modal, { title: "Heads Up!", isOpen: showingCallInfoModal, closeModal: this.toggleCallInfoModal, content: `${type === 'family'
                        ? "Your sitter's"
                        : `The ${appointmentData.family.name} family's`} phone number will become available 1 hour before the appointment starts. Please check back later.`, secondaryButton: 'Close' }),
                type === 'sitter' && (React.createElement("div", { className: "appt-section family-info" },
                    React.createElement("div", { className: "family" },
                        React.createElement(Avatar, { imageSrc: appointmentData.family.avatar_filename, profileColor: appointmentData.owner.profile_color }),
                        React.createElement("h2", null,
                            appointmentData.family.name,
                            " Family"),
                        React.createElement("p", null,
                            React.createElement("i", { className: "fas fa-map-marker-alt" }),
                            ' ',
                            displayAddress(appointmentData.appointment, appointmentData.owner))))),
                React.createElement("div", { className: "appt-section appt-date" },
                    React.createElement("div", { className: "appt-section-inner" },
                        React.createElement("h4", null,
                            "APPOINTMENT #",
                            appointmentData.appointment.id),
                        React.createElement("h2", null, displayAppointmentDate(appointmentData.appointment)),
                        appointmentData.appointment.status ===
                            'time change requested' && (React.createElement("h3", null,
                            displayAppointmentTime(appointmentData.appointment, true),
                            ' ',
                            "(",
                            displayAppointmentDuration(appointmentData.appointment, true),
                            ") ",
                            React.createElement("i", { className: "far fa-plus-square" }))),
                        appointmentData.appointment.status !==
                            'time change requested' && (React.createElement("h3", null,
                            displayAppointmentTime(appointmentData.appointment),
                            " (",
                            displayAppointmentDuration(appointmentData.appointment),
                            ")",
                            ' ',
                            React.createElement("i", { className: "far fa-plus-square" }))),
                        type === 'family' && (React.createElement("div", { className: "appt-actions" },
                            (appointmentData.appointment.status === 'pending' ||
                                appointmentData.appointment.status === 'accepted') && (React.createElement("button", { className: "theme-btn white tall", onClick: this.toggleTimeChange },
                                React.createElement("i", { className: "fas fa-clock" }),
                                "\u00A0Request Time Change")),
                            appointmentData.appointment.status === 'accepted' && (React.createElement("button", { className: "theme-btn white tall", onClick: this.toggleDetailsChange },
                                React.createElement("i", { className: "fas fa-edit" }),
                                "\u00A0Change Details")),
                            appointmentData.appointment.status !== 'completed' &&
                                appointmentData.appointment.status !== 'cancelled' && (React.createElement("button", { className: "theme-btn white tall", onClick: this.toggleCancelAppointment },
                                React.createElement("i", { className: "far fa-times-circle" }),
                                "\u00A0Cancel")),
                            appointmentData.appointment.status === 'completed' &&
                                !!appointmentData.has_sit_log && (React.createElement(Link, { to: `/appointment/${appointmentData.appointment.id}/log`, className: "theme-btn slate-blue tall" }, "Sit Summary")),
                            !!showingCancelAppointment && (React.createElement(AppointmentCancelWrapper, { appointmentData: appointmentData, cancelAppointment: this.cancelAppointment, toggleCancelAppointment: this.toggleCancelAppointment, fees: fees })),
                            !!showingTimeChange && (React.createElement(AppointmentTimeChangeRequest, { appointmentData: appointmentData, toggleTimeChange: this.toggleTimeChange, refreshAppointments: this.refreshAppointments })),
                            !!showingDetailsChange && (React.createElement(AppointmentDetailsChange, { appointmentData: appointmentData, toggleDetailsChange: this.toggleDetailsChange, refreshAppointments: this.refreshAppointments })))),
                        type === 'sitter' &&
                            (appointmentData.appointment.status === 'accepted' ||
                                appointmentData.appointment.status === 'completed') &&
                            hoursUntilAppointment <= 1 && (React.createElement("div", { className: "appt-actions" },
                            React.createElement("a", { className: "theme-btn slate-blue tall", href: this.makeTelephoneNumber(appointmentData.owner.phone1), target: "_blank", rel: "noopener noreferrer" },
                                React.createElement("i", { className: "fas fa-mobile-alt" }),
                                "\u00A0",
                                appointmentData.owner.phone1))),
                        type === 'sitter' &&
                            (appointmentData.appointment.status === 'accepted' ||
                                appointmentData.appointment.status === 'completed') &&
                            hoursUntilAppointment > 1 && (React.createElement("div", { className: "appt-actions" },
                            React.createElement("button", { className: "theme-btn slate-blue tall disabled", onClick: this.toggleCallInfoModal },
                                React.createElement("i", { className: "fas fa-mobile-alt" }),
                                "\u00A0Call Member"))))),
                type === 'family' && (React.createElement("div", { className: "appt-section sitter-info" },
                    React.createElement("div", { className: "appt-section-inner" },
                        appointmentData.sitters.length > 0 && (React.createElement("div", { className: "sitters" }, appointmentData.sitters.map((sitter, idx) => (React.createElement("div", { className: "sitter", key: `sitter-${idx}` },
                            React.createElement(Avatar, { imageSrc: sitter.avatar_filename, profileColor: sitter.profile_color, className: "sitter-col" }),
                            React.createElement("div", { className: "sitter-col sitter-deets" },
                                React.createElement("h3", null,
                                    appointmentData.appointment.status ===
                                        'pending' ||
                                        appointmentData.appointment.status ===
                                            'submitted' ||
                                        appointmentData.appointment.status ===
                                            'submitted-timed'
                                        ? 'Pending'
                                        : 'Confirmed',
                                    ' ',
                                    "Sitter"),
                                React.createElement("h2", null, sitter.firstname),
                                React.createElement("p", { onClick: () => {
                                        this.showSitter(sitter);
                                    } },
                                    React.createElement("i", { className: "fas fa-address-card" }),
                                    " About",
                                    ' ',
                                    sitter.firstname)),
                            (appointmentData.appointment.status === 'accepted' ||
                                appointmentData.appointment.status ===
                                    'completed') &&
                                hoursUntilAppointment <= 1 && (React.createElement("div", { className: "sitter-col sitter-call" },
                                React.createElement("a", { className: "theme-btn blue tall", href: this.makeTelephoneNumber(sitter.phone1), target: "_blank", rel: "noopener noreferrer" },
                                    React.createElement("i", { className: "fas fa-mobile-alt" }),
                                    "\u00A0",
                                    sitter.phone1))),
                            (appointmentData.appointment.status === 'accepted' ||
                                appointmentData.appointment.status ===
                                    'completed') &&
                                hoursUntilAppointment > 1 && (React.createElement("div", { className: "sitter-col sitter-call" },
                                React.createElement("button", { className: "theme-btn blue tall disabled", onClick: this.toggleCallInfoModal },
                                    React.createElement("i", { className: "fas fa-mobile-alt" }),
                                    "\u00A0Call Sitter")))))))),
                        appointmentData.sitters.length === 0 && (React.createElement("div", { className: "sitters pending" },
                            React.createElement("h2", null, "Sitters Pending...")))),
                    currentSitter && (React.createElement("div", { className: "sitter-profile-modal" },
                        React.createElement("div", { className: "close-profile", onClick: this.closeSitter },
                            React.createElement("i", { className: "fas fa-times" })),
                        React.createElement("div", { className: "profile-wrapper" },
                            React.createElement(SitterProfile, { data: {
                                    personalInfo: currentSitter,
                                    profileSitter: currentSitter.profile_sitter,
                                }, omitActions: true })))))),
                type === 'sitter' && (React.createElement(React.Fragment, null,
                    React.createElement("div", { className: "appt-section child-info sitter-view" }, appointmentData.children.map((child, idx) => (React.createElement("div", { className: "child", key: `child-profile-${idx}` },
                        React.createElement("div", { className: "left cursor-pointer" },
                            React.createElement(Avatar, { imageSrc: child.avatar_filename, profileColor: child.profile_color })),
                        React.createElement("div", { className: "right" },
                            React.createElement("h3", null, child.firstname),
                            React.createElement("p", null, displayAge(child.DOB)),
                            React.createElement("p", null, child.profile_child.sick ? 'Sick' : 'Healthy'),
                            React.createElement("h4", { className: "child-desktop-profile" },
                                React.createElement("i", { className: "fas fa-child" }),
                                " About Me"),
                            React.createElement("p", { className: "child-desktop-profile" }, child.profile_child.about || 'None'),
                            React.createElement("h4", { className: "child-desktop-profile" },
                                React.createElement("i", { className: "fas fa-notes-medical" }),
                                " Allergies & Medical Notes"),
                            React.createElement("p", { className: "child-desktop-profile" },
                                this.displayChildMedicalInfo(child),
                                !!child.profile_child.allergy_info && (React.createElement(React.Fragment, null,
                                    React.createElement("br", null),
                                    child.profile_child.allergy_info))),
                            (appointmentData.appointment.status === 'accepted' || appointmentData.appointment.status === 'completed') && (React.createElement(React.Fragment, null,
                                React.createElement("h4", { className: "child-desktop-profile" },
                                    React.createElement("i", { className: "fas fa-user-md" }),
                                    " Pediatrician Info"),
                                React.createElement("p", { className: "child-desktop-profile" }, child.profile_child.pediatrician_info || 'None')))),
                        React.createElement("div", { className: "child-mobile-set" },
                            React.createElement("i", { className: "fas fa-expand-alt", onClick: () => {
                                    this.setChild(child);
                                } })))))),
                    currentChild && (React.createElement("div", { className: "child-mobile-profile" },
                        React.createElement("div", { className: "outer" },
                            React.createElement("div", { className: "inner" },
                                React.createElement("div", { className: "avatar" },
                                    React.createElement("div", { className: "avatar-wrapper" },
                                        React.createElement("img", { src: currentChild.avatar_filename }))),
                                React.createElement("h3", null, currentChild.firstname),
                                React.createElement("p", { className: "slate-blue" }, displayAge(currentChild.DOB)),
                                React.createElement("p", { className: "slate-blue" }, currentChild.profile_child.sick ? 'Sick' : 'Healthy'),
                                React.createElement("h4", null,
                                    React.createElement("i", { className: "fas fa-child" }),
                                    " About Me"),
                                React.createElement("p", null, currentChild.profile_child.about || 'None'),
                                React.createElement("h4", null,
                                    React.createElement("i", { className: "fas fa-notes-medical" }),
                                    " Allergies & Medical Notes"),
                                React.createElement("p", null,
                                    this.displayChildMedicalInfo(currentChild),
                                    !!currentChild.profile_child.allergy_info && (React.createElement(React.Fragment, null,
                                        React.createElement("br", null),
                                        currentChild.profile_child.allergy_info))),
                                (appointmentData.appointment.status === 'accepted' || appointmentData.appointment.status === 'completed') && (React.createElement(React.Fragment, null,
                                    React.createElement("h4", null,
                                        React.createElement("i", { className: "fas fa-user-md" }),
                                        " Pediatrician Info"),
                                    React.createElement("p", null, currentChild.profile_child.pediatrician_info || 'None'))),
                                React.createElement("br", null),
                                React.createElement("br", null))),
                        React.createElement("div", { className: "child-bg", onClick: () => {
                                this.setChild(null);
                            } }),
                        React.createElement("div", { className: "close-modal" },
                            React.createElement("i", { className: "fas fa-compress-alt", onClick: () => {
                                    this.setChild(null);
                                } })))))),
                React.createElement("div", { className: `appt-section appt-info ${type}` },
                    React.createElement("div", { className: "info-section" },
                        React.createElement("div", { className: "title" },
                            React.createElement("h2", null,
                                React.createElement("i", { className: "fas fa-info-circle" }),
                                " About the Sit")),
                        React.createElement("div", { className: "info" },
                            React.createElement("div", { className: "info-col active slate-blue" },
                                React.createElement("i", { className: "far fa-usd-circle" }),
                                React.createElement("h4", null, "Fee"),
                                React.createElement("p", null,
                                    "$",
                                    this.getHourlyRate(),
                                    "/hr")),
                            React.createElement("div", { className: appointmentData.appointment.overnight
                                    ? 'info-col active'
                                    : 'info-col' },
                                React.createElement("i", { className: "fas fa-moon-stars" }),
                                React.createElement("h4", null, "Overnight"),
                                React.createElement("p", null, !!appointmentData.appointment.overnight ? 'Yes' : 'No')),
                            React.createElement("div", { className: appointmentData.appointment.flex
                                    ? 'info-col active'
                                    : 'info-col' },
                                React.createElement("i", { className: "far fa-plus-square" }),
                                React.createElement("h4", null, "Wiggle Room"),
                                React.createElement("p", null, !!appointmentData.appointment.flex ? 'Yes' : 'No'),
                                !!appointmentData.appointment.flex && (React.createElement("p", { className: "info-amt" },
                                    "(",
                                    appointmentData.appointment.flex_room,
                                    " min)"))),
                            React.createElement("div", { className: appointmentData.appointment.driving_needed
                                    ? 'info-col active'
                                    : 'info-col' },
                                React.createElement("i", { className: "far fa-car" }),
                                React.createElement("h4", null, "Driving"),
                                React.createElement("p", null, appointmentData.appointment.driving_needed
                                    ? 'Yes'
                                    : 'No'),
                                React.createElement("p", { className: "info-amt" }),
                                !!appointmentData.appointment.driving_needed && (React.createElement("p", { className: "info-amt" },
                                    "(Est. ",
                                    appointmentData.appointment.driving_distance,
                                    ")"))),
                            React.createElement("div", { className: appointmentData.pets.length > 0
                                    ? 'info-col active'
                                    : 'info-col' },
                                React.createElement("i", { className: "fas fa-paw" }),
                                React.createElement("h4", null, "Pets"),
                                React.createElement("p", null, appointmentData.pets.length > 0
                                    ? this.displayPets(appointmentData.pets)
                                    : 'None'))),
                        type === 'family' && (React.createElement(React.Fragment, null,
                            React.createElement("div", { className: "title" },
                                React.createElement("h2", null,
                                    React.createElement("i", { className: "fas fa-info-circle" }),
                                    " Your Appointment Notes")),
                            React.createElement("div", { className: "notes" },
                                React.createElement("p", null, !!appointmentData.appointment.notes
                                    ? appointmentData.appointment.notes
                                    : 'None')),
                            !!appointmentData.family.house_rules && (React.createElement(React.Fragment, null,
                                React.createElement("br", null),
                                React.createElement("div", { className: "title" },
                                    React.createElement("h2", null,
                                        React.createElement("i", { className: "fas fa-info-circle" }),
                                        " House Rules")),
                                React.createElement("div", { className: "notes" },
                                    React.createElement("p", null, appointmentData.family.house_rules)))),
                            !!appointmentData.booking_appointment_communications && appointmentData.booking_appointment_communications.length > 0 && (React.createElement(React.Fragment, null,
                                React.createElement("br", null),
                                React.createElement("div", { className: "title" },
                                    React.createElement("h2", null,
                                        React.createElement("i", { className: "fas fa-info-circle" }),
                                        " Detail Change Messages")),
                                React.createElement("div", { className: "notes" }, appointmentData.booking_appointment_communications.map((comm) => (React.createElement("p", { key: `booking-comm-${comm.id}` }, comm.message))))))))),
                    type === 'sitter' && (React.createElement(React.Fragment, null,
                        React.createElement("div", { className: "info-section" },
                            React.createElement("div", { className: "title" },
                                React.createElement("h2", null,
                                    React.createElement("i", { className: "fas fa-info-circle" }),
                                    " Allergies & Medical Notes")),
                            React.createElement("div", { className: "medical" },
                                React.createElement("table", null,
                                    React.createElement("tbody", null, appointmentData.children.map((child, idx) => (React.createElement("tr", { key: `child-med-info-${idx}` },
                                        React.createElement("td", null,
                                            React.createElement("p", { className: "name" },
                                                child.firstname,
                                                ":")),
                                        React.createElement("td", null,
                                            React.createElement("p", null, this.displayChildMedicalInfo(child)))))))))),
                        React.createElement("div", { className: "info-section" },
                            React.createElement("div", { className: "title" },
                                React.createElement("h2", null,
                                    React.createElement("i", { className: "fas fa-info-circle" }),
                                    "\u00A0Notes From Family")),
                            React.createElement("div", { className: "notes" },
                                React.createElement("p", null, !!appointmentData.appointment.notes
                                    ? appointmentData.appointment.notes
                                    : 'None'))),
                        React.createElement("div", { className: "info-section" },
                            React.createElement("div", { className: "title" },
                                React.createElement("h2", null,
                                    React.createElement("i", { className: "fas fa-info-circle" }),
                                    "\u00A0Notes From Admin")),
                            React.createElement("div", { className: "notes" },
                                React.createElement("p", null, !!appointmentData.appointment.sitter_notes
                                    ? appointmentData.appointment.sitter_notes
                                    : 'None'))),
                        !!appointmentData.family.admin_family_notes && (React.createElement("div", { className: "info-section" },
                            React.createElement("div", { className: "title" },
                                React.createElement("h2", null,
                                    React.createElement("i", { className: "fas fa-info-circle" }),
                                    "\u00A0Notes from the SmartSitter Team")),
                            React.createElement("div", { className: "notes" },
                                React.createElement("p", null, !!appointmentData.family.admin_family_notes
                                    ? appointmentData.family.admin_family_notes
                                    : 'None')))),
                        !!appointmentData.family.house_rules && (React.createElement("div", { className: "info-section" },
                            React.createElement("div", { className: "title" },
                                React.createElement("h2", null,
                                    React.createElement("i", { className: "fas fa-info-circle" }),
                                    " House Rules")),
                            React.createElement("div", { className: "notes" },
                                React.createElement("p", null, appointmentData.family.house_rules)))),
                        !!appointmentData.booking_appointment_communications && appointmentData.booking_appointment_communications.length > 0 && (React.createElement("div", { className: "info-section" },
                            React.createElement("div", { className: "title" },
                                React.createElement("h2", null,
                                    React.createElement("i", { className: "fas fa-info-circle" }),
                                    " Detail Change Messages")),
                            React.createElement("div", { className: "notes" }, appointmentData.booking_appointment_communications.map((comm) => (React.createElement("p", { key: `booking-comm-${comm.id}` }, comm.message)))))))),
                    type === 'sitter' &&
                        appointmentData.appointment.status === 'accepted' && (React.createElement("div", { className: "appt-actions" },
                        React.createElement("button", { className: "theme-btn color-favorite tall", onClick: this.toggleRequestCover },
                            React.createElement("i", { className: "fas fa-user-circle" }),
                            "\u00A0Request Sub Sitter"),
                        React.createElement(AppointmentCoverRequest, { isOpen: showingRequestCover, sitterPiid: auth.user.personal_info.id, apptId: appointmentData.appointment.id, closeModal: this.toggleRequestCover, hoursUntilAppt: hoursUntilAppointment, onCoverRequestSuccess: this.onCoverRequestSuccess }))),
                    type === 'sitter' &&
                        appointmentData.appointment.status === 'completed' &&
                        !appointmentData.has_sit_log && (React.createElement("div", { className: "appt-actions" },
                        React.createElement(Link, { to: `/appointment/${appointmentData.appointment.id}/log`, className: "theme-btn slate-blue tall" }, "Enter Sit Log")))),
                type === 'family' && (React.createElement(ChildrenDetails, { familyChildren: appointmentData.children, toggleSick: this.toggleSick, toggleHealthy: this.toggleHealthy })),
                type === 'sitter' &&
                    appointmentData.appointment.status ===
                        'time change requested' && (React.createElement("div", { className: "appt-detail-actions time-change-requested" },
                    React.createElement("h2", { className: "theme-h2" }, "Time Change Request"),
                    React.createElement("p", null,
                        "Original:",
                        ' ',
                        displayAppointmentTime(appointmentData.appointment),
                        " (",
                        displayAppointmentDuration(appointmentData.appointment),
                        ")"),
                    React.createElement("p", null,
                        React.createElement("b", null,
                            "New:",
                            ' ',
                            displayAppointmentTime(appointmentData.appointment, true),
                            ' ',
                            "(",
                            displayAppointmentDuration(appointmentData.appointment, true),
                            ")")),
                    React.createElement("div", { className: "btn-wrapper" },
                        React.createElement("button", { className: "theme-btn primary-green", onClick: this.acceptTimeChange, disabled: respondingToTimeChange },
                            !!confirming && (React.createElement("span", null,
                                React.createElement("i", { className: "fas fa-spinner fa-spin" }),
                                "\u00A0Accepting...")),
                            !confirming && (React.createElement("span", null,
                                React.createElement("i", { className: "fas fa-check-circle" }),
                                "\u00A0Accept"))),
                        React.createElement("button", { className: "theme-btn error-red", onClick: this.rejectTimeChange, disabled: respondingToTimeChange },
                            !!declining && (React.createElement("span", null,
                                React.createElement("i", { className: "fas fa-spinner fa-spin" }),
                                "\u00A0Declining...")),
                            !declining && (React.createElement("span", null,
                                React.createElement("i", { className: "fas fa-times-circle" }),
                                "\u00A0Decline")))))),
                type === 'sitter' && !!actionable &&
                    (appointmentData.appointment.status === 'submitted' ||
                        appointmentData.appointment.status === 'submitted-timed') && (React.createElement("div", { className: "appt-detail-actions sitter-appt-actions" },
                    React.createElement("div", { className: "btn-wrapper" },
                        React.createElement("button", { className: "theme-btn primary-green", onClick: this.acceptAppointment, disabled: respondingToAppointment },
                            !!confirming && (React.createElement("span", null,
                                React.createElement("i", { className: "fas fa-spinner fa-spin" }),
                                "\u00A0Accepting...")),
                            !confirming && (React.createElement("span", null,
                                React.createElement("i", { className: "fas fa-check-circle" }),
                                "\u00A0Accept"))),
                        React.createElement("button", { className: "theme-btn error-red", onClick: this.rejectAppointment, disabled: respondingToAppointment },
                            !!declining && (React.createElement("span", null,
                                React.createElement("i", { className: "fas fa-spinner fa-spin" }),
                                "\u00A0Declining...")),
                            !declining && (React.createElement("span", null,
                                React.createElement("i", { className: "fas fa-times-circle" }),
                                "\u00A0Decline")))))),
                type === 'sitter' &&
                    appointmentData.appointment.status === 'pending-cover' && (React.createElement("div", { className: "appt-detail-actions sitter-appt-actions" },
                    React.createElement("div", { className: "btn-wrapper" },
                        React.createElement("button", { className: "theme-btn primary-green", onClick: this.acceptCoverRequest, disabled: respondingToAppointment },
                            confirming && (React.createElement("span", null,
                                React.createElement("i", { className: "fas fa-spinner fa-spin" }),
                                "\u00A0Signing Up...")),
                            !confirming && (React.createElement("span", null,
                                React.createElement("i", { className: "fas fa-check-circle" }),
                                "\u00A0Sign Up")))),
                    false && (React.createElement(WarningText, null, "This appointment is still pending. YOU HAVE ANOTHER BOOKING AT THIS TIME. Please wait for admin confirmation.")))),
                isUnclaimedSit && !isPendingApproval && (React.createElement("div", { className: "appt-detail-actions sitter-appt-actions" },
                    React.createElement("div", { className: "btn-wrapper" },
                        React.createElement("button", { className: "theme-btn primary-green", onClick: () => this.setState({ claimSitConfirmationActive: true }) },
                            React.createElement("span", null,
                                React.createElement("i", { className: "fas fa-check" }),
                                "\u00A0Claim Sit"))),
                    conflict && (React.createElement(WarningText, null, "You have another booking at this time")))),
                isUnclaimedSit && isPendingApproval && (React.createElement("div", { className: "appt-detail-actions sitter-appt-actions" },
                    React.createElement(WarningText, null,
                        "This appointment is still pending.",
                        ' ',
                        conflict && 'You have another booking at this time.',
                        " Please wait for admin confirmation."))))),
            React.createElement(AppointmentClaimSitConfirmation, { isOpen: claimSitConfirmationActive, onRequestClose: () => this.setState({ claimSitConfirmationActive: false }), onSuccess: this.getAppointmentData, conflict: conflict, sitterPiid: auth.user.personal_info.id, appointmentId: appointmentData.appointment.id })));
    }
}
export default connect(mapAuthState)(AppointmentDetail);
