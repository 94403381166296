import React from 'react';
const Checkbox = (props) => {
    const { option, value, index, name, onChange } = props;
    const htmlId = `${name}-${index}`;
    const checked = value && value.includes(String(option.value));
    return (React.createElement("div", { className: "checkbox-group" },
        React.createElement("input", { checked: checked, value: option.value, id: htmlId, type: "checkbox", className: checked ? 'active' : 'inactive', name: name, onChange: onChange }),
        React.createElement("label", { htmlFor: htmlId },
            React.createElement("span", null,
                React.createElement("div", { className: "checkbox" }, checked && React.createElement("i", { className: "far fa-check" })),
                option.label))));
};
const CheckboxGroup = (props) => {
    const { name, label, options, required, error } = props;
    const classNames = ['form-field', name, 'checkbox'];
    return (React.createElement("div", { className: classNames.join(' ') },
        React.createElement("label", { className: "", htmlFor: name },
            label,
            required && React.createElement("span", { className: "required" }, "\u00A0*")),
        options.map((option, index) => (React.createElement(Checkbox, { ...props, option: option, key: index, index: index }))),
        error && (React.createElement("span", { className: "error", role: "alert" },
            React.createElement("strong", null, error)))));
};
export default CheckboxGroup;
