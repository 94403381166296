export default [
    {
        name: '',
        id: null,
    },
    {
        name: 'Sundays',
        id: 0,
    },
    {
        name: 'Mondays',
        id: 1,
    },
    {
        name: 'Tuesdays',
        id: 2,
    },
    {
        name: 'Wednesdays',
        id: 3,
    },
    {
        name: 'Thursdays',
        id: 4,
    },
    {
        name: 'Fridays',
        id: 5,
    },
    {
        name: 'Saturdays',
        id: 6,
    },
];
