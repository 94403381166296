import $ from 'jquery';
import moment from 'moment-timezone';
import React from 'react';
import { connect } from 'react-redux';
import { booleanRadioOptions, states } from '@ss/data';
import { displayAddress } from '@ss/helpers';
import BooleanInput from '@ss/components/shared/Form/BooleanInput';
import ChildCheckbox from '@ss/components/shared/Form/ChildCheckbox';
import Input from '@ss/components/shared/Form/Input';
import Radio from '@ss/components/shared/Form/Radio';
import Select from '@ss/components/shared/Form/Select';
import Textarea from '@ss/components/shared/Form/Textarea';
import { mapAuthState } from '../../../../helpers/mapAuthState';

class BookingDetailsRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showDrivingDistance: false,
      addressConfirmed: false,
      editingAddress: false,
      errors: {},
    };
  }
  componentDidMount() {
    this.syncPersonalInfo();
    this.scrollTop();
  }
  componentDidUpdate(prevProps, prevState) {
    const prevAppt = prevProps.appointment;
    const { appointment } = this.props;

    // If driving is true, show the driving_distance field
    if (
      appointment['driving_needed'] === 1 &&
      prevAppt['driving_needed'] !== appointment['driving_needed']
    ) {
      this.setState({
        showDrivingDistance: true,
      });
    } else if (
      appointment['driving_needed'] === 0 &&
      prevAppt['driving_needed'] !== appointment['driving_needed']
    ) {
      this.setState({
        showDrivingDistance: false,
      });
    }
  }
  scrollTop = () => {
    $('main').animate({ scrollTop: 0 }, 0);
  };
  syncPersonalInfo = () => {
    const { idx, auth } = this.props;
    const { personal_info } = auth.user;
    const { address1, address2 = '', city, state, zip } = personal_info;
    this.props.handleBulkApptChange(idx, {
      address1,
      address2: !!address2 ? address2 : '',
      city,
      state,
      zip,
    });
  };
  toggleEditAddress = (e) => {
    e.preventDefault();
    this.setState({
      editingAddress: !this.state.editingAddress,
    });
  };
  confirmAddress = () => {
    this.setState({
      addressConfirmed: !this.state.addressConfirmed,
    });
  };
  handleChildCheckboxChange = ({ currentTarget: input }) => {
    let children = [...this.props.appointment.selected_children];
    if (input.checked) {
      children.push(input.value);
    } else {
      //remove from array
      children = children.filter(function (item) {
        return item !== input.value;
      });
    }
    const { idx } = this.props;
    if (!children) children = [];
    this.props.handleApptChange(idx, 'selected_children', children);
  };
  isAppointmentCompleted = () => {
    const { appointment } = this.props;
    const { addressConfirmed, editingAddress } = this.state;

    if (!addressConfirmed) {
      if (editingAddress) {
        if (
          !appointment.address1 ||
          !appointment.city ||
          !appointment.state ||
          !appointment.zip
        ) {
          return false;
        }
      } else {
        return false;
      }
    }
    if (appointment.driving_needed === null) {
      return false;
    }
    if (!!appointment.driving_needed && !appointment.driving_distance) {
      return false;
    }
    if (!appointment.notes) {
      return false;
    }
    if (appointment.selected_children.length === 0) {
      return false;
    }
    return true;
  };
  render() {
    const {
      children,
      sitters,
      personalSitters,
      idx,
      appointment,
      numAppointments,
    } = this.props;
    const {
      errors,
      showDrivingDistance,
      editingAddress,
      addressConfirmed,
      selectingSitter,
      currentSitter,
      numSitters,
      sitterForAppt,
    } = this.state;
    let canAdvanceStep = this.isAppointmentCompleted();
    return (
      <div className="booking-details-row">
        <h2>Details for Appointment #{idx + 1}</h2>
        <div className="booking-info">
          {!!appointment.overnight && !!appointment.num_nights && (
            <div className="date">
              <h3>
                {moment(appointment.date).format('MMM D')}
                &nbsp;-&nbsp;
                <span>
                  {moment(appointment.date)
                    .add(appointment.num_nights, 'days')
                    .format('MMM D')}
                </span>
              </h3>
              <h3 className="weekday">
                {moment(appointment.date).format('ddd')}
                &nbsp;-&nbsp;
                <span>
                  {moment(appointment.date)
                    .add(appointment.num_nights, 'days')
                    .format('ddd')}
                </span>
              </h3>
            </div>
          )}
          {(!appointment.overnight || !appointment.num_nights) && (
            <div className="date">
              <h3>{moment(appointment.date).format('MMM D')}</h3>
              <h3 className="weekday">
                {moment(appointment.date).format('dddd')}
              </h3>
            </div>
          )}
          <div className="time">
            <p>
              {moment(appointment.start_time, 'HH:mm').format('h:mm a')}
              &nbsp;-&nbsp;
              {moment(appointment.end_time, 'HH:mm').format('h:mm a')}
            </p>
            {!!appointment.flex && (
              <p className="wiggle">
                ({appointment.flex_room} min Wiggle Room)
              </p>
            )}
          </div>
          <div
            className="remove"
            onClick={() => {
              this.props.removeAppointment(idx);
            }}
          >
            <button className="theme-btn small error-red">
              <i className="fas fa-trash-alt"></i>&nbsp;Remove
            </button>
          </div>
        </div>
        <div className="booking-details-form">
          <p>
            <b>Sit Details:</b>
          </p>
          {editingAddress && (
            <div className="form-group address">
              <Input
                className="address1"
                name={`apt-${idx}-address1`}
                label="Address"
                type="text"
                value={appointment['address1']}
                error={errors['address1']}
                onChange={(e) => {
                  this.props.handleApptChange(idx, 'address1', e.target.value);
                }}
                required={true}
              />
              <Input
                className="address2"
                name={`apt-${idx}-address2`}
                label="Apt/Suite"
                type="text"
                value={appointment['address2']}
                error={errors['address2']}
                onChange={(e) => {
                  this.props.handleApptChange(idx, 'address2', e.target.value);
                }}
                required={false}
              />
              <Input
                className="city"
                name={`apt-${idx}-city`}
                label="City"
                type="text"
                value={appointment['city']}
                error={errors['city']}
                onChange={(e) => {
                  this.props.handleApptChange(idx, 'city', e.target.value);
                }}
                required={true}
              />
              <Select
                className="state"
                name={`apt-${idx}-state`}
                label="State"
                options={states}
                value={appointment['state']}
                error={errors['state']}
                onChange={(e) => {
                  this.props.handleApptChange(idx, 'state', e.target.value);
                }}
                required={true}
              />
              <Input
                className="zip"
                name={`apt-${idx}-zip`}
                label="ZIP"
                type="text"
                value={appointment['zip']}
                error={errors['zip']}
                onChange={(e) => {
                  this.props.handleApptChange(idx, 'zip', e.target.value);
                }}
                required={true}
              />
            </div>
          )}
          {!editingAddress && (
            <div className="form-group address">
              <Input
                className="address"
                name={`apt-${idx}-address`}
                label="Address"
                type="text"
                value={displayAddress(appointment)}
                error={errors['driving_distance']}
                onChange={(e) => e.preventDefault()}
                required={true}
                disabled={true}
              />
              <div className="confirm-address">
                <BooleanInput
                  className="confirm_address"
                  name={`apt-${idx}-confirm_address`}
                  label="Confirm Address"
                  type="text"
                  value={addressConfirmed}
                  error={errors['driving_distance']}
                  onChange={this.confirmAddress}
                />
                <a href="#" onClick={this.toggleEditAddress}>
                  Edit Address
                </a>
              </div>
            </div>
          )}
          <div className="form-group">
            <Radio
              className="driving_needed"
              name={`apt-${idx}-driving_needed`}
              label="Will this appointment require driving kids?"
              options={booleanRadioOptions}
              value={appointment['driving_needed']}
              error={errors['driving_needed']}
              onChange={(e) => {
                this.props.handleApptChange(
                  idx,
                  'driving_needed',
                  e.target.value,
                  'number'
                );
              }}
              required={true}
            />
            {!!showDrivingDistance && (
              <Input
                className="driving_distance"
                name={`apt-${idx}-driving_distance`}
                label="Approx. how many miles required?"
                type="number"
                value={appointment['driving_distance']}
                error={errors['driving_distance']}
                onChange={(e) => {
                  this.props.handleApptChange(
                    idx,
                    'driving_distance',
                    e.target.value
                  );
                }}
                required={true}
              />
            )}
          </div>
          <Textarea
            className="notes"
            name={`apt-${idx}-notes`}
            label="Notes for this appointment"
            value={appointment['notes']}
            error={errors['notes']}
            onChange={(e) => {
              this.props.handleApptChange(idx, 'notes', e.target.value);
            }}
            required={true}
          />
          <ChildCheckbox
            className="selected_children"
            name={`apt-${idx}-selected_children`}
            label="Select kids that need babysitting for this appointment"
            options={children}
            value={appointment['selected_children']}
            error={errors['selected_children']}
            onChange={this.handleChildCheckboxChange}
            required={true}
          />
        </div>
        <div className="booking-footer">
          {idx == numAppointments - 1 && canAdvanceStep && (
            <button
              className={`theme-btn blue`}
              onClick={this.props.onFormComplete}
            >
              Next Step - Select Sitters
            </button>
          )}
          {idx == numAppointments - 1 && !canAdvanceStep && (
            <button
              className={`theme-btn blue disabled`}
              onClick={(e) => e.preventDefault()}
            >
              Next Step - Select Sitters
            </button>
          )}
          {idx < numAppointments - 1 && canAdvanceStep && (
            <button
              className={`theme-btn primary-green`}
              onClick={this.props.nextAppointment}
            >
              <i className="far fa-plus-circle"></i>&nbsp;Add Details for Sit #
              {idx + 2}
            </button>
          )}
          {idx < numAppointments - 1 && !canAdvanceStep && (
            <button
              className={`theme-btn primary-green disabled`}
              onClick={(e) => e.preventDefault()}
            >
              <i className="far fa-plus-circle"></i>&nbsp;Add Details for Sit #
              {idx + 2}
            </button>
          )}
        </div>
      </div>
    );
  }
}

export default connect(mapAuthState)(BookingDetailsRow);
