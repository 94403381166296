import { process } from '@progress/kendo-data-query';
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import React from 'react';
import { registerSteps } from '../../../data';
import { findLinkGroupByTitle } from '../../../helpers';
import sitterService from '../../../services/sitterService';
import { Modal } from '../../shared';
import Sidebar from '../Sidebar';
import {
  AppStatusCell,
  ColumnMenu,
  DateCell,
  ManageCell,
} from './KendoComponents';

const createDataState = (dataState) => {
  return {
    result: process(products.slice(0), dataState),
    dataState: dataState,
  };
};

class ApplicationAcceptanceListing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sitters: [],
      result: [],
      loading: true,
      linkGroup: findLinkGroupByTitle('Providers'),
      dataState: {
        sort: [{ field: 'created_at', dir: 'asc' }],
        filter: {
          logic: 'and',
          filters: [],
        },
        filterOperators: {
          text: [
            {
              text: 'grid.filterContainsOperator',
              operator: 'contains',
            },
            { text: 'grid.filterEqOperator', operator: 'eq' },
          ],
        },
        skip: 0,
        take: 50,
        total: 0,
      },
      showStatusLegend: false,
    };
  }
  componentDidMount = () => {
    this.getProviders();
  };
  getProviders = async () => {
    try {
      const { dataState } = this.state;
      const response = await sitterService.getPending({
        skip: dataState.skip,
        take: dataState.take,
        sort: dataState.sort,
        filter: dataState.filter,
      });
      if (response.status === 200) {
        const { sitters, count } = response.data.data;
        this.setState({
          result: sitters,
          dataState: {
            ...dataState,
            total: count,
          },
          sitters,
          loading: false,
        });
      }
    } catch (e) {
      console.log(e);
      this.setState({
        loading: false,
      });
    }
  };
  pageChange = (event) => {
    this.setState(
      {
        dataState: {
          ...this.state.dataState,
          skip: event.page.skip,
          take: event.page.take,
        },
      },
      () => {
        this.getProviders();
      }
    );
  };
  sortChange = (event) => {
    this.setState(
      {
        dataState: {
          ...this.state.dataState,
          sort: event.sort,
        },
      },
      () => {
        this.getProviders();
      }
    );
  };
  filterChange = (event) => {
    this.setState(
      {
        dataState: {
          ...this.state.dataState,
          filter: event.filter,
        },
      },
      () => {
        this.getProviders();
      }
    );
  };
  handleDataStateChange = (e) => {
    let dataState = e.data;
    this.setState({
      result: process([...this.state.sitters].slice(0), dataState),
      dataState: dataState,
    });
  };
  handleRowClick = (e) => {
    console.log(e);
    const { id } = e.dataItem.personal_info;
    this.props.history.push(`/providers/applications/${id}`);
  };
  toggleStatusLegend = () => {
    this.setState({ showStatusLegend: !this.state.showStatusLegend });
  };
  render() {
    const { loading, sitters, linkGroup, dataState, result, showStatusLegend } =
      this.state;

    if (!!loading) {
      return (
        <main className="admin admin-listing with-sidebar providers application-acceptance">
          <div className="sidebar-wrapper">
            <Sidebar
              {...this.props}
              iconClass="fas fa-id-card"
              linkGroup={linkGroup}
            />
          </div>
          <div className="content">
            <h4 className="section-label">
              <i className="fas fa-address-card"></i>&nbsp;Loading Providers...
            </h4>
          </div>
        </main>
      );
    }

    return (
      <main className="admin admin-listing with-sidebar providers application-acceptance">
        <div className="sidebar-wrapper">
          <Sidebar
            {...this.props}
            iconClass="fas fa-id-card"
            linkGroup={linkGroup}
          />
        </div>
        <div className="content">
          <h4 className="section-label">
            <i className="fas fa-address-card"></i>&nbsp;Pending Provider
            Applications
          </h4>
          <div className="filter-btn-wrapper">
            <button
              className="theme-btn small slate-blue"
              onClick={this.toggleStatusLegend}
            >
              Show Status Legend
            </button>
          </div>
          <Modal
            title="Status Legend"
            isOpen={showStatusLegend}
            closeModal={this.toggleStatusLegend}
            content={
              <div className="status-legend">
                <p>
                  The Provider's Status shows their{' '}
                  <em>most recent completed Onboarding step</em>. For example,
                  if someone's status reads "Availability", it means they have
                  completed the Availability and are on the next step
                  (Onboarding meeting).
                </p>
                <p>
                  If you would like to filter Providers by their Onboarding
                  Step, you must use the ID of the step (as opposed to the
                  name). Below are the IDs associated with each step for handy
                  reference.
                </p>
                {registerSteps.map((step, idx) => {
                  if (step.slug.indexOf('sitter-') > -1) {
                    return (
                      <p key={`onboarding-step-${step.id}`}>
                        {step.id}: {step.step.replace('Sitter ', '')}
                      </p>
                    );
                  }
                })}
              </div>
            }
            primaryButton={'Close'}
            handleClick={this.toggleStatusLegend}
          />
          <div className="kendo-table">
            <Grid
              data={result}
              pageable={true}
              sortable={true}
              {...dataState}
              onPageChange={this.pageChange}
              onSortChange={this.sortChange}
              onFilterChange={this.filterChange}
              onRowClick={this.handleRowClick}
            >
              <GridColumn title="ID" field="personal_info.id" width={80} />
              <GridColumn
                title="First Name"
                field="personal_info.firstname"
                filter={'text'}
                columnMenu={ColumnMenu}
              />
              <GridColumn
                title="Last Name"
                field="personal_info.lastname"
                filter={'text'}
                columnMenu={ColumnMenu}
              />
              <GridColumn
                title="Step"
                field="personal_info.user.step"
                cell={AppStatusCell}
                filter={'text'}
                columnMenu={ColumnMenu}
              />
              <GridColumn
                title="Submitted On"
                field="created_at"
                cell={DateCell}
                width={125}
              />
              <GridColumn
                title=" "
                field="personal_info.id"
                width={100}
                cell={(props) => <ManageCell {...props} {...this.props} />}
              />
            </Grid>
          </div>
        </div>
      </main>
    );
  }
}

export default ApplicationAcceptanceListing;
