export const authConstants = {
    REGISTER_REQUEST: 'USERS_REGISTER_REQUEST',
    REGISTER_SUCCESS: 'USERS_REGISTER_SUCCESS',
    REGISTER_FAILURE: 'USERS_REGISTER_FAILURE',
    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',
    LOGOUT_REQUEST: 'USERS_LOGOUT_REQUEST',
    LOGOUT_SUCCESS: 'USERS_LOGOUT_SUCCESS',
    LOGOUT_FAILURE: 'USERS_LOGOUT_FAILURE',
    TOKEN_REFRESH_REQUEST: 'USERS_TOKEN_REFRESH_REQUEST',
    TOKEN_REFRESH_SUCCESS: 'USERS_TOKEN_REFRESH_SUCCESS',
    TOKEN_REFRESH_FAILURE: 'USERS_TOKEN_REFRESH_FAILURE',
    UPDATE_REQUEST: 'USERS_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'USERS_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'USERS_UPDATE_FAILURE',
    PASSWORD_CHANGE_REQUEST: 'USERS_PASSWORD_CHANGE_REQUEST',
    PASSWORD_CHANGE_SUCCESS: 'USERS_PASSWORD_CHANGE_SUCCESS',
    PASSWORD_CHANGE_FAILURE: 'USERS_PASSWORD_CHANGE_FAILURE',
};
