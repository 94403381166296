import React from 'react';
import { slugify } from '../../../helpers';
const ActivitiesSelector = ({ name, label, options, required, value = [], error, ...rest }) => {
    const classNames = ['form-field', name, 'activities-selector'];
    return (React.createElement("div", { className: classNames.join(' ') },
        React.createElement("label", { className: "title", htmlFor: name },
            label,
            required && React.createElement("span", { className: "required" }, "\u00A0*")),
        React.createElement("div", { className: "checkbox-group-wrapper" }, options.map((option, idx) => {
            const randId = Math.round(Math.random() * 100000);
            const optId = `${name}-${idx}-${randId}`;
            const optClassName = value.includes(String(option.id))
                ? 'active'
                : 'inactive';
            return (React.createElement("div", { className: `checkbox-group ${optClassName}`, key: `${name}-opt-${idx}` },
                value.includes(String(option.id)) && (React.createElement("input", { ...rest, checked: true, value: option.id, name: name, id: optId, type: "checkbox", className: optClassName })),
                value.includes(String(option.id)) === false && (React.createElement("input", { ...rest, value: option.id, name: name, id: optId, type: "checkbox", className: optClassName })),
                React.createElement("label", { className: "sitter-activity-tile", key: `sitter-activity-${option.id}`, htmlFor: optId },
                    React.createElement("div", { className: "outer" },
                        React.createElement("div", { className: `tile ${slugify(option.name)}` },
                            React.createElement("p", null, option.name))))));
        })),
        error && (React.createElement("span", { className: "error", role: "alert" },
            React.createElement("strong", null, error)))));
};
export default ActivitiesSelector;
