import { balanceConstants } from '../constants/balanceConstants';
const initialState = {
    transactions: [],
    balance: '0.00',
};
export function balance(state = initialState, action) {
    switch (action.type) {
        case balanceConstants.BALANCE_SUCCESS:
            return {
                ...state,
                balance: action.balance,
            };
        case balanceConstants.TRANSACTIONS_SUCCESS:
            return {
                balance: action.balance,
                transactions: action.transactions,
            };
        case balanceConstants.BALANCE_FAILURE:
        case balanceConstants.TRANSACTIONS_FAILURE:
            return {
                ...initialState,
            };
        case balanceConstants.BALANCE_REQUEST:
        case balanceConstants.TRANSACTIONS_REQUEST:
        default:
            return {
                ...state,
            };
    }
}
