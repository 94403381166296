export default {
    rid: null,
    start_time: '',
    end_time: '',
    flex: 0,
    flex_room: null,
    overnight: 0,
    num_nights: null,
    driving_needed: 0,
    driving_distance: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
    requested_provider: '',
    selected_sitters: [],
    selected_children: [],
};
