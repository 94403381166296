import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import familyService from '../../../services/familyService';
import {
  displayAge,
  displayProfileColor,
  upperCaseFirst,
} from '../../../helpers';
import { mapAuthState } from '../../../helpers/mapAuthState';

class FamilyProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      children: [],
      pets: [],
      details: {},
      loading: true,
    };
  }
  componentDidMount = () => {
    this.getFamilyDetails();
  };
  getFamilyDetails = async () => {
    try {
      const { family_id } = this.props.auth.user.personal_info;
      const response = await familyService.get(family_id);
      console.log(response);
      if (response.status === 200) {
        const { pets, children, profileFamily } = response.data.data;
        this.setState({
          pets,
          children,
          details: profileFamily,
          loading: false,
        });
      }
    } catch (e) {
      console.log(e);
    }
  };
  profileColor = () => {
    const { profile_color } = this.props.auth.user.personal_info;
  };
  render = () => {
    const { user } = this.props.auth;
    const { pets, details, children, loading } = this.state;

    return (
      <main className="account profile family">
        <div
          className={`profile-header ${displayProfileColor(
            user.personal_info.profile_color
          )}`}
        >
          <div className="inner">
            <div className="avatar">
              <div className="avatar-wrapper">
                {!!user.personal_info.avatar_filename && (
                  <img src={user.personal_info.avatar_filename} />
                )}
                {!user.personal_info.avatar_filename && (
                  <i className="far fa-camera"></i>
                )}
              </div>
            </div>
            <h2 className="theme-h2">
              {!!details.name ? upperCaseFirst(details.name) : ''} Family
            </h2>
            <Link to={`/settings/account`} className="theme-btn white">
              <i className="far fa-pen-square"></i>&nbsp;Edit
            </Link>
          </div>
        </div>
        <div className="profile-body">
          <div className="children profile-list">
            <h4 className="section-label">
              <i className="fas fa-child"></i>&nbsp;Children
            </h4>
            <div className="profile-list-wrapper">
              {children.map((child) => (
                <div className="list-item child" key={`child-${child.id}`}>
                  <Link
                    className="inner"
                    to={`/profile/child/${child.profile_child.id}`}
                  >
                    <div className="avatar">
                      <div className="avatar-wrapper">
                        {!!child.avatar_filename && (
                          <img src={child.avatar_filename} />
                        )}
                        {!child.avatar_filename && (
                          <i className="far fa-camera"></i>
                        )}
                      </div>
                    </div>
                    <div className="bio">
                      <h3 className="theme-h3">{child.firstname}</h3>
                      <p>{displayAge(child.DOB)}</p>
                      <i className="fas fa-arrow-right"></i>
                    </div>
                  </Link>
                </div>
              ))}
            </div>
            <Link className="theme-btn primary-green" to="/profile/child/new">
              <i className="far fa-plus-circle"></i>&nbsp;Add Another Child
            </Link>
          </div>
          <div className="pets profile-list">
            <h4 className="section-label">
              <i className="fas fa-paw"></i>&nbsp;Pets
            </h4>
            <div className="profile-list-wrapper">
              {pets.map((pet) => (
                <div className="list-item pet" key={`pet-${pet.id}`}>
                  <Link
                    className="inner"
                    to={`/profile/pet/${pet.profile_pet.id}`}
                  >
                    <div className="avatar">
                      <div className="avatar-wrapper">
                        {!!pet.avatar_filename && (
                          <img src={pet.avatar_filename} />
                        )}
                        {!pet.avatar_filename && (
                          <i className="far fa-camera"></i>
                        )}
                      </div>
                    </div>
                    <div className="bio">
                      <h3 className="theme-h3">{pet.firstname}</h3>
                      <p>
                        {pet.profile_pet.age_years}
                        &nbsp;Years Old
                      </p>
                    </div>
                  </Link>
                </div>
              ))}
            </div>
            <Link className="theme-btn primary-green" to="/profile/pet/new">
              <i className="far fa-plus-circle"></i>&nbsp;Add Another Pet
            </Link>
          </div>
        </div>
      </main>
    );
  };
}

export default connect(mapAuthState)(FamilyProfile);
