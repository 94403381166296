import React from 'react';
import moment from 'moment-timezone';
import { filter } from 'lodash-es';

class BulkCalendar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: null,
      showingCalendarLegend: false,
    };
  }
  componentDidMount() {
    let programNumWeeks = this.props.data.program_num_weeks;
    let programStartDate = this.props.data.program_start_date;

    // Snag the first day of the first week and save it
    let startDate = moment(programStartDate).startOf('week');
    this.setState({ startDate });
  }
  toggleCalendarLegend = () => {
    this.setState({
      showingCalendarLegend: !this.state.showingCalendarLegend,
    });
  };
  render() {
    const { startDate } = this.state;
    return (
      <div className="bulk-appointment-calendar-component">
        {!!startDate && (
          <div className="bulk-calendar-wrapper">{this.renderCalendar()}</div>
        )}
      </div>
    );
  }
  renderCalendar = () => {
    const { startDate, showingCalendarLegend } = this.state;
    const { data, currentAppointments } = this.props;
    const { repeatingAppointments, randomAppointments } = data;
    const calElements = [];
    const numWeeks = this.props.data.program_num_weeks;
    const weekdays = 6;

    for (var currentWeek = 1; currentWeek <= numWeeks; currentWeek++) {
      // Weeks starts at 1 because we use the actual number to keep track of repeating appointments
      // as in, it's easier to use the actual number here and subtract 1 below when adding
      const startOfWeek = moment(startDate)
        .add(currentWeek - 1, 'weeks')
        .startOf('week');
      const weekElements = [];
      for (var currentDay = 0; currentDay <= 6; currentDay++) {
        // Whereas here we use the actual numeric value of the day for simplicity's sake
        // it also lines up with the form data as well (appointment.weekday)
        const momentDay = moment(startOfWeek).add(currentDay, 'days');
        const monthName = moment(momentDay).format('MMM').toLowerCase();
        // display month highlights when the day is either....
        // a) the first visible day of the month (as in june 22 is the start date of the program - week 1 day 0 - show the highlights)
        // or b) it is actually the start of the new month (july 1).
        const firstVisibleDayOfMonth =
          (currentWeek === 1 && currentDay === 0) ||
          momentDay.isSame(moment(momentDay).startOf('month'));

        // Check repeating appointments for a match
        const dayRepeatingAppointments = filter(
          repeatingAppointments,
          (appt) => {
            return (
              appt.weekday === currentDay &&
              appt.repeatingOptions.indexOf(`${currentWeek}`) > -1
            );
          }
        );
        const hasDayRepeatingAppointments = dayRepeatingAppointments.length > 0;

        // Check if there are any pre-booked appointments for this day
        const dayCurrentAppointments = filter(currentAppointments, (appt) => {
          return moment(appt.start_time).isSame(momentDay, 'day');
        });
        const hasDayCurrentAppointments = dayCurrentAppointments.length > 0;

        // Check repeating appointments for a match
        const dayRandomAppointments = filter(randomAppointments, (appt) => {
          return moment(appt.date).isSame(momentDay, 'day');
        });
        const hasDayRandomAppointments = dayRandomAppointments.length > 0;

        // Here we add all the filtered day and repeating appointments together
        const allDayAppointments = [
          ...dayRepeatingAppointments,
          ...dayRandomAppointments,
        ];

        // For now we only use that for overnight, but it might be useful for other things later
        const dayOvernightAppointments = filter(
          allDayAppointments,
          (appt) => appt.overnight === 1
        );
        const hasOvernightAppointments = dayOvernightAppointments.length > 0;

        // Get the number of appointments for the day
        const numAppointments = allDayAppointments.length;
        const hasMultipleAppts = numAppointments > 1;

        //@TODO: integrate holidays
        const hasHoliday = false;

        // Check random appointments for a match
        const dayElement = (
          <div
            className={`bulk-cal day color-${monthName} ${
              firstVisibleDayOfMonth ? 'month-cap' : ''
            } ${hasDayRepeatingAppointments ? 'repeating' : ''} ${
              hasDayRandomAppointments ? 'random' : ''
            }`}
            key={`bulk-cal-day-${currentWeek}-${currentDay}`}
          >
            <div className="day-inner">
              <div className="content">
                <p>
                  {momentDay.format('D')}
                  {firstVisibleDayOfMonth && (
                    <span>&nbsp;{momentDay.format('MMM')}</span>
                  )}
                </p>
                <div className="appt-top-icons">
                  {(hasOvernightAppointments || hasDayRepeatingAppointments) &&
                    hasDayCurrentAppointments && (
                      <i className="icon fas fa-calendar-star"></i>
                    )}
                  {hasHoliday && <i className="icon fas fa-flag"></i>}
                </div>
                <div className="appt-icons">
                  {hasDayRepeatingAppointments && (
                    <i className="icon fas fa-repeat"></i>
                  )}
                  {hasOvernightAppointments && (
                    <i className="icon fas fa-moon-stars"></i>
                  )}
                  {!hasOvernightAppointments &&
                    !hasDayRepeatingAppointments &&
                    hasDayCurrentAppointments && (
                      <i className="icon fas fa-calendar-star"></i>
                    )}
                </div>
                {hasMultipleAppts && (
                  <div className="num-appts">
                    <div className="icon multiple-icon">X{numAppointments}</div>
                  </div>
                )}
              </div>
            </div>
          </div>
        );
        weekElements.push(dayElement);
      }
      const weekElement = (
        <div className="bulk-cal week" key={`bulk-cal-week-${currentWeek}`}>
          {weekElements}
        </div>
      );
      calElements.push(weekElement);
    }

    return (
      <div className="bulk-appointment-calendar">
        <div className="bulk-calendar-legend">
          <button
            onClick={this.toggleCalendarLegend}
            className="toggle-repeat-btn"
          >
            <i className="fas fa-sliders-v-square"></i>
            &nbsp;&nbsp;&nbsp;Calendar Legend
          </button>
          {!!showingCalendarLegend && (
            <div className="inner">
              <div className="legend-icon existing">
                <i className="icon fas fa-calendar-star"></i>
                <p>Existing Appt.</p>
              </div>
              <div className="legend-icon overnight">
                <i className="icon fas fa-moon-stars"></i>
                <p>Overnight</p>
              </div>
              <div className="legend-icon selected">
                <div className="icon selected-appt-icon"></div>
                <p>Random</p>
              </div>
              <div className="legend-icon repeating">
                <i className="icon fas fa-repeat"></i>
                <p>Repeating</p>
              </div>
              <div className="legend-icon multiple">
                <div className="icon multiple-icon">X</div>
                <p>Multiple Appts.</p>
              </div>
              <div className="legend-icon holiday">
                <i className="icon fas fa-flag"></i>
                <p>Holiday</p>
              </div>
            </div>
          )}
        </div>
        <div className="bulk-calendar-header">
          <p>
            <b>Week 1 - Week {numWeeks}:&nbsp;</b>
            <span>
              {moment(startDate).format('MMM Do')}&nbsp;-&nbsp;
              {moment(startDate)
                .add(numWeeks - 1, 'weeks')
                .endOf('week')
                .format('MMM Do')}
            </span>
          </p>
        </div>
        <div className="bulk-calendar">
          <div className="bulk-cal week title">
            <div className="bulk-cal day title">
              <p className="title">Sun</p>
            </div>
            <div className="bulk-cal day title">
              <p className="title">Mon</p>
            </div>
            <div className="bulk-cal day title">
              <p className="title">Tue</p>
            </div>
            <div className="bulk-cal day title">
              <p className="title">Wed</p>
            </div>
            <div className="bulk-cal day title">
              <p className="title">Thu</p>
            </div>
            <div className="bulk-cal day title">
              <p className="title">Fri</p>
            </div>
            <div className="bulk-cal day title">
              <p className="title">Sat</p>
            </div>
          </div>
          {calElements}
        </div>
      </div>
    );
  };
}

export default BulkCalendar;
