import React from 'react';
import { Avatar } from '../../shared';

import { displayAge } from '../../../helpers';

class ChildrenDetails extends React.Component {
  render() {
    const { familyChildren } = this.props;
    return (
      <div className="appt-section child-info">
        {familyChildren.map((child, idx) => (
          <div className="child" key={`child-${idx}`}>
            <Avatar
              imageSrc={child.avatar_filename}
              profileColor={child.profile_color}
            />
            <div className="name">
              <h3>{child.firstname}</h3>
              <p>{displayAge(child.DOB)}</p>
            </div>
            {/* todo add back in later */}
            {/* <div className="healthy-sick-toggler-wrapper">
							<div className={child.profile_child.sick ? "healthy-sick-toggler sick" : "healthy-sick-toggler healthy"}>
								<div className="hs-btn healthy">
									<button onClick={(e) => { toggleHealthy(child) }}>
										<i className="fas fa-thermometer-three-quarters"></i> Healthy
									</button>
								</div>
								<div className="hs-btn sick">
									<button onClick={(e) => { toggleSick(child) }}>
										<i className="fas fa-thermometer-three-quarters"></i> Sick
									</button>
								</div>
							</div>
						</div> */}
          </div>
        ))}
      </div>
    );
  }
}

export default ChildrenDetails;
