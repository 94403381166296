import React from 'react';
import { connect } from 'react-redux';
import { Elements, StripeProvider } from 'react-stripe-elements';
import { stripeKey } from '../../../config';
import AppointmentCancel from './AppointmentCancel';

const fontsObj = [
  {
    cssSrc: 'https://fonts.googleapis.com/css?family=Open+Sans&display=swap',
  },
];

class AppointmentCancelWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      //
    };
  }
  render() {
    return (
      <div className="appointment-cancel-wrapper">
        <StripeProvider apiKey={stripeKey}>
          <Elements fonts={fontsObj}>
            <AppointmentCancel
              {...this.props}
              cancelAppointment={this.props.cancelAppointment}
              toggleCancelAppointment={this.props.toggleCancelAppointment}
              fees={this.props.fees}
            />
          </Elements>
        </StripeProvider>
      </div>
    );
  }
}

function mapState(state) {
  return {
    auth: state.auth,
    balance: state.balance,
  };
}

export default connect(mapState)(AppointmentCancelWrapper);
