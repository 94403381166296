import { GridColumnMenuCheckboxFilter, GridColumnMenuFilter, } from '@progress/kendo-react-grid';
import { get } from 'lodash-es';
import moment from 'moment-timezone';
import React from 'react';
import { Link } from 'react-router-dom';
export const ColumnMenu = (props) => {
    return (React.createElement("div", null,
        React.createElement(GridColumnMenuFilter, { ...props, expanded: true })));
};
export const ColumnMenuCheckboxFilter = (props) => {
    return (React.createElement("div", null,
        React.createElement(GridColumnMenuCheckboxFilter, { ...props, data: props.sitters, expanded: true })));
};
export const DateCell = (props) => {
    return (React.createElement("td", null, moment(props.dataItem[props.field]).format('M/D/YY')));
};
export const TimeCell = (props) => {
    return (React.createElement("td", null, moment(props.dataItem[props.field], 'HH:mm:ss').format('h:mm a')));
};
export const ManageCell = (props) => {
    const id = get(props.dataItem, props.field);
    return (React.createElement("td", { className: "manage-cell" },
        React.createElement(Link, { className: "theme-btn blue small", to: `/providers/applications/${id}` }, "Manage")));
};
export const ManageProvidersCell = (props) => {
    const id = get(props.dataItem, props.field);
    return (React.createElement("td", { className: "manage-cell" },
        React.createElement(Link, { className: "theme-btn blue small", to: `/providers/edit/${id}` }, "Manage")));
};
export const ManageFamiliesCell = (props) => {
    const id = get(props.dataItem, props.field);
    return (React.createElement("td", { className: "manage-cell" },
        React.createElement(Link, { className: "theme-btn blue small", to: `/families/members/${id}` }, "Manage")));
};
export const AppStatusCell = (props) => {
    const step = get(props.dataItem, props.field);
    if (step) {
        const name = step.step.replace('Sitter ', '');
        return React.createElement("td", null, name);
    }
    return React.createElement("td", null);
};
export const AvailabilityCell = (props) => {
    const availability_count = get(props.dataItem, props.field);
    const availability = availability_count > 8 ? 'Complete' : 'Not Complete';
    return React.createElement("td", null, availability);
};
export const ActiveCell = (props) => {
    const active = get(props.dataItem, props.field);
    return React.createElement("td", null, active > 0 ? 'Active' : 'Inactive');
};
