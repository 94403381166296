import clsx from 'clsx';
import moment from 'moment-timezone';
import React, { Component, forwardRef } from 'react';
import DatePicker from 'react-datepicker';
const CustomInput = forwardRef(function CustomInput(props, ref) {
    return (React.createElement("input", { ...props, type: "text", readOnly: true, className: "filter-input", ref: ref }));
});
class DatePickerComponent extends Component {
    state = {
        inputValue: '',
        focus: false,
        active: false,
    };
    handleInputChange = (newValue) => {
        const inputValue = newValue.replace(/\W/g, '');
        this.setState({ inputValue });
        return inputValue;
    };
    onFocus = () => {
        this.setState({
            focus: true,
        });
    };
    onBlur = () => {
        this.setState({
            focus: false,
        });
    };
    onChange = (e) => {
        this.props.onChange(e, this.props.name);
    };
    render() {
        const { name, label, required, dateFormat, value, error, disabled, minDate, maxDate = null, } = this.props;
        const popperModifiers = [];
        if (name === 'recurring_end_date') {
            popperModifiers.push({
                name: 'preventOverflow',
                options: {
                    enabled: true,
                    escapeWithReference: true,
                    boundariesElement: 'viewport',
                },
            });
        }
        return (React.createElement("div", { className: clsx('form-field', 'datepicker', name, {
                active: value,
                focus: this.state.focus,
            }) },
            React.createElement(DatePicker, { selected: value ? new Date(value) : undefined, dateFormat: dateFormat, onChange: this.onChange, onFocus: this.onFocus, onBlur: this.onBlur, onCalendarClose: this.onBlur, isClearable: true, minDate: minDate ? minDate : moment().toDate(), maxDate: maxDate, disabled: disabled, popperPlacement: "top-end", preventOpenOnFocus: true, popperModifiers: popperModifiers, customInput: React.createElement(CustomInput) }),
            React.createElement("label", { htmlFor: name },
                label,
                required && React.createElement("span", { className: "required" }, "\u00A0*")),
            !!error && (React.createElement("span", { className: "error", role: "alert" },
                React.createElement("strong", null, error)))));
    }
}
export default DatePickerComponent;
