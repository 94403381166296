const userHasCompletedRegistration = (user) => {
    if (!user) {
        return false;
    }
    if (user.step === null) {
        // If their step_id is null, we assume they were seeded and do not have a step_id
        // Admins also have a null step_id, but their role check comes before this.
        return false;
    }
    return (user.step.slug === 'sitter-awaiting-admin-approval' ||
        user.step.slug === 'sitter-application-finished' ||
        user.step.slug === 'family-application-finished');
};
export default userHasCompletedRegistration;
