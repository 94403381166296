import clsx from 'clsx';
import React from 'react';
import CreatableSelect from 'react-select/creatable';
import reactSelectStyles from '@ss/theme/reactSelectStyles';
class SelectSearch extends React.Component {
    state = {
        focus: false,
        active: false,
    };
    onFocus = () => {
        this.setState({
            focus: true,
        });
    };
    onBlur = () => {
        this.setState({
            focus: false,
        });
    };
    render() {
        const { name, value, error, label, options, required, tooltip, ...rest } = this.props;
        const { focus } = this.state;
        return (React.createElement("div", { className: clsx('form-field', 'select-search', name, {
                active: value.length > 0,
                focus: focus, // eslint-disable-line object-shorthand
            }) },
            React.createElement(CreatableSelect, { id: name, className: value ? 'active' : 'inactive', value: value, name: name, options: options, isMulti: true, isClearable: false, placeholder: false, onFocus: this.onFocus, onBlur: this.onBlur, closeMenuOnSelect: true, styles: reactSelectStyles, components: { IndicatorSeparator: () => null }, ...rest }),
            React.createElement("label", { className: "", htmlFor: name },
                label,
                required && React.createElement("span", { className: "required" }, "\u00A0*")),
            tooltip && (React.createElement("div", { className: "tooltip-wrapper" },
                React.createElement("div", { className: "inner" },
                    React.createElement("i", { className: "fas fa-info-circle" }),
                    React.createElement("div", { className: "tooltip-item" },
                        React.createElement("p", null, tooltip))))),
            error && (React.createElement("span", { className: "error", role: "alert" },
                React.createElement("strong", null, error)))));
    }
}
export default SelectSearch;
