import React from 'react';

export default class CalendarHeader extends React.Component {
  render() {
    return (
      <div className="align_center header">
        <h4>
          <i className="icon far fa-calendar-alt"></i>Appointment Calendar
        </h4>
        <div className="calendar-key">
          <span className="key">
            <i className="appointment icon fas fa-calendar-star"></i>
            Appointment
          </span>
          <span className="key">
            <i className="holiday icon fas fa-flag-alt"></i>Holiday
          </span>
          <span className="key">
            <i className="icon day fas fa-square"></i>Current Day
          </span>
        </div>
        {/* <div className="view-full-btn">
                    <button className="theme-btn slate-blue small">View Full Calendar</button>
                </div> */}
      </div>
    );
  }
}
