import React from 'react';
import { connect } from 'react-redux';
import { authActions } from '../../../actions/authActions';
import { mapAuthState } from '../../../helpers/mapAuthState';
import { FormStepTracker } from '../../shared';
import { sendPixelEvent } from '@ss/helpers';
import trackerSteps from './trackerSteps.json';

class Complete extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      //
    };
  }
  componentDidMount() {
    const { user } = this.props.auth;
    if (!user) {
      this.sendUserBack();
    }
  }
  componentDidUpdate(prevProps, prevState) {
    //
  }
  sendUserBack() {
    alert('You must sign up or log in to view this page.');
    window.open('/', '_self');
  }
  advanceStep = async (e) => {
    if (!!e) {
      e.preventDefault();
    }
    try {
      sendPixelEvent('ProviderOnboardingComplete');
      const { id } = this.props.auth.user;
      const response = await this.props.updateUserStep(
        'sitter-application-finished',
        id
      );
      console.log(response);
    } catch (e) {
      console.log(e);
    }
  };
  render() {
    const {
      //
    } = this.state;
    return (
      <div className="registration family step-7 completion-page">
        <div className="form">
          <div className="inner">
            <FormStepTracker steps={trackerSteps} currentStep={4} />
            <div className="form-header">
              <h2>Registration Success</h2>
              <p>
                Thank you and congratulations. You have successfully applied and
                have been registered as a SmartSitter. Welcome to the family!
              </p>
            </div>
            <div className="form-body">
              <ul>
                <li>
                  <p>
                    <span className="form-step-circle">
                      <i className="far fa-check"></i>
                    </span>
                    Application Accepted
                  </p>
                </li>
                <li>
                  <p>
                    <span className="form-step-circle">
                      <i className="far fa-check"></i>
                    </span>
                    Terms Accepted
                  </p>
                </li>
                <li>
                  <p>
                    <span className="form-step-circle">
                      <i className="far fa-check"></i>
                    </span>
                    Application Approved
                  </p>
                </li>
                <li>
                  <p>
                    <span className="form-step-circle">
                      <i className="far fa-check"></i>
                    </span>
                    Private Profile Completed
                  </p>
                </li>
                <li>
                  <p>
                    <span className="form-step-circle">
                      <i className="far fa-check"></i>
                    </span>
                    Public Profile Completed
                  </p>
                </li>
                <li>
                  <p>
                    <span className="form-step-circle">
                      <i className="far fa-check"></i>
                    </span>
                    Availability Set
                  </p>
                </li>
              </ul>
            </div>
            <button className="theme-btn blue tall" onClick={this.advanceStep}>
              Go to your dashboard
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const actionCreators = {
  updateUserStep: authActions.updateUserStep,
};

export default connect(mapAuthState, actionCreators)(Complete);
