import { filter } from 'lodash-es';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { pets } from '../../../data';
import { mapAuthState } from '../../../helpers/mapAuthState';
import petService from '../../../services/petService';
import PetForm from '../../Forms/PetForm';
import { Modal, ProfileColorPicker } from '../../shared';

const AvatarDisplayComponent = (props) => {
  return (
    <div className="avatar">
      <div className="avatar-wrapper">
        {!!props.value && <img src={props.value} />}
        {!props.value && <i className="far fa-camera"></i>}
      </div>
    </div>
  );
};

class PetProfile extends PetForm {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      loading: true,
      id: null,
      creating: true,
      family_id: null,
      showRemovePet: false,
      removingPet: false,
    };
  }
  componentDidMount = () => {
    const { id } = this.props.match.params;
    if (id !== 'new' && Number(id) == id) {
      // abusing a quirk of JS where "11" == 11 to ensure the ID is a number
      this.getPet(id);
    } else {
      this.setState({
        data: { ...this.state.freshSchema },
        creating: true,
        family_id: this.props.auth.user.personal_info.family_id,
      });
    }
  };
  getPet = async (id) => {
    try {
      const response = await petService.get(id);
      console.log(response);
      if (response.status === 200) {
        const { data } = response.data;
        this.formatData(data);
      }
    } catch (e) {
      console.log(e);
    }
  };
  formatData = ({ medicalInfo, personalInfo, profilePet }) => {
    const { avatar_filename, firstname, family_id, profile_color } =
      personalInfo;
    const { age_years, notes, type, veterinarian_info } = profilePet;

    let showOther = false;

    const { data } = this.state;

    data['avatar_filename'] = avatar_filename;
    data['firstname'] = firstname;
    data['age_years'] = age_years;
    data['notes'] = notes;
    data['veterinarian_info'] = veterinarian_info;
    data['profile_color'] =
      profile_color !== null ? profile_color : 'primary-coral';

    const petType = filter(pets, (pet) => {
      return pet.id === type;
    });

    if (!petType.length) {
      data['type'] = 'Other';
      data['other'] = type;
      showOther = true;
    } else {
      data['type'] = type;
    }

    this.setState({
      data,
      creating: false,
      personalInfo,
      profilePet,
      id: profilePet.id,
      family_id,
      showOther,
    });
  };
  toggleRemovePet = (e) => {
    if (!!e) e.preventDefault();
    this.setState({
      showRemovePet: !this.state.showRemovePet,
    });
  };
  doRemovePet = async (e) => {
    e.preventDefault();
    try {
      const { id } = this.props.match.params;
      if (id !== 'new' && Number(id) == id) {
        const response = await petService.delete(id);
        if (response.status === 204) {
          toast.success('This pet has been removed from your account.');
          this.props.history.push('/profile');
        }
      }
    } catch (e) {
      console.log(e);
    }
  };
  handleProfileColorChange = (colorClass) => {
    const { data } = this.state;
    data['profile_color'] = colorClass;
    this.setState({ data });
  };
  doSubmit = async (callback) => {
    const response = await this.submitFormData();
    callback();
    if (response.status === 200 || response.status === 201) {
      toast.success('Hooray! Your changes are saved.');
      if (this.state.creating) {
        const { data } = response.data;
        const { id } = data.profilePet;
        this.formatData(data);
        this.setState(
          {
            creating: false,
          },
          () => {
            this.props.history.replace(`/profile/pet/${id}`);
          }
        );
      }
    }
  };
  render() {
    const { data, creating, removingPet, showRemovePet } = this.state;

    return (
      <main className="account profile pet">
        <form onSubmit={this.handleSubmit}>
          <div
            className={`profile-header profile-color gradient ${
              !!data.profile_color ? data.profile_color : 'primary-coral'
            }`}
          >
            <div className="inner">
              {this.renderFile(
                'avatar_filename',
                'Upload Your Public Profile Image',
                'image/jpg, image/png',
                false,
                AvatarDisplayComponent
              )}
              <h2 className="theme-h2">{data.firstname}&nbsp;</h2>
            </div>
          </div>
          <div className="profile-body">
            <h2>Pet Profile</h2>
            <ProfileColorPicker
              value={data.profile_color}
              onProfileColorChange={this.handleProfileColorChange}
            />
            {this.renderForm()}
            {this.renderButton(
              'Save Pet Details',
              'Saving...',
              'theme-btn blue'
            )}
            <div className="cancel-btn">
              {!creating && (
                <button
                  className="theme-btn small error-red"
                  onClick={this.toggleRemovePet}
                >
                  Delete
                </button>
              )}
              <Link className="theme-btn small slate-blue" to="/profile">
                Cancel
              </Link>
            </div>
          </div>
        </form>
        <Modal
          title="Confirm Deletion"
          isOpen={showRemovePet}
          closeModal={this.toggleRemovePet}
          content={
            'Are you sure you want to remove this pet from your account?'
          }
          primaryButton={removingPet ? ' Removing' : 'Remove'}
          secondaryButton={'Close'}
          handleClick={this.doRemovePet}
        />
      </main>
    );
  }
}

export default connect(mapAuthState)(PetProfile);
