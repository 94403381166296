export function initializeDevice() {
    const classNames = [];
    if (/(ipad|iphone|ipod)/i.test(navigator.userAgent)) {
        classNames.push('device-ios');
    }
    if (/android/i.test(navigator.userAgent)) {
        classNames.push('device-android');
    }
    if (classNames.length > 0) {
        classNames.push('on-device');
    }
    document.querySelector('html').classList.add(...classNames);
}
