import React from 'react';
import { mobileBp } from '../../../config';
import { AppointmentListingRow } from './AppointmentListingRow';
import { AppointmentPendingReviewsInfo } from './AppointmentPendingReviewsInfo';
import CalendarContainer from './CalendarContainer';
function noAppointmentsLabel(appointmentStatus) {
    switch (appointmentStatus) {
        case 'all':
            return 'upcoming';
        case 'accepted':
            return 'confirmed';
        default:
            return appointmentStatus;
    }
}
class AppointmentListing extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentView: 'list',
        };
    }
    toggleView(view) {
        this.setState({
            currentView: view,
        });
    }
    render() {
        const { appointments, type, windowWidth, changeAppointmentStatus, appointmentStatus, pendingReviewsCount, } = this.props;
        const { currentView } = this.state;
        return (React.createElement("div", { className: "appointments-container" },
            React.createElement("h2", null,
                appointmentStatus === 'completed' ? (React.createElement("i", { className: "fas fa-history past-appointments-icon" })) : (React.createElement("i", { className: "fas fa-calendar-star" })),
                ' ',
                appointmentStatus === 'completed'
                    ? 'Completed Appointments'
                    : 'Upcoming Appointments'),
            React.createElement("div", { className: "right-button", onClick: () => appointmentStatus === 'completed'
                    ? changeAppointmentStatus('all')
                    : changeAppointmentStatus('completed') },
                appointmentStatus === 'completed' && (React.createElement(React.Fragment, null,
                    React.createElement("i", { className: "fas fa-calendar-star past-appointments-icon" }),
                    ' ',
                    React.createElement("span", null, "Upcoming Appts."))),
                appointmentStatus !== 'completed' && (React.createElement(React.Fragment, null,
                    React.createElement("i", { className: "fas fa-history past-appointments-icon" }),
                    ' ',
                    React.createElement("span", null, "Past Appts.")))),
            appointmentStatus !== 'completed' && !!pendingReviewsCount && (React.createElement(AppointmentPendingReviewsInfo, { count: pendingReviewsCount, onClick: () => changeAppointmentStatus('completed') })),
            windowWidth < mobileBp && (React.createElement("div", { className: `mobile-view-toggle ${currentView}` },
                React.createElement("div", { className: "list", onClick: () => {
                        this.toggleView('list');
                    } },
                    React.createElement("p", null,
                        React.createElement("i", { className: "fal fa-list-alt" }),
                        " List")),
                React.createElement("div", { className: "calendar", onClick: () => {
                        this.toggleView('calendar');
                    } },
                    React.createElement("p", null,
                        React.createElement("i", { className: "far fa-calendar-alt" }),
                        "Calendar")))),
            type === 'family' && currentView === 'list' && (React.createElement("div", { className: "filter-options" }, appointmentStatus !== 'completed' && (React.createElement(React.Fragment, null,
                React.createElement("button", { className: appointmentStatus === 'all'
                        ? 'theme-btn blue small'
                        : 'theme-btn grey small', onClick: () => changeAppointmentStatus('all') }, "All"),
                React.createElement("button", { className: appointmentStatus === 'accepted'
                        ? 'theme-btn blue small'
                        : 'theme-btn grey small', onClick: () => changeAppointmentStatus('accepted') }, "Confirmed"),
                React.createElement("button", { className: appointmentStatus === 'pending'
                        ? 'theme-btn blue small'
                        : 'theme-btn grey small', onClick: () => changeAppointmentStatus('pending') }, "Pending"),
                React.createElement("button", { className: appointmentStatus === 'cancelled'
                        ? 'theme-btn blue small'
                        : 'theme-btn grey small', onClick: () => changeAppointmentStatus('cancelled') }, "Cancelled"))))),
            windowWidth < mobileBp && currentView === 'calendar' && (React.createElement(CalendarContainer, { appointments: appointments })),
            (windowWidth >= mobileBp || currentView === 'list') &&
                appointments.map((appointment) => (React.createElement(AppointmentListingRow, { appointment: appointment, key: appointment.id, type: type }))),
            appointments.length === 0 && (React.createElement("div", { style: { marginTop: 20 } },
                "You have no ",
                noAppointmentsLabel(appointmentStatus),
                " appointments."))));
    }
}
export default AppointmentListing;
