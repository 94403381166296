import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Switch, withRouter, } from 'react-router-dom';
import links from '../../data/links';
import { mapAuthState } from '../../helpers/mapAuthState';
import systemNoticesService from '../../services/systemNoticesService';
import { Header, Page } from '../shared';
import { AnnouncementsEdit, AnnouncementsGrid } from './Announcements';
import { AppointmentsDetail, AppointmentsListing, AppointmentsSitLog, } from './Appointments';
import AppointmentsEdit from './Appointments/AppointmentsEdit';
import { AdminBooking } from './Booking';
import { CouponsEdit, CouponsGrid } from './Coupons';
import { ChildrenEdit, FamiliesEdit, FamiliesGrid, ManageSittersGrid, MembersEdit, MembersGrid, PetEdit, } from './Families';
import { LogsListing } from './Logs';
import Pricing from './Pricing/Pricing';
import { ProgramBooking, ProgramsGrid } from './Programs';
import { ApplicationAcceptanceDetail, ApplicationAcceptanceListing, ProviderAvailability, ProvidersEdit, ProvidersGrid, ProviderReviews, } from './Providers';
import AvailabilityGrid from './Providers/AvailabilityGrid';
import { ReportsGrid } from './Reports';
class AdminSection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            appointments: [],
            appointmentData: null,
            notifications: [],
            systemNotices: [],
            appointmentStatus: 'all',
            menuOpen: false,
            detailOpen: false,
            windowWidth: undefined,
        };
    }
    componentDidMount() {
        this.getSystemNotices();
        this.onWindowResize();
        $(window).on('resize', this.onWindowResize);
    }
    componentWillUnmount() {
        $(window).off('resize', this.onWindowResize);
    }
    componentDidUpdate(prevProps) {
        const currentPath = this.props.location.pathname;
        const prevPath = prevProps.location.pathname;
        if (currentPath !== prevPath) {
            this.setState({
                menuOpen: false,
            });
        }
    }
    onWindowResize = () => {
        const windowWidth = document.documentElement.clientWidth;
        this.setState({ windowWidth });
    };
    getSystemNotices = async () => {
        const response = await systemNoticesService.getSystemNotices('parent');
        if (response.status === 200) {
            const { systemNotices } = response.data.data;
            this.setState({ systemNotices });
        }
    };
    toggleMenu = () => {
        this.setState((prevState) => ({
            ...prevState,
            menuOpen: !prevState.menuOpen,
        }));
    };
    render() {
        const { menuOpen, detailOpen, windowWidth } = this.state;
        return (React.createElement(Page, { id: "admin-section", menuOpen: menuOpen, detailOpen: detailOpen, header: React.createElement(Header, { type: "admin", className: "admin", links: links.admin, menuOpen: menuOpen, detailOpen: detailOpen, toggleMenu: this.toggleMenu, pathname: this.props.location.pathname }) },
            React.createElement(Switch, null,
                React.createElement(Route, { path: "/dashboard", exact: true, render: (props) => (React.createElement(AppointmentsListing, { ...props })) }),
                React.createElement(Route, { path: "/providers/availability", exact: true, render: (props) => React.createElement(AvailabilityGrid, { ...props }) }),
                React.createElement(Route, { path: "/providers/applications", exact: true, render: (props) => React.createElement(ApplicationAcceptanceListing, { ...props }) }),
                React.createElement(Route, { path: "/providers/applications/:id", exact: true, render: (props) => React.createElement(ApplicationAcceptanceDetail, { ...props }) }),
                React.createElement(Route, { path: "/providers/edit/:id", exact: true, render: (props) => React.createElement(ProvidersEdit, { ...props }) }),
                React.createElement(Route, { path: "/providers/availability/edit/:id", exact: true, render: (props) => (React.createElement(ProviderAvailability, { ...props, windowWidth: windowWidth })) }),
                React.createElement(Route, { path: "/providers/manage", exact: true, render: (props) => React.createElement(ProvidersGrid, { ...props }) }),
                React.createElement(Route, { path: "/providers/reviews", exact: true, render: (props) => React.createElement(ProviderReviews, { ...props }) }),
                React.createElement(Route, { path: "/families/manage", exact: true, render: (props) => React.createElement(FamiliesGrid, { ...props }) }),
                React.createElement(Route, { path: "/families/create", exact: true, render: (props) => React.createElement(FamiliesEdit, { ...props }) }),
                React.createElement(Route, { path: "/families/members/:id", exact: true, render: (props) => React.createElement(MembersGrid, { ...props }) }),
                React.createElement(Route, { path: "/families/members/:id/edit", exact: true, render: (props) => React.createElement(FamiliesEdit, { ...props }) }),
                React.createElement(Route, { path: "/families/sitters/:id/:type", exact: true, render: (props) => React.createElement(ManageSittersGrid, { ...props }) }),
                React.createElement(Route, { path: "/families/members/edit/:id/:family_id?", exact: true, render: (props) => React.createElement(MembersEdit, { ...props }) }),
                React.createElement(Route, { path: "/families/children/edit/:id/:family_id?", exact: true, render: (props) => React.createElement(ChildrenEdit, { ...props }) }),
                React.createElement(Route, { path: "/families/pets/edit/:id/:family_id?", exact: true, render: (props) => React.createElement(PetEdit, { ...props }) }),
                React.createElement(Route, { path: "/families/appointments/book/:id/:booking_id?", exact: true, render: (props) => React.createElement(AdminBooking, { ...props }) }),
                React.createElement(Route, { path: "/programs", exact: true, render: (props) => React.createElement(ProgramsGrid, { ...props }) }),
                React.createElement(Route, { path: "/programs/:filter", exact: true, render: (props) => React.createElement(ProgramsGrid, { ...props }) }),
                React.createElement(Route, { path: "/programs/view/:program_id", exact: true, render: (props) => React.createElement(ProgramBooking, { ...props }) }),
                React.createElement(Route, { path: "/families/programs/book/:family_id", exact: true, render: (props) => React.createElement(ProgramBooking, { ...props }) }),
                React.createElement(Route, { path: "/appointments", exact: true, render: (props) => React.createElement(AppointmentsListing, { ...props }) }),
                React.createElement(Route, { path: "/appointments/:filter", exact: true, render: (props) => React.createElement(AppointmentsListing, { ...props }) }),
                React.createElement(Route, { path: "/appointments/view/:id", exact: true, render: (props) => (React.createElement(AppointmentsDetail, { ...props })) }),
                React.createElement(Route, { path: "/appointments/edit/:id", exact: true, render: (props // TODO: HOW IS THIS WORKING?
                    ) => React.createElement(AppointmentsEdit, { ...props }) }),
                React.createElement(Route, { path: "/appointments/log/:id", exact: true, render: (props) => (React.createElement(AppointmentsSitLog, { ...props })) }),
                React.createElement(Route, { path: "/reports/:type", exact: true, render: (props) => (React.createElement(ReportsGrid, { ...props })) }),
                React.createElement(Route, { path: "/settings/coupons", exact: true, component: CouponsGrid }),
                React.createElement(Route, { path: "/settings/coupons/create", exact: true, component: CouponsEdit }),
                React.createElement(Route, { path: "/settings/coupons/edit/:id", exact: true, component: CouponsEdit }),
                React.createElement(Route, { path: "/settings/logs", exact: true, render: (props) => React.createElement(LogsListing, { ...props }) }),
                React.createElement(Route, { path: "/settings/announcements", exact: true, render: (props) => React.createElement(AnnouncementsGrid, { ...props }) }),
                React.createElement(Route, { path: "/settings/announcements/create", exact: true, render: (props) => React.createElement(AnnouncementsEdit, { ...props }) }),
                React.createElement(Route, { path: "/settings/announcements/:id", exact: true, render: (props) => (React.createElement(AnnouncementsEdit, { ...props })) }),
                React.createElement(Route, { path: "/settings/pricing", exact: true, component: Pricing }),
                React.createElement(Redirect, { to: "/dashboard" }))));
    }
}
export default withRouter(connect(mapAuthState)(AdminSection));
