import Joi from 'joi';
import moment from 'moment-timezone';
import React from 'react';
import childService from '../../services/childService';
import httpService from '../../services/httpService';
import Form from '../shared/Form';
const freshSchema = {
    avatar_filename: '',
    firstname: '',
    lastname: '',
    dob_year: '',
    dob_month: '',
    dob_day: '',
    about: '',
    unborn: 0,
    selected_medical_conditions: [],
    same_pediatrician_info: 0,
    allergy_info: '',
    pediatrician_info: '',
    profile_color: 'primary-coral',
    notes: '',
};
class ChildForm extends Form {
    schema = Joi.object({
        avatar_filename: Joi.alternatives(Joi.string(), Joi.object())
            .optional()
            .allow(null)
            .allow('')
            .label('Avatar'),
        profile_color: Joi.string()
            .optional()
            .allow(null)
            .allow('')
            .label('Profile Color'),
        firstname: Joi.string().required().label('First Name'),
        lastname: Joi.string().required().label('Last Name'),
        dob_year: Joi.number().integer().min(1900).required().label('DOB - Year'),
        dob_month: Joi.number()
            .integer()
            .min(1)
            .max(12)
            .required()
            .label('DOB - Month'),
        dob_day: Joi.number()
            .integer()
            .min(1)
            .max(31)
            .required()
            .label('DOB - Day'),
        about: Joi.string().optional().allow(null).allow('').label('About Me'),
        unborn: Joi.boolean().truthy(1).falsy(0).label('Expecting'),
        same_pediatrician_info: Joi.boolean()
            .truthy(1)
            .falsy(0)
            .label('Same Pediatrician Info'),
        selected_medical_conditions: Joi.array()
            .optional()
            .label('Selected Medical Conditions'),
        allergy_info: Joi.string()
            .optional()
            .allow(null)
            .allow('')
            .label('Medical Condition Info'),
        pediatrician_info: Joi.string()
            .optional()
            .allow(null)
            .allow('')
            .label('Pediatrician Info'),
        notes: Joi.string()
            .optional()
            .allow(null)
            .allow('')
            .label('Notes and Rules'),
    });
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            creating: false,
            data: { ...freshSchema },
            freshSchema,
            medicalConditions: [],
        };
    }
    getMedicalConditions = async () => {
        const response = await httpService.get('/api/medicalInfo');
        if (response.status === 200) {
            const { medical_info } = response.data.data;
            this.setState({
                medicalConditions: medical_info.map((info) => ({
                    ...info,
                    value: info.id,
                    label: info.name,
                })),
            });
        }
    };
    submitFormData = async () => {
        const apiData = { ...this.state.data };
        const { family_id, creating, id } = this.state;
        const { dob_day, dob_year, dob_month } = apiData;
        const DOB = moment(`${dob_year}-${dob_month}-${dob_day}`, 'YYYY-M-D').format('YYYY-MM-DD');
        apiData.DOB = DOB;
        apiData.family_id = family_id;
        delete apiData.dob_day;
        delete apiData.dob_year;
        delete apiData.dob_month;
        delete apiData.same_pediatrician_info;
        const data = new FormData();
        const headers = { headers: { 'content-type': 'multipart/form-data' } };
        data.append('_method', 'post');
        for (const key of Object.keys(apiData)) {
            if (typeof apiData[key] === 'object') {
                if (key === 'avatar_filename') {
                    if (apiData[key] !== null) {
                        data.append(key, apiData[key], 'image.jpg');
                    }
                }
                else {
                    data.append(key, JSON.stringify(apiData[key]));
                }
            }
            else {
                data.append(key, apiData[key]);
            }
        }
        try {
            return creating
                ? await childService.store(data, headers)
                : await childService.update(id, data, headers);
        }
        catch (error) {
            console.log(error);
            return error;
        }
    };
    renderForm(admin = false) {
        const { data, medicalConditions } = this.state;
        return (React.createElement("div", { className: "child-profile-form" },
            this.renderInput('firstname', 'First Name', 'text', true),
            this.renderInput('lastname', 'Last Name', 'text', true),
            React.createElement("div", { className: "form-field-group field-with-checkbox dob" },
                !!data.unborn && (React.createElement(React.Fragment, null, this.renderTextDatePicker('dob', 'Expected Delivery Date (MM/DD/YYYY)', true))),
                !data.unborn && (React.createElement(React.Fragment, null, this.renderTextDatePicker('dob', 'Date of Birth (MM/DD/YYYY)', true))),
                this.renderBoolean('unborn', 'Are you expecting a new baby?')),
            this.renderTextarea('about', 'About Me', false),
            this.renderSelectSearch('selected_medical_conditions', 'Any allergies or medical conditions? (optional)', medicalConditions, false),
            this.renderTextarea('allergy_info', 'Notes on Allergies and Medical Conditions (optional)', false),
            React.createElement("div", { className: "form-field-group field-with-checkbox pediatrician_info" },
                this.renderTextarea('pediatrician_info', 'Pediatrician Information (optional)', false),
                admin === false &&
                    this.renderBoolean('same_pediatrician_info', 'Check this box if all Pediatrician information is the same for all children in your family.')),
            this.renderTextarea('notes', 'Notes and Rules (optional)', false)));
    }
}
export default ChildForm;
