import React from 'react';
import { connect } from 'react-redux';

import Signup from './Signup';
import Application from './Application';
import ApplicationSubmitted from './ApplicationSubmitted';
import ScheduleInterview from './ScheduleInterview';
import PrivateProfile from './PrivateProfile';
import PublicProfile from './PublicProfile';
import Availability from './Availability';
import SitterOnboarding from './SitterOnboarding';
import Complete from './Complete';
import { mapAuthState } from '../../../helpers/mapAuthState';

class SitterRegistration extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 'signup',
      steps: [
        'signup',
        'application',
        'application-submitted',
        'schedule-interview',
        'private-profile',
        'public-profile',
        'availability',
        'onboarding',
        'complete',
      ],
    };
  }
  componentDidMount() {
    if (!!this.props.auth.user) {
      if (!!this.props.auth.user.step) {
        this.setStepFromUser();
      } else {
        this.setStepFromUrl();
      }
    } else {
      this.setStepFromUrl();
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (!!this.props.auth.user && !!prevProps.auth.user) {
      if (this.props.auth.user.step_id !== prevProps.auth.user.step_id) {
        this.setStepFromUser();
      }
    } else if (!!this.props.auth.user && !prevProps.auth.user) {
      // user signed in
      this.setStepFromUser();
    }
  }
  setStepFromUser = () => {
    const { user } = this.props.auth;
    const { step } = user;
    let pageStep = false;
    switch (step.slug) {
      case 'sitter-signup':
        pageStep = 'application';
        break;
      case 'sitter-application-questions':
        pageStep = 'application-submitted';
        break;
      case 'sitter-application-pending':
        pageStep = 'schedule-interview';
        break;
      case 'sitter-application-approved':
        pageStep = 'private-profile';
        break;
      case 'sitter-private-profile':
        pageStep = 'public-profile';
        break;
      case 'sitter-public-profile':
        pageStep = 'availability';
        break;
      case 'sitter-availability':
        pageStep = 'onboarding';
        break;
      case 'sitter-onboarding':
        pageStep = 'complete';
        break;
    }
    if (!!pageStep) this.setState({ step: pageStep }, this.updateUrl);
  };
  setStepFromUrl = () => {
    const { step } = this.props.match.params;
    if (!!step) {
      const { steps } = this.state;
      if (steps.indexOf(step) === -1) {
        this.setState(
          {
            step: steps[0],
          },
          this.updateUrl
        );
      } else {
        this.setState({ step });
      }
    }
  };
  advanceStep = () => {
    let { step, steps } = this.state;
    let idx = steps.indexOf(step);
    if (idx < steps.length - 1) {
      idx += 1;
      step = steps[idx];
      this.setState({ step }, this.updateUrl);
    }
  };
  updateUrl = () => {
    const { step } = this.state;
    switch (step) {
      case 'signup':
        window.history.replaceState(null, null, '/register/sitter/signup');
        break;
      case 'application':
        window.history.replaceState(null, null, '/register/sitter/application');
        break;
      case 'application-submitted':
        window.history.replaceState(
          null,
          null,
          '/register/sitter/application-submitted'
        );
        break;
      case 'schedule-interview':
        window.history.replaceState(null, null, '/schedule-interview');
        break;
      case 'private-profile':
        window.history.replaceState(
          null,
          null,
          '/register/sitter/private-profile'
        );
        break;
      case 'public-profile':
        window.history.replaceState(
          null,
          null,
          '/register/sitter/public-profile'
        );
        break;
      case 'onboarding':
        window.history.replaceState(null, null, '/register/sitter/onboarding');
        break;
      case 'complete':
        window.history.replaceState(null, null, '/register/sitter/complete');
        break;
    }
  };
  render() {
    const { step, formData } = this.state;
    const { user } = this.props.auth;
    const { windowWidth } = this.props;
    return (
      <main className="registration sitter">
        {step === 'signup' && <Signup />}
        {step === 'application' && <Application />}
        {step === 'application-submitted' && <ApplicationSubmitted />}
        {step === 'schedule-interview' && <ScheduleInterview />}
        {step === 'private-profile' && <PrivateProfile />}
        {step === 'public-profile' && <PublicProfile />}
        {step === 'availability' && <Availability windowWidth={windowWidth} />}
        {step === 'onboarding' && <SitterOnboarding />}
        {step === 'complete' && <Complete />}
      </main>
    );
  }
}

export default connect(mapAuthState)(SitterRegistration);
