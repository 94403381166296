import reactSelectStyles from '@ss/theme/reactSelectStyles';
import React, { Component } from 'react';
import AsyncSelect from 'react-select/async';
class AsyncSelectComponent extends Component {
    state = {
        inputValue: '',
        focus: false,
        active: false,
        newValue: '',
    };
    handleInputChange = (newValue) => {
        this.setState({ newValue });
        return newValue;
    };
    onChange = (value) => {
        this.props.onChange(this.props.name, value);
    };
    onFocus = () => {
        this.setState({
            focus: true,
        });
    };
    onBlur = () => {
        this.setState({
            focus: false,
        });
    };
    render() {
        const { name, label, required, loadOptions, value, error } = this.props;
        const { focus } = this.state;
        const classNames = [
            'form-field',
            name,
            'async-select',
            !!value ? 'active' : '',
            focus ? 'focus' : '',
        ];
        // edge case for program booking page
        if (value && value.label === undefined) {
            value.label = value.firstname + ' ' + value.lastname;
        }
        return (React.createElement("div", { className: classNames.join(' ') },
            React.createElement(AsyncSelect, { cacheOptions: true, value: value, loadOptions: loadOptions, defaultOptions: true, placeholder: false, onInputChange: this.handleInputChange, onChange: this.onChange, styles: reactSelectStyles, onFocus: this.onFocus, onBlur: this.onBlur, components: { IndicatorSeparator: () => null } }),
            React.createElement("label", { className: "", htmlFor: name },
                label,
                required && React.createElement("span", { className: "required" }, "\u00A0*")),
            error && (React.createElement("span", { className: "error", role: "alert" },
                React.createElement("strong", null, error)))));
    }
}
export default AsyncSelectComponent;
