import $ from 'jquery';
import { each } from 'lodash-es';
import moment from 'moment-timezone';
import React from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { authActions } from '../../../actions/authActions';
import httpService from '../../../services/httpService';
import { FormStepTracker } from '../../shared';
import Form from '../../shared/Form';
import trackerSteps from './trackerSteps';

import Joi from 'joi';
import { mapAuthState } from '../../../helpers/mapAuthState';

const checkboxOptions = [{ label: 'Yes', value: true }];

const freshSchema = {
  firstname: '',
  lastname: '',
  dob_year: '',
  dob_month: '',
  dob_day: '',
  unborn: 0,
  selected_medical_conditions: [],
  same_pediatrician_info: 0,
  allergy_info: '',
  pediatrician_info: '',
  notes: '',
};

class Children extends Form {
  constructor(props) {
    super(props);
    this.state = {
      data: freshSchema,
      freshSchema,
      errors: {},
      proceed: false,
      childrenAdded: 0,
      medicalConditions: [],
    };
  }
  schema = Joi.object({
    firstname: Joi.string().required().label('First Name'),
    lastname: Joi.string().required().label('Last Name'),
    dob_year: Joi.number().integer().min(1900).required().label('DOB - Year'),
    dob_month: Joi.number()
      .integer()
      .min(1)
      .max(12)
      .required()
      .label('DOB - Month'),
    dob_day: Joi.number()
      .integer()
      .min(1)
      .max(31)
      .required()
      .label('DOB - Day'),
    unborn: Joi.boolean().truthy(1).falsy(0).label('Expecting'),
    same_pediatrician_info: Joi.boolean()
      .truthy(1)
      .falsy(0)
      .label('Same Pediatrician Info'),
    selected_medical_conditions: Joi.array()
      .optional()
      .label('Selected Medical Conditions'),
    allergy_info: Joi.string()
      .optional()
      .allow(null)
      .allow('')
      .label('Medical Condition Info'),
    pediatrician_info: Joi.string()
      .optional()
      .allow(null)
      .allow('')
      .label('Pediatrician Info'),
    notes: Joi.string()
      .optional()
      .allow(null)
      .allow('')
      .label('Notes and Rules'),
  });
  componentDidMount() {
    const { user } = this.props.auth;
    if (!user) {
      this.sendUserBack();
    } else {
      this.getMedicalConditions();
    }
  }
  getMedicalConditions = async () => {
    const response = await httpService.get('/api/medicalInfo');
    if (response.status === 200) {
      let { medical_info } = response.data.data;
      medical_info = each(medical_info, (info) => {
        info['value'] = info.id;
        info['label'] = info.name;
      });
      this.setState({
        medicalConditions: medical_info,
      });
    }
  };
  sendUserBack() {
    alert('You must sign up or log in to view this page.');
    window.open('/', '_self');
  }
  handleDefaultSubmit = (e) => {
    e.preventDefault();
    this.setState(
      {
        proceed: false,
      },
      () => {
        this.handleSubmit(e);
      }
    );
  };
  doSubmit = async (callback) => {
    let apiData = { ...this.state.data };
    const { family_id } = this.props.auth.user.personal_info;
    const { same_pediatrician_info, dob_day, dob_year, dob_month } = apiData;

    apiData['DOB'] = moment(
      `${dob_year}-${dob_month}-${dob_year}`,
      'YYYY-M-D'
    ).format('YYYY-MM-DD');
    apiData['family_id'] = family_id;

    delete apiData['dob_day'];
    delete apiData['dob_year'];
    delete apiData['dob_month'];
    delete apiData['same_pediatrician_info'];

    try {
      const response = await httpService.post(
        '/api/profileChild/store',
        apiData
      );

      if (response.status === 201 && response.data.message === 'OK') {
        if (this.state.proceed) {
          toast.success('Child saved successfully!');
          this.advanceStep();
        } else {
          let { data, childrenAdded, freshSchema } = this.state;
          childrenAdded += 1;
          data = freshSchema;
          if (same_pediatrician_info) {
            data['pediatrician_info'] = apiData['pediatrician_info'];
            data['same_pediatrician_info'] = 1;
          }
          this.setState({
            data,
            childrenAdded,
          });

          toast.success('Child saved successfully!');

          $('.registration').animate({ scrollTop: 0 });

          callback();
        }
      } else {
        $('.registration').animate({ scrollTop: 0 });
        callback();
      }
    } catch (e) {
      console.log(e);
      callback();
    }
  };
  checkFormDataAndAdvance = (e) => {
    if (!!e) {
      e.preventDefault();
    }
    const { data, childrenAdded } = this.state;
    const { firstname, lastname, dob_year, dob_month, dob_day } = data;
    if (!!childrenAdded) {
      // If children have been added, we aren't sure if they want to save & proceed, or just proceed.
      // We need to check if any of the required fields are present and make a guess
      if (!!firstname || !!lastname || !!dob_year || !!dob_month || !!dob_day) {
        this.setState(
          {
            proceed: true,
          },
          this.handleSubmit
        );
      } else {
        this.advanceStep();
      }
    } else {
      // If no children have been added, we assume they want to save & proceed.
      this.setState(
        {
          proceed: true,
        },
        this.handleSubmit
      );
    }
  };
  advanceStep = async () => {
    try {
      const { id } = this.props.auth.user;
      const response = await this.props.updateUserStep('family-children', id);
    } catch (e) {
      console.log(e);
    }
  };
  render() {
    const { childrenAdded, data, medicalConditions } = this.state;
    return (
      <div className="registration family step-3 children">
        <div className="form">
          <div className="inner">
            <FormStepTracker steps={trackerSteps} currentStep={1} />
            <div className="form-header">
              <h2>Add Children</h2>
            </div>
            <form onSubmit={this.handleDefaultSubmit}>
              <div className="form-group justify-space-between">
                <h3>Child {childrenAdded + 1}</h3>
                {this.renderInput('firstname', 'First Name', 'text', true)}
                {this.renderInput('lastname', 'Last Name', 'text', true)}
                <div className="form-field-group field-with-checkbox">
                  {!!data.unborn && (
                    <React.Fragment>
                      {this.renderTextDatePicker(
                        'dob',
                        'Expected Delivery Date (MM/DD/YYYY)',
                        true
                      )}
                    </React.Fragment>
                  )}
                  {!data.unborn && (
                    <React.Fragment>
                      {this.renderTextDatePicker(
                        'dob',
                        'Date of Birth (MM/DD/YYYY)',
                        true
                      )}
                    </React.Fragment>
                  )}
                  {this.renderBoolean(
                    'unborn',
                    'Are you expecting a new baby?'
                  )}
                </div>
                {this.renderSelectSearch(
                  'selected_medical_conditions',
                  'Any allergies or medical conditions? (optional)',
                  medicalConditions,
                  false
                )}
                {this.renderTextarea(
                  'allergy_info',
                  'Notes on Allergies and Medical Conditions (optional)',
                  false
                )}
                <div className="form-field-group field-with-checkbox">
                  {this.renderTextarea(
                    'pediatrician_info',
                    'Pediatrician Information (optional)',
                    false
                  )}
                  {this.renderBoolean(
                    'same_pediatrician_info',
                    'Check this box if all Pediatrician information is the same for all children in your family.'
                  )}
                </div>
                {this.renderTextarea(
                  'notes',
                  'Notes and Rules (optional)',
                  false
                )}
              </div>
              {this.renderButton(
                'Add Another Child',
                'Adding...',
                'theme-btn primary-green',
                'far fa-plus-circle'
              )}
              <div className="form-field button next-step">
                {!!this.state.submitting && (
                  <button
                    type="submit"
                    className="theme-btn blue"
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                  >
                    <i className="fas fa-spinner fa-spin"></i> Adding and
                    proceeding...
                  </button>
                )}
                {!this.state.submitting && (
                  <button
                    type="submit"
                    className="theme-btn blue"
                    onClick={this.checkFormDataAndAdvance}
                  >
                    Next
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

const actionCreators = {
  updateUserStep: authActions.updateUserStep,
};

export default connect(mapAuthState, actionCreators)(Children);
