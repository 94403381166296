import React from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import Joi from 'joi';
import systemNoticesService from '@ss/services/systemNoticesService';
import { findLinkGroupByTitle } from '@ss/helpers';
import { Form } from '../../shared';
import Sidebar from '../Sidebar';
const announcementTypes = [
    { name: '', id: '' },
    { name: 'Family', id: 'parent' },
    { name: 'Sitter', id: 'sitter' },
    { name: 'Both', id: 'both' },
];
const announcementStatuses = [
    { name: '', id: '' },
    { name: 'Active', id: 'active' },
    { name: 'Inactive', id: 'inactive' },
];
class AnnouncementsEdit extends Form {
    schema = Joi.object({
        type: Joi.string().required().label('Type'),
        status: Joi.string().required().label('Status'),
        name: Joi.string().required().label('Name'),
        systemNotice: Joi.string().required().label('Notice'),
    });
    constructor(props) {
        super(props);
        this.state = {
            data: {
                type: '',
                status: '',
                name: '',
                systemNotice: '',
            },
            errors: {},
            systemNotice: null,
            creating: false,
            submitting: false,
            loading: true,
            linkGroup: findLinkGroupByTitle('Settings'),
            id: null,
        };
    }
    componentDidMount() {
        if (!this.props.match.params.id) {
            this.setState({
                creating: true,
                loading: false,
            });
        }
        else {
            this.getAnnouncement();
        }
    }
    getAnnouncement = async () => {
        try {
            const data = { ...this.state.data };
            const { id } = this.props.match.params;
            const response = await systemNoticesService.details(id);
            if (response.status === 200) {
                const { systemNotice } = response.data.data;
                data.type = systemNotice.type;
                data.status = systemNotice.status;
                data.name = systemNotice.name;
                data.systemNotice = systemNotice.notice;
                this.setState({
                    systemNotice,
                    data,
                    loading: false,
                    creating: false,
                });
            }
        }
        catch (error) {
            console.log(error);
            toast.error('Uh oh! Something went wrong!');
        }
    };
    doSubmit = async (callback) => {
        try {
            if (this.state.creating) {
                const data = { ...this.state.data };
                // this is to get around some weird styling on items with "notice"
                const response = await systemNoticesService.store({
                    ...data,
                    notice: data.systemNotice,
                });
                callback();
                if (response.status === 201) {
                    const { systemNotice } = response.data.data;
                    this.props.history.replace(`/settings/announcements/${systemNotice.id}`);
                    this.getAnnouncement();
                    toast.success('Hooray! This Announcement has been created!');
                }
            }
            else {
                const data = { ...this.state.data };
                // this is to get around some weird styling on items with "notice"
                data.notice = data.systemNotice;
                const { id } = this.state.systemNotice;
                const response = await systemNoticesService.update(id, data);
                callback();
                if (response.status === 201) {
                    const { systemNotice } = response.data.data;
                    this.props.history.replace(`/settings/announcements/${systemNotice.id}`);
                    this.getAnnouncement();
                    toast.success('Hooray! This Announcement has been updated!');
                }
            }
        }
        catch (error) {
            console.log(error);
            callback();
            toast.error('Uh oh! Something went wrong!');
        }
    };
    deleteAnnouncement = async () => {
        this.setState({
            deleting: true,
        });
        try {
            const data = { ...this.state.data };
            const { id } = this.state.systemNotice;
            const response = await systemNoticesService.delete(id, data);
            if (response.status === 204) {
                toast.success('Hooray! This Announcement has been deleted!');
                this.props.history.push('/settings/announcements');
            }
        }
        catch (error) {
            console.log(error);
            toast.error('Uh oh! Something went wrong!');
            this.setState({
                deleting: false,
            });
        }
    };
    goBack = (event) => {
        if (event) {
            event.preventDefault();
        }
        this.props.history.goBack();
    };
    render() {
        const { submitting, creating, systemNotice, loading, linkGroup, deleting } = this.state;
        if (!!loading) {
            return (React.createElement("main", { className: "dashboard admin manage" },
                React.createElement("div", { className: "sidebar-wrapper" },
                    React.createElement(Sidebar, { ...this.props, iconClass: "fas fa-id-card", linkGroup: linkGroup })),
                React.createElement("div", { className: "content" },
                    React.createElement("div", { className: "coupons" },
                        React.createElement("h2", null,
                            React.createElement("i", { className: "fas fa-spinner fa-spin" }),
                            "\u00A0Loading Announcement...")))));
        }
        return (React.createElement("main", { className: "dashboard admin manage" },
            React.createElement("div", { className: "sidebar-wrapper" },
                React.createElement(Sidebar, { ...this.props, iconClass: "fas fa-id-card", linkGroup: linkGroup })),
            React.createElement("div", { className: "content" },
                React.createElement("div", { className: "coupons" },
                    React.createElement("h2", null, !!creating
                        ? 'Create Announcement'
                        : `Editing Announcement #${systemNotice.id}`),
                    React.createElement("form", { onSubmit: this.handleSubmit, className: "system-notice-form form" },
                        this.renderSelect('type', 'Type', announcementTypes, true),
                        this.renderSelect('status', 'Status', announcementStatuses, true),
                        this.renderInput('name', 'Name', 'text', true),
                        this.renderInput('systemNotice', 'System Notice', 'text', true),
                        React.createElement("div", { className: "divider" }),
                        React.createElement("div", { className: "align_center row" },
                            !!submitting && (React.createElement("button", { type: "button", className: "theme-btn blue large save-btn", disabled: true, onClick: (e) => e.preventDefault() },
                                React.createElement("i", { className: "fas fa-spinner fa-spin" }),
                                "\u00A0Saving...")),
                            !submitting && (React.createElement("button", { type: "button", className: "theme-btn blue large save-btn", onClick: this.handleSubmit },
                                React.createElement("i", { className: "fas fa-save" }),
                                "\u00A0Save Announcement"))),
                        React.createElement("br", null),
                        React.createElement("div", { className: "align_center row" },
                            !!deleting && (React.createElement("button", { type: "button", className: "theme-btn error-red small", disabled: true, onClick: (e) => e.preventDefault() },
                                React.createElement("i", { className: "fas fa-spinner fa-spin" }),
                                "\u00A0Deleting...")),
                            !deleting && (React.createElement("button", { type: "button", className: "theme-btn error-red small", onClick: this.deleteAnnouncement },
                                React.createElement("i", { className: "fas fa-times-circle" }),
                                "\u00A0Delete"))),
                        React.createElement("br", null),
                        React.createElement("div", { className: "align_center row" },
                            React.createElement(Link, { to: '/settings/announcements', className: "theme-btn slate-blue small" }, "Back")))))));
    }
}
export default AnnouncementsEdit;
