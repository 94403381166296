import { useMemo } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useReportError } from '@ss/hooks/useReportError';
import { familyService } from '@ss/services';
import { toast } from 'react-toastify';
function getSitterPreferenceData({ sitterPreference, familyId, sitterPiid, nextValues, }) {
    return {
        ...(sitterPreference ?? {
            id: -1,
            excluded: 0,
            interested: 0,
            favorite: 0,
            family_id: familyId,
            sitter_piid: sitterPiid,
        }),
        ...nextValues,
    };
}
export function useSitterProfileActions({ familyId, piid, }) {
    const queryKey = useMemo(() => ['family-sitter-preference', familyId, piid], [familyId, piid]);
    const reportError = useReportError();
    const queryClient = useQueryClient();
    const query = useQuery(queryKey, function () {
        return familyService.getSitterFamilyPreference(familyId, piid);
    }, {
        onError: (error) => {
            reportError('Unable to fetch family sitter preference', error);
        },
    });
    const previouselyHired = query.data?.previouselyHired ? true : false;
    const blocked = query.data?.sitterPreference?.excluded ? true : false;
    const favorited = query.data?.sitterPreference?.favorite ? true : false;
    const saved = query.data?.sitterPreference?.interested ? true : false;
    const commonMutationOptions = {
        onError: (err, _params, context) => {
            if (context?.previousData) {
                queryClient.setQueryData(queryKey, context.previousData);
            }
            reportError('Error while update sitter preferences', err);
            toast.error('We couldn’t change the state for this user');
        },
        onSettled: () => {
            queryClient.invalidateQueries(queryKey);
        },
    };
    const handleOnMutate = async (nextValues) => {
        await queryClient.cancelQueries(queryKey);
        const previousData = queryClient.getQueryData(queryKey);
        if (previousData) {
            const nextData = {
                ...previousData,
                sitterPreference: getSitterPreferenceData({
                    sitterPreference: previousData.sitterPreference,
                    familyId,
                    sitterPiid: piid,
                    nextValues,
                }),
            };
            queryClient.setQueryData(queryKey, () => nextData);
        }
        return { previousData };
    };
    const blockedMutation = useMutation(function (nextValue) {
        return familyService.blockSitter(familyId, piid, {
            excluded: nextValue ? 1 : 0,
        });
    }, {
        ...commonMutationOptions,
        onMutate: (nextValue) => handleOnMutate({ excluded: nextValue ? 1 : 0 }),
    });
    const favoriteMutation = useMutation(function (nextValue) {
        return familyService.favoriteSitter(familyId, piid, {
            favorite: nextValue ? 1 : 0,
        });
    }, {
        ...commonMutationOptions,
        onMutate: (nextValue) => handleOnMutate({ favorite: nextValue ? 1 : 0 }),
    });
    const savedMutation = useMutation(function (nextValue) {
        return familyService.saveSitter(familyId, piid, {
            interested: nextValue ? 1 : 0,
        });
    }, {
        ...commonMutationOptions,
        onMutate: (nextValue) => handleOnMutate({ interested: nextValue ? 1 : 0 }),
    });
    const toggleBlocked = () => {
        blockedMutation.mutate(!blocked);
    };
    const toggleFavorited = () => {
        favoriteMutation.mutate(!favorited);
    };
    const toggleSaved = () => {
        savedMutation.mutate(!saved);
    };
    return {
        query,
        previouselyHired,
        blocked,
        favorited,
        saved,
        toggleBlocked,
        toggleFavorited,
        toggleSaved,
    };
}
