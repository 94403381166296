import React from 'react';
import couponService from '../../services/couponService';
import { toast } from 'react-toastify';
import Input from './Form/Input';
class CouponChecker extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            code: '',
            submitting: false,
            valid: false,
            coupon: null,
        };
    }
    checkCouponCode = async () => {
        this.setState({ submitting: true });
        try {
            const { type } = this.props;
            const { code } = this.state;
            const data = { type, code };
            const response = await couponService.check(data);
            if (response.status === 200) {
                const { coupon } = response.data.data;
                this.setState({
                    coupon,
                    submitting: false,
                    valid: true,
                }, this.applyCouponCode);
            }
        }
        catch (error) {
            toast.error('Invalid coupon code');
            console.log(error);
            this.setState({ submitting: false });
        }
    };
    applyCouponCode = () => {
        const { coupon } = this.state;
        toast.success(`Hooray! Coupon code ${coupon.code} has been applied for ${coupon.type === 'percentage'
            ? `${Number.parseFloat(coupon.amount) * 100}%`
            : '$' + coupon.amount} off!`);
        this.props.applyCouponCode(coupon);
    };
    handleChange = (event) => {
        const code = event.target.value.toUpperCase();
        this.setState({ code });
    };
    render() {
        const { className, required } = this.props;
        const { code, submitting, valid } = this.state;
        return (React.createElement("div", { className: `coupon-checker-component ${className}` },
            React.createElement("h3", null, "Coupon"),
            React.createElement("div", { className: "coupon-checker-wrapper" },
                React.createElement(Input, { className: `coupon-checker-${className}`, name: `coupon-checker-${className}`, label: "Coupon Code", type: "text", value: code, onChange: this.handleChange, required: required !== undefined ? required : true, disabled: valid }),
                !!valid && (React.createElement("button", { className: "theme-btn small slate-blue", onClick: (e) => e.preventDefault, disabled: true },
                    React.createElement("i", { className: "fas fa-check" }),
                    "\u00A0Applied!")),
                !!submitting && !valid && (React.createElement("button", { className: "theme-btn small slate-blue", onClick: this.checkCouponCode },
                    React.createElement("i", { className: "fas fa-spinner fa-spin" }),
                    "\u00A0Applying...")),
                !submitting && !valid && (React.createElement("button", { className: "theme-btn small slate-blue", onClick: this.checkCouponCode },
                    React.createElement("i", { className: "fas fa-check" }),
                    "\u00A0Apply")))));
    }
}
export default CouponChecker;
