import { Grid, GridColumn } from '@progress/kendo-react-grid';
import React from 'react';
import { findLinkGroupByTitle } from '../../../helpers';
import appointmentService from '../../../services/appointmentService';
import {
  ColumnMenu,
  DateCell,
  FamilyCell,
} from '../Appointments/KendoComponents';
import Sidebar from '../Sidebar';
import ProgramManageBtn from './ProgramManageBtn';
import ProgramStatusCell from './ProgramStatusCell';

const INITIAL_DATA_STATE = {
  sort: [{ field: 'created_at', dir: 'desc' }],
  filter: {
    logic: 'and',
    filters: [],
  },
  filterOperators: {
    text: [
      { text: 'grid.filterContainsOperator', operator: 'contains' },
      { text: 'grid.filterEqOperator', operator: 'eq' },
    ],
  },
  skip: 0,
  take: 50,
  total: 0,
};

class ProgramsGrid extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bookings: [],
      result: [],
      loading: true,
      linkGroup: findLinkGroupByTitle('Programs'),
      dataState: { ...INITIAL_DATA_STATE },
      filters: [
        'all',
        'new',
        'assigning',
        'proposal-sent',
        'accepted',
        'rejected',
        'completed',
        'cancelled',
      ],
      currentFilter: 'all',
    };
  }
  componentDidMount = () => {
    const { filter } = this.props.match.params;
    const { search } = this.props.history.location;
    if (!!search) {
      const urlParams = new URLSearchParams(this.props.history.location.search);
      let filterObj = urlParams.get('filter');
      const skip = urlParams.get('skip');
      filterObj = JSON.parse(filterObj);
      const { dataState } = this.state;
      dataState['filter'] = filterObj;
      dataState['skip'] = Number(skip);
      let currentFilter = !!filter ? filter : 'all';
      this.setState(
        {
          dataState,
          currentFilter,
        },
        this.getBookings
      );
    } else {
      this.setFilter(filter);
    }
  };
  componentDidUpdate(prevProps, prevState) {
    const { filter } = this.props.match.params;
    const prevFilter = prevProps.match.params.filter;
    if (filter !== prevFilter) {
      this.setFilter(filter);
    }
  }
  getBookings = async () => {
    const { currentFilter, dataState } = this.state;

    const { filter, skip } = dataState;
    let filterUrl = JSON.stringify(filter);
    filterUrl = encodeURIComponent(filterUrl);
    // let newUrl = `/bookings/${currentFilter}?filter=${filterUrl}&skip=${skip}`;
    // this.props.history.replace(newUrl);

    try {
      const response = await appointmentService.getBookings('program', {
        status: currentFilter,
        skip: dataState.skip,
        take: dataState.take,
        sort: dataState.sort,
        filter: dataState.filter,
      });
      if (response.status === 200) {
        const { bookings, count } = response.data.data;
        this.setState({
          result: bookings,
          dataState: {
            ...dataState,
            total: count,
          },
          bookings,
          loading: false,
        });
      }
    } catch (e) {
      console.log(e);
      this.setState({
        loading: false,
      });
    }
  };
  setFilter = (filter) => {
    let currentFilter = !!filter ? filter : 'all';
    this.setState(
      {
        currentFilter,
        loading: true,
        dataState: { ...INITIAL_DATA_STATE },
      },
      this.getBookings
    );
  };
  pageChange = (event) => {
    this.setState(
      {
        dataState: {
          ...this.state.dataState,
          skip: event.page.skip,
          take: event.page.take,
        },
      },
      () => {
        this.getBookings();
      }
    );
  };
  sortChange = (event) => {
    this.setState(
      {
        dataState: {
          ...this.state.dataState,
          sort: event.sort,
        },
      },
      () => {
        this.getBookings();
      }
    );
  };
  filterChange = (event) => {
    this.setState(
      {
        dataState: {
          ...INITIAL_DATA_STATE,
          filter: event.filter,
          skip: 0,
        },
      },
      () => {
        this.getBookings();
      }
    );
  };
  render() {
    const { loading, currentFilter, bookings, linkGroup, dataState, result } =
      this.state;

    if (loading && !bookings.length) {
      return (
        <main className="admin admin-listing with-sidebar appointments">
          <div className="sidebar-wrapper">
            <Sidebar
              {...this.props}
              iconClass="fas fa-calendar-star"
              linkGroup={linkGroup}
            />
          </div>
          <div className="content">
            <h4 className="section-label">
              <i className="fas fa-spinner fa-spin"></i>
              &nbsp;Loading Programs...
            </h4>
          </div>
        </main>
      );
    }

    return (
      <main className="admin admin-listing with-sidebar appointments">
        <div className="sidebar-wrapper">
          <Sidebar
            {...this.props}
            iconClass="fas fa-id-card"
            linkGroup={linkGroup}
          />
        </div>
        <div className="content">
          <h4 className="section-label">
            {!!loading && <i className="fas fa-spinner fa-spin"></i>}
            {!loading && <i className="fas fa-calendar-star"></i>}
            &nbsp;Viewing{' '}
            {currentFilter === 'assigning' ? 'Open' : currentFilter} Program
            Bookings
          </h4>
          <div className="filter-btn-wrapper">
            <button
              className="theme-btn slate-blue small"
              onClick={() => {
                this.setFilter('all');
              }}
            >
              All
            </button>
            <button
              className="theme-btn slate-blue small"
              onClick={() => {
                this.setFilter('new');
              }}
            >
              New
            </button>
            <button
              className="theme-btn slate-blue small"
              onClick={() => {
                this.setFilter('assigning');
              }}
            >
              Open
            </button>
            <button
              className="theme-btn slate-blue small"
              onClick={() => {
                this.setFilter('proposal-sent');
              }}
            >
              Proposal Sent
            </button>
            <button
              className="theme-btn slate-blue small"
              onClick={() => {
                this.setFilter('accepted');
              }}
            >
              Accepted
            </button>
            <button
              className="theme-btn slate-blue small"
              onClick={() => {
                this.setFilter('rejected');
              }}
            >
              Rejected
            </button>
            <button
              className="theme-btn slate-blue small"
              onClick={() => {
                this.setFilter('completed');
              }}
            >
              Completed
            </button>
            <button
              className="theme-btn slate-blue small"
              onClick={() => {
                this.setFilter('cancelled');
              }}
            >
              Cancelled
            </button>
          </div>
          <div className="kendo-table">
            <Grid
              data={result}
              pageable={true}
              sortable={true}
              {...dataState}
              onRowClick={this.handleRowClick}
              onPageChange={this.pageChange}
              onSortChange={this.sortChange}
              onFilterChange={this.filterChange}
            >
              <GridColumn
                title="Status"
                field="status"
                sortable={false}
                cell={ProgramStatusCell}
                width={125}
              />
              <GridColumn
                title="Family"
                field="profile_family.name"
                cell={(props) => (
                  <FamilyCell {...props} {...this.props} var="family" />
                )}
                columnMenu={ColumnMenu}
              />
              <GridColumn
                title="Start Date"
                field="start_date"
                cell={DateCell}
                width={100}
              />
              <GridColumn
                title="End Date"
                field="end_date"
                cell={DateCell}
                width={100}
              />
              <GridColumn
                title="Total Appts"
                field="appointments"
                width={125}
              />
              <GridColumn
                title="Date Submitted"
                field="created_at"
                cell={DateCell}
                width={150}
              />
              <GridColumn title="ID" field="id" width={60} />
              <GridColumn
                title=" "
                field="id"
                width={100}
                cell={(props) => (
                  <ProgramManageBtn {...props} {...this.props} />
                )}
              />
            </Grid>
          </div>
        </div>
      </main>
    );
  }
}

export default ProgramsGrid;
