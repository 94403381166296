import React from 'react';
import $ from 'jquery';
import clsx from 'clsx';
const TextDatePicker = (props) => {
    const { yearName, monthName, dayName, label, yearVal, monthVal, dayVal, errors, required, onChange, ...rest } = props;
    const handleChange = (event) => {
        // This is really gross and it should probably change, but essentially, we assume that the
        // next character entered will be the last, and automatically focus the next relevant input
        // field. this is done BEFORE the value is officially changed, so we have to
        const currentTarget = event.currentTarget;
        let value = currentTarget.value;
        const name = currentTarget.name;
        if (name === monthName && value.length >= 2) {
            document.querySelector(`input[name=${dayName}]`)?.focus();
            value = value.slice(0, 2);
        }
        if (name === dayName && value.length === 2) {
            document.querySelector(`input[name=${yearName}]`)?.focus();
            value = value.slice(0, 2);
        }
        if (name === yearName && value.length >= 4) {
            document.querySelector(`input[name=${yearName}]`)?.blur();
            value = value.slice(0, 4);
        }
        // fake the actual onChange event with our (potentially modified) value
        // this is just to force the string limit.
        onChange({
            ...event,
            currentTarget: {
                ...currentTarget,
                type: currentTarget.type,
                name,
                value,
            },
        });
    };
    const hasValue = !!yearVal || !!monthVal || !!dayVal;
    const onClick = () => {
        if (!hasValue) {
            document.querySelector(`input[name=${monthName}]`)?.focus();
        }
    };
    const onBlur = () => {
        const isActive = !!yearVal || !!monthVal || !!dayVal;
        $(`.form-field.text-date-picker.${yearName}`).removeClass('focus');
        if (!isActive) {
            $(`.form-field.text-date-picker.${yearName}`).removeClass('active');
        }
    };
    const onFocus = () => {
        document
            .querySelector(`.form-field.text-date-picker.${yearName}`)
            ?.classList.add('active', 'focus');
    };
    const className = hasValue ? 'active' : 'inactive';
    return (React.createElement("div", { className: clsx('form-field text-date-picker', yearName, monthName, dayName, className), onClick: onClick },
        React.createElement("div", { className: "form-field-wrapper" },
            React.createElement("span", null,
                React.createElement("input", { className: "month", type: "number", name: monthName, value: monthVal, step: "1", onFocus: onFocus, onBlur: onBlur, onChange: handleChange, ...rest }),
                React.createElement("span", null, "/"),
                React.createElement("input", { className: "day", type: "number", name: dayName, value: dayVal, step: "1", onFocus: onFocus, onBlur: onBlur, onChange: handleChange, ...rest }),
                React.createElement("span", null, "/"),
                React.createElement("input", { className: "year", type: "number", name: yearName, value: yearVal, step: "1", onFocus: onFocus, onBlur: onBlur, onChange: handleChange, ...rest }))),
        React.createElement("label", { htmlFor: "date-picker" },
            label,
            required && React.createElement("span", { className: "required" }, "\u00A0*")),
        (errors[yearName] || errors[monthName] || errors[dayName]) && (React.createElement("span", { className: "error", role: "alert" },
            React.createElement("strong", null,
                errors[yearName],
                ".\u00A0"),
            React.createElement("strong", null,
                errors[monthName],
                ".\u00A0"),
            React.createElement("strong", null,
                errors[dayName],
                ".\u00A0")))));
};
export default TextDatePicker;
