import { buildApiUrl } from './../helpers/buildApiUrl';
import httpService from './httpService';
const get = (id = null) => {
    const apiUrl = buildApiUrl(id ? `/fees/${id}` : '/fees');
    return httpService.request('get', apiUrl);
};
const store = (data, headers) => {
    const apiUrl = buildApiUrl('/fees/store');
    return httpService.post(apiUrl, data, headers);
};
const update = (id, data, headers) => {
    const apiUrl = buildApiUrl(`/fees/update/${id}`);
    return httpService.post(apiUrl, data, headers);
};
const bulkUpdate = (data) => {
    const apiUrl = buildApiUrl('/fees/bulkUpdate');
    return httpService.post(apiUrl, data);
};
const deleteFee = (id, data, headers) => {
    const apiUrl = buildApiUrl(`/fees/delete/${id}`);
    return httpService.delete(apiUrl, {}, headers);
};
export default {
    get,
    store,
    update,
    delete: deleteFee,
    bulkUpdate,
};
