const makeTimeIncrements = (startHour = 7, endHour = 24) => {
    const increments = ['00', '15', '30', '45'];
    const times = [{ label: '', value: '', id: '', name: '' }];
    for (let rawHour = startHour; rawHour < endHour; rawHour++) {
        // append a 0 before the time if it is single digit
        const valueHour = rawHour < 10 ? `0${rawHour}` : rawHour;
        // if the hour is 0 (midnight), set it to 12, otherwise if it's over 13 (1pm or later), subtract 12
        const baseRawHour = rawHour < 13 ? rawHour : rawHour - 12;
        const labelHour = baseRawHour === 0 ? 12 : baseRawHour;
        for (const minute of increments) {
            const valueTime = `${valueHour}:${minute}`;
            const labelTime = `${labelHour}:${minute} ${rawHour < 12 ? 'am' : 'pm'}`;
            const time = {
                label: labelTime,
                value: valueTime,
                id: valueTime,
                name: labelTime,
            };
            times.push(time);
        }
    }
    return times;
};
export default makeTimeIncrements;
