import { displayAge, findLinkGroupByTitle, firstInitial, upperCaseFirst, } from '@ss/helpers';
import appointmentService from '@ss/services/appointmentService';
import moment from 'moment-timezone';
import React from 'react';
import Sidebar from '../Sidebar';
class AppointmentsSitLog extends React.Component {
    state = {
        errors: {},
        sitLog: null,
        loading: true,
        linkGroup: findLinkGroupByTitle('Appointments'),
    };
    componentDidMount = () => {
        if (this.props.match.params.id) {
            const { id } = this.props.match.params;
            if (Number.isNaN(Number.parseInt(id, 10)) === false) {
                this.getSitLog(id);
            }
        }
    };
    getSitLog = async (id) => {
        try {
            const response = await appointmentService.getSitLog(id);
            if (response.status === 200) {
                const { sitLog } = response.data.data;
                this.setState({ sitLog, loading: false });
            }
        }
        catch (error) {
            console.log(error);
        }
    };
    render() {
        const { props, state } = this;
        const { loading, sitLog, linkGroup } = state;
        if (loading) {
            return (React.createElement("main", { className: "admin admin-detail with-sidebar appointments" },
                React.createElement("div", { className: "sidebar-wrapper" },
                    React.createElement(Sidebar, { ...props, iconClass: "fas fa-id-card", linkGroup: linkGroup })),
                React.createElement("div", { className: "content" },
                    React.createElement("h3", null, "Loading Sit Log Details..."))));
        }
        return (React.createElement("main", { className: "admin admin-detail with-sidebar appointments" },
            React.createElement("div", { className: "sidebar-wrapper" },
                React.createElement(Sidebar, { ...props, iconClass: "fas fa-id-card", linkGroup: linkGroup })),
            React.createElement("div", { className: "content admin-view-sit-log" },
                React.createElement("h3", { className: "theme-h2" }, "Sit Summary"),
                React.createElement("h4", { className: "section-label" }, "Details"),
                React.createElement("p", null,
                    React.createElement("b", null, "Start Time:"),
                    React.createElement("br", null),
                    moment(sitLog.appointment.start_time).format('dddd, MMMM Do YYYY, h:mm a')),
                React.createElement("p", null,
                    React.createElement("b", null, "End Time:"),
                    React.createElement("br", null),
                    moment(sitLog.appointment.end_time).format('dddd, MMMM Do YYYY, h:mm a')),
                React.createElement("p", null,
                    React.createElement("b", null, "Family:"),
                    React.createElement("br", null),
                    sitLog.appointment.family.name),
                React.createElement("p", null,
                    React.createElement("b", null, "Children:"),
                    React.createElement("br", null),
                    sitLog.appointment.children.map((child, idx) => (React.createElement("span", { key: `sitter-log-child-${child.id}` },
                        child.firstname,
                        "\u00A0(",
                        displayAge(child.DOB),
                        ")",
                        idx < sitLog.appointment.children.length - 1 ? ', ' : '')))),
                sitLog.appointment.sitters.length > 0 && (React.createElement("p", null,
                    React.createElement("b", null,
                        "Sitter",
                        sitLog.appointment.sitters.length > 1 ? 's' : '',
                        ":"),
                    React.createElement("br", null),
                    sitLog.appointment.sitters.map((sitter, idx) => (React.createElement("span", { key: `sitter-log-${sitter.id}` },
                        upperCaseFirst(sitter.firstname),
                        "\u00A0",
                        firstInitial(sitter.lastname),
                        idx < sitLog.appointment.sitters.length - 1 ? ', ' : ''))))),
                !!sitLog.sitter_notes && (React.createElement("p", null,
                    React.createElement("b", null, "Sitter Notes:"),
                    React.createElement("br", null),
                    sitLog.sitter_notes)),
                React.createElement("h4", { className: "section-label" }, "Activities"),
                sitLog.activities.map((activity) => (React.createElement("div", { className: "sit-log-activity", key: `activity-${activity.id}` },
                    React.createElement("p", null,
                        React.createElement("b", null,
                            activity.name,
                            ":"),
                        React.createElement("br", null),
                        activity.description,
                        activity.start_time && (React.createElement(React.Fragment, null,
                            React.createElement("br", null),
                            React.createElement("span", null,
                                "Start Time:",
                                ' ',
                                moment(activity.start_time).format('dddd, MMMM Do YYYY, h:mm a')))),
                        activity.end_time && (React.createElement(React.Fragment, null,
                            React.createElement("br", null),
                            React.createElement("span", null,
                                "End Time:",
                                ' ',
                                moment(activity.end_time).format('dddd, MMMM Do YYYY, h:mm a')))))))))));
    }
}
export default AppointmentsSitLog;
