import { useHistory, useLocation } from 'react-router';
import { buildSearchUrl } from '@ss/helpers/buildSearchUrl';
const PARAM_FROM_DATE = 'from';
const PARAM_TO_DATE = 'to';
const PARAM_ZIP = 'zip';
const PARAM_AGES = 'ages';
const PARAM_FAMILY = 'family';
const PARAM_NAME_MAP = {
    fromDate: PARAM_FROM_DATE,
    toDate: PARAM_TO_DATE,
    zip: PARAM_ZIP,
    ages: PARAM_AGES,
    family: PARAM_FAMILY,
};
export function useUnclaimedAppointmentFilters() {
    const location = useLocation();
    const history = useHistory();
    const params = new URLSearchParams(location.search);
    const fromDate = params.get(PARAM_FROM_DATE) ?? '';
    const toDate = params.get(PARAM_TO_DATE) ?? '';
    const zip = params.get(PARAM_ZIP) ?? '';
    const ages = params.getAll(PARAM_AGES) ?? [];
    const family = params.get(PARAM_FAMILY) ?? '';
    const currentParams = { fromDate, toDate, zip, ages, family };
    const setFilter = (nextParams) => {
        history.replace(buildSearchUrl({
            pathname: location.pathname,
            search: { ...currentParams, ...nextParams },
            paramNameMap: PARAM_NAME_MAP,
        }));
    };
    return {
        ...currentParams,
        setFilter,
    };
}
