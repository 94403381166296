import { each } from 'lodash-es';
import React from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  displayAddress,
  displayVehicleInfo,
  findLinkGroupByTitle,
} from '../../../helpers';
import sitterService from '../../../services/sitterService';
import { Form } from '../../shared';
import Sidebar from '../Sidebar';
import Joi from 'joi';

class ApplicationAcceptanceDetail extends Form {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        admin_notes: '',
      },
      errors: {},
      languages: [],
      acceptedAgeRanges: [],
      selectedActivities: [],
      medicalInfo: [],
      profileSitter: {},
      personalInfo: {},
      user: {},
      loading: true,
      linkGroup: findLinkGroupByTitle('Providers'),
      accept: false,
    };
  }
  schema = Joi.object({
    admin_notes: Joi.string()
      .optional()
      .allow('')
      .allow(null)
      .label('Admin Notes'),
  });
  componentDidMount = () => {
    this.getSitter();
  };
  getSitter = async () => {
    try {
      const { id } = this.props.match.params;
      const response = await sitterService.get(id);
      if (response.status === 200) {
        const {
          languages,
          personalInfo,
          profileSitter,
          user,
          acceptedAgeRanges,
          selectedActivities,
          medicalInfo,
        } = response.data.data;
        let { admin_notes } = profileSitter;
        this.setState({
          languages,
          personalInfo,
          profileSitter,
          user,
          acceptedAgeRanges,
          selectedActivities,
          medicalInfo,
          loading: false,
          data: {
            admin_notes,
          },
        });
      }
    } catch (e) {
      console.log(e);
      this.setState({
        loading: false,
      });
    }
  };
  displayArray = (array, field) => {
    const data = this.state[array];
    let items = [];
    each(data, (item) => {
      items.push(item[field]);
    });
    return items.join(', ');
  };
  doSubmit = async (callback) => {
    try {
      const apiData = { ...this.state.data };
      const { accept, personalInfo, user } = this.state;
      apiData['accept'] = accept;
      const { id } = personalInfo;
      const { step } = user;
      const response = await sitterService.updateApplicationStatus(
        id,
        apiData,
        step.slug
      );
      if (response.status === 200) {
        const { profileSitter, user } = response.data.data;
        let { admin_notes } = profileSitter;
        this.setState({
          profileSitter,
          user,
          data: {
            admin_notes,
          },
        });
        if (accept) {
          toast.success('Hooray! The Provider has been updated!');
        } else {
          toast.success("The Provider's application has been rejected.");
        }
      }
      callback();
    } catch (e) {
      console.log(e);
      callback();
    }
  };
  saveSitter = async () => {
    try {
      const { id } = this.state.personalInfo;
      const apiData = { ...this.state.data };
      const response = await sitterService.update(id, apiData);
      if (response.status === 200) {
        toast.success('Hooray! The Provider has been updated!');
      }
    } catch (e) {
      console.log(e);
      toast.error('Uh oh! Something went wrong!');
    }
  };
  handleAcceptSubmit = () => {
    this.setState(
      {
        accept: true,
      },
      this.handleSubmit
    );
  };
  handleRejectSubmit = () => {
    this.setState(
      {
        accept: false,
      },
      this.handleSubmit
    );
  };
  render() {
    const { props } = this;
    const {
      loading,
      accept,
      personalInfo,
      profileSitter,
      user,
      languages,
      linkGroup,
      submitting,
      medicalInfo,
      acceptedAgeRanges,
      selectedActivities,
    } = this.state;

    if (!!loading) {
      return (
        <main className="admin admin-detail with-sidebar providers application-acceptance">
          <div className="sidebar-wrapper">
            <Sidebar
              {...props}
              iconClass="fas fa-id-card"
              linkGroup={linkGroup}
            />
          </div>
          <div className="content">
            <h3>Loading Provider Details...</h3>
          </div>
        </main>
      );
    }

    return (
      <main className="admin admin-detail with-sidebar providers application-acceptance">
        <div className="sidebar-wrapper">
          <Sidebar
            {...props}
            iconClass="fas fa-id-card"
            linkGroup={linkGroup}
          />
        </div>
        <div className="content">
          <h3>
            Provider: {personalInfo.firstname}&nbsp;
            {personalInfo.lastname}
          </h3>
          <div className="detail-table">
            <h4 className="section-label">Details</h4>
            <div className="table-row">
              <div className="table-cell header">
                <p>Email</p>
              </div>
              <div className="table-cell value">
                <p>{user.email}</p>
              </div>
            </div>
            <div className="table-row">
              <div className="table-cell header">
                <p>Date of Birth</p>
              </div>
              <div className="table-cell value">
                <p>{personalInfo.DOB}</p>
              </div>
            </div>
            <div className="table-row">
              <div className="table-cell header">
                <p>Gender</p>
              </div>
              <div className="table-cell value">
                <p>{personalInfo.gender}</p>
              </div>
            </div>
            <div className="table-row">
              <div className="table-cell header">
                <p>Have Children?</p>
              </div>
              <div className="table-cell value">
                <p>{!!profileSitter.ownChildren ? 'Yes' : 'No'}</p>
              </div>
            </div>
            <div className="table-row">
              <div className="table-cell header">
                <p>Mobile Number</p>
              </div>
              <div className="table-cell value">
                <p>{personalInfo.phone1}</p>
              </div>
            </div>
            <div className="table-row">
              <div className="table-cell header">
                <p>Address</p>
              </div>
              <div className="table-cell value">
                <p>{displayAddress(personalInfo)}</p>
              </div>
            </div>
            <h4 className="section-label">Transportation</h4>
            <div className="table-row">
              <div className="table-cell header">
                <p>Have Vehicle?</p>
              </div>
              <div className="table-cell value">
                <p>{!!profileSitter.vehicle ? 'Yes' : 'No'}</p>
              </div>
            </div>
            {!!profileSitter.vehicle && (
              <div className="table-row">
                <div className="table-cell header">
                  <p>Vehicle Info</p>
                </div>
                <div className="table-cell value">
                  <p>{displayVehicleInfo(profileSitter)}</p>
                </div>
              </div>
            )}
            <div className="table-row">
              <div className="table-cell header">
                <p>Valid License?</p>
              </div>
              <div className="table-cell value">
                <p>{!!profileSitter.valid_drivers_license ? 'Yes' : 'No'}</p>
              </div>
            </div>
            <div className="table-row">
              <div className="table-cell header">
                <p>Willing to transport children?</p>
              </div>
              <div className="table-cell value">
                <p>
                  {!!profileSitter.willing_transport_children ? 'Yes' : 'No'}
                </p>
              </div>
            </div>
            <h4 className="section-label">Education</h4>
            <div className="table-row">
              <div className="table-cell header">
                <p>High School attended</p>
              </div>
              <div className="table-cell value">
                <p>{profileSitter.high_school_attended}</p>
              </div>
            </div>
            <div className="table-row">
              <div className="table-cell header">
                <p>Currently enrolled in college?</p>
              </div>
              <div className="table-cell value">
                <p>{!!profileSitter.college ? 'Yes' : 'No'}</p>
              </div>
            </div>
            {!!profileSitter.college && (
              <div className="table-row">
                <div className="table-cell header">
                  <p>College Name</p>
                </div>
                <div className="table-cell value">
                  <p>{profileSitter.college_name}</p>
                </div>
              </div>
            )}
            {!!profileSitter.college && (
              <div className="table-row">
                <div className="table-cell header">
                  <p>College Student Status</p>
                </div>
                <div className="table-cell value">
                  <p>{profileSitter.college_status}</p>
                </div>
              </div>
            )}
            {!!profileSitter.college && (
              <div className="table-row">
                <div className="table-cell header">
                  <p>Field of Study</p>
                </div>
                <div className="table-cell value">
                  <p>{profileSitter.college_field}</p>
                </div>
              </div>
            )}
            <div className="table-row">
              <div className="table-cell header">
                <p>First Aid certified?</p>
              </div>
              <div className="table-cell value">
                <p>{!!profileSitter.first_aid_certified ? 'Yes' : 'No'}</p>
              </div>
            </div>
            <div className="table-row">
              <div className="table-cell header">
                <p>CPR certified?</p>
              </div>
              <div className="table-cell value">
                <p>{!!profileSitter.cpr_certified ? 'Yes' : 'No'}</p>
              </div>
            </div>
            <div className="table-row">
              <div className="table-cell header">
                <p>Languages</p>
              </div>
              <div className="table-cell value">
                <p>
                  {!!languages.length
                    ? this.displayArray('languages', 'language')
                    : 'None'}
                </p>
              </div>
            </div>
            <h4 className="section-label">Background</h4>
            <div className="table-row">
              <div className="table-cell header">
                <p>How did they hear about us?</p>
              </div>
              <div className="table-cell value">
                <p>{profileSitter.hear_about_us}</p>
              </div>
            </div>
            <div className="table-row">
              <div className="table-cell header">
                <p>How long have they lived in the area?</p>
              </div>
              <div className="table-cell value">
                <p>{profileSitter.area_length_time}</p>
              </div>
            </div>
            <div className="table-row">
              <div className="table-cell header">
                <p>Recent backround check</p>
              </div>
              <div className="table-cell value">
                <p>{!!profileSitter.recent_background_check ? 'Yes' : 'No'}</p>
              </div>
            </div>
            <div className="table-row">
              <div className="table-cell header">
                <p>Recent arrest</p>
              </div>
              <div className="table-cell value">
                <p>{!!profileSitter.arrested ? 'Yes' : 'No'}</p>
              </div>
            </div>
            {!!profileSitter.arrested && (
              <div className="table-row">
                <div className="table-cell header">
                  <p>Arrest Info</p>
                </div>
                <div className="table-cell value">
                  <p>{profileSitter.arrested_info}</p>
                </div>
              </div>
            )}
            <div className="table-row">
              <div className="table-cell header">
                <p>Convicted of felony / misdemeanor?</p>
              </div>
              <div className="table-cell value">
                <p>{!!profileSitter.felony_misdemeanor ? 'Yes' : 'No'}</p>
              </div>
            </div>
            {!!profileSitter.felony_misdemeanor && (
              <div className="table-row">
                <div className="table-cell header">
                  <p>Felony / Misdemeanor Info</p>
                </div>
                <div className="table-cell value">
                  <p>{profileSitter.felony_misdemeanor_info}</p>
                </div>
              </div>
            )}
            <div className="table-row">
              <div className="table-cell header">
                <p>Conviced of DUI?</p>
              </div>
              <div className="table-cell value">
                <p>{!!profileSitter.dui ? 'Yes' : 'No'}</p>
              </div>
            </div>
            {!!profileSitter.dui && (
              <div className="table-row">
                <div className="table-cell header">
                  <p>DUI Info</p>
                </div>
                <div className="table-cell value">
                  <p>{profileSitter.dui_info}</p>
                </div>
              </div>
            )}
            <h4 className="section-label">Employment Info</h4>
            <div className="table-row">
              <div className="table-cell header">
                <p>Employed?</p>
              </div>
              <div className="table-cell value">
                <p>{!!personalInfo.employed ? 'Yes' : 'No'}</p>
              </div>
            </div>
            {!!personalInfo.employed && (
              <div className="table-row">
                <div className="table-cell header">
                  <p>Employer Name</p>
                </div>
                <div className="table-cell value">
                  <p>{personalInfo.employer}</p>
                </div>
              </div>
            )}
            {!!personalInfo.employed && (
              <div className="table-row">
                <div className="table-cell header">
                  <p>Employment Status</p>
                </div>
                <div className="table-cell value">
                  <p>{personalInfo.employment_status}</p>
                </div>
              </div>
            )}
            {!!personalInfo.employed && (
              <div className="table-row">
                <div className="table-cell header">
                  <p>Employment Notes</p>
                </div>
                <div className="table-cell value">
                  <p>{personalInfo.employment_notes}</p>
                </div>
              </div>
            )}
            <h4 className="section-label">Final Thoughts</h4>
            <div className="table-row">
              <div className="table-cell header">
                <p>Why they want to work for us</p>
              </div>
              <div className="table-cell value">
                <p>{profileSitter.why_work}</p>
              </div>
            </div>
            <div className="table-row">
              <div className="table-cell header">
                <p>Other Notes</p>
              </div>
              <div className="table-cell value">
                <p>{profileSitter.other_info}</p>
              </div>
            </div>
            <h4 className="section-label">Private Profile</h4>
            <div className="table-row">
              <div className="table-cell header">
                <p>Emergency Contact Name</p>
              </div>
              <div className="table-cell value">
                <p>{profileSitter.emergency_contact_name}</p>
              </div>
            </div>
            <div className="table-row">
              <div className="table-cell header">
                <p>Emergency Contact Phone</p>
              </div>
              <div className="table-cell value">
                <p>{profileSitter.emergency_contact_phone}</p>
              </div>
            </div>
            <div className="table-row">
              <div className="table-cell header">
                <p>Marital Status</p>
              </div>
              <div className="table-cell value">
                <p>{profileSitter.marital_status}</p>
              </div>
            </div>
            <div className="table-row">
              <div className="table-cell header">
                <p>Pets?</p>
              </div>
              <div className="table-cell value">
                <p>{!!profileSitter.pets ? 'Yes' : 'No'}</p>
              </div>
            </div>
            <div className="table-row">
              <div className="table-cell header">
                <p>Smoke?</p>
              </div>
              <div className="table-cell value">
                <p>{!!profileSitter.smoke ? 'Yes' : 'No'}</p>
              </div>
            </div>
            <div className="table-row">
              <div className="table-cell header">
                <p>Medical Info</p>
              </div>
              <div className="table-cell value">
                <p>
                  {!!medicalInfo.length
                    ? this.displayArray('medicalInfo', 'name')
                    : 'None'}
                </p>
              </div>
            </div>
            <div className="table-row">
              <div className="table-cell header">
                <p>Medical Notes</p>
              </div>
              <div className="table-cell value">
                <p>
                  {!!profileSitter.allergy_info
                    ? profileSitter.allergy_info
                    : 'None'}
                </p>
              </div>
            </div>
            <h4 className="section-label">Public Profile</h4>
            <div className="table-row">
              <div className="table-cell header">
                <p>Avatar</p>
              </div>
              <div className="table-cell value">
                {!!personalInfo.avatar_filename && (
                  <img
                    style={{ maxWidth: '200px' }}
                    src={personalInfo.avatar_filename}
                  />
                )}
                {!personalInfo.avatar_filename && <p>None</p>}
              </div>
            </div>
            <div className="table-row">
              <div className="table-cell header">
                <p>Intro</p>
              </div>
              <div className="table-cell value">
                <p>{profileSitter.intro}</p>
              </div>
            </div>
            <div className="table-row">
              <div className="table-cell header">
                <p>Bio</p>
              </div>
              <div className="table-cell value">
                <p>{profileSitter.bio}</p>
              </div>
            </div>
            <div className="table-row">
              <div className="table-cell header">
                <p>Preferred Age Ranges</p>
              </div>
              <div className="table-cell value">
                <p>
                  {!!acceptedAgeRanges.length
                    ? this.displayArray('acceptedAgeRanges', 'name')
                    : 'None'}
                </p>
              </div>
            </div>
            <div className="table-row">
              <div className="table-cell header">
                <p>Preferred Activities</p>
              </div>
              <div className="table-cell value">
                <p>
                  {!!selectedActivities.length
                    ? this.displayArray('selectedActivities', 'name')
                    : 'None'}
                </p>
              </div>
            </div>
          </div>
          <div className="form">
            <form onSubmit={this.handleSubmit}>
              {this.renderTextarea('admin_notes', 'Admin Notes')}
            </form>
          </div>
          {user.step.slug == 3 && (
            <p>
              This Provider has been sent an interview request. Accepting their
              application will allow them to complete their profile.
            </p>
          )}
          {user.step.slug !== 'sitter-application-finished' &&
            user.step.slug !== 'sitter-application-denied' && (
              <div className="actions">
                {user.step.slug === 'sitter-application-questions' && (
                  <button
                    className="theme-btn blue"
                    disabled={submitting}
                    onClick={this.handleAcceptSubmit}
                  >
                    {submitting && accept && (
                      <i className="fas fa-spinner fa-spin"></i>
                    )}
                    {submitting && accept
                      ? 'Sending...'
                      : 'Send Interview Request'}
                  </button>
                )}
                {user.step.slug === 'sitter-application-pending' && (
                  <button
                    className="theme-btn blue"
                    disabled={submitting}
                    onClick={this.handleAcceptSubmit}
                  >
                    {submitting && accept && (
                      <i className="fas fa-spinner fa-spin"></i>
                    )}
                    {submitting && accept
                      ? 'Approving...'
                      : 'Allow Provider to finish Profile'}
                  </button>
                )}
                {user.step.slug === 'sitter-availability' && (
                  <button
                    className="theme-btn blue"
                    disabled={submitting}
                    onClick={this.handleAcceptSubmit}
                  >
                    {submitting && accept && (
                      <i className="fas fa-spinner fa-spin"></i>
                    )}
                    {submitting && accept
                      ? 'Approving...'
                      : 'Give Provider dashboard access'}
                  </button>
                )}
                {user.step.slug === 'sitter-awaiting-admin-approval' && (
                  <button
                    className="theme-btn blue"
                    disabled={submitting}
                    onClick={this.handleAcceptSubmit}
                  >
                    {submitting && accept && (
                      <i className="fas fa-spinner fa-spin"></i>
                    )}
                    {submitting && accept
                      ? 'Approving...'
                      : 'Fully Approve Provider'}
                  </button>
                )}
                <button
                  className="theme-btn blue"
                  disabled={submitting}
                  onClick={this.saveSitter}
                >
                  {submitting && accept && (
                    <i className="fas fa-spinner fa-spin"></i>
                  )}
                  {submitting && accept ? 'Saving...' : 'Save Admin Notes'}
                </button>
                <button
                  className="theme-btn primary-coral"
                  disabled={submitting}
                  onClick={this.handleRejectSubmit}
                >
                  {submitting && !accept && (
                    <i className="fas fa-spinner fa-spin"></i>
                  )}
                  {submitting && !accept ? 'Rejecting...' : 'Reject'}
                </button>
              </div>
            )}
          <br />
          {user.step.slug == 'sitter-application-approved' && (
            <p>
              Great! This Provider has been accepted! They will recieve an email
              informing them that they can now continue their profile.
            </p>
          )}
          {user.step.slug == 'sitter-awaiting-admin-approval' && (
            <p>
              This Provider has been given access to their dashboard but is
              still awaiting Admin approval before they can begin sitting.
            </p>
          )}
          {user.step.slug == 'sitter-application-finished' && (
            <p>
              This Provider's application has been approved by an admin. They
              are now available for sitting.
            </p>
          )}
          {user.step.slug == 'sitter-application-denied' && (
            <p>This Provider's application has been rejected.</p>
          )}
          <div className="back">
            <Link
              className="theme-btn small slate-blue"
              to="/providers/applications"
            >
              Back
            </Link>
          </div>
        </div>
      </main>
    );
  }
}

export default ApplicationAcceptanceDetail;
