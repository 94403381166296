import React, { useCallback, useEffect, useRef, useState } from 'react';
import { NotificationPortal } from './NotificationPortal';
export const NotificationsMenu = (props) => {
    const { notifications } = props;
    const buttonLabel = `Notifications (${notifications.length} unread)`;
    const [isOpened, setIsOpened] = useState(false);
    const handleToggle = () => {
        setIsOpened((prevState) => !prevState);
    };
    const onRequestClose = useCallback(() => {
        setIsOpened(false);
    }, []);
    useEffect(() => {
        if (!isOpened) {
            return;
        }
        const closeOnEscape = function (event) {
            if (event.key === 'Escape') {
                setIsOpened(false);
            }
        };
        document.body.addEventListener('keydown', closeOnEscape);
        return () => {
            document.body.removeEventListener('keydown', closeOnEscape);
        };
    }, [isOpened]);
    const togglerRef = useRef(null);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "notifications" },
            React.createElement("button", { "aria-label": buttonLabel, className: "notification-icon", "aria-haspopup": "true", "aria-controls": "notification-menu", "aria-expanded": isOpened, onClick: handleToggle, ref: togglerRef },
                notifications.length > 0 && React.createElement("i", { className: "fas fa-bell-on" }),
                notifications.length === 0 && React.createElement("i", { className: "fas fa-bell" }),
                React.createElement("span", { "aria-hidden": "true", className: "notification-icon__counter" }, notifications.length),
                React.createElement("span", { className: "notification-icon__triangle" })),
            isOpened && (React.createElement(NotificationPortal, { notifications: notifications, onRequestClose: onRequestClose, togglerRef: togglerRef })))));
};
