import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { mapAuthState } from '../../../helpers/mapAuthState';

class SitterProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      //
    };
  }
  componentDidMount = () => {
    //
  };
  render = () => {
    const { user } = this.props.auth;
    const { profile_color } = user.personal_info;

    return (
      <main className="account profile sitter">
        <div
          className={`profile-header profile-color gradient ${
            !!profile_color ? profile_color : 'primary-coral'
          }`}
        >
          <div className="inner">
            <div className="avatar">
              <div className="avatar-wrapper">
                {!!user.personal_info.avatar_filename && (
                  <img src={user.personal_info.avatar_filename} />
                )}
                {!user.personal_info.avatar_filename && (
                  <i className="far fa-camera"></i>
                )}
              </div>
            </div>
            <h2 className="theme-h2">
              {user.personal_info.firstname}&nbsp;
              {user.personal_info.lastname}
            </h2>
          </div>
        </div>
        <div className="profile-body">
          <div className="links">
            <Link className="link" to="/profile/private">
              <div className="inner">
                <div className="icon">
                  <i className="fas fa-user-shield"></i>
                </div>
                <div className="text">
                  <h3 className="theme-h3">Private Profile</h3>
                  <i className="fas fa-arrow-right"></i>
                </div>
              </div>
            </Link>
            <Link className="link" to="/profile/public">
              <div className="inner">
                <div className="icon">
                  <i className="fas fa-id-card"></i>
                </div>
                <div className="text">
                  <h3 className="theme-h3">Public Profile</h3>
                  <i className="fas fa-arrow-right"></i>
                </div>
              </div>
            </Link>
            <Link className="link" to="/profile/availability">
              <div className="inner">
                <div className="icon">
                  <i className="fas fa-calendar-alt"></i>
                </div>
                <div className="text">
                  <h3 className="theme-h3">Availability</h3>
                  <i className="fas fa-arrow-right"></i>
                </div>
              </div>
            </Link>
          </div>
        </div>
      </main>
    );
  };
}

export default connect(mapAuthState)(SitterProfile);
