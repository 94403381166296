import Joi from 'joi';
import { findIndex } from 'lodash-es';
import moment from 'moment-timezone';
import React from 'react';
import { Prompt } from 'react-router-dom';
import { toast } from 'react-toastify';
import { makeTimeIncrements } from '../../helpers';
import sitterService from '../../services/sitterService';
import { Form, Modal } from '../shared';
import Calendar from '../shared/Appointments/Calendar';
import AvailabilityRow from '../Sitter/Account/AvailabilityRow';
class SitterAvailabilityForm extends Form {
    schema = Joi.object({
        sitterAvailability: Joi.array()
            .items(Joi.object({
            id: Joi.number(),
            date: Joi.date().required().label('Date'),
            timeslots: Joi.array()
                .items(Joi.object({
                start_time: Joi.string().required().label('Start Time'),
                end_time: Joi.string().required().label('End Time'),
            }))
                .label('Timeslots'),
            end_time: Joi.optional().allow('').allow(null),
            start_time: Joi.optional().allow('').allow(null),
        }))
            .label('Dates'),
        availability_notes: Joi.optional().allow('').allow(null),
        recurring_start_time: Joi.optional().allow('').allow(null),
        recurring_end_date: Joi.optional().allow('').allow(null),
        recurring_end_time: Joi.optional().allow('').allow(null),
        recurring_start_date: Joi.optional().allow('').allow(null),
    });
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            data: {
                sitterAvailability: [],
                recurring_start_time: null,
                recurring_end_time: null,
                recurring_start_date: null,
                recurring_end_date: null,
            },
            freshSchema: {
                id: null,
                date: new Date(),
                timeslots: [],
                start_time: null,
                end_time: null,
            },
            errors: {},
            loading: true,
            times: makeTimeIncrements(),
            days: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
            selectedDays: [],
            canDecreaseDate: false,
            today: moment(),
            showRecurringTimeSlot: false,
            showRecurringErrorModal: false,
            recurringError: null,
            end_times: makeTimeIncrements(),
            start_times: makeTimeIncrements(7, 21),
            submitting: false,
            dirty: false,
        };
    }
    onAvailabilityChangeData = (idx, availData) => {
        const data = { ...this.state.data };
        data.sitterAvailability[idx] = availData;
        this.setState({ data });
    };
    removeAvailability = (idx) => {
        const { data } = this.state;
        const sitterAvailability = [...data.sitterAvailability];
        sitterAvailability.splice(idx, 1);
        data.sitterAvailability = sitterAvailability;
        const dirty = sitterAvailability.length > 0;
        this.setState({ data, dirty });
    };
    validateForm = () => {
        const { sitterAvailability } = this.state.data;
        this.setState({
            submitting: true,
        });
        // validate that they all have a timeslot
        const timeslotCheck = sitterAvailability.every(({ timeslots }) => timeslots.length > 0);
        if (!timeslotCheck) {
            toast.error('Oops! Please fill in a timeslot for each day selected');
            this.setState({
                errors: {},
                submitting: false,
            });
        }
        else {
            const errors = this.validate();
            if (errors) {
                toast.error('Oops! Looks like a few fields need some attention.');
                this.setState({
                    errors,
                    submitting: false,
                });
                return;
            }
            // this is probably not the greatest way to check, but not a lot of time
            const monthAmounts = [0, 0];
            const nextMonths = [
                moment().add(1, 'months').format('MM'),
                moment().add(2, 'months').format('MM'),
            ];
            for (const [i, nextMonth] of nextMonths.entries()) {
                for (const avail of sitterAvailability) {
                    if (moment(avail.date).format('MM') === nextMonth) {
                        monthAmounts[i]++;
                    }
                }
            }
            this.doSubmit(this.postSubmit);
            // if (monthAmounts[0] > 3 && monthAmounts[1] > 3) {
            //   this.doSubmit(this.postSubmit);
            // } else {
            //   this.setState({
            //     showRecurringErrorModal: !this.state.showRecurringErrorModal,
            //   });
            // }
        }
    };
    loadOldMonths = () => {
        if (this.state.canDecreaseDate) {
            const firstMonth = this.state.months[0];
            const earliestMonth = moment(firstMonth).subtract(this.state.numMonths, 'months');
            const months = this.state.numMonths === 3
                ? [
                    earliestMonth,
                    moment(firstMonth).subtract(2, 'months'),
                    moment(firstMonth).subtract(1, 'months'),
                ]
                : [earliestMonth];
            if (earliestMonth.isSameOrBefore(this.state.today, 'month')) {
                this.setState({
                    months,
                    canDecreaseDate: false,
                });
            }
            else {
                this.setState({
                    months,
                    canDecreaseDate: true,
                });
            }
        }
    };
    loadMoreMonths = () => {
        const lastMonth = this.state.months[this.state.months.length - 1];
        const months = this.state.numMonths === 3
            ? [
                moment(lastMonth).add(1, 'months'),
                moment(lastMonth).add(2, 'months'),
                moment(lastMonth).add(3, 'months'),
            ]
            : [moment(lastMonth).add(1, 'months')];
        this.setState({
            months,
            canDecreaseDate: true,
        });
    };
    onSelectedDay = (currentDate) => {
        if (moment(currentDate).isSameOrAfter(this.state.today) === false) {
            toast.error('Uh oh! You must select a date after today.');
            return;
        }
        const data = { ...this.state.data };
        toast.dismiss();
        const date = moment(currentDate).format('YYYY-MM-DD');
        const selected = data.sitterAvailability.filter((item) => moment(item.date).format('YYYY-MM-DD') === date);
        if (selected.length > 0) {
            toast.error('Uh oh! You have already selected this day');
        }
        else {
            const freshSchema = { ...this.state.freshSchema };
            // we assign it a random id here so that we can reference that instead of its index
            // using its index in the array causes problems with splicing
            freshSchema.id = Math.round(Math.random() * 10000000);
            freshSchema.date = date;
            freshSchema.timeslots = [];
            const sitterAvailability = [...data.sitterAvailability];
            sitterAvailability.push(freshSchema);
            data.sitterAvailability = sitterAvailability;
            this.setState({ data });
        }
    };
    submitFormData = async (callback) => {
        try {
            let piid = this.state.id;
            if (piid === null) {
                piid = this.props.auth.user.personal_info.id;
            }
            const availability = [...this.state.data.sitterAvailability];
            const apiData = {
                sitter_availability: availability,
                availability: true,
            };
            const response = await sitterService.update(piid, apiData);
            if (response.status === 200) {
                this.setState({ submitting: false, dirty: false });
                toast.success('Hooray! Your availability has been saved!');
                if (callback) {
                    callback();
                }
            }
            else {
                this.setState({ submitting: false, dirty: true });
                toast.error('There was an issue saving your availability');
            }
        }
        catch {
            this.setState({ submitting: false, dirty: true });
            toast.error('There was an issue saving your availability');
        }
    };
    toggleAvailabilityModal = (event) => {
        if (event) {
            event.preventDefault();
        }
        this.setState({
            showRecurringTimeSlot: !this.state.showRecurringTimeSlot,
        });
    };
    toggleSelectedDay = (day) => {
        let { selectedDays } = this.state;
        if (selectedDays.includes(day)) {
            selectedDays = selectedDays.filter((s_day) => {
                return s_day !== day;
            });
        }
        else {
            selectedDays.push(day);
        }
        this.setState({ selectedDays });
    };
    dirtyForm = () => {
        this.setState({ dirty: true });
    };
    filterEndTimes = () => {
        const newEndTimes = [...this.state.times];
        const { data } = this.state;
        const idx = findIndex(newEndTimes, (time) => {
            return String(time.value) === String(data.recurring_start_time);
        });
        const length = idx + 12;
        if (length === newEndTimes.length) {
            // if they select the very last time slot (11:45pm), then there are 0 options left
            // So we force there to be only 1 item
            newEndTimes.splice(0, length - 1);
        }
        else {
            newEndTimes.splice(0, length);
        }
        data.recurring_end_time =
            newEndTimes[0] !== undefined ? newEndTimes[0].value : '23:45';
        this.setState({
            end_times: newEndTimes,
            data,
        });
    };
    saveRecurringTimeSlot = () => {
        const { selectedDays, data } = this.state;
        const { recurring_start_date, recurring_end_date, recurring_start_time, recurring_end_time, sitterAvailability, } = data;
        this.setState({ recurringError: null });
        if (selectedDays.length === 0 ||
            recurring_start_date === null ||
            recurring_end_date === null ||
            recurring_start_time === null ||
            recurring_end_time === null) {
            this.setState({ recurringError: 'Please Select All Fields!' });
        }
        else {
            this.dirtyForm();
            const daysIndex = {
                Sun: 0,
                Mon: 1,
                Tue: 2,
                Wed: 3,
                Thu: 4,
                Fri: 5,
                Sat: 6,
            };
            const start = moment(recurring_start_date);
            const end = moment(recurring_end_date);
            const dates = [];
            for (const selectedDay of selectedDays) {
                const tmp = start.clone().day(daysIndex[selectedDay]);
                if (tmp.isAfter(start, 'd') ||
                    tmp.format('YYYY-MM-DD') === start.format('YYYY-MM-DD')) {
                    dates.push(tmp.format('YYYY-MM-DD'));
                }
                while (tmp.isBefore(end, 'd')) {
                    tmp.add(7, 'days');
                    if (tmp.isBefore(end, 'd') ||
                        tmp.format('YYYY-MM-DD') === end.format('YYYY-MM-DD')) {
                        dates.push(tmp.format('YYYY-MM-DD'));
                    }
                }
            }
            const results = [];
            const filteredDates = [];
            for (const date of dates) {
                for (const [i, avail] of sitterAvailability.entries()) {
                    if (date === avail.date) {
                        // adding new timeslot
                        sitterAvailability[i].timeslots.push({
                            start_time: recurring_start_time,
                            end_time: recurring_end_time,
                        });
                        filteredDates.push(date);
                    }
                }
            }
            for (const date of dates) {
                if (!filteredDates.includes(date)) {
                    results.push({
                        id: Math.round(Math.random() * 10000000),
                        date,
                        timeslots: [
                            {
                                start_time: recurring_start_time,
                                end_time: recurring_end_time,
                            },
                        ],
                    });
                }
            }
            const { data, showRecurringTimeSlot } = this.state;
            if (results.length > 0 || filteredDates.length > 0) {
                this.setState({
                    showRecurringTimeSlot: !showRecurringTimeSlot,
                    selectedDays: [],
                    data: {
                        ...data,
                        sitterAvailability: [...sitterAvailability, ...results],
                        recurring_start_time: null,
                        recurring_end_time: null,
                        recurring_end_date: null,
                        recurring_start_date: null,
                    },
                });
            }
            else {
                this.setState({
                    recurringError: 'There Are No Days That Match That Range',
                });
            }
        }
    };
    renderForm = () => {
        const { data, canDecreaseDate, months, dirty, times, showRecurringTimeSlot, days, selectedDays, recurringError, end_times, start_times, showRecurringErrorModal, submitting, } = this.state;
        const { sitterAvailability, recurring_start_time, recurring_start_date } = data;
        const endTimeDisabled = !recurring_start_time;
        const endDateDisabled = !recurring_start_date;
        const today = moment().toDate();
        const end_date_start = moment(recurring_start_date).toDate();
        const sortedAvailability = [...sitterAvailability].sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
        return (React.createElement(React.Fragment, null,
            React.createElement("button", { type: "submit", className: "theme-btn blue auto-width", onClick: this.toggleAvailabilityModal },
                React.createElement("i", { className: "fas fa-sync-alt" }),
                "Add Recurring Time"),
            React.createElement("main", { className: "availability sitter-availability" },
                React.createElement(Prompt, { when: dirty, message: "Changes will be LOST if you navigate away from the page. Are you sure you want to proceed?" }),
                React.createElement("div", { className: "content" },
                    React.createElement("div", { className: "sitter-availability-form" },
                        React.createElement("div", { className: "calendar-view" },
                            React.createElement("div", { className: "calendar-wrapper" },
                                React.createElement("button", { type: "button", className: `arrow prev ${!canDecreaseDate ? 'disabled' : ''}`, onClick: this.loadOldMonths },
                                    React.createElement("i", { className: "fas fa-arrow-alt-left" })),
                                React.createElement("button", { className: "arrow next", type: "button", onClick: this.loadMoreMonths },
                                    React.createElement("i", { className: "fas fa-arrow-alt-right" })),
                                React.createElement("div", { className: "calendars" }, months.map((month, i) => {
                                    return (React.createElement(Calendar, { key: i, appointments: sitterAvailability, showAppointments: false, date: month, onSelectedDay: this.onSelectedDay, type: "availability" }));
                                })))),
                        React.createElement("div", { className: "availability-fields" },
                            React.createElement("div", { className: "availability-rows" }, sortedAvailability.map((avail, idx) => (React.createElement(AvailabilityRow, { key: `avail-${idx}-${avail.id}`, availability: avail, times: times, idx: idx, onAvailabilityChangeData: this.onAvailabilityChangeData, removeAvailability: this.removeAvailability, dirty: this.dirtyForm }))))),
                        React.createElement("div", { className: "submit-wrapper" },
                            React.createElement("div", { className: "form-field button submit" },
                                React.createElement("button", { type: "submit", className: "theme-btn primary-green", disabled: submitting, onClick: this.validateForm },
                                    submitting && React.createElement("i", { className: "fas fa-spinner fa-spin" }),
                                    !submitting && React.createElement("i", { className: "fas fa-save" }),
                                    submitting ? 'Saving...' : 'Save'))))),
                React.createElement(Modal, { title: "Add Recurring Time Slot", isOpen: showRecurringTimeSlot, closeModal: () => {
                        this.setState({
                            showRecurringTimeSlot: !showRecurringTimeSlot,
                            selectedDays: [],
                            data: {
                                ...data,
                                recurring_start_time: null,
                                recurring_end_time: null,
                                recurring_end_date: null,
                                recurring_start_date: null,
                            },
                        });
                    }, content: React.createElement(React.Fragment, null,
                        React.createElement("div", null, "Enter your start and end times for this recurring time slot"),
                        React.createElement("div", { className: "modal-time-slot" },
                            React.createElement("div", { className: "form-field-wrapper start_time" }, this.renderSelect('recurring_start_time', 'Start Time', start_times, true)),
                            React.createElement("div", { className: "form-field-wrapper end_time" }, this.renderSelect('recurring_end_time', 'End Time', end_times, true, { disabled: endTimeDisabled }))),
                        React.createElement("div", null, "What days of the week does this time slot apply?"),
                        React.createElement("div", { className: "modal-week-days" }, days.map((day) => {
                            return (React.createElement("div", { key: day, className: selectedDays.includes(day) ? 'day selected' : 'day', onClick: () => this.toggleSelectedDay(day) }, day));
                        })),
                        React.createElement("div", null, "Enter the start and end dates for this recurring slot"),
                        React.createElement("div", { className: "modal-time-slot" },
                            React.createElement("div", { className: "form-field-wrapper start_time" }, this.renderDatePicker('recurring_start_date', 'Start Date', true, 'MMM d, yyyy', false, today)),
                            React.createElement("div", { className: "form-field-wrapper end_time" }, this.renderDatePicker('recurring_end_date', 'End Date', true, 'MMM d, yyyy', endDateDisabled, end_date_start))),
                        recurringError && (React.createElement("div", { className: "error" }, recurringError))), saveButton: 'Apply', secondaryButton: 'Close', handleClick: () => this.saveRecurringTimeSlot() }),
                React.createElement(Modal, { title: "Required Timeslots", isOpen: showRecurringErrorModal, closeModal: () => {
                        this.setState({
                            showRecurringErrorModal: !showRecurringErrorModal,
                            errors: {},
                            submitting: false,
                        });
                    }, content: 'It appears you have not fulfilled the requirement for 4 time slots in the next 2 subsequent months. If you proceed, an administrator will be notified to review your status.', saveButton: 'Proceed to Save', secondaryButton: 'Cancel', handleClick: () => this.setState({
                        showRecurringErrorModal: !showRecurringErrorModal,
                    }, () => {
                        this.doSubmit(this.postSubmit);
                    }) }))));
    };
}
export default SitterAvailabilityForm;
