import React from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { displayAge } from '../../helpers';
import { mapAuthState } from '../../helpers/mapAuthState';
import appointmentService from '../../services/appointmentService';
import SitLogForm from '../Forms/SitLogForm';
class SitLog extends SitLogForm {
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            appointmentData: null,
            loading: true,
            id: null,
        };
    }
    componentDidMount = () => {
        this.getAppointmentById(this.props.match.params.id);
    };
    getAppointmentById = async (id) => {
        const response = await appointmentService.get(id);
        if (response.status === 200) {
            const appointmentData = response.data.data;
            this.setState({
                appointmentData,
            });
        }
    };
    doSubmit = async (callback) => {
        try {
            await this.submitFormData();
            callback();
            toast.success('Hooray! Your sit log has been submitted.');
            this.props.history.goBack();
        }
        catch (error) {
            console.log(error);
            callback();
        }
    };
    render() {
        const { appointmentData } = this.state;
        if (!appointmentData) {
            return (React.createElement("main", { className: "sit-log form loading" },
                React.createElement("div", { className: "sitter-sit-log-form-wrapper" },
                    React.createElement("h2", { className: "theme-h2" }, "Loading Sit Log Data..."))));
        }
        return (React.createElement("main", { className: "sit-log form" },
            React.createElement("div", { className: "sitter-sit-log-form-wrapper" },
                React.createElement("h2", { className: "theme-h2" }, "Sit Log"),
                React.createElement("h4", { className: "section-label" }, "Details"),
                React.createElement("p", null,
                    React.createElement("b", null, "Family:"),
                    React.createElement("br", null),
                    appointmentData.family.name),
                React.createElement("p", null,
                    React.createElement("b", null, "Children:"),
                    React.createElement("br", null),
                    appointmentData.children.map((child, idx) => (React.createElement("span", { key: `sitter-log-child-${child.id}` },
                        child.firstname,
                        "\u00A0(",
                        displayAge(child.DOB),
                        ")",
                        idx < appointmentData.children.length - 1 ? ', ' : '')))),
                React.createElement("form", { onSubmit: this.handleSubmit },
                    this.renderForm(),
                    this.renderButton('Save', 'Saving...', 'theme-btn blue')))));
    }
}
export default connect(mapAuthState)(SitLog);
