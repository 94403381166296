import '@reach/menu-button/styles.css';
import { useNotificationsQueryKey } from '@ss/hooks/queryKeys';
import { useReportError } from '@ss/hooks/useReportError';
import { notificationService } from '@ss/services';
import React from 'react';
import { useQuery } from 'react-query';
import { NotificationsMenu } from './NotificationsMenu';
export const Notifications = () => {
    const reportError = useReportError();
    const query = useQuery(useNotificationsQueryKey(), notificationService.getNotifications, {
        onError: (error) => {
            reportError('Unable to fetch notifications', error);
        },
    });
    const data = query.isSuccess ? query.data : [];
    return React.createElement(NotificationsMenu, { notifications: data });
};
