import $ from 'jquery';
import React from 'react';
import { connect } from 'react-redux';
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  injectStripe,
} from 'react-stripe-elements';
import { toast } from 'react-toastify';
import { states } from '../../../data';
import stripeService from '../../../services/stripeService';
import { Form } from '../../shared';

import Joi from 'joi';
import { mapAuthState } from '../../../helpers/mapAuthState';

const fieldStyles = {
  fontFamily: "'Open Sans', sans-serif",
  fontSize: '16px',
  color: '#252525',
  '::placeholder': {
    color: '#FFF',
  },
};

const radioOptions = [
  { label: 'Yes', value: 1 },
  { label: 'No', value: 0 },
];

class NewCardForm extends Form {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        firstname: '',
        lastname: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
        zip: '',
      },
      errors: {},
      user: {},
    };
  }
  schema = Joi.object({
    firstname: Joi.string().required().label('First Name'),
    lastname: Joi.string().required().label('Last Name'),
    address1: Joi.string().required().label('Address (line 1)'),
    address2: Joi.string()
      .optional()
      .allow('')
      .allow(null)
      .label('Address (line 2)'),
    city: Joi.string().required().label('City'),
    state: Joi.string().required().label('State'),
    zip: Joi.string().required().label('ZIP code'),
  });
  componentDidMount() {
    // Syncing the personal info was causing confusion when the card was saved
    // Since it wasn't pulling in the old billing info for edit, it looked like the billing address
    // wasn't saving properly. To avoid confusion, we're just presenting them with a blank form.
    // this.syncPersonalInfo();
  }
  componentDidUpdate(prevProps, prevState) {
    const prevData = prevState.data;
    const { data } = this.state;
  }
  syncPersonalInfo = () => {
    const { personal_info } = this.props.auth.user;
    const { firstname, lastname, address1, address2, city, state, zip } =
      personal_info;
    const { data } = this.state;

    data['firstname'] = firstname;
    data['lastname'] = lastname;
    data['address1'] = address1;
    data['address2'] = address2;
    data['city'] = city;
    data['state'] = state;
    data['zip'] = zip;

    this.setState({
      data,
    });
  };
  doSubmit = async (callback) => {
    let display_error = false;

    try {
      const { id } = this.props.auth.user;
      const { firstname, lastname, address1, address2, city, state, zip } =
        this.state.data;
      const cardNumber = this.props.elements.getElement('cardNumber');
      let { paymentMethod, error } =
        await this.props.stripe.createPaymentMethod({
          type: 'card',
          card: cardNumber,
          billing_details: {
            name: firstname + ' ' + lastname,
            address: {
              line1: address1,
              line2: address2,
              city,
              postal_code: zip,
              state,
            },
          },
        });
      if (error) {
        this.handleStripeError(error);
        callback();
      } else {
        console.log(paymentMethod);
        const token = {
          paymentMethod: paymentMethod,
        };

        const response = await stripeService.update(id, token);
        console.log(response);

        if (response.status === 200 && response.data.payment_accepted == true) {
          this.props.onPaymentMethodSaved(paymentMethod);
          toast.success('Your default card has been saved!');
        } else {
          toast.error(
            'Your card was declined. Please check your information and try again.'
          );
          callback();
        }
      }
    } catch (e) {
      console.log(e);
      callback();
    }
  };
  handleStripeError = (errorObj) => {
    const { code } = errorObj;
    if (code === 'incomplete_number') {
      //
    }
    if (code === 'incomplete_expiry') {
      //
    }
    if (code === 'incomplete_cvc') {
      //
    }
    $('.registration').animate({ scrollTop: 0 });
  };
  toggleEditBilling = () => {
    this.setState({
      editBillingAddress: !this.state.editBillingAddress,
    });
  };
  render() {
    return (
      <form onSubmit={this.handleSubmit} className="new-card-form">
        <div className="payment-fields">
          <div className="payment-billing-address">
            {this.renderInput('firstname', 'First Name', 'text', true)}
            {this.renderInput('lastname', 'Last Name', 'text', true)}
            {this.renderInput('address1', 'Address', 'text', true)}
            {this.renderInput('address2', 'Apt/Suite', 'text')}
            {this.renderInput('city', 'City', 'text', true)}
            {this.renderSelect('state', 'State', states, true)}
            {this.renderInput('zip', 'ZIP', 'number', true)}
          </div>
          <div className="payment-field card-number">
            <CardNumberElement
              className="card-number"
              style={{ base: fieldStyles }}
            />
            <label>
              Card Number<span className="required">&nbsp;*</span>
            </label>
          </div>
          <div className="payment-field card-expiry">
            <CardExpiryElement
              className="card-expiry"
              placeholder="Expiration"
              style={{ base: fieldStyles }}
            />
            <label>
              Expiration Date
              <span className="required">&nbsp;*</span>
            </label>
          </div>
          <div className="payment-field card-cvc-num">
            <CardCvcElement
              className="card-cvc-num"
              style={{ base: fieldStyles }}
            />
            <label>
              CSV<span className="required">&nbsp;*</span>
            </label>
          </div>
        </div>
        <div className="booking-footer">
          {this.renderButton(
            'Save card',
            'Saving',
            'theme-btn slate-blue small',
            false
          )}
        </div>
      </form>
    );
  }
}

export default connect(mapAuthState)(injectStripe(NewCardForm));
