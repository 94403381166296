import clsx from 'clsx';
import React from 'react';
const getRemainingWords = (value) => {
    if (value === '' || value === null) {
        return 0;
    }
    return value.split(' ').length;
};
const onKeyDown = (event, type) => {
    const invalidChars = ['-', '+', 'e'];
    if (type === 'number' && invalidChars.includes(event.key)) {
        event.preventDefault();
    }
};
const Input = ({ name, label, type, error, value, required, className = '', wordCount, ...rest }) => {
    return (React.createElement("div", { className: clsx('form-field', name, type, value ? 'active' : 'inactive', className) },
        React.createElement("input", { ...rest, value: value, name: name, id: name, type: type, className: clsx(value ? 'active' : 'inactive', error ? 'error' : ''), onKeyDown: (e) => {
                onKeyDown(e, type);
            } }),
        React.createElement("label", { htmlFor: name },
            label,
            required && React.createElement("span", { className: "required" }, "\u00A0*")),
        wordCount && (React.createElement("span", { className: "word-count" },
            getRemainingWords(value),
            "\u00A0/\u00A0",
            wordCount,
            " words")),
        error && (React.createElement("span", { className: "error", role: "alert" },
            React.createElement("strong", null, error)))));
};
export default Input;
