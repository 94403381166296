import { buildApiUrl } from '../helpers/buildApiUrl';
import { getNextCursor } from './../helpers/pagination';
import httpService from './httpService';
const list = (data = {}) => {
    const apiUrl = buildApiUrl('/profileSitter');
    return httpService.request('get', apiUrl, data);
};
const available = (appointment_id, type, data = {}) => {
    const apiUrl = buildApiUrl(`/profileSitter/available/${appointment_id}/${type}`);
    return httpService.request('get', apiUrl, data);
};
const availability = (data = {}) => {
    const apiUrl = buildApiUrl('/profileSitter/availability');
    return httpService.request('get', apiUrl, data);
};
const get = (id) => {
    const apiUrl = buildApiUrl(`/profileSitter/${id}`);
    return httpService.request('get', apiUrl);
};
const getPending = (data) => {
    const apiUrl = buildApiUrl('/profileSitter/pending');
    return httpService.request('get', apiUrl, data);
};
const getActiveRequests = (piid) => {
    const apiUrl = buildApiUrl(`/profileSitter/appointments/active/${piid}`);
    return httpService.request('get', apiUrl);
};
const updateApplicationStatus = (id, data, status) => {
    const apiUrl = buildApiUrl(`/profileSitter/application/${id}/${status}`);
    return httpService.post(apiUrl, data);
};
const store = (data, headers) => {
    const apiUrl = buildApiUrl('/profileSitter/store');
    return httpService.post(apiUrl, data, headers);
};
const update = (id, data, headers) => {
    const apiUrl = buildApiUrl(`/profileSitter/update/${id}`);
    return httpService.post(apiUrl, data, headers);
};
const search = (data, headers) => {
    const apiUrl = buildApiUrl('/profileSitter/search');
    return httpService.get(apiUrl, data, headers);
};
const filter = async (params) => {
    const apiUrl = buildApiUrl('/profileSitter/filter');
    const { pageParam = 0, perPage = 10, ...rest } = params;
    const requestParams = {
        take: String(perPage),
        skip: String(pageParam * perPage),
        ...rest,
    };
    const response = await httpService.get(apiUrl, requestParams);
    const { data, meta } = response.data;
    return { data, nextCursor: getNextCursor(meta) };
};
const deleteSitter = (id) => {
    const data = new FormData();
    const headers = { headers: { 'content-type': 'multipart/form-data' } };
    data.append('_method', 'delete');
    const apiUrl = buildApiUrl(`/profileSitter/delete/${id}`);
    return httpService.delete(apiUrl, {}, headers);
};
const confirmAppointment = (sitterPiid, appointmentId) => {
    const apiUrl = buildApiUrl(`/profileSitter/appointments/confirm/${sitterPiid}/${appointmentId}`);
    return httpService.post(apiUrl);
};
const declineAppointment = (sitterPiid, appointmentId) => {
    const apiUrl = buildApiUrl(`/profileSitter/appointments/decline/${sitterPiid}/${appointmentId}`);
    return httpService.post(apiUrl);
};
const storeSitLog = (appointmentId, data) => {
    const apiUrl = buildApiUrl(`/sitLog/store/${appointmentId}`);
    return httpService.post(apiUrl, data);
};
const storeSitterSignups = (data) => {
    const apiUrl = buildApiUrl('/sitterSignups/store');
    return httpService.post(apiUrl, data);
};
const getBlockedSitters = async (familyId) => {
    const apiUrl = buildApiUrl(`/profileSitter/blocked/${familyId}`);
    const response = await httpService.get(apiUrl);
    return response.data.data.sitters;
};
export default {
    list,
    get,
    getPending,
    getActiveRequests,
    store,
    update,
    delete: deleteSitter,
    search,
    filter,
    confirmAppointment,
    declineAppointment,
    updateApplicationStatus,
    availability,
    available,
    storeSitLog,
    storeSitterSignups,
    getBlockedSitters,
};
