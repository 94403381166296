import Joi from 'joi';
import React from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { authActions } from '../../actions/authActions';
import { joiPasswordRulesHelper, userHasCompletedRegistration, userHasRole, } from '../../helpers';
import { mapAuthState } from '../../helpers/mapAuthState';
import Form from './Form';
class PasswordResetSet extends Form {
    schema = Joi.object({
        password: Joi.string()
            .custom(joiPasswordRulesHelper, 'custom validation')
            .required()
            .messages({
            'password.lowercase': 'Password must contain at least 1 lowercase character.',
            'password.uppercase': 'Password must contain at least 1 uppercase character.',
            'password.number': 'Password must contain at least 1 number',
            'password.special_character': 'Password must contain at least 1 special character.',
            'password.length': 'Password must be at least 8 characters long.',
        })
            .label('New Password'),
    });
    constructor(props) {
        super(props);
        this.state = { ...this.state, data: { password: '' }, errors: {} };
    }
    doSubmit = async (callback) => {
        const apiData = { ...this.state.data, token: this.props.match.params.hash };
        try {
            const response = await this.props.resetPassword(apiData);
            if (response.status === 201) {
                toast.success('Hooray! Your password has been saved.');
                const { user } = this.props.auth;
                if (!!user) {
                    const { step } = user;
                    if (!step && userHasRole(user, 'Family')) {
                        this.props.history.push('/register/family/profile');
                    }
                    if (!step && userHasRole(user, 'Sitter')) {
                        this.props.history.push('/register/sitter/application');
                    }
                    if (!userHasCompletedRegistration(user) && step) {
                        // The register section for each side determines where they need to go
                        switch (step.slug) {
                            case 'sitter-signup':
                            case 'sitter-application-questions':
                            case 'sitter-application-pending':
                            case 'sitter-application-approved':
                            case 'sitter-private-profile':
                            case 'sitter-public-profile':
                            case 'sitter-availability':
                            case 'sitter-application-finished':
                                this.props.history.push('/register/sitter');
                                break;
                            case 'family-signup':
                            case 'family-profile':
                            case 'family-children':
                            case 'family-pets':
                            case 'family-payments':
                                this.props.history.push('/register/family');
                                break;
                        }
                    }
                }
            }
            callback();
        }
        catch {
            callback();
        }
    };
    goBack = (event) => {
        if (event) {
            event.preventDefault();
        }
        this.props.history.goBack();
    };
    render() {
        return (React.createElement("main", { className: "password update" },
            React.createElement("div", { className: "inner" },
                React.createElement("form", { onSubmit: this.handleSubmit },
                    React.createElement("h2", null, "Reset Password"),
                    this.renderPasswordWithRules('password', 'New Password'),
                    this.renderButton('Save Password', 'Saving...', 'theme-btn blue')))));
    }
}
const actionCreators = {
    resetPassword: authActions.resetPassword,
};
export default connect(mapAuthState, actionCreators)(PasswordResetSet);
