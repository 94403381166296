const reactSelectStyles = {
    control: (styles, state) => {
        return {
            ...styles,
            backgroundColor: 'white',
            borderWidth: '1px',
            borderStyle: 'solid',
            borderColor: '#d2d2d2',
            boxShadow: 'none',
            borderBottomLeftRadius: state.menuIsOpen
                ? 0
                : styles.borderBottomLeftRadius,
            borderBottomRightRadius: state.menuIsOpen
                ? 0
                : styles.borderBottomRightRadius,
        };
    },
    valueContainer: (styles) => ({
        ...styles,
        paddingTop: '26px',
        paddingLeft: '10px',
        paddingRight: '10px',
        paddingBottom: '10px',
    }),
    option: (styles) => ({
        ...styles,
        color: '#2C2C2C',
        borderBottom: '1px solid #D2D2D2',
        fontSize: '16px',
        lineHeight: '1.1em',
        ':hover': styles[':hover'],
        ':focus': styles[':focus'],
        ':active': styles[':active'],
        ':last-of-type': {
            borderBottom: 'none',
        },
    }),
    menu: (styles) => ({
        ...styles,
        border: '1px solid #d2d2d2',
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        marginTop: 0,
        boxShadow: 'none',
    }),
    menuList: (styles) => ({
        ...styles,
        paddingTop: 0,
        paddingBottom: 0,
    }),
    input: (styles) => ({
        ...styles,
        margin: 0,
        padding: 0,
        width: '10px',
    }),
    multiValue: (styles) => ({
        ...styles,
        backgroundColor: '#1b5687',
        borderRadius: '4px',
    }),
    multiValueLabel: (styles) => ({
        ...styles,
        fontSize: '13px',
        lineHeight: '1.1em',
        color: '#fff',
        paddingTop: '3px',
        paddingRight: '3px',
        paddingBottom: '4px',
        paddingLeft: '10px',
    }),
    multiValueRemove: (styles) => ({
        ...styles,
        color: '#fff',
        backgroundColor: 'transparent',
        borderTopRightRadius: '4px',
        borderBottomRightRadius: '4px',
    }),
};
export default reactSelectStyles;
