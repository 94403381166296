import { displayAge, slugify, upperCaseFirst } from '@ss/helpers';
import appointmentService from '@ss/services/appointmentService';
import communicationsService from '@ss/services/communicationsService';
import sitterService from '@ss/services/sitterService';
import { each, find, sortBy } from 'lodash-es';
import moment from 'moment-timezone';
import React from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { mapAuthState } from '../../../helpers/mapAuthState';
import { Avatar, Modal } from '../../shared';
import Calendar from '../../shared/Appointments/Calendar';
import BooleanInput from '../../shared/Form/BooleanInput';
import Textarea from '../../shared/Form/Textarea';
class ProgramDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {
                sitter_notes: '',
                confirm_1: false,
                confirm_2: false,
                confirm_3: false,
            },
            loading: true,
            submitting: false,
            currentTab: 'calendar',
            signingUp: false,
            returning: false,
            appointments: [],
            sitterSignups: [],
            sitterSignup: null,
            communications: [],
            activities: [],
            communication: '',
            family: null,
            children: [],
            booking: null,
            owner: null,
            canDecreaseDate: false,
            months: [moment(), moment().add(1, 'months'), moment().add(2, 'months')],
            today: moment(new Date()),
            unavailableAppointments: [],
            programStartDate: false,
            programEndDate: false,
            showSubmitModal: false,
            numMonths: 0,
        };
    }
    componentDidMount() {
        this.getAppointments();
        this.getCommunications();
        if (this.props.windowWidth >= 1200 && this.state.numMonths !== 3) {
            this.setState({
                numMonths: 3,
                months: [
                    moment(),
                    moment().add(1, 'months'),
                    moment().add(2, 'months'),
                ],
            });
        }
        else if (this.props.windowWidth < 1200 && this.state.numMonths !== 1) {
            this.setState({
                numMonths: 1,
                months: [moment()],
            });
        }
    }
    componentDidUpdate = (prevProps) => {
        if (this.props.windowWidth !== prevProps.windowWidth) {
            if (this.props.windowWidth >= 1200 && this.state.numMonths !== 3) {
                this.setState({
                    numMonths: 3,
                    months: [
                        moment(),
                        moment().add(1, 'months'),
                        moment().add(2, 'months'),
                    ],
                });
            }
            else if (this.props.windowWidth < 1200 && this.state.numMonths !== 1) {
                this.setState({
                    numMonths: 1,
                    months: [moment()],
                });
            }
        }
    };
    getAppointments = async () => {
        try {
            const { id } = this.props.match.params;
            const sitterPiid = this.props.auth.user.personal_info.id;
            if (id) {
                const response = await appointmentService.getBooking(id);
                if (response.status === 200) {
                    const { unavailableAppointments } = this.state;
                    const { bookingAppointments, family, children, booking, owner, sitterSignups, activities, } = response.data.data;
                    const sortedAppts = sortBy(bookingAppointments, [
                        'start_time',
                        'end_time',
                    ]);
                    const programStartDate = moment(sortedAppts[0].start_time);
                    const programEndDate = moment(sortedAppts[sortedAppts.length - 1].start_time);
                    const sitterSignup = find(sitterSignups, (signup) => {
                        return signup.sitter_piid === sitterPiid;
                    });
                    if (sitterSignup) {
                        each(sitterSignup.sitter_signup_appointments, (appt) => {
                            unavailableAppointments.push(appt.appointment_id);
                        });
                    }
                    this.setState({
                        loading: false,
                        family,
                        children,
                        booking,
                        owner,
                        appointments: sortedAppts,
                        programStartDate,
                        programEndDate,
                        sitterSignups,
                        sitterSignup,
                        activities,
                        signingUp: !!sitterSignup,
                        unavailableAppointments,
                        returning: !!sitterSignup,
                    });
                }
            }
        }
        catch (error) {
            console.log(error);
        }
    };
    getCommunications = async () => {
        try {
            const { id } = this.props.match.params;
            const sitterPiid = this.props.auth.user.personal_info.id;
            const response = await communicationsService.get('booking', id, 'sitter', sitterPiid);
            if (response.status === 200) {
                const { communications } = response.data.data;
                this.setState({
                    communications,
                    communication: '',
                });
            }
        }
        catch (error) {
            console.log(error);
        }
    };
    displayChildrenAges = (children) => {
        const childAges = [];
        for (const child of children) {
            const childAge = displayAge(child.DOB, 'yo', 'mo');
            childAges.push(childAge);
        }
        return childAges.join(' / ');
    };
    loadOldMonths = () => {
        if (this.state.canDecreaseDate) {
            const firstMonth = this.state.months[0];
            const earliestMonth = moment(firstMonth).subtract(this.state.numMonths, 'months');
            const months = this.state.numMonths === 3
                ? [
                    earliestMonth,
                    moment(firstMonth).subtract(2, 'months'),
                    moment(firstMonth).subtract(1, 'months'),
                ]
                : [earliestMonth];
            if (earliestMonth.isSameOrBefore(this.state.today, 'month')) {
                this.setState({
                    months,
                    canDecreaseDate: false,
                });
            }
            else {
                this.setState({
                    months,
                    canDecreaseDate: true,
                });
            }
        }
    };
    loadMoreMonths = () => {
        const lastMonth = this.state.months[this.state.months.length - 1];
        const months = this.state.numMonths === 3
            ? [
                moment(lastMonth).add(1, 'months'),
                moment(lastMonth).add(2, 'months'),
                moment(lastMonth).add(3, 'months'),
            ]
            : [moment(lastMonth).add(1, 'months')];
        this.setState({
            months,
            canDecreaseDate: true,
        });
    };
    toggleSignup = () => {
        this.setState((prevState) => ({
            ...prevState,
            signingUp: !prevState.signingUp,
            currentTab: 'calendar',
        }));
    };
    toggleUnavailableForAppointment = (id) => {
        const unavailableAppointments = [...this.state.unavailableAppointments];
        const idx = unavailableAppointments.indexOf(id);
        if (idx > -1) {
            unavailableAppointments.splice(idx, 1);
        }
        else {
            unavailableAppointments.push(id);
        }
        this.setState({ unavailableAppointments });
    };
    toggleSubmitModal = () => {
        this.setState((prevState) => ({
            ...prevState,
            showSubmitModal: !prevState.showSubmitModal,
        }));
    };
    saveSitterSignups = async () => {
        const { data, unavailableAppointments } = this.state;
        if (!!data.confirm_1 && !!data.confirm_2 && !!data.confirm_3) {
            try {
                const booking_id = this.props.match.params.id;
                const sitter_piid = this.props.auth.user.personal_info.id;
                const apiData = {
                    booking_id,
                    sitter_piid,
                    appointment_ids: unavailableAppointments,
                    notes: data.sitter_notes,
                };
                const response = await sitterService.storeSitterSignups(apiData);
                if (response.status === 201) {
                    this.setState({
                        showSubmitModal: false,
                        // signingUp: false,
                        returning: true,
                    });
                    toast.success('Woo! Your signups for this program have been submitted!');
                }
            }
            catch (error) {
                console.log(error);
            }
        }
    };
    handleChange = (name, value) => {
        const data = { ...this.state.data };
        data[name] = value;
        this.setState({ data });
    };
    sendNewMessage = async () => {
        const { communication } = this.state;
        if (!!communication) {
            const bookingId = this.props.match.params.id;
            const fromPiid = this.props.auth.user.personal_info.id;
            try {
                const data = {
                    model_id: bookingId,
                    from_piid: fromPiid,
                    model_type: 'booking',
                    comm_type: 'sitter',
                    from_type: 'sitter',
                    to_type: 'admin',
                    message: communication,
                };
                const response = await communicationsService.store(data);
                if (response.status === 201) {
                    toast.success('Hooray! Your Communication has been sent to the admins and will be reviewed shortly.');
                    this.getCommunications();
                }
            }
            catch (error) {
                console.log(error);
                toast.error('Uh oh! Something went wrong!');
            }
        }
        else {
            toast.error('You must enter a message before sending it.');
        }
    };
    handleCommunicationChange = (event) => {
        const value = event.target.value;
        this.setState({
            communication: value,
        });
    };
    setCurrentTab = (currentTab) => {
        this.setState({
            currentTab,
        });
    };
    renderActivities = () => {
        const { activities } = this.state;
        return (React.createElement("p", null, activities.length > 0
            ? activities.map((activity) => (React.createElement("span", { key: `program-activitiy-${activity.id}` },
                activity.name,
                React.createElement("br", null))))
            : 'None'));
    };
    render() {
        const { loading, appointments, months, canDecreaseDate, family, signingUp, children, booking, programStartDate, programEndDate, unavailableAppointments, showSubmitModal, data, communications, communication, returning, currentTab, } = this.state;
        const { windowWidth } = this.props;
        if (!!loading) {
            return (React.createElement("main", { className: "user-programs program-detail sitter" },
                React.createElement("div", { className: "mobile-header" },
                    React.createElement("h4", { className: "section-label" },
                        React.createElement("i", { className: "fas fa-spinner fa-spin" }),
                        "\u00A0Loading Consistent Care Request...")),
                React.createElement("div", { className: "program-inner" },
                    React.createElement("h4", { className: "section-label" },
                        React.createElement("i", { className: "fas fa-spinner fa-spin" }),
                        "\u00A0Loading Consistent Care Request..."))));
        }
        return (React.createElement("main", { className: "user-programs program-detail sitter" },
            React.createElement("div", { className: "mobile-header" },
                React.createElement("h4", { className: "section-label" },
                    React.createElement("i", { className: "fas fa-calendar-star" }),
                    "\u00A0",
                    family.name,
                    " Family\u2019s Consistent Care Request"),
                React.createElement("div", { className: "mobile-tab-selection" },
                    React.createElement("div", { className: `mobile-tab calendar-tab ${currentTab === 'calendar' ? 'active' : ''}`, onClick: () => {
                            this.setCurrentTab('calendar');
                        } },
                        React.createElement("h3", null,
                            React.createElement("i", { className: "far fa-calendar-alt" }),
                            "\u00A0Calendar"),
                        React.createElement("hr", null)),
                    React.createElement("div", { className: `mobile-tab details-tab ${currentTab === 'details' ? 'active' : ''}`, onClick: () => {
                            this.setCurrentTab('details');
                        } },
                        React.createElement("h3", null,
                            React.createElement("i", { className: "far fa-list-alt" }),
                            "\u00A0Details"),
                        React.createElement("hr", null)))),
            (windowWidth > 1199 || currentTab === 'calendar') && (React.createElement("div", { className: "program-inner" },
                React.createElement("h4", { className: "section-label" },
                    React.createElement("i", { className: "fas fa-calendar-star" }),
                    "\u00A0",
                    family.name,
                    " Family\u2019s Consistent Care Request"),
                !!signingUp && !returning && (React.createElement("div", { className: "program-instructions" },
                    React.createElement("h3", null, "PLEASE UNSELECT THE DAYS YOU CANNOT MAKE"))),
                React.createElement("div", { className: "program-calendar" },
                    React.createElement("div", { className: "calendar-wrapper" },
                        React.createElement("button", { className: `arrow prev ${!canDecreaseDate ? 'disabled' : ''}`, onClick: this.loadOldMonths },
                            React.createElement("i", { className: "fas fa-arrow-alt-left" })),
                        React.createElement("button", { className: "arrow next", onClick: this.loadMoreMonths },
                            React.createElement("i", { className: "fas fa-arrow-alt-right" })),
                        React.createElement("div", { className: "calendars" }, months.map((month, i) => {
                            return (React.createElement(Calendar, { key: i, appointments: appointments, showAppointments: false, date: month, type: "program", unavailableAppointments: unavailableAppointments, signingUp: signingUp }));
                        })))),
                React.createElement("div", { className: "program-appointments" }, appointments.map((appointment) => (React.createElement("div", { className: "program-appointment", key: `program-appointment-${appointment.id}` },
                    !!signingUp && (React.createElement("div", { className: "program-col signup" },
                        unavailableAppointments.includes(appointment.id) && (React.createElement("button", { className: "program-sign-up unavailable", onClick: () => {
                                this.toggleUnavailableForAppointment(appointment.id);
                            } },
                            React.createElement("i", { className: "fas fa-times-circle" }))),
                        unavailableAppointments.includes(appointment.id) ===
                            false && (React.createElement("button", { className: "program-sign-up available", onClick: () => {
                                this.toggleUnavailableForAppointment(appointment.id);
                            } },
                            React.createElement("i", { className: "fas fa-check-circle" }))))),
                    React.createElement("div", { className: "program-col date" },
                        React.createElement("h4", null, "Day"),
                        React.createElement("p", null, !!appointment.overnight
                            ? `${moment(appointment.start_time).format('MMM Do')} - ${moment(appointment.end_time).format('MMM Do')}`
                            : moment(appointment.start_time).format('MMMM Do'))),
                    React.createElement("div", { className: "program-col start" },
                        React.createElement("h4", null, "Start Time"),
                        React.createElement("p", null, moment(appointment.start_time).format('h:mm a'))),
                    React.createElement("div", { className: "program-col end" },
                        React.createElement("h4", null, "End Time"),
                        React.createElement("p", null, moment(appointment.end_time).format('h:mm a'))),
                    React.createElement("div", { className: "program-col wiggle" },
                        React.createElement("h4", null, "Wiggle Room?"),
                        React.createElement("p", null, !!appointment.flex
                            ? `Yes (${appointment.flex_room} mins)`
                            : 'No')),
                    React.createElement("div", { className: "program-col driving" },
                        React.createElement("h4", null, "Driving?"),
                        React.createElement("p", null, !!appointment.driving_needed
                            ? `Yes (${appointment.driving_distance} mi)`
                            : 'No')),
                    React.createElement("div", { className: "program-col children" },
                        React.createElement("h4", null, "Children"),
                        React.createElement("p", null, this.displayChildrenAges(appointment.children))))))))),
            (windowWidth > 1199 || currentTab === 'details') && (React.createElement("div", { className: "program-sidebar sidebar-right" },
                React.createElement("div", { className: "sidebar-actions" },
                    !signingUp && (React.createElement("button", { className: "theme-btn blue tall", onClick: this.toggleSignup },
                        React.createElement("i", { className: "fas fa-check" }),
                        "\u00A0Sign Up")),
                    !!signingUp && (React.createElement("button", { className: "theme-btn primary-green tall", onClick: this.toggleSubmitModal },
                        React.createElement("i", { className: "fas fa-check" }),
                        "\u00A0Submit")),
                    !!returning && (React.createElement("p", { style: {
                            letterSpacing: 0,
                            marginTop: '8px',
                        } }, "Please note, you have already signed up for this request, but you can still adjust your sign-ups. Remove any dates by deselecting them and clicking \u201CSubmit\u201D to save the changes!")),
                    React.createElement(Modal, { title: "Confirm Signup", isOpen: showSubmitModal, closeModal: this.toggleSubmitModal, content: React.createElement("div", { className: "program-sitter-submission-modal" },
                            React.createElement("p", null,
                                ' ',
                                "Please list three potential dates and times, (within the next two weeks) that you can be available for a complimentary Meet & Greet video call with the family prior to finalizing the appointments."),
                            React.createElement(Textarea, { className: "sitter_notes", name: "sitter_notes", label: "Notes", value: data.sitter_notes, onChange: (e) => {
                                    this.handleChange('sitter_notes', e.target.value);
                                }, required: true }),
                            React.createElement(BooleanInput, { value: data.confirm_1, name: "confirm_1", label: "I have reviewed my schedule and confirm I am available for all appointments included in my sign up.", onChange: () => {
                                    this.handleChange('confirm_1', !data.confirm_1);
                                } }),
                            React.createElement(BooleanInput, { value: data.confirm_2, name: "confirm_2", label: "I understand any available time slots that conflict with my sign up will be temporarily removed so my schedule is reserved for this family in the meantime.", onChange: () => {
                                    this.handleChange('confirm_2', !data.confirm_2);
                                } }),
                            React.createElement(BooleanInput, { value: data.confirm_3, name: "confirm_3", label: "I understand that I am making a commitment to provide Consistent Childcare to a family in the SmartSitter community. In the event that I am not able to complete the assignment for any reason, at any time, I am required to give two weeks notice. Failure to do so will result in a fine and account termination.", onChange: () => {
                                    this.handleChange('confirm_3', !data.confirm_3);
                                } })), primaryButton: 'Submit Sign-up', secondaryButton: 'Close', handleClick: this.saveSitterSignups })),
                React.createElement("div", { className: "sidebar-family-info" },
                    React.createElement(Avatar, { imageSrc: family.avatar_filename, profileColor: family.profile_color }),
                    React.createElement("div", { className: "info" },
                        React.createElement("h3", null, family.name),
                        React.createElement("p", null, this.displayChildrenAges(children)),
                        React.createElement("h5", null, appointments[0].zip))),
                React.createElement("div", { className: "info-section status" },
                    React.createElement("h4", null, "Status"),
                    React.createElement("p", { className: `program-status-pill ${slugify(booking.status)}` }, booking.status === 'assigning' ||
                        booking.status === 'assigning-pending'
                        ? 'Open'
                        : booking.status
                            .split('-')
                            .map((str) => upperCaseFirst(str))
                            .join(' '))),
                React.createElement("div", { className: "info-section" },
                    React.createElement("h4", null, "Dates of Care"),
                    React.createElement("p", null,
                        programStartDate && programStartDate.format('MMMM Do'),
                        " to",
                        ' ',
                        programEndDate && programEndDate.format('MMMM Do'))),
                React.createElement("div", { className: "info-section" },
                    React.createElement("h4", null, "Notes From Our Office Team"),
                    React.createElement("p", null,
                        " ",
                        booking.sitter_notes)),
                React.createElement("div", { className: "info-section" },
                    React.createElement("h4", null, "Family House Rules"),
                    React.createElement("p", null,
                        " ",
                        !!family.house_rules ? family.house_rules : 'None')),
                React.createElement("div", { className: "info-section" },
                    React.createElement("h4", null, "Admin Notes on the Family"),
                    React.createElement("p", null, family.admin_family_notes || 'None')),
                React.createElement("div", { className: "info-section" },
                    React.createElement("h4", null, "Total Appointments"),
                    React.createElement("p", null,
                        React.createElement("b", null, appointments.length))),
                React.createElement("div", { className: "info-section" },
                    React.createElement("h4", null, "Selected Activities"),
                    this.renderActivities()),
                React.createElement("div", { className: "info-section" },
                    React.createElement("h4", null, "Additional Services Requested"),
                    React.createElement("p", null, booking.additional_services || 'None')),
                React.createElement("div", { className: "info-section communications" },
                    React.createElement("h4", null, "Message Our Office Team"),
                    React.createElement(Textarea, { className: "communication", name: "program-communication", label: 'Message', value: communication, onChange: this.handleCommunicationChange, required: false }),
                    React.createElement("button", { className: "theme-btn small slate-blue", onClick: this.sendNewMessage }, "Send"),
                    communications.map((communication) => (React.createElement("div", { className: "communication-item", key: `comm-${communication.id}` },
                        communication.from_type === 'admin' && React.createElement("h4", null, "From Admin"),
                        communication.from_type === 'sitter' && React.createElement("h4", null, "To Admin"),
                        React.createElement("p", null, communication.message),
                        React.createElement("p", { className: "info" },
                            "Note By ",
                            communication.firstname,
                            "\u00A0",
                            communication.lastname),
                        React.createElement("p", { className: "info" }, moment(communication.created_at).format('dddd M/D/YYYY [at] h:mm a'))))))))));
    }
}
export default connect(mapAuthState)(ProgramDetail);
