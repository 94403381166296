import clsx from 'clsx';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { getAcceptedAgeRangesRequest } from '../../../services';
import { CustomSelect } from '../../shared/Form/CustomSelect';
import Input from '../../shared/Form/Input';
import RangeDatePicker from '../../shared/Form/RangeDatePicker';
import { useUnclaimedAppointmentFilters } from './useUnclaimedAppointmentFilters';
import { format, parse } from 'date-fns';
const STORAGE_KEY = 'unclaimed-appointment-filter-expanded';
const DATE_FORMAT = 'yyyy-MM-dd';
export const UnclaimedAppointmentFilter = () => {
    const [isExpanded, setIsExpanded] = useState(() => {
        const savedValue = JSON.parse(localStorage.getItem(STORAGE_KEY));
        return savedValue ?? true;
    });
    const handleToggle = () => {
        setIsExpanded((prevState) => {
            const nextState = !prevState;
            localStorage.setItem(STORAGE_KEY, JSON.stringify(nextState));
            return nextState;
        });
    };
    const { family, ages, zip, setFilter, fromDate, toDate } = useUnclaimedAppointmentFilters();
    const handleFamilyChange = (event) => {
        setFilter({ family: event.target.value });
    };
    const handleZipChange = (event) => {
        setFilter({ zip: event.target.value });
    };
    const handleAgeChange = (nextAge) => {
        setFilter({ ages: nextAge });
    };
    const handleDateChange = (dateRange) => {
        const [from, to] = dateRange;
        setFilter({
            fromDate: from ? format(from, DATE_FORMAT) : '',
            toDate: to ? format(to, DATE_FORMAT) : '',
        });
    };
    const acceptedAgeRangesQuery = useQuery('accepted-age-ranges', getAcceptedAgeRangesRequest, {
        staleTime: Number.POSITIVE_INFINITY,
    });
    if (acceptedAgeRangesQuery.isFetching) {
        return React.createElement("p", null, "Loading form...");
    }
    if (acceptedAgeRangesQuery.isError) {
        return (React.createElement("p", null,
            "We were not able to fetch form data.",
            ' ',
            React.createElement("button", { type: "button", className: "theme-btn small blue", onClick: () => {
                    acceptedAgeRangesQuery.refetch();
                } }, "Try again")));
    }
    const acceptedAgeRangeOptions = acceptedAgeRangesQuery.data.map((option) => ({
        value: String(option.id),
        label: option.name,
    }));
    const rangeStartDate = fromDate
        ? parse(fromDate, DATE_FORMAT, new Date())
        : null;
    const rangeEndDate = toDate ? parse(toDate, DATE_FORMAT, new Date()) : null;
    return (React.createElement("div", { className: clsx('unclaimed-filters', {
            'is-expanded': isExpanded,
        }) },
        React.createElement("div", { className: "unclaimed-filters__head" },
            React.createElement("h3", null, "Filters"),
            React.createElement("button", { type: "button", "aria-label": "Toggle Filters", onClick: handleToggle },
                React.createElement("span", { className: "fas fa-arrow-down" }))),
        React.createElement("div", { className: "unclaimed-filters__body" },
            React.createElement("div", { className: "unclaimed-appointments-form" },
                React.createElement("div", { className: "form-group" },
                    React.createElement(RangeDatePicker, { name: "date-range", label: "Search by Date", startDate: rangeStartDate, endDate: rangeEndDate, autoComplete: "off", onChange: handleDateChange })),
                React.createElement("div", { className: "form-group" },
                    React.createElement(Input, { name: "zip_filter", label: "Search by Zip Code", type: "text", value: zip, autoComplete: "off", onChange: handleZipChange })),
                React.createElement("div", { className: "form-group" },
                    React.createElement(CustomSelect, { id: "age-range-filter", label: "Search by Age Range", options: acceptedAgeRangeOptions, value: ages, onChange: handleAgeChange, required: false, multiple: true })),
                React.createElement("div", { className: "form-group" },
                    React.createElement(Input, { name: "family_filter", label: "Search by family name", type: "text", autoComplete: "off", value: family, onChange: handleFamilyChange }))))));
};
