import React from 'react';
const FormStepTracker = (props) => {
    const { currentStep, steps } = props;
    return (React.createElement("div", { className: "form-step-tracker" },
        React.createElement("div", { className: "inner" }, steps.map((step, idx) => {
            const classNames = ['form-step', `step-${idx}`];
            if (currentStep > idx) {
                classNames.push('completed');
            }
            if (currentStep === idx) {
                classNames.push('current');
            }
            return (React.createElement("div", { key: `step-${idx}`, className: classNames.join(' ') },
                React.createElement("div", { className: "form-step-circle" }, currentStep > idx && React.createElement("i", { className: "far fa-check" })),
                React.createElement("h4", null, step.name)));
        }))));
};
export default FormStepTracker;
