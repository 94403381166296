import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import { toast } from 'react-toastify';

import familyService from '../../../../services/familyService';
import { Avatar, SitterProfile, Modal } from '../../../shared';
import Input from '../../../shared/Form/Input';
import {
  displayMonthAbbrev,
  firstInitial,
  upperCaseFirst,
} from '../../../../helpers';
import { each, filter, find, map, orderBy } from 'lodash-es';
import { mapAuthState } from '../../../../helpers/mapAuthState';

class BookingSitterSelection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showUnassignedModal: false,
      // initialLoad: true,
      totalFillableAppointments: 0,
      currentTab: 'sitters',
      currentSitter: null,
      selectedSitters: [],
      remainingSitters: [],
      remainingAppointments: [],
      unassignableAppointments: [],
      savedSitters: [],
      favoritedSitters: [],
      sitterApptNums: {},
    };
  }
  componentDidMount() {
    this.filterData(true);
  }
  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.sitters.length > 0 && prevProps.sitters.length === 0) {
      this.filterData(true);
    }
  };
  filterData = (initialLoad = false) => {
    let { totalFillableAppointments } = this.state;

    // "unassignable" here means no sitters are available to cover it, hence we cannot assign a sitter to them
    let unassignableAppointments = [];

    let appointments = filter([...this.props.appointments], (appointment) => {
      let hasAvailableSitters = this.hasAvailableSitters(appointment);
      if (!hasAvailableSitters) {
        unassignableAppointments.push(appointment);
      }
      return appointment.selected_sitters.length === 0 && hasAvailableSitters;
    });

    // We check every time the data needs to be filtered, that way we always have an accurate
    // count of how many appointments have no sitter matches.
    appointments = map(appointments, (appointment) => {
      return appointment.rid;
    });

    // Filter out the sitters here based on the remaining appointments that need filled
    let sitters = filter([...this.props.sitters], (sitter) => {
      let match = false;
      each(appointments, (rid) => {
        if (sitter.available_appointments.indexOf(rid) > -1) match = true;
      });
      return match;
    });
    // And then sort the sitters by who can take the most
    sitters = orderBy(sitters, (s) => s.available_appointments.length, [
      'desc',
    ]);

    if (initialLoad) {
      // On initial load we get the total number of assignable appointments.
      // On all other loops this is ignored.
      totalFillableAppointments = appointments.length;
    }
    this.setState({
      remainingSitters: sitters,
      remainingAppointments: appointments,
      unassignableAppointments,
      totalFillableAppointments,
    });
  };
  displaySitterAge = (sitter) => {
    const sitterDob = moment(sitter.DOB, 'YYYY-MM-DD');
    return `${moment().diff(sitterDob, 'years')} years old`;
  };
  toggleTab = () => {
    this.setState({
      currentTab:
        this.state.currentTab === 'sitters' ? 'appointments' : 'sitters',
    });
  };
  showSitter = (sitter) => {
    this.setState({ currentSitter: sitter });
  };
  setSitter = async (sitter) => {
    const { id, available_appointments } = sitter;
    const { appointments } = this.props;
    const { remainingAppointments } = this.state;
    let apptRids = filter(appointments, (appointment) => {
      const { rid } = appointment;
      // The sitter needs to be available and the appointment needs to be "remaining",
      // i.e. it needs to not have a sitter filled already, so we can't just bulk set by the
      //  sitter's availability, else we might override a previous selection.
      return (
        available_appointments.indexOf(rid) > -1 &&
        remainingAppointments.indexOf(rid) > -1
      );
    });
    apptRids = map(apptRids, (r) => r.rid);
    if (apptRids.length > 0) {
      let success = await this.props.setSamePropByIds(
        apptRids,
        'selected_sitters',
        [sitter.id]
      );
      const { selectedSitters, sitterApptNums } = this.state;
      selectedSitters.push(sitter);
      sitterApptNums[sitter.id] = apptRids.length;
      this.setState(
        {
          selectedSitters,
          sitterApptNums,
        },
        () => {
          this.filterData();
        }
      );
    }
  };
  removeSitter = async (sitter) => {
    const { id } = sitter;
    let { appointments } = this.props;
    let apptRids = filter(appointments, (appointment) => {
      return appointment.selected_sitters.indexOf(id) > -1;
    });
    apptRids = map(apptRids, (a) => a.rid);
    if (apptRids.length > 0) {
      let success = await this.props.setSamePropByIds(
        apptRids,
        'selected_sitters',
        []
      );
      const selectedSitters = [...this.state.selectedSitters];
      let idx = selectedSitters.indexOf(sitter);
      if (idx > -1) {
        selectedSitters.splice(idx, 1);
        this.setState(
          {
            selectedSitters,
          },
          () => {
            this.filterData();
          }
        );
      }
    }
  };
  hasAvailableSitters = (appointment) => {
    return (
      filter([...this.props.sitters], (sitter) => {
        return sitter.available_appointments.indexOf(appointment.rid) > -1;
      }).length > 0
    );
  };
  closeCurrentSitter = () => {
    this.setState({ currentSitter: null });
  };
  saveCurrentSitter = async () => {
    try {
      const { family_id } = this.props.auth.user.personal_info;
      const sitterId = this.state.currentSitter.id;
      const response = await familyService.saveSitter(family_id, sitterId, {
        interested: 1,
      });
      if (response.status === 200) {
        toast.success('Hooray! This sitter has been marked as interested!');
        const savedSitters = [...this.state.savedSitters];
        savedSitters.push(sitterId);
        this.setState({ savedSitters });
      }
    } catch (e) {
      console.log(e);
    }
  };
  favoriteCurrentSitter = async () => {
    try {
      const { family_id } = this.props.auth.user.personal_info;
      const sitterId = this.state.currentSitter.id;
      const response = await familyService.favoriteSitter(family_id, sitterId, {
        favorite: 1,
      });
      if (response.status === 200) {
        toast.success('Hooray! This sitter has been favorited!');
        const favoritedSitters = [...this.state.favoritedSitters];
        favoritedSitters.push(sitterId);
        this.setState({ favoritedSitters });
      }
    } catch (e) {
      console.log(e);
    }
  };
  getSitterById = (sitterId) => {
    return find(this.props.sitters, (sitter) => {
      return sitter.id === sitterId;
    });
  };
  closeUnassignedModal = () => {
    this.setState({ showUnassignedModal: false });
  };
  onFinalizeClick = () => {
    const { sitters } = this.props;
    let hasRemainingAppts = this.state.remainingAppointments.length > 0;
    let hasUnassignableAppts = this.state.unassignableAppointments.length > 0;
    if (sitters.length > 0 && (hasRemainingAppts || hasUnassignableAppts)) {
      this.setState({
        showUnassignedModal: true,
      });
    } else {
      this.props.onFormComplete();
    }
  };
  render() {
    const { appointments, sitters } = this.props;
    const {
      showUnassignedModal,
      currentTab,
      currentSitter,
      remainingSitters,
      selectedSitters,
      remainingAppointments,
      unassignableAppointments,
      totalFillableAppointments,
      sitterApptNums,
      favoritedSitters,
      savedSitters,
    } = this.state;
    let filledAppts = totalFillableAppointments - remainingAppointments.length;
    return (
      <div className="booking-form sitter-selection">
        {sitters.length > 0 && (
          <div className="sitter-selection-wrapper">
            <div className="booking-tabs">
              <div
                className={`booking-tab ${
                  currentTab === 'sitters' ? 'active' : ''
                }`}
                onClick={this.toggleTab}
              >
                <p>
                  <i className="fas fa-address-card"></i>
                  &nbsp;Available Sitters
                </p>
              </div>
              <div
                className={`booking-tab ${
                  currentTab === 'appointments' ? 'active' : ''
                }`}
                onClick={this.toggleTab}
              >
                <p>
                  <i className="fas fa-address-card"></i>
                  &nbsp;Appointments ({filledAppts}/{appointments.length})
                </p>
              </div>
            </div>
            <div className="sitter-selection">
              {currentTab === 'sitters' && (
                <div className="sitter-list">
                  {(remainingSitters.length > 0 ||
                    selectedSitters.length > 0) && (
                    <div className="booking-sitters-section">
                      {selectedSitters.map((sitter) => (
                        <div
                          className={`sitter selected ${
                            !!sitter.favorite ||
                            favoritedSitters.indexOf(sitter.id) > -1
                              ? 'favorite'
                              : ''
                          } ${
                            !!sitter.saved ||
                            savedSitters.indexOf(sitter.id) > -1
                              ? 'saved'
                              : ''
                          } `}
                          key={`sitter-selected-${sitter.id}`}
                        >
                          <Avatar
                            imageSrc={sitter.avatar_filename}
                            profileColor={sitter.profile_color}
                          />
                          <div className="info">
                            <h3>
                              {upperCaseFirst(sitter.firstname)}
                              &nbsp;
                              {firstInitial(sitter.lastname)}
                            </h3>
                            <p>{this.displaySitterAge(sitter)}</p>
                            {!!sitter.used &&
                              !(
                                !!sitter.saved ||
                                savedSitters.indexOf(sitter.id) > -1
                              ) && <p className="used">Previous Sitter</p>}
                            {(!!sitter.saved ||
                              savedSitters.indexOf(sitter.id) > -1) && (
                              <p className="used">Interested</p>
                            )}
                          </div>
                          <div className="appts-available selected">
                            <p>Selected</p>
                            <p>
                              {sitterApptNums[sitter.id]} of{' '}
                              {totalFillableAppointments}
                            </p>
                          </div>
                          <div className="actions">
                            <button
                              className="theme-btn small slate-blue"
                              onClick={() => {
                                this.showSitter(sitter);
                              }}
                            >
                              <i className="fas fa-user-circle"></i>
                              &nbsp;Profile
                            </button>
                            <button
                              className="theme-btn small error-red"
                              onClick={() => {
                                this.removeSitter(sitter);
                              }}
                            >
                              <i className="fas fa-times"></i>
                              &nbsp;Remove
                            </button>
                          </div>
                        </div>
                      ))}
                      {remainingSitters.map((sitter, idx) => (
                        <div
                          className={`sitter ${
                            !!sitter.favorite ||
                            favoritedSitters.indexOf(sitter.id) > -1
                              ? 'favorite'
                              : ''
                          } ${
                            !!sitter.saved ||
                            savedSitters.indexOf(sitter.id) > -1
                              ? 'saved'
                              : ''
                          } `}
                          key={`sitter-${sitter.id}`}
                        >
                          <Avatar
                            imageSrc={sitter.avatar_filename}
                            profileColor={sitter.profile_color}
                          />
                          <div className="info">
                            <h3>
                              {upperCaseFirst(sitter.firstname)}
                              &nbsp;
                              {firstInitial(sitter.lastname)}
                            </h3>
                            <p>{this.displaySitterAge(sitter)}</p>
                            {!!sitter.used &&
                              !(
                                !!sitter.saved ||
                                savedSitters.indexOf(sitter.id) > -1
                              ) && <p className="used">Previous Sitter</p>}
                            {(!!sitter.saved ||
                              savedSitters.indexOf(sitter.id) > -1) && (
                              <p className="used">Interested</p>
                            )}
                          </div>
                          <div className="appts-available">
                            <p>Available</p>
                            <p>
                              {Math.min(
                                sitter.available_appointments.length,
                                remainingAppointments.length
                              )}{' '}
                              of {totalFillableAppointments}
                            </p>
                          </div>
                          <div className="actions">
                            <button
                              className="theme-btn small slate-blue"
                              onClick={() => {
                                this.showSitter(sitter);
                              }}
                            >
                              <i className="fas fa-user-circle"></i>
                              &nbsp;Profile
                            </button>
                            <button
                              className="theme-btn small primary-green"
                              onClick={() => {
                                this.setSitter(sitter);
                              }}
                            >
                              <i className="fas fa-check"></i>
                              &nbsp;Select
                            </button>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              )}
              {currentTab === 'appointments' && (
                <div className="appointment-list-wrapper">
                  {unassignableAppointments.length > 0 && (
                    <div className="no-sitters-notice">
                      <p>Please note: No available sitters for</p>
                      <ul>
                        {unassignableAppointments.map((appointment) => {
                          if (
                            !!appointment.overnight &&
                            !!appointment.num_nights
                          ) {
                            return (
                              <li key={`appt-uncovered-${appointment.rid}`}>
                                <p>
                                  {moment(appointment.date).format('MMMM D')}
                                  &nbsp;-&nbsp;
                                  {moment(appointment.date)
                                    .add(appointment.num_nights, 'days')
                                    .format('MMMM D')}
                                </p>
                              </li>
                            );
                          } else {
                            return (
                              <li key={`appt-uncovered-${appointment.rid}`}>
                                <p>
                                  {moment(appointment.date).format('MMMM D')}
                                </p>
                              </li>
                            );
                          }
                        })}
                      </ul>
                      <p>However, we will work hard to find a sitter.</p>
                    </div>
                  )}
                  <div className="appointment-list">
                    {appointments.map((appointment) => {
                      let hasAvailableSitters =
                        this.hasAvailableSitters(appointment);
                      return (
                        <div
                          className="booking-info"
                          key={`appt-sitter-select-${appointment.rid}`}
                        >
                          {!!appointment.overnight && !!appointment.num_nights && (
                            <div className="date">
                              <h3>
                                {moment(appointment.date).format('MMM D')}
                                &nbsp;-&nbsp;
                                <span>
                                  {moment(appointment.date)
                                    .add(appointment.num_nights, 'days')
                                    .format('MMM D')}
                                </span>
                              </h3>
                              <h3 className="weekday">
                                {moment(appointment.date).format('ddd')}
                                &nbsp;-&nbsp;
                                <span>
                                  {moment(appointment.date)
                                    .add(appointment.num_nights, 'days')
                                    .format('ddd')}
                                </span>
                              </h3>
                            </div>
                          )}
                          {(!appointment.overnight ||
                            !appointment.num_nights) && (
                            <div className="date">
                              <h3>
                                {moment(appointment.date).format('MMM D')}
                              </h3>
                              <h3 className="weekday">
                                {moment(appointment.date).format('dddd')}
                              </h3>
                            </div>
                          )}
                          <div className="time">
                            <p>
                              {moment(appointment.start_time, 'HH:mm').format(
                                'h:mm a'
                              )}
                              &nbsp;-&nbsp;
                              {moment(appointment.end_time, 'HH:mm').format(
                                'h:mm a'
                              )}
                            </p>
                            {!!appointment.flex && (
                              <p className="wiggle">
                                ({appointment.flex_room} min Wiggle Room)
                              </p>
                            )}
                          </div>
                          {appointment.selected_sitters.length > 0 && (
                            <div className="sitters-wrapper">
                              {appointment.selected_sitters.map((sitterId) => {
                                let sitter = this.getSitterById(sitterId);
                                return (
                                  <div
                                    className="sitter"
                                    key={`sitter-appt-${sitter.id}`}
                                  >
                                    <Avatar
                                      imageSrc={sitter.avatar_filename}
                                      profileColor={sitter.profile_color}
                                    />
                                    <div className="name">
                                      <p>
                                        {upperCaseFirst(sitter.firstname)}
                                        &nbsp;
                                        {firstInitial(sitter.lastname)}
                                      </p>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          )}
                          {appointment.selected_sitters.length === 0 &&
                            hasAvailableSitters && (
                              <div className="sitters-wrapper">
                                <div className="sitter unassigned">
                                  <div className="avatar">
                                    <div className="avatar-wrapper profile-color background slate-blue">
                                      <i className="fas fa-user"></i>
                                    </div>
                                  </div>
                                  <div className="name">
                                    <p>Unassigned</p>
                                  </div>
                                </div>
                              </div>
                            )}
                          {appointment.selected_sitters.length === 0 &&
                            !hasAvailableSitters && (
                              <div className="sitters-wrapper">
                                <div className="sitter unavailable">
                                  <div className="avatar">
                                    <div className="avatar-wrapper profile-color background brand-blue">
                                      <i className="fas fa-user"></i>
                                    </div>
                                  </div>
                                  <div className="name">
                                    <p>None Avail.</p>
                                  </div>
                                </div>
                              </div>
                            )}
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
        {sitters.length === 0 && (
          <div className="no-available-sitters">
            <p>
              Oh no! We currently do not have any sitters available for your
              selected date(s) and time(s). If you would like to proceed without
              selecting a sitter, our office team will promote your appointment
              request to our team of sitters in efforts to provide a solution as
              soon as possible - our availability fluctuates often!
            </p>
            <p>
              In the event you would like to remove your request or find
              alternate care, please alert our team and we will credit your
              scheduling fee(s) back to your SmartSitter account to be put towards
              future use.
            </p>
            <p>
              Alternatively, if you have worked with a Sitter in the past and
              would like to request them for this booking, you may enter their
              name here.
            </p>
            <p>
              <b>
                NOTE: This does NOT guarantee your Sitter will be chosen for
                your booking!
              </b>
            </p>
            <Input
              className="requested_provider"
              name={`booking-requested_provider`}
              label="Requested Sitter"
              type="text"
              value={appointments[0]['requested_provider']}
              error={null}
              onChange={(e) => {
                this.props.setSameProp('requested_provider', e.target.value);
              }}
              required={false}
            />
            <p>
              Click Next Step below to proceed without a sitter selection & we
              will be in touch with an update on availability shortly!
            </p>
          </div>
        )}
        {currentSitter && (
          <div className="sitter-profile-modal">
            <div className="close-profile" onClick={this.closeCurrentSitter}>
              <i className="fas fa-times"></i>
            </div>
            <div className="profile-wrapper">
              <SitterProfile
                data={{
                  personalInfo: currentSitter,
                  profileSitter: currentSitter.profile_sitter,
                }}
                omitActions
              />
              <div className="bsp-footer">
                {!currentSitter.used &&
                  savedSitters.indexOf(currentSitter.id) === -1 &&
                  !currentSitter.saved &&
                  !currentSitter.favorite && (
                    <button
                      className={`theme-btn slate-blue`}
                      onClick={this.saveCurrentSitter}
                    >
                      <i className="fas fa-save"></i>
                      &nbsp;Interested
                    </button>
                  )}
                {favoritedSitters.indexOf(currentSitter.id) === -1 &&
                  !!currentSitter.used &&
                  !currentSitter.favorite && (
                    <button
                      className={`theme-btn color-favorite`}
                      onClick={this.favoriteCurrentSitter}
                    >
                      <i className="fas fa-star"></i>
                      &nbsp;Favorite
                    </button>
                  )}
              </div>
            </div>
          </div>
        )}
        <Modal
          title="You have Unassigned Appointments"
          isOpen={showUnassignedModal}
          closeModal={this.closeUnassignedModal}
          content={
            <div className="sitter-unassigned-modal">
              <p>
                But no worries! Our team will work hard to find a sitter for all
                your appointments.
              </p>
              <p>
                Alternatively, if you have worked with a Sitter in the past and
                would like to request them for this booking, you may enter their
                name here.
              </p>
              <p>
                <b>
                  NOTE: This does NOT guarantee your Sitter will be chosen for
                  your booking!
                </b>
              </p>
              <Input
                className="requested_provider"
                name={`booking-requested_provider`}
                label="Requested Sitter"
                type="text"
                value={appointments[0]['requested_provider']}
                error={null}
                onChange={(e) => {
                  this.props.setSameProp('requested_provider', e.target.value);
                }}
                required={false}
              />
            </div>
          }
          primaryButton={'Continue'}
          secondaryButton={'Close'}
          handleClick={this.props.onFormComplete}
        />
        <div className="booking-footer">
          <button className={`theme-btn blue`} onClick={this.onFinalizeClick}>
            Next Step - Finalize
          </button>
        </div>
      </div>
    );
  }
}

export default connect(mapAuthState)(BookingSitterSelection);
