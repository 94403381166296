import React from 'react';
import { upperCaseFirst, slugify } from '../../../helpers';

const ProgramStatusCell = (props) => {
  let status = props.dataItem[props.field];
  if (status === 'assigning-pending' || status === 'assigning') {
    status = 'open';
  }
  return (
    <td className={`program-status-cell ${slugify(status)}`}>
      <p className={`program-status-pill ${slugify(status)}`}>
        {status
          .split('-')
          .map((str) => upperCaseFirst(str))
          .join(' ')}
      </p>
    </td>
  );
};

export default ProgramStatusCell;
