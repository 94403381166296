import { GridColumnMenuCheckboxFilter, GridColumnMenuFilter, } from '@progress/kendo-react-grid';
import { get } from 'lodash-es';
import React from 'react';
import { Link } from 'react-router-dom';
export const ColumnMenu = (props) => {
    return (React.createElement("div", null,
        React.createElement(GridColumnMenuFilter, { ...props, expanded: true })));
};
export const ColumnMenuCheckboxFilter = (props) => {
    const { sitters, ...rest } = props;
    return (React.createElement("div", null,
        React.createElement(GridColumnMenuCheckboxFilter, { ...rest, data: sitters, expanded: true })));
};
export const ManageCell = (props) => {
    const id = get(props.dataItem, props.field);
    return (React.createElement("td", { className: "manage-cell" },
        React.createElement(Link, { className: "theme-btn blue small", to: `/settings/coupons/edit/${id}` }, "Manage")));
};
