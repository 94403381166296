import { parseISO } from 'date-fns';
import React, { useMemo } from 'react';
import { useQuery } from 'react-query';
import { getUnavailableDatesTimesRequest } from '../../../services';
import { UnclaimedAppointmentFilter } from './UnclaimedAppointmentFilters';
import { UnclaimedAppointmentsListing } from './UnclaimedAppointmentsListing';
const UnclaimedAppointments = () => {
    const query = useQuery('appointment-times', getUnavailableDatesTimesRequest);
    const schedule = useMemo(() => query.data?.map(({ start, end }) => ({
        start: parseISO(start),
        end: parseISO(end),
    })), [query.data]);
    return (React.createElement("main", { className: "unclaimed" },
        React.createElement("div", { className: "unclaimed-container" },
            React.createElement("h2", { className: "section-label" },
                React.createElement("i", { className: "fas fa-calendar-star" }),
                " Pending Appointments"),
            query.isLoading && React.createElement("div", null, "Loading data..."),
            query.isError && (React.createElement("div", null,
                "We are having trouble loading data for this listing.",
                ' ',
                React.createElement("button", { type: "button", className: "theme-btn small", onClick: () => query.refetch() }, "Try Again"))),
            query.isSuccess && (React.createElement(React.Fragment, null,
                React.createElement(UnclaimedAppointmentFilter, null),
                React.createElement(UnclaimedAppointmentsListing, { schedule: schedule }))))));
};
export default UnclaimedAppointments;
