import clsx from 'clsx';
import React from 'react';
import Avatar from '../Avatar';
function getDefaultValue(value) {
    if (!value) {
        return [];
    }
    if (typeof value === 'string') {
        return value.split(',');
    }
    return value;
}
const SingleChildCheckbox = (props) => {
    const { option, name, index, onChange } = props;
    const value = getDefaultValue(props.value);
    const randId = Math.round(Math.random() * 100000);
    const htmlId = `${name}-${index}-${randId}`;
    const checked = value.includes(String(option.id));
    const activeClassName = checked ? 'active' : 'inactive';
    return (React.createElement("div", { className: clsx('checkbox-group', activeClassName) },
        React.createElement("input", { checked: checked, value: option.id, name: name, id: htmlId, type: "checkbox", className: activeClassName, onChange: onChange }),
        React.createElement("label", { className: "inner", htmlFor: htmlId },
            React.createElement(Avatar, { imageSrc: option.avatar_filename }),
            React.createElement("div", { className: "label" },
                React.createElement("p", null, option.firstname),
                React.createElement("div", { className: "checkbox" }, checked && React.createElement("i", { className: "far fa-check" }))))));
};
const ChildCheckbox = (props) => {
    const { name, label, options, required, error } = props;
    return (React.createElement("div", { className: clsx('form-field', name, 'checkbox', 'child-checkbox') },
        React.createElement("label", { className: "title", htmlFor: name },
            label,
            required && React.createElement("span", { className: "required" }, "\u00A0*")),
        options.map((option, index) => (React.createElement(SingleChildCheckbox, { key: option.id, option: option, ...props, index: index }))),
        error && (React.createElement("span", { className: "error", role: "alert" },
            React.createElement("strong", null, error)))));
};
export default ChildCheckbox;
