import React from 'react';
import { connect } from 'react-redux';
import $ from 'jquery';
import moment from 'moment-timezone';
import {
  CardElement,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  injectStripe,
} from 'react-stripe-elements';
import { toast } from 'react-toastify';

import stripeService from '../../../services/stripeService';
import familyLedgerService from '../../../services/familyLedgerService';

import Joi from 'joi';

import {
  states,
  booleanRadioOptions as radioOptions,
  stripeFieldStyles as fieldStyles,
} from '../../../data';

import { Form, CouponChecker } from '../../shared';

class ProgramFeeForm extends Form {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        using_balance: 0,
        firstname: '',
        lastname: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
        zip: '',
      },
      submitting: false,
      bookingFee: 0,
      actualFee: 0,
      coupon: false,
      errors: {},
      user: {},
    };
  }
  // schema = Joi.object({
  //     firstname: Joi.string()
  //         .required()
  //         .label("First Name"),
  //     lastname: Joi.string()
  //         .required()
  //         .label("Last Name"),
  //     address1: Joi.string()
  //         .required()
  //         .label("Address (line 1)"),
  //     address2: Joi.string()
  //         .optional().allow("").allow(null)
  //         .label("Address (line 2)"),
  //     city: Joi.string()
  //         .required()
  //         .label("City"),
  //     state: Joi.string()
  //         .required()
  //         .label("State"),
  //     zip: Joi.string()
  //         .required()
  //         .label("ZIP code"),
  //     balance: Joi.number()
  //         .positive()
  //         .required()
  //         .label("ZIP code"),
  // });
  componentDidMount() {
    this.syncPersonalInfo();
    this.calculateBookingFee();
  }
  componentDidUpdate(prevProps, prevState) {
    const prevData = prevState.data;
    const { data } = this.state;
  }
  syncPersonalInfo = () => {
    const { personal_info } = this.props.auth.user;
    const { firstname, lastname, address1, address2, city, state, zip } =
      personal_info;
    const { data } = this.state;
    const { balance } = this.props;

    data['firstname'] = firstname;
    data['lastname'] = lastname;
    data['address1'] = address1;
    data['address2'] = address2;
    data['city'] = city;
    data['state'] = state;
    data['zip'] = zip;

    this.setState({
      data,
      familyId: personal_info.family_id,
    });
  };
  calculateBookingFee = () => {
    const bookingFee = this.props.appointments.length * 10;
    let actualFee = bookingFee;
    if (this.state.coupon !== false) {
      const { type, amount } = this.state.coupon;
      if (type === 'percentage') {
        actualFee = Math.max(0, bookingFee - bookingFee * amount);
      } else if (type === 'amount') {
        actualFee = Math.max(bookingFee - amount);
      }
    }
    this.setState({
      bookingFee,
      actualFee,
    });
  };
  doSubmit = async (e) => {
    this.setState({ submitting: true });

    e.preventDefault();

    let display_error = false;

    try {
      const { id } = this.props.auth.user;

      let amount = this.state.actualFee;

      if (this.state.data.using_balance) {
        // We only need to set the amount to something else if the user is using their balance
        // AND the balance is less than the fee. otherwise we can just charge the full fee.
        let balance = parseFloat(this.props.balance.balance);
        if (balance < amount) {
          amount = amount - balance;
        } else {
          amount = 0;
        }
      }

      const paymentData = {
        amount: amount,
        type: 'program-proposal-accept',
      };
      if (this.state.coupon !== false) {
        paymentData['coupon'] = this.state.coupon;
      }

      const response = await stripeService.charge(id, paymentData);
      if (response.status === 200 && response.data.payment_accepted == true) {
        if (!!this.state.data.using_balance) {
          this.createBalance();
        } else {
          this.props.onSuccessfulPayment();
        }
      } else {
        toast.error(
          'Your card was declined. Please check your information and try again.'
        );
        this.setState({ submitting: false });
      }
    } catch (error) {
      this.setState({ submitting: false });
    }
  };
  createBalance = async () => {
    try {
      const { family_id } = this.props.auth.user.personal_info;

      let balance = parseFloat(this.props.balance.balance);
      let { actualFee } = this.state;
      // Here we use the minimum of the two - if the fee is $10 and the credit is $15, we want to use the $10.
      // If the credit is $12 and the fee is $15, we would want to use the $12.
      let amount = Math.min(balance, actualFee);
      const { booking } = this.props;

      const apiData = {
        amount: -amount,
        notes: `User used $${amount} to pay their proposal fee for Consistent Care Request ${
          booking.id
        } on ${moment().format('MM/DD [at] hh:mm a')}`,
        family_id: family_id,
      };
      const response = await familyLedgerService.store(family_id, apiData);
      if (response.status === 201) {
        this.props.onSuccessfulPayment();
      } else {
        toast.error('Unusual error response from the server!');
        this.setState({
          submitting: false,
        });
      }
    } catch (e) {
      console.log(e);
      toast.error('Unusual error response from the server!');
      this.setState({
        submitting: false,
      });
    }
  };
  handleStripeError = (errorObj) => {
    const { code } = errorObj;
    if (code === 'incomplete_number') {
      //
    }
    if (code === 'incomplete_expiry') {
      //
    }
    if (code === 'incomplete_cvc') {
      //
    }
  };
  toggleEditBilling = () => {
    this.setState({
      editBillingAddress: !this.state.editBillingAddress,
    });
  };
  applyCouponCode = (coupon) => {
    this.setState(
      {
        coupon,
      },
      this.calculateBookingFee
    );
  };
  render() {
    const { hasStripeUser, auth } = this.props;
    const { user } = auth;
    const { bookingFee, actualFee, submitting, coupon } = this.state;
    const { balance } = this.props.balance;
    return (
      <form onSubmit={this.doSubmit} className="program-fee-form">
        {coupon === false && (
          <p className="booking-fee">
            Total Due Today: ${parseFloat(bookingFee).toFixed(2)}
          </p>
        )}
        {coupon !== false && (
          <div>
            <p className="coupon-info">
              Consistent Care Booking Fee: ${parseFloat(bookingFee).toFixed(2)}
            </p>
            <p className="coupon-info">
              Coupon:{' '}
              {coupon.type === 'percentage'
                ? coupon.amount * 100 + '%'
                : '$' + coupon.amount}{' '}
              off
            </p>
            <p className="booking-fee">
              Total Due Today: ${parseFloat(actualFee).toFixed(2)}
            </p>
          </div>
        )}
        <CouponChecker
          className="program-proposal-accept"
          type="program-proposal-accept"
          applyCouponCode={this.applyCouponCode}
        />
        <div className="payment-info-row">
          <div className="icon">
            {user.card_brand === 'visa' && <i className="fab fa-cc-visa"></i>}
            {user.card_brand === 'mastercard' && (
              <i className="fab fa-cc-mastercard"></i>
            )}
            {user.card_brand === 'discover' && (
              <i className="fab fa-cc-discover"></i>
            )}
            {user.card_brand === 'amex' && <i className="fab fa-cc-amex"></i>}
            {user.card_brand !== 'visa' &&
              user.card_brand !== 'mastercard' &&
              user.card_brand !== 'discover' &&
              user.card_brand !== 'amex' && (
                <i className="far fa-credit-card"></i>
              )}
          </div>
          <div className="last-four">
            <p>....&nbsp;{user.card_last_four}</p>
          </div>
        </div>
        {parseFloat(balance) > 0 && (
          <React.Fragment>
            {this.renderBoolean(
              'using_balance',
              `You have a credit of $${balance}. Would you like to use it today?`
            )}
          </React.Fragment>
        )}
        <div className="booking-footer">
          {submitting &&
            this.renderButton(
              'Pay Now',
              'Paying',
              'theme-btn primary-green',
              'fas fa-spinner fa-spin',
              true
            )}
          {!submitting &&
            this.renderButton(
              'Pay Now',
              'Paying',
              'theme-btn primary-green',
              'far fa-check-circle'
            )}
        </div>
      </form>
    );
  }
}

function mapState(state) {
  return {
    auth: state.auth,
    balance: state.balance,
  };
}

export default connect(mapState)(injectStripe(ProgramFeeForm));
