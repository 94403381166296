import React from 'react';
import { connect } from 'react-redux';
import { Route, Switch, Redirect, withRouter } from 'react-router-dom';

import {
  SystemNotices,
  AppointmentDetail,
  Page,
  PasswordResetSend,
  PasswordResetSet,
  Header,
  Login,
} from '../shared';
import { userHasCompletedRegistration, userHasRole } from '../../helpers';
import FamilyRegistration from './FamilyRegistration';
import SitterRegistration from './SitterRegistration';
import Landing from './Landing';

import registerSteps from '../../data/registerSteps';
import { mapAuthState } from '../../helpers/mapAuthState';

class PublicSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menuOpen: false,

      windowWidth: false,
    };
  }
  componentDidMount() {
    this.onWindowResize();
    $(window).on('resize', this.onWindowResize);

    const { user } = this.props.auth;

    if (!!user) {
      const { step } = user;

      if (!step && userHasRole(user, 'Family')) {
        this.props.history.push('/register/family/profile');
      }
      if (!step && userHasRole(user, 'Sitter')) {
        this.props.history.push('/register/sitter/application');
      }

      if (!!step && !userHasCompletedRegistration(user)) {
        switch (step.slug) {
          case 'sitter-application-pending':
            this.props.history.push('/register/sitter/application-submitted');
            break;
          case 'sitter-application-approved':
            this.props.history.push('/schedule-interview');
            break;
          case 'sitter-private-profile':
            this.props.history.push('/register/sitter/private-profile');
            break;
          case 'sitter-public-profile':
            this.props.history.push('/register/sitter/public-profile');
            break;
          case 'sitter-availability':
            this.props.history.push('/register/sitter/availability');
            break;
          case 'sitter-onboarding':
            this.props.history.push('/register/sitter/onboarding');
            break;
          case 'sitter-application-finished':
            this.props.history.push('/register/sitter/complete');
            break;
          case 'family-signup':
            this.props.history.push('/register/family/profile');
            return;
          case 'family-profile':
            this.props.history.push('/register/family/children');
            return;
          case 'family-children':
            this.props.history.push('/register/family/pets');
            return;
          case 'family-pets':
            this.props.history.push('/register/family/payments');
            return;
          case 'family-payments':
            this.props.history.push('/register/family/complete');
            return;
          // 15 means they are done and ready to go to the dashboard
        }
      }
    }
  }
  componentWillUnmount() {
    $(window).off('resize', this.onWindowResize);
  }
  onWindowResize = () => {
    // const windowWidth = $(window).width();
    const windowWidth = document.documentElement.clientWidth;
    this.setState({ windowWidth });
  };
  toggleMenu = () => {
    let { menuOpen } = this.state;
    menuOpen = !menuOpen;
    this.setState({ menuOpen });
  };
  render() {
    const { menuOpen, windowWidth } = this.state;

    return (
      <Page
        id="public-section"
        menuOpen={menuOpen}
        header={
          <Header
            type="public"
            className="public"
            menuOpen={menuOpen}
            toggleMenu={this.toggleMenu}
          />
        }
      >
        <Switch>
          <Route
            path="/register/sitter"
            exact={true}
            render={(props) => (
              <SitterRegistration {...props} windowWidth={windowWidth} />
            )}
          />
          <Route
            path="/register/sitter/:step"
            exact={true}
            render={(props) => (
              <SitterRegistration {...props} windowWidth={windowWidth} />
            )}
          />
          <Route
            path="/register/family"
            exact={true}
            component={FamilyRegistration}
          />
          <Route
            path="/register/family/:step"
            exact={true}
            component={FamilyRegistration}
          />
          <Route
            path="/schedule-interview"
            exact={true}
            render={(props) => (
              <SitterRegistration {...props} windowWidth={windowWidth} />
            )}
          />
          <Route
            path="/login"
            exact={true}
            render={(props) => <Login {...props} />}
          />
          <Route
            path="/password/reset"
            exact={true}
            component={PasswordResetSend}
          />
          <Route
            path="/password/reset/:hash"
            exact={true}
            component={PasswordResetSet}
          />
          <Route path="/" exact={true} render={() => <Landing />} />
          <Redirect to="/login" />
        </Switch>
      </Page>
    );
  }
}

export default withRouter(connect(mapAuthState)(PublicSection));
