import { each, find } from 'lodash-es';
import moment from 'moment-timezone';
import React from 'react';
import { booleanRadioOptions, states, wiggleOptions } from '../../../../data';
import {
  displayAddress,
  findFeeBySlug,
  firstInitial,
  upperCaseFirst,
} from '../../../../helpers';
import ChildCheckbox from '../../../shared/Form/ChildCheckbox';
import Input from '../../../shared/Form/Input';
import Radio from '../../../shared/Form/Radio';
import Select from '../../../shared/Form/Select';
import Textarea from '../../../shared/Form/Textarea';

class BookingReviewRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showDrivingDistance: false,
      totalFee: 0,
      hourlyFee: 0,
      hourlyRate: 0,
      drivingFee: 0,
      mileageRate: 0,
      wiggleRoomFee: 0,
      minMileageFee: 0,
      twelveHourFee: 0,
      flexFee: 0,
      duration: 0,
      showWiggleTime: false,
    };
  }
  componentDidMount = () => {
    this.calculateFees();
    this.setState({
      showDrivingDistance: !!this.props.appointment.driving_needed,
      showWiggleTime: !!this.props.appointment.flex,
    });
  };
  componentDidUpdate = (prevProps, prevState) => {
    const prevAppt = prevProps.appointment;
    const { appointment, idx } = this.props;

    // If driving is true, show the driving_distance field
    if (
      appointment['driving_needed'] === 1 &&
      prevAppt['driving_needed'] !== appointment['driving_needed']
    ) {
      this.setState({
        showDrivingDistance: true,
      });
    } else if (
      appointment['driving_needed'] === 0 &&
      prevAppt['driving_needed'] !== appointment['driving_needed']
    ) {
      this.setState({
        showDrivingDistance: false,
      });
    }

    // If driving is true, show the driving_distance field
    if (appointment['flex'] === 1 && prevAppt['flex'] !== appointment['flex']) {
      this.setState(
        {
          showWiggleTime: true,
        },
        () => {
          // Flex room defaults to 1 if flex has changed from 0 to 1
          this.props.handleApptChange(
            idx,
            'flex_room',
            wiggleOptions[0].id,
            'number'
          );
        }
      );
    } else if (
      appointment['flex'] === 0 &&
      prevAppt['flex'] !== appointment['flex']
    ) {
      this.setState(
        {
          showWiggleTime: false,
        },
        () => {
          // Flex room defaults to null if flex == 0
          this.props.handleApptChange(idx, 'flex_room', null);
        }
      );
    }
  };
  getHourlyRate = () => {
    const { fees, appointment } = this.props;
    const { date, start_time, end_time, overnight, num_nights } = appointment;
    let startDateAndTime = moment(`${date} ${start_time}`, 'YYYY-MM-DD HH:mm');
    let endDateAndTime = !!overnight
      ? moment(
          `${moment(date, 'YYYY-MM-DD')
            .add(num_nights, 'days')
            .format('YYYY-MM-DD')} ${end_time}`,
          'YYYY-MM-DD HH:mm'
        )
      : moment(`${date} ${end_time}`, 'YYYY-MM-DD HH:mm');
    const weekday = startDateAndTime.format('d');
    const hour = startDateAndTime.format('H');
    if (Number(weekday) === 6 && Number(hour) >= 15) {
      return parseFloat(findFeeBySlug('surge_pricing', fees).amount);
    } else {
      const numChildren = appointment.selected_children.length;
      let childFeeSlug = 'one_child_per_hour';
      if (numChildren >= 2 && numChildren <= 3)
        childFeeSlug = 'two_three_children_per_hour';
      else if (numChildren > 3) childFeeSlug = 'four_plus_children_per_hour';
      return parseFloat(findFeeBySlug(childFeeSlug, fees).amount);
    }
  };
  getNameById = (id, items, type) => {
    let item = find(items, (item) => {
      return item.id == id;
    });
    if (!!item) {
      if (type === 'children') {
        return upperCaseFirst(item.firstname);
      }
      if (type === 'sitters') {
        return (
          upperCaseFirst(item.firstname) + ' ' + firstInitial(item.lastname)
        );
      }
    }
    return false;
  };
  displayKidNames = (appointment) => {
    const { children } = this.props;
    const { selected_children } = appointment;
    const names = [];
    each(selected_children, (child) => {
      let name = this.getNameById(child, children, 'children');
      names.push(name);
    });
    return names.join(', ');
  };
  displaySitterNames = (appointment) => {
    const { sitters } = this.props;
    const { selected_sitters } = appointment;
    const names = [];
    each(selected_sitters, (sitter) => {
      let name = this.getNameById(sitter, sitters, 'sitters');
      names.push(name);
    });
    return names.join(', ');
  };
  openAppointment = () => {
    const { rid } = this.props.appointment;
    this.props.toggleEditAppointment(rid);
  };
  saveAppointment = () => {
    const { rid } = this.props.appointment;
    this.props.toggleEditAppointment(rid);
    this.calculateFees();
  };
  handleChildCheckboxChange = ({ currentTarget: input }) => {
    let children = [...this.props.appointment.selected_children];
    if (input.checked) {
      children.push(input.value);
    } else {
      //remove from array
      children = children.filter(function (item) {
        return item !== input.value;
      });
    }
    const { idx } = this.props;
    if (!children) children = [];
    this.props.handleApptChange(idx, 'selected_children', children);
  };
  calculateFees = () => {
    const { appointment, fees, children } = this.props;
    let {
      hourlyRate,
      hourlyFee,
      drivingFee,
      mileageRate,
      minMileageFee,
      duration,
      flexFee,
    } = this.state;

    let totalFee = 0;

    if (!!fees.length) {
      let {
        date,
        overnight,
        num_nights,
        start_time,
        end_time,
        driving_needed,
        driving_distance,
        selected_children,
        flex,
        flex_room,
      } = appointment;

      let startDateAndTime = moment(
        `${date} ${start_time}`,
        'YYYY-MM-DD HH:mm'
      );
      let endDateAndTime = !!overnight
        ? moment(
            `${moment(date, 'YYYY-MM-DD')
              .add(num_nights, 'days')
              .format('YYYY-MM-DD')} ${end_time}`,
            'YYYY-MM-DD HH:mm'
          )
        : moment(`${date} ${end_time}`, 'YYYY-MM-DD HH:mm');
      // Get the # of hours
      duration = moment
        .duration(endDateAndTime.diff(startDateAndTime))
        .asHours();

      hourlyRate = this.getHourlyRate();
      hourlyFee = Math.max(hourlyRate * duration, 30);
      hourlyFee = hourlyFee.toFixed(2);
      totalFee += hourlyFee;

      if (!!flex) {
        // Flex is in 15-minute increments. The flex fee is # increments * 1/4 of the hourly rate
        // So here we get the 1/4 of the hourly rate
        let flexRate = hourlyRate / 4;
        // And here we get the number of 15-min increments
        let flexIncrements = parseInt(flex_room) / 15;
        flexFee = flexRate * flexIncrements;
        totalFee += flexFee;
        flexFee = parseFloat(flexFee).toFixed(2);
      }

      // Calculate driving fee
      if (!!driving_needed) {
        const drivingFeeRate = parseFloat(
          findFeeBySlug('mileage_rate', fees).amount
        );
        const minDrivingFeeRate = parseFloat(
          findFeeBySlug('mileage_min', fees).amount
        );
        mileageRate = drivingFeeRate.toFixed(2);
        minMileageFee = minDrivingFeeRate.toFixed(2);
        const dist = !!driving_distance ? driving_distance : 0;
        let drivingDistanceFee = drivingFeeRate * parseFloat(dist);

        // There is a minimum fee of $5 for driving
        drivingFee = parseFloat(
          Math.max(drivingDistanceFee, minDrivingFeeRate)
        );
        totalFee += drivingFee;
        drivingFee = parseFloat(drivingFee).toFixed(2);
      }
    }

    totalFee = parseFloat(totalFee).toFixed(2);

    this.setState({
      hourlyRate,
      hourlyFee,
      drivingFee,
      mileageRate,
      minMileageFee,
      totalFee,
      duration,
      flexFee,
    });
  };
  render = () => {
    const { appointment, children, sitters, editing, idx } = this.props;
    const {
      hourlyRate,
      drivingFee,
      mileageRate,
      minMileageFee,
      hourlyFee,
      totalFee,
      duration,
      flexFee,
      showDrivingDistance,
      showWiggleTime,
    } = this.state;
    const errors = {};
    return (
      <div className="fold">
        {!!editing && (
          <div className="detail-section-wrapper">
            <div className="detail-section">
              <button
                className="theme-btn small slate-blue"
                onClick={this.saveAppointment}
              >
                <i className="fas fa-save"></i>&nbsp;Save
              </button>
              <h4 className="section-label">
                <i className="fas fa-list"></i>&nbsp;Sit Times
              </h4>
              <p>
                {moment(appointment.start_time, 'HH:mm').format('h:mm a')}
                &nbsp;-&nbsp;
                {moment(appointment.end_time, 'HH:mm').format('h:mm a')}
                {!!appointment.flex && (
                  <span className="wiggle">
                    ({appointment.flex_room} min Wiggle Room)
                  </span>
                )}
              </p>
            </div>
            <div className="detail-section">
              <h4 className="section-label">
                <i className="fas fa-list"></i>&nbsp;Sit Details
              </h4>
              <div className="form-group address">
                <Input
                  className="address1"
                  name={`apt-${idx}-address1`}
                  label="Address"
                  type="text"
                  value={appointment['address1']}
                  error={errors['address1']}
                  onChange={(e) => {
                    this.props.handleApptChange(
                      idx,
                      'address1',
                      e.target.value
                    );
                  }}
                  required={true}
                />
                <Input
                  className="address2"
                  name={`apt-${idx}-address2`}
                  label="Apt/Suite"
                  type="text"
                  value={appointment['address2']}
                  error={errors['address2']}
                  onChange={(e) => {
                    this.props.handleApptChange(
                      idx,
                      'address2',
                      e.target.value
                    );
                  }}
                  required={false}
                />
                <Input
                  className="city"
                  name={`apt-${idx}-city`}
                  label="City"
                  type="text"
                  value={appointment['city']}
                  error={errors['city']}
                  onChange={(e) => {
                    this.props.handleApptChange(idx, 'city', e.target.value);
                  }}
                  required={true}
                />
                <Select
                  className="state"
                  name={`apt-${idx}-state`}
                  label="State"
                  options={states}
                  value={appointment['state']}
                  error={errors['state']}
                  onChange={(e) => {
                    this.props.handleApptChange(idx, 'state', e.target.value);
                  }}
                  required={true}
                />
                <Input
                  className="zip"
                  name={`apt-${idx}-zip`}
                  label="ZIP"
                  type="text"
                  value={appointment['zip']}
                  error={errors['zip']}
                  onChange={(e) => {
                    this.props.handleApptChange(idx, 'zip', e.target.value);
                  }}
                  required={true}
                />
              </div>
              <div className="form-group flex">
                <Radio
                  className="flex"
                  name={`apt-${idx}-flex`}
                  label="Wiggle Room:"
                  options={booleanRadioOptions}
                  value={appointment['flex']}
                  error={errors['flex']}
                  onChange={(e) => {
                    this.props.handleApptChange(
                      idx,
                      'flex',
                      e.target.value,
                      'number'
                    );
                  }}
                  required={true}
                />
                {!!showWiggleTime && (
                  <Select
                    className="flex_room"
                    name={`apt-${idx}-flex_room`}
                    label="Time"
                    options={wiggleOptions}
                    value={appointment['flex_room']}
                    error={errors['flex_room']}
                    onChange={(e) => {
                      this.props.handleApptChange(
                        idx,
                        'flex_room',
                        e.target.value,
                        'number'
                      );
                    }}
                    required={true}
                  />
                )}
              </div>
              <div className="form-group">
                <Radio
                  className="driving_needed"
                  name={`apt-${idx}-driving_needed`}
                  label="Will this appointment require driving kids?"
                  options={booleanRadioOptions}
                  value={appointment['driving_needed']}
                  error={errors['driving_needed']}
                  onChange={(e) => {
                    this.props.handleApptChange(
                      idx,
                      'driving_needed',
                      e.target.value,
                      'number'
                    );
                  }}
                  required={true}
                />
                {!!showDrivingDistance && (
                  <Input
                    className="driving_distance"
                    name={`apt-${idx}-driving_distance`}
                    label="Approx. how many miles required?"
                    type="number"
                    value={appointment['driving_distance']}
                    error={errors['driving_distance']}
                    onChange={(e) => {
                      this.props.handleApptChange(
                        idx,
                        'driving_distance',
                        e.target.value,
                        'number'
                      );
                    }}
                    required={true}
                  />
                )}
              </div>
              <Textarea
                className="notes"
                name={`apt-${idx}-notes`}
                label="Notes for this appointment"
                value={appointment['notes']}
                error={errors['notes']}
                onChange={(e) => {
                  this.props.handleApptChange(idx, 'notes', e.target.value);
                }}
                required={false}
              />
              <ChildCheckbox
                className="selected_children"
                name={`apt-${idx}-selected_children`}
                label="Select kids that need babysitting for this appointment"
                options={children}
                value={appointment['selected_children']}
                error={errors['selected_children']}
                onChange={this.handleChildCheckboxChange}
                required={true}
              />
            </div>
          </div>
        )}
        {!editing && (
          <div className="detail-section-wrapper">
            <div className="detail-section">
              <button
                className="theme-btn small slate-blue"
                onClick={this.openAppointment}
              >
                <i className="fas fa-edit"></i>&nbsp;Edit
              </button>
              <h4 className="section-label">
                <i className="fas fa-list"></i>&nbsp;Sit Times
              </h4>
              <p>
                {moment(appointment.start_time, 'HH:mm').format('h:mm a')}
                &nbsp;-&nbsp;
                {moment(appointment.end_time, 'HH:mm').format('h:mm a')}
                {!!appointment.flex && (
                  <span className="wiggle">
                    &nbsp;&nbsp;({appointment.flex_room} min Wiggle Room)
                  </span>
                )}
              </p>
            </div>
            <div className="detail-section">
              <h4 className="section-label">
                <i className="fas fa-list"></i>&nbsp;Sit Details
              </h4>
              <p>
                <b>Address:</b>
                <br />
                {displayAddress(appointment)}
              </p>
              <p>
                <b>Driving Required:</b>
                <br />
                {appointment.driving_needed
                  ? `Yes (${appointment.driving_distance} mi)`
                  : `No`}
              </p>
              <p>
                <b>Details for Sit:</b>
                <br />
                {appointment.notes}
              </p>
              <p>
                <b>Kids:</b>
                <br />
                {this.displayKidNames(appointment)}
              </p>
              <p>
                <b>Sitter:</b>
                <br />
                {this.displaySitterNames(appointment)}
              </p>
            </div>
          </div>
        )}
        {!!appointment.overnight && !!appointment.num_nights && (
          <div className="projected-cost">
            <p>Overnight sitting rates paid directly to provider:</p>
            <p>
              Normal hourly rate applies to daytime sitting hours requested from
              7am-7pm
            </p>
            <p>$99 flat rate applies to overnight sitting hours from 7pm-7am</p>
          </div>
        )}
        {(!appointment.overnight || !appointment.num_nights) && (
          <div className="projected-cost">
            <h4>Projected Cost</h4>
            <p>
              {duration} hours - ${hourlyRate}/hr
            </p>
            {!!appointment.flex && (
              <p>
                Wiggle Room ({appointment.flex_room} mins) - ${flexFee}
              </p>
            )}
            {!!appointment.driving && (
              <p>
                Driving ({appointment.driving_distance} mi) - ${drivingFee}
              </p>
            )}
            <h4 className="total">Estimated Total - ${totalFee}</h4>
          </div>
        )}
      </div>
    );
  };
}

export default BookingReviewRow;
