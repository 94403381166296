import { useHistory } from 'react-router';
import { notificationService } from '@ss/services';
import { useMutation, useQueryClient } from 'react-query';
import { useNotificationsQueryKey } from '@ss/hooks/queryKeys';
import { useReportError } from '@ss/hooks/useReportError';
import { toast } from 'react-toastify';
export function useNotificationActions() {
    const queryKey = useNotificationsQueryKey();
    const queryClient = useQueryClient();
    const reportError = useReportError();
    const history = useHistory();
    const ignoreMutation = useMutation(notificationService.ignoreNotification, {
        onMutate: async (id) => {
            await queryClient.cancelQueries(queryKey);
            const previousData = queryClient.getQueryData(queryKey);
            if (previousData) {
                const nextData = previousData.filter((item) => item.id !== id) ?? [];
                queryClient.setQueryData(queryKey, () => nextData);
            }
            return { previousData };
        },
        onError(err, _notificationId, context) {
            if (context?.previousData) {
                queryClient.setQueryData(queryKey, context.previousData);
            }
            reportError('Error while ignoring notification', err);
            toast.error('We couldn’t ignore this notification');
        },
        onSettled: () => {
            queryClient.invalidateQueries(queryKey);
        },
    });
    const ignoreAllMutation = useMutation(notificationService.ignoreAllNotifications, {
        onMutate: async () => {
            await queryClient.cancelQueries(queryKey);
            const previousData = queryClient.getQueryData(queryKey);
            queryClient.setQueryData(queryKey, () => []);
            return { previousData };
        },
        onError(err, _params, context) {
            if (context?.previousData) {
                queryClient.setQueryData(queryKey, context.previousData);
            }
            reportError('Error while ignoring all notifications', err);
            toast.error('We couldn’t ignore notification. Try again');
        },
        onSettled: () => {
            queryClient.invalidateQueries(queryKey);
        },
    });
    const execute = (notification) => {
        if (notification.action === 'AppointmentReview') {
            history.push(`/review/${notification.model_id}`);
            return;
        }
        if (notification.action === 'AdminAppointment') {
            history.push(`/appointments/view/${notification.model_id}`);
            return;
        }
        alert(`execute action: ${notification.action} for model ${notification.model_id}`);
    };
    return {
        execute,
        ignore: ignoreMutation.mutate,
        ignoreAll: ignoreAllMutation.mutate,
    };
}
