import clsx from 'clsx';
import React, { forwardRef, useState } from 'react';
import DatePicker from 'react-datepicker';
const CustomInput = forwardRef(function CustomInput(props, ref) {
    return (React.createElement("input", { ...props, type: "text", readOnly: true, className: "filter-input", ref: ref }));
});
const RangeDatePicker = (props) => {
    const { name, label, required, dateFormat, onChange, error, disabled, minDate, maxDate = null, autoComplete, } = props;
    const [isFocus, setIsFocus] = useState(false);
    const [dateRange, setDateRange] = useState([
        props.startDate,
        props.endDate,
    ]);
    const [startDate, endDate] = dateRange;
    const onFocus = () => setIsFocus(true);
    const onBlur = () => setIsFocus(false);
    const handleChange = (nextDateRange) => {
        setDateRange(nextDateRange);
        onChange(nextDateRange);
    };
    return (React.createElement("div", { className: clsx('form-field', 'datepicker', name, {
            active: startDate || endDate,
            focus: isFocus,
        }) },
        React.createElement("div", null,
            React.createElement(DatePicker, { id: name, selectsRange: true, startDate: startDate, endDate: endDate, dateFormat: dateFormat, onChange: handleChange, onFocus: onFocus, onBlur: onBlur, onCalendarClose: onBlur, isClearable: true, minDate: minDate ?? new Date(), maxDate: maxDate, disabled: disabled, popperPlacement: "bottom-start", preventOpenOnFocus: true, customInput: React.createElement(CustomInput), autoComplete: autoComplete }),
            React.createElement("span", { className: "far fa-calendar" })),
        React.createElement("label", { htmlFor: name },
            label,
            required && React.createElement("span", { className: "required" }, "\u00A0*")),
        error && (React.createElement("span", { className: "error", role: "alert" },
            React.createElement("strong", null, error)))));
};
export default RangeDatePicker;
