import $ from 'jquery';
import { each, filter, map } from 'lodash-es';
import moment from 'moment-timezone';
import React from 'react';
import { connect } from 'react-redux';
import { Link, Prompt } from 'react-router-dom';
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  injectStripe,
} from 'react-stripe-elements';
import { toast } from 'react-toastify';
import { balanceActions } from '../../../../actions/balanceActions';
import { states } from '../../../../data';
import { PaymentHelper } from '../../../shared';
import { findFeeBySlug, upperCaseFirst } from '../../../../helpers';
import appointmentService from '../../../../services/appointmentService';
import familyLedgerService from '../../../../services/familyLedgerService';
import familyService from '../../../../services/familyService';
import feeService from '../../../../services/feeService';
import stripeService from '../../../../services/stripeService';
import { CouponChecker } from '../../../shared';
import Form from '../../../shared/Form';
import BookingDates from './BookingDates';
import BookingDetails from './BookingDetails';
import BookingReview from './BookingReview';
import BookingSitterSelection from './BookingSitterSelection';
import BookingTimes from './BookingTimes';
import Joi from 'joi';

const fieldStyles = {
  fontFamily: "'Open Sans', sans-serif",
  fontSize: '16px',
  color: '#252525',
  '::placeholder': {
    color: '#FFF',
  },
};

const UNSAVED_CHANGES_MESSAGE =
  'Changes will be LOST if you navigate away from the page. Are you sure you want to proceed?';

class RegularBooking extends PaymentHelper {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        booking_appointments: [],
        using_balance: false,
        firstname: '',
        lastname: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
        zip: '',
      },
      freshSchema: {
        rid: null,
        all_children: 1,
        start_time: null,
        end_time: null,
        flex: 0,
        flex_room: null,
        overnight: 0,
        num_nights: null,
        driving_needed: null,
        driving_distance: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
        zip: '',
        selected_sitters: [],
        selected_children: [],
      },
      dirty: false,
      step: 1,
      errors: {},
      today: moment(),
      sitters: [],
      children: [],
      fees: [],
      stripeUser: null,
      holidays: [],
      canAdvanceStep: false,
      hasStripeUser: false,
      changingPaymentInfo: false,
      sameTimes: false,

      bookingFee: 0,
      actualFee: 0,

      submitting: false,

      coupon: false,
    };
  }
  componentDidMount = () => {
    this.getBalance();
    this.getChildren();
    // this.getSitters();
    this.getFeeData();
    this.getStripeCustomer();

    window.onbeforeunload = () => {
      const { dirty } = this.state;
      return dirty ? UNSAVED_CHANGES_MESSAGE : null;
    };
  };
  componentWillUnmount = () => {
    window.onbeforeunload = () => {
      return null;
    };
  };
  componentDidUpdate(prevProps, prevState) {
    // Balance checking
    const prevBalance = prevProps.balance.balance;
    const { balance } = this.props.balance;
    if (parseFloat(balance) < 0) {
      toast.error(
        `Uh oh! Looks like you have an outstanding balance of $${parseFloat(
          Math.abs(balance)
        )}. You must pay this before you are allowed to book appointments.`
      );
      this.props.history.push('/settings/billing');
    }

    // Check for anything that needs to be done when the step advances
    const { step } = this.state;
    const prevStep = prevState.step;
    if (step !== prevStep) {
      this.scrollTop();
      if (step === 2) {
        this.getChildren();
      }
      if (step === 3) {
        this.getSitters();
      }
      if (step === 5) {
        this.calculateBookingFees();
      }
      if (step === 6) {
        this.getBalance();
      }
    }

    // Check if the appointment length has changed on step 4 and if so, recalculate the booking fee
    const appointments = this.state.data.booking_appointments;
    const prevAppointments = prevState.data.booking_appointments;
    if (appointments.length !== prevAppointments.length) {
      if (appointments.length === 0) {
        this.setState({ step: 1 });
      }

      if (step === 5) {
        this.calculateBookingFees();
      }
    }
  }
  getBalance = async () => {
    try {
      const { family_id } = this.props.auth.user.personal_info;
      const response = await this.props.getBalance(family_id);
    } catch (e) {
      console.log(e);
    }
  };
  getFeeData = async () => {
    const response = await feeService.get();
    if (response.status === 200) {
      const { fees } = response.data.data;
      this.setState({
        fees,
      });
    }
  };
  getStripeCustomer = async () => {
    try {
      const { id } = this.props.auth.user;
      const response = await stripeService.get(id);
      if (response.status === 200) {
        const { user, stripeUser } = response.data.data;
        this.setState({
          stripeUser,
          hasStripeUser: true,
        });
      }
    } catch (e) {
      console.log(e);
    }
  };
  getChildren = async () => {
    try {
      const { family_id } = this.props.auth.user.personal_info;
      const response = await familyService.get(family_id);
      if (response.status === 200) {
        const { children } = response.data.data;
        let selected_children = map(children, (child) => {
          return child.id + '';
        });
        const freshSchema = { ...this.state.freshSchema };
        freshSchema['selected_children'] = selected_children;
        this.setState(
          {
            children,
            freshSchema,
          },
          () => {
            this.setSameProp('selected_children', selected_children);
          }
        );
      }
    } catch (e) {
      console.log(e);
      toast.error('Uh oh! Something went wrong! Please refresh and try again!');
    }
  };
  getSitters = async () => {
    try {
      const { family_id } = this.props.auth.user.personal_info;
      const rawAppointments = [...this.state.data.booking_appointments];
      let appointments = [];
      each(rawAppointments, (appointment) => {
        let appt = { ...appointment };
        appt['start_date'] = appt['date'];
        appt['end_date'] = !!appt.overnight
          ? moment(
              moment(appt['date'], 'YYYY-MM-DD')
                .add(appt['num_nights'], 'days')
                .format('YYYY-MM-DD')
            )
          : appt['date'];
        appointments.push(appt);
      });
      const apiData = { appointments };
      const response = await familyService.getSitters(family_id, apiData);
      if (response.status === 200) {
        const { sitters } = response.data.data;
        this.setState({
          sitters,
        });
      }
    } catch (e) {
      console.log(e);
      toast.error('Uh oh! Something went wrong! Please refresh and try again!');
    }
  };
  scrollTop = () => {
    $('main').animate({ scrollTop: 0 }, 0);
  };
  advanceStep = (e) => {
    if (this.state.canAdvanceStep) {
      if (!!e) e.preventDefault();
      let { step } = this.state;
      step += 1;
      this.setState({
        step,
        canAdvanceStep: false,
      });
    }
  };
  onSelectedDay = (currentDate) => {
    const isSameOrAfter = currentDate.isSameOrAfter(this.state.today, 'day');
    const isBeforeMaxDate = currentDate.isSameOrBefore(
      moment().add(60, 'days'),
      'day'
    );
    if (!isSameOrAfter) {
      toast.error('Uh oh! You must select a date after today.');
    } else if (!isBeforeMaxDate) {
      toast.error(
        'Uh oh! You can only book appointments that are within 60 days from today.'
      );
    } else {
      if (currentDate.isSame(this.state.today, 'day')) {
        var late = moment('22:45', 'HH:mm');
        if (late.isSameOrBefore(moment())) {
          toast.dismiss();
          toast.error(
            'It is too late today to book an appointment. Please select a different date.'
          );
        } else {
          this.pushNewDate(currentDate);
        }
      } else {
        this.pushNewDate(currentDate);
      }
    }
  };
  pushNewDate = (currentDate) => {
    toast.dismiss();
    const data = { ...this.state.data };
    const appointments = [...data['booking_appointments']];
    const appointment = {
      ...this.state.freshSchema,
      // Appointment default start time to be 9am, which means we need to set both start and end time and filter end times automatically
      start_time: '09:00',
      end_time: '12:00',
    };
    appointment['date'] = currentDate.format('YYYY-MM-DD');
    // We add the length of the appts array to try to minimize the chance that 2 apps will have the same rid
    appointment['rid'] =
      appointments.length + 1 + Math.round(Math.random() * 10000000000);
    if (appointments.length >= 10) {
      toast.error('You cannot book more than 10 appointments at once.');
    } else {
      appointments.push(appointment);
      data['booking_appointments'] = appointments;
      this.setState({
        data,
        dirty: true,
        canAdvanceStep: true,
      });
    }
  };
  removeAppointment = (idx) => {
    const data = { ...this.state.data };
    const appointments = [...data['booking_appointments']];
    appointments.splice(idx, 1);
    data['booking_appointments'] = appointments;
    let dirty = appointments.length > 0;
    this.setState({
      data,
      dirty,
    });
  };
  toggleSameTimes = (value) => {
    this.setState({
      sameTimes: Boolean(value),
    });
  };
  onTimeFormComplete = () => {
    this.setState({ step: 3 });
  };
  onDetailsFormComplete = () => {
    this.setState({ step: 4 });
  };
  onSitterSelectionFormComplete = () => {
    this.setState({ step: 5 });
  };
  handleApptChange = (idx, key, value, type = 'default') => {
    // Sets 1 field to 1 appointment
    // Type is used specifically for numbers only.
    const data = { ...this.state.data };
    const booking_appointments = [...data['booking_appointments']];
    const appt = { ...booking_appointments[idx] };
    if (Array.isArray(value)) {
      // If it's an array, we just want to set it as well
      appt[key] = value;
    } else if (type === 'number') {
      // If it's a number, we want to parse it as such
      appt[key] = Number(value);
    } else {
      // Otherwise we just set it
      appt[key] = value;
    }
    booking_appointments[idx] = appt;
    data['booking_appointments'] = booking_appointments;
    this.setState({ data });
  };
  handleBulkApptChange = (idx, fieldsObj) => {
    // Sets a bunch of fields to 1 appointment
    // Handle bulk value changes (as an object of key > values)
    const data = { ...this.state.data };
    const booking_appointments = [...data['booking_appointments']];
    const appt = { ...booking_appointments[idx] };
    const keys = Object.keys(fieldsObj);
    keys.map((key) => {
      // We don't process the value here because it's passed in as an object
      // As in, we can just pass in exactly what we want beforehand instead of worrying
      // about shitty HTML form element shenanigans.
      appt[key] = fieldsObj[key];
    });
    booking_appointments[idx] = appt;
    data['booking_appointments'] = booking_appointments;
    this.setState({ data });
  };
  setSameProp = (key, value, type = 'default') => {
    // Sets 1 property to all appointments
    const data = { ...this.state.data };
    const appointments = [];
    each(data['booking_appointments'], (appointment, idx) => {
      const appt = { ...appointment };
      if (Array.isArray(value)) {
        // If it's an array, we just want to set it as well
        appt[key] = value;
      } else if (type === 'number') {
        // If it's a number, we want to parse it as such
        appt[key] = Number(value);
      } else {
        // Otherwise we just set it
        appt[key] = value;
      }
      appointments.push(appt);
    });
    data['booking_appointments'] = appointments;
    this.setState({ data });
  };
  setBulkSameProp = (fieldsObj) => {
    // Sets 1 property to all appointments
    const data = { ...this.state.data };
    const appointments = [];
    const keys = Object.keys(fieldsObj);
    each(data['booking_appointments'], (appointment, idx) => {
      const appt = { ...appointment };
      keys.map((key) => {
        appt[key] = fieldsObj[key];
      });
      appointments.push(appt);
    });
    data['booking_appointments'] = appointments;
    this.setState({ data });
  };
  setSamePropByIds = (ids, key, value, type = 'default') => {
    // Sets 1 property to a group of appointments, based on their ric
    // ids is an array of rid's that we will filter against and set the values accordingly
    // We also need to do some re-filtering when this is done, so we return a promise here
    // so we can `await` its response and fire off callbacks accordingly
    var promise = new Promise((resolve, reject) => {
      const data = { ...this.state.data };
      const appointments = [];
      each(data['booking_appointments'], (appointment, idx) => {
        const appt = { ...appointment };
        let { rid } = appt;
        if (ids.indexOf(rid) > -1) {
          appt[key] = value;
        }
        appointments.push(appt);
      });
      data['booking_appointments'] = appointments;
      this.setState({ data }, () => {
        resolve(true);
      });
    });
    return promise;
  };
  isFormDone = () => {
    // This is currently only used for steps 2 and 4
    let done = false;
    const { step } = this.state;
    let failedAppointments = filter(
      this.state.data.booking_appointments,
      (appointment) => {
        const { start_time, end_time, flex, flex_room, overnight, num_nights } =
          appointment;
        if (step === 2) {
          // If flex is yes, then flex_room is required. Otherwise, it auto-passes (since flex must be either 1 or 0)
          const flexValid = !!flex ? flex && !!flex_room : true;
          // If driving is yes, then driving is required. Otherwise, it auto-passes (since driving must be either 1 or 0)
          const overnightValid = !!overnight ? overnight && !!num_nights : true;
          return !(flexValid && overnightValid && !!start_time && !!end_time);
        }
        if (step === 4) {
          const {
            address1,
            city,
            state,
            zip,
            selected_children,
            driving_needed,
            driving_distance,
          } = appointment;
          // If flex is yes, then flex_room is required. Otherwise, it auto-passes (since flex must be either 1 or 0)
          const flexValid = !!flex ? flex && !!flex_room : true;
          // If driving is yes, then driving is required. Otherwise, it auto-passes (since driving must be either 1 or 0)
          const drivingValid = !!driving_needed
            ? driving_needed && !!driving_distance
            : driving_needed !== null;
          console.log(drivingValid, driving_needed, driving_needed !== null);
          return !(
            flexValid &&
            drivingValid &&
            !!address1 &&
            !!city &&
            !!state &&
            !!zip &&
            selected_children.length > 0 &&
            !!start_time &&
            !!end_time
          );
        }
      }
    );
    return failedAppointments.length === 0;
  };
  calculateBookingFees = () => {
    // totalFee will be the full bookingFee, sans any discounts
    let totalFee = 0;
    // actualFee will be the actual fee the user is charged, after coupons and discounts
    let actualFee = 0;
    const appointments = this.state.data.booking_appointments;
    each(appointments, (appointment) => {
      if (!!appointment.overnight && !!appointment.num_nights) {
        let num = appointment.num_nights;
        for (var i = 0; i <= num; i++) {
          // Get the moment obj of the start date by adding i days to the start date.
          // i being 0 initially gets the start date of the first appointment
          let startDateAndTime = moment(
            `${appointment.date} ${appointment.start_time}`,
            'YYYY-MM-DD HH:mm'
          ).add(i, 'days');
          totalFee += this.calculateBookingFee(startDateAndTime);
        }
      } else {
        // Get the moment obj of the start date
        let startDateAndTime = moment(
          `${appointment.date} ${appointment.start_time}`,
          'YYYY-MM-DD HH:mm'
        );
        totalFee += this.calculateBookingFee(startDateAndTime);
      }
    });
    actualFee = totalFee;
    if (this.state.coupon !== false) {
      const { type, amount } = this.state.coupon;
      if (type === 'percentage') {
        actualFee = Math.max(0, totalFee - totalFee * amount);
      } else if (type === 'amount') {
        actualFee = Math.max(0, totalFee - amount);
      }
    }
    this.setState({
      bookingFee: parseFloat(totalFee).toFixed(2),
      actualFee: actualFee,
    });
  };
  calculateBookingFee = (date) => {
    // Then find the hours until the appointment
    const hoursUntilAppt = moment.duration(date.diff(moment())).asHours();
    let bookingRateSlug = 'regular';
    // let bookingType = "Regular";
    // And then use that to calculate which booking rate to charge them
    if (hoursUntilAppt <= 48) {
      bookingRateSlug = 'regular_rush';
      // bookingType = "Rush";
    }
    return parseFloat(findFeeBySlug(bookingRateSlug, this.state.fees).amount);
  };
  changePaymentInfo = (e) => {
    e.preventDefault();
    this.setState({
      changingPaymentInfo: !this.state.changingPaymentInfo,
    });
  };
  applyCouponCode = (coupon) => {
    this.setState({ coupon }, this.calculateBookingFees);
  };
  doSubmit = async () => {
    this.setState({
      submitting: true,
    });
    // if (this.state.data.using_balance) {
    //     this.createBalance();
    // } else {
    //     this.submitPayment();
    // };
    this.submitPayment();
  };
  submitPayment = async () => {
    let display_error = false;

    try {
      const { hasStripeUser, changingPaymentInfo } = this.state;
      const { id } = this.props.auth.user;

      let amount = this.state.actualFee;

      if (this.state.data.using_balance) {
        // We only need to set the amount to something else if the user is using their balance
        // AND the balance is less than the fee. otherwise we can just charge the full fee.
        let balance = parseFloat(this.props.balance.balance);
        if (balance < amount) {
          amount = amount - balance;
        } else {
          amount = 0;
        }
      }

      const paymentData = {
        amount: amount,
        type: 'booking',
      };
      if (this.state.coupon !== false) {
        paymentData['coupon'] = this.state.coupon;
      }

      if (hasStripeUser && !changingPaymentInfo) {
        const response = await stripeService.charge(id, paymentData);
        if (response.status === 200 && response.data.payment_accepted == true) {
          if (this.state.data.using_balance) {
            this.createBalance();
          } else {
            this.submitBooking();
          }
        } else {
          toast.error(
            'Your card was declined. Please check your information and try again.'
          );
          this.setState({
            submitting: false,
          });
        }
      } else {
        const { firstname, lastname, address1, address2, city, state, zip } =
          this.state.data;
        const cardNumber = this.props.elements.getElement('cardNumber');
        let { paymentMethod, error } =
          await this.props.stripe.createPaymentMethod({
            type: 'card',
            card: cardNumber,
            billing_details: {
              name: firstname + ' ' + lastname,
              address: {
                line1: address1,
                line2: address2,
                city,
                postal_code: zip,
                state,
              },
            },
          });
        if (error) {
          this.handleStripeError(error);
          this.setState({
            submitting: false,
          });
        } else {
          paymentData['paymentMethod'] = paymentMethod;
          const response = await stripeService.charge(id, paymentData);

          if (
            response.status === 200 &&
            response.data.payment_accepted == true
          ) {
            if (this.state.data.using_balance) {
              this.createBalance();
            } else {
              this.submitBooking();
            }
          } else {
            toast.error(
              'Your card was declined. Please check your information and try again.'
            );
            this.setState({
              submitting: false,
            });
          }
        }
      }
    } catch (e) {
      console.log(e);
      this.setState({
        submitting: false,
      });
    }
  };
  createBalance = async () => {
    try {
      const numAppts = this.state.data.booking_appointments.length;
      const { family_id } = this.props.auth.user.personal_info;

      let balance = parseFloat(this.props.balance.balance);
      let { actualFee } = this.state;
      // Here we use the minimum of the two - if the fee is $10 and the credit is $15, we want to use the $10.
      // If the credit is $12 and the fee is $15, we would want to use the $12.
      let amount = Math.min(balance, actualFee);

      const apiData = {
        amount: -amount,
        notes: `User used $${amount} to book ${numAppts} appointments on ${moment().format(
          'MM/DD [at] hh:mm a'
        )}`,
        family_id: family_id,
      };
      const response = await familyLedgerService.store(family_id, apiData);
      if (response.status === 201) {
        this.submitBooking();
      } else {
        toast.error('Unusual error response from the server!');
        this.setState({
          submitting: false,
        });
      }
    } catch (e) {
      console.log(e);
      toast.error('Unusual error response from the server!');
      this.setState({
        submitting: false,
      });
    }
  };
  handleStripeError = (errorObj) => {
    const { code } = errorObj;
    if (code === 'incomplete_number') {
      //
    }
    if (code === 'incomplete_expiry') {
      //
    }
    if (code === 'incomplete_cvc') {
      //
    }
  };
  onPaymentComplete = () => {
    this.submitBooking();
  };
  submitBooking = async () => {
    try {
      const piid = this.props.auth.user.personal_info.id;
      const appointments = [...this.state.data.booking_appointments];
      let booking_appointments = [];
      each(appointments, (appointment) => {
        let appt = appointment;
        appt['start_time'] = moment(
          `${appt['date']} ${appt['start_time']}`,
          'YYYY-MM-DD HH:mm:ss'
        ).format();
        appt['end_time'] = !!appt.overnight
          ? moment(
              `${moment(appt['date'], 'YYYY-MM-DD')
                .add(appt['num_nights'], 'days')
                .format('YYYY-MM-DD')} ${appt['end_time']}`,
              'YYYY-MM-DD HH:mm:ss'
            ).format()
          : moment(
              `${appt['date']} ${appt['end_time']}`,
              'YYYY-MM-DD HH:mm:ss'
            ).format();
        delete appt['date'];
        delete appt['id'];
        booking_appointments.push(appt);
      });
      const apiData = { booking_appointments };
      const response = await appointmentService.create(piid, apiData);
      if (response.status === 200) {
        toast.success(
          `Your appointment${
            booking_appointments.length > 1 ? 's have' : ' has'
          } been saved.`
        );
        this.setState({
          step: 6,
          dirty: false,
        });
      } else {
        toast.error('Unusual error response from the server!');
        this.setState({
          submitting: false,
        });
      }
    } catch (e) {
      console.log(e);
      this.setState({
        submitting: false,
      });
    }
  };
  render() {
    const {
      step,
      canAdvanceStep,
      data,
      sameTimes,
      sitters,
      children,
      fees,
      bookingFee,
      actualFee,
      hasStripeUser,
      changingPaymentInfo,
      submitting,
      dirty,
      coupon,
    } = this.state;
    const { using_balance } = this.state.data;
    const { balance } = this.props.balance;
    const { user } = this.props.auth;
    const { booking_appointments } = data;

    return (
      <div className={`booking-component step-${step}`}>
        <div className="booking-component-inner">
          <div className="regular-booking-wrapper">
            <Prompt when={dirty} message={UNSAVED_CHANGES_MESSAGE} />
            {step < 5 && (
              <div className="booking-header">
                <div className="inner">
                  <div
                    className={`booking-step ${
                      step === 1 ? 'active' : step > 1 ? 'completed' : ''
                    }`}
                  >
                    <i className="fas fa-calendar-star"></i>
                    <p>
                      Calendar
                      <br />
                      Dates
                    </p>
                  </div>
                  <div
                    className={`booking-step ${
                      step === 2 ? 'active' : step > 2 ? 'completed' : ''
                    }`}
                  >
                    <i className="fas fa-clock"></i>
                    <p>
                      Start /<br />
                      End Times
                    </p>
                  </div>
                  <div
                    className={`booking-step ${
                      step === 3 ? 'active' : step > 3 ? 'completed' : ''
                    }`}
                  >
                    <i className="fas fa-list-alt"></i>
                    <p>
                      Sit Details
                      <br />
                      &nbsp;
                    </p>
                  </div>
                  <div
                    className={`booking-step ${
                      step === 4 ? 'active' : step > 4 ? 'completed' : ''
                    }`}
                  >
                    <i className="fas fa-address-card"></i>
                    <p>
                      Select
                      <br />
                      Sitters
                    </p>
                  </div>
                </div>
              </div>
            )}
            <div className="booking-body">
              {step === 1 && (
                <div className="book-appointment-form dates">
                  <h2>Select Booking Dates</h2>
                  {booking_appointments.length > 0 && (
                    <p className="dates">
                      <b>({booking_appointments.length})</b>{' '}
                      {booking_appointments.length > 1 ? 'Dates' : 'Date'}{' '}
                      Selected
                    </p>
                  )}
                  {booking_appointments.length == 0 && <p>&nbsp;</p>}
                  <BookingDates
                    appointments={booking_appointments}
                    removeAppointment={this.removeAppointment}
                    onSelectedDay={this.onSelectedDay}
                    canAdvanceStep={booking_appointments.length > 0}
                    onAdvanceStep={this.advanceStep}
                  />
                </div>
              )}
              {step === 2 && (
                <div className="book-appointment-form times">
                  <h2>Set Start And End Times</h2>
                  <BookingTimes
                    appointments={booking_appointments}
                    onFormComplete={this.onTimeFormComplete}
                    removeAppointment={this.removeAppointment}
                    toggleSameTimes={this.toggleSameTimes}
                    sameTimes={sameTimes}
                    handleApptChange={this.handleApptChange}
                    handleBulkApptChange={this.handleBulkApptChange}
                    setSameProp={this.setSameProp}
                    setBulkSameProp={this.setBulkSameProp}
                    isFormDone={this.isFormDone}
                  />
                </div>
              )}
              {step === 3 && (
                <div className="book-appointment-form details">
                  <BookingDetails
                    appointments={booking_appointments}
                    removeAppointment={this.removeAppointment}
                    handleApptChange={this.handleApptChange}
                    handleBulkApptChange={this.handleBulkApptChange}
                    onFormComplete={this.onDetailsFormComplete}
                    sitters={sitters}
                    children={children}
                  />
                </div>
              )}
              {step === 4 && (
                <div className="book-appointment-form details">
                  <h2>Select Sitters</h2>
                  <BookingSitterSelection
                    appointments={booking_appointments}
                    removeAppointment={this.removeAppointment}
                    handleApptChange={this.handleApptChange}
                    setSamePropByIds={this.setSamePropByIds}
                    setSameProp={this.setSameProp}
                    onFormComplete={this.onSitterSelectionFormComplete}
                    sitters={sitters}
                    children={children}
                  />
                </div>
              )}
              {step === 5 && (
                <div className="book-appointment-form details">
                  <h2>Appointment Review</h2>
                  <BookingReview
                    appointments={booking_appointments}
                    handleApptChange={this.handleApptChange}
                    removeAppointment={this.removeAppointment}
                    sitters={sitters}
                    children={children}
                    onFormComplete={this.doSubmit}
                    submitting={submitting}
                    fees={fees}
                    isFormDone={this.isFormDone}
                    PaymentSection={
                      <div className="booking-payment-info">
                        <div className="booking-coupon">
                          <CouponChecker
                            className="booking"
                            type="booking"
                            applyCouponCode={this.applyCouponCode}
                          />
                        </div>
                        <div className="booking-fees">
                          <div className="line-item">
                            <h3>Total Charged Today</h3>
                            <div className="fee">
                              <div className="label">
                                <p>Booking Fee</p>
                              </div>
                              <div className="value">
                                <h4 className="booking-fee">${bookingFee}</h4>
                              </div>
                            </div>
                            {coupon !== false && (
                              <div className="fee-breakdown">
                                <div className="label">
                                  <p>Coupon</p>
                                </div>
                                <div className="value">
                                  <h4>
                                    -
                                    {coupon.type === 'percentage'
                                      ? coupon.amount * 100 + '%'
                                      : '$' + coupon.amount}{' '}
                                    off
                                  </h4>
                                </div>
                              </div>
                            )}
                            {coupon !== false && !using_balance && (
                              <div className="fee-breakdown">
                                <div className="label">
                                  <p>Charged Today</p>
                                </div>
                                <div className="value">
                                  <h4>${parseFloat(actualFee).toFixed(2)}</h4>
                                </div>
                              </div>
                            )}
                            {!!using_balance && (
                              <React.Fragment>
                                <div className="fee-breakdown">
                                  <div className="label">
                                    <p>Credit Available</p>
                                  </div>
                                  <div className="value">
                                    <h4>${parseFloat(balance)}</h4>
                                  </div>
                                </div>
                                <div className="fee-breakdown">
                                  <div className="label">
                                    <p>Charged Today</p>
                                  </div>
                                  <div className="value">
                                    <h4>
                                      $
                                      {parseFloat(balance) >= actualFee
                                        ? 0
                                        : actualFee - parseFloat(balance)}
                                    </h4>
                                  </div>
                                </div>
                                <div className="fee-breakdown">
                                  <div className="label">
                                    <p>Remaining Balance</p>
                                  </div>
                                  <div className="value">
                                    <h4>
                                      $
                                      {parseFloat(balance) <= actualFee
                                        ? 0
                                        : parseFloat(balance) - actualFee}
                                    </h4>
                                  </div>
                                </div>
                              </React.Fragment>
                            )}
                          </div>
                        </div>
                        <div className="payment-info">
                          <h2>Payment Information</h2>
                          {parseFloat(balance) > 0 && (
                            <React.Fragment>
                              {this.renderBoolean(
                                'using_balance',
                                `You have a credit of $${balance}. Would you like to use it today?`
                              )}
                            </React.Fragment>
                          )}
                          {hasStripeUser && !changingPaymentInfo && (
                            <div className="payment-info-item">
                              <h3>
                                {user.card_brand !== null
                                  ? upperCaseFirst(user.card_brand)
                                  : user.card_brand}
                              </h3>
                              <p>
                                ....&nbsp;
                                {user.card_last_four}
                              </p>
                              <div className="btn-wrapper">
                                <button
                                  className="theme-btn small blue"
                                  onClick={this.changePaymentInfo}
                                >
                                  Change
                                </button>
                              </div>
                            </div>
                          )}
                          {(!hasStripeUser || !!changingPaymentInfo) && (
                            <div className="payment-fields">
                              <div className="payment-billing-address">
                                {this.renderInput(
                                  'firstname',
                                  'First Name',
                                  'text',
                                  true
                                )}
                                {this.renderInput(
                                  'lastname',
                                  'Last Name',
                                  'text',
                                  true
                                )}
                                {this.renderInput(
                                  'address1',
                                  'Address',
                                  'text',
                                  true
                                )}
                                {this.renderInput(
                                  'address2',
                                  'Apt/Suite',
                                  'text'
                                )}
                                {this.renderInput('city', 'City', 'text', true)}
                                {this.renderSelect(
                                  'state',
                                  'State',
                                  states,
                                  true
                                )}
                                {this.renderInput('zip', 'ZIP', 'number', true)}
                              </div>
                              <div className="payment-field card-number">
                                <CardNumberElement
                                  className="card-number"
                                  style={{
                                    base: fieldStyles,
                                  }}
                                />
                                <label>
                                  Card Number
                                  <span className="required">&nbsp;*</span>
                                </label>
                              </div>
                              <div className="payment-field card-expiry">
                                <CardExpiryElement
                                  className="card-expiry"
                                  placeholder="Expiration"
                                  style={{
                                    base: fieldStyles,
                                  }}
                                />
                                <label>
                                  Expiration Date
                                  <span className="required">&nbsp;*</span>
                                </label>
                              </div>
                              <div className="payment-field card-cvc-num">
                                <CardCvcElement
                                  className="card-cvc-num"
                                  style={{
                                    base: fieldStyles,
                                  }}
                                />
                                <label>
                                  CSV
                                  <span className="required">&nbsp;*</span>
                                </label>
                              </div>
                              {!!hasStripeUser && (
                                <div
                                  className="btn-wrapper"
                                  style={{
                                    marginTop: '16px',
                                  }}
                                >
                                  <button
                                    className="theme-btn small blue"
                                    onClick={this.changePaymentInfo}
                                  >
                                    Use Default Card
                                  </button>
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    }
                  />
                </div>
              )}
              {step === 6 && (
                <div className="book-appointment-form confirmation">
                  <h2>Appointments Scheduled</h2>
                  <div className="booking-confirmation">
                    <p>
                      <b>
                        We have received your request
                        {booking_appointments.length > 1 ? 's' : ''}
                        !.
                      </b>
                    </p>
                    <p>
                      Please note, this is NOT a confirmation, but a work in
                      progress.
                    </p>
                    <p>
                      If your first provider choice is not available to confirm
                      the request, the SmartSitter Office will work to confirm another
                      available Sitter as soon as possible. You can log in
                      at any time to check the status of your appointment
                      {booking_appointments.length > 1 ? 's' : ''}. An official
                      email confirmation will be sent once a provider accepts
                      your request.
                    </p>
                    <p>We'll be in touch very soon!</p>
                    <p>Thank you!</p>
                    <Link
                      className="theme-btn small slate-blue"
                      to={'/dashboard'}
                    >
                      Back to Dashboard
                    </Link>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapState(state) {
  return {
    auth: state.auth,
    balance: state.balance,
  };
}

const actionCreators = {
  getBalance: balanceActions.getBalance,
};

export default connect(mapState, actionCreators)(injectStripe(RegularBooking));
