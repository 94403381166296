import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import moment from 'moment-timezone';

import SitterAvailabilityForm from '../../Forms/SitterAvailabilityForm';
import sitterService from '../../../services/sitterService';
import { Modal } from '../../shared';
import { mapAuthState } from '../../../helpers/mapAuthState';

class Availability extends SitterAvailabilityForm {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      availability: [],
      loading: true,
      numMonths: 3,
      months: [moment(), moment().add(1, 'months'), moment().add(2, 'months')],
      id: null,
      dirty: false,
    };
  }
  componentDidMount = () => {
    this.getSitter();

    if (this.props.windowWidth >= 1200 && this.state.numMonths != 3) {
      this.setState({
        numMonths: 3,
        months: [
          moment(),
          moment().add(1, 'months'),
          moment().add(2, 'months'),
        ],
      });
    } else if (this.props.windowWidth < 1200 && this.state.numMonths != 1) {
      this.setState({
        numMonths: 1,
        months: [moment()],
      });
    }

    window.onbeforeunload = () => {
      const { dirty } = this.state;
      return dirty
        ? 'Changes will be LOST if you navigate away from the page. Are you sure you want to proceed?'
        : null;
    };
  };
  componentWillUnmount = () => {
    window.onbeforeunload = () => {
      return null;
    };
  };
  componentDidUpdate = (prevProps, prevState) => {
    const prevData = prevState.data;
    const { data } = this.state;
    console.log(data, prevData);
    if (this.props.windowWidth != prevProps.windowWidth) {
      console.log('resizing');
      if (this.props.windowWidth >= 1200 && this.state.numMonths != 3) {
        console.log('large');
        this.setState({
          numMonths: 3,
          months: [
            moment(),
            moment().add(1, 'months'),
            moment().add(2, 'months'),
          ],
        });
      } else if (this.props.windowWidth < 1200 && this.state.numMonths != 1) {
        console.log('small');
        this.setState({
          numMonths: 1,
          months: [moment()],
        });
      }
    }

    if (
      !!data['recurring_start_time'] &&
      prevData['recurring_start_time'] !== data['recurring_start_time']
    ) {
      console.log('filtering');
      this.filterEndTimes();
    }
  };
  getSitter = async () => {
    try {
      const { id } = this.props.auth.user.personal_info;
      const response = await sitterService.get(id);
      this.formatData(response.data.data);
    } catch (e) {
      console.log(e);
    }
  };
  formatData = async ({ availability, personalInfo, profileSitter }) => {
    const { data } = this.state;
    let timeslotAvailability = [];

    //checking if they have their profiles fully filled out
    const required = [];
    if (personalInfo.DOB === null) {
      required.push('Date of Birth in Account Settings');
    }
    if (personalInfo.address1 === null) {
      required.push('Private Profile in My Profile');
    }
    if (profileSitter.bio === null) {
      required.push('Public Profile in My Profile');
    }
    if (required.length > 0) {
      this.props.history.goBack();
      toast.error(
        'Please fill these out before filling out availability: ' +
          required.join(', ')
      );
    }

    //set up timeslots
    availability.map((value, index) => {
      if (timeslotAvailability.length > 0) {
        let foundIndex = -1;
        for (let i = 0; i < timeslotAvailability.length; i++) {
          if (timeslotAvailability[i]['date'] === value.date) {
            foundIndex = i;
          }
        }

        if (foundIndex !== -1) {
          timeslotAvailability[foundIndex] = {
            ...timeslotAvailability[foundIndex],
            timeslots: [
              ...timeslotAvailability[foundIndex].timeslots,
              {
                start_time: value.start_time,
                end_time: value.end_time,
              },
            ],
          };
        } else {
          timeslotAvailability.push({
            ...value,
            timeslots: [
              {
                start_time: value.start_time,
                end_time: value.end_time,
              },
            ],
          });
        }
      } else {
        timeslotAvailability.push({
          ...value,
          timeslots: [
            {
              start_time: value.start_time,
              end_time: value.end_time,
            },
          ],
        });
      }
    });
    data['sitterAvailability'] = timeslotAvailability;
    data['availability_notes'] = profileSitter.availability_notes;
    this.setState({
      data,
      id: personalInfo.id,
    });
  };
  doSubmit = async (callback) => {
    try {
      await this.submitFormData();
    } catch (e) {
      console.log(e);
      callback();
    }
  };
  render() {
    const { data } = this.state;
    const { user } = this.props.auth;

    return (
      <main className="account profile availability">
        {/* <form onSubmit={this.submitFormData}> */}
        <div className="profile-body">
          <h2>Availability Schedule</h2>
          {this.renderForm()}
          {/* {this.renderButton("Save Availability", "Saving...", "theme-btn blue")} */}
        </div>
        {/* </form> */}
      </main>
    );
  }
}

export default connect(mapAuthState)(Availability);
