import React from 'react';

import Sidebar from '../Sidebar';
import moment from 'moment-timezone';
import { findLinkGroupByTitle } from '../../../helpers';
import ReactModal from '../../shared/ReactModal';
import { toast } from 'react-toastify';
import personalInfoService from '../../../services/personalInfoService';
import familyService from '../../../services/familyService';
import MemberForm from '../../Forms/MemberForm';
import { baseUrl } from '../../../config';
import authService from '../../../services/authService';

class MembersEdit extends MemberForm {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      tab: 'regular',
      saving: false,
      deleting: false,
      isOpen: false,
      linkGroup: findLinkGroupByTitle('Families'),

      user: null,
      subscription: null,

      loading: true,
      cancelling: false,
      showCancel: false,
      sending: false,
      id: null,
      creating: false,
      family_id: null,
    };
  }

  componentDidMount = () => {
    const { id, family_id } = this.props.match.params;
    if (id !== 'new' && Number(id) == id) {
      // abusing a quirk of JS where "11" == 11 to ensure the ID is a number
      this.getMember(id);
    } else {
      this.setState({
        data: { ...this.state.freshSchema },
        creating: true,
        family_id: family_id,
      });
    }
  };
  getMember = async (id) => {
    try {
      const response = await personalInfoService.get(id);
      this.formatData(response.data.data);
    } catch (e) {
      console.log(e);
    }
  };

  formatData = ({
    acceptedAgeRanges,
    languages,
    personalInfo,
    profileSitter,
    user,
    selectedActivities,
    medicalInfo,
    subscription,
  }) => {
    const { data } = this.state;

    const {
      firstname,
      lastname,
      address1,
      address2,
      city,
      state,
      zip,
      DOB,
      gender,
      phone1,
    } = personalInfo;

    const { email, login_hash } = user;

    data['email'] = email;

    data['firstname'] = firstname;
    data['lastname'] = lastname;
    data['address1'] = address1;
    data['address2'] = address2;
    data['city'] = city;
    data['state'] = state;
    data['zip'] = zip;
    data['gender'] = gender;
    data['phone1'] = phone1;

    data['login_hash'] = login_hash;

    if (DOB !== null) {
      const dobPieces = DOB.split('-');
      if (dobPieces.length === 3) {
        // We assume format "YYYY-MM-DD"
        // so after splittig, it should be: [ "YYYY", "MM", "DD" ]
        data['dob_year'] = dobPieces[0];
        data['dob_month'] = dobPieces[1];
        data['dob_day'] = dobPieces[2];
      }
    }

    this.setState({
      data,
      user,
      subscription,
      id: personalInfo.id,
    });
  };

  doSubmit = async (callback) => {
    this.setState({ saving: true });
    const response = await this.submitFormData();
    if (response.status === 200) {
      toast.success('Hooray! The family member was updated!');
      this.goBack();
      callback();
    } else {
      //throw error
    }
    this.setState({ saving: false });
  };

  changeTab = (tab) => {
    this.setState({ tab });
  };

  closeModal = () => {
    this.setState({ isOpen: false, showCancel: false });
  };

  toggleCancelSubscription = () => {
    this.setState({
      showCancel: !this.state.showCancel,
    });
  };

  cancelSubscription = async () => {
    this.setState({
      cancelling: true,
    });
    try {
      const { id } = this.state.user;
      const response = await familyService.cancelSubscription(id);
      console.log(response);
      if (response.status === 200) {
        const { user } = response.data.data;
        toast.success("The user's subscription has been cancelled.");
        this.setState({
          showCancel: false,
          cancelling: false,
          user,
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  sendInviteLink = async () => {
    this.setState({
      sending: true,
    });
    try {
      const { email, login_hash } = this.state.user;
      const response = await authService.sendVerifyEmail({
        type: 'parent',
        email,
        login_hash,
      });
      if (response.status === 200) {
        toast.success('Invite Link Sent');
        this.setState({
          sending: false,
        });
      }
    } catch (e) {
      console.log(e);
      toast.error('There was a problem sending the invite link');
      this.setState({
        sending: false,
      });
    }
  };

  deleteMember = async () => {
    const { id } = this.state;

    this.setState({ deleting: true });

    let data = new FormData();
    const headers = { headers: { 'content-type': 'multipart/form-data' } };
    data.append('_method', 'delete');

    const response = await personalInfoService.delete(id, data, headers);

    if (response.status === 204) {
      toast.success('Hooray! The family member was deleted.');
      this.goBack();
    } else {
      toast.error('Oops! Looks like something went wrong.');
    }

    this.setState({ deleting: false });
  };

  goBack = (e) => {
    if (!!e) {
      e.preventDefault();
    }
    this.props.history.goBack();
  };

  render() {
    const {
      tab,
      saving,
      deleting,
      linkGroup,
      isOpen,
      creating,
      cancelling,
      showCancel,
      user,
      sending,
      subscription,
    } = this.state;

    return (
      <main className="dashboard admin manage">
        <div className="sidebar-wrapper">
          <Sidebar
            {...this.props}
            iconClass="fas fa-id-card"
            linkGroup={linkGroup}
          />
        </div>
        <div className="content">
          <div className="providers">
            <h2>{creating === false ? 'Edit' : 'Create'} Family Member</h2>
            <div className="providers-form form">
              {this.renderForm(tab)}
              <div className="divider"></div>
              {!!user && (
                <React.Fragment>
                  <div className="subscription-cancellation">
                    {!!user && !!user.requested_subscription_cancellation && (
                      <p>
                        This user has requested a subscription cancellation.
                      </p>
                    )}
                    {!subscription ||
                      (subscription && subscription.ends_at === null && (
                        <button
                          type="button"
                          className="theme-btn blue large save-btn"
                          disabled={cancelling}
                          onClick={this.toggleCancelSubscription}
                        >
                          {cancelling && (
                            <i className="fas fa-spinner fa-spin"></i>
                          )}
                          {cancelling
                            ? ' Cancelling...'
                            : 'Cancel Subscription'}
                        </button>
                      ))}
                    {subscription && subscription.ends_at !== null && (
                      <div>
                        <b>Subscription ends on:</b>{' '}
                        {moment(subscription.ends_at).format('MMMM Do, YYYY')}
                      </div>
                    )}
                    {subscription &&
                      subscription.ends_at === null &&
                      subscription.renews_at !== null && (
                        <div>
                          <b>Subscription renews on:</b>{' '}
                          {moment(subscription.renews_at).format(
                            'MMMM Do, YYYY'
                          )}
                        </div>
                      )}
                    {user.cancel_reason !== null && (
                      <div>
                        <b>Cancel Reason:</b> {user.cancel_reason}
                      </div>
                    )}
                  </div>
                  <div className="subscription-cancellation">
                    <button
                      type="button"
                      className="theme-btn blue large"
                      disabled={sending}
                      onClick={this.sendInviteLink}
                    >
                      {sending && <i className="fas fa-spinner fa-spin"></i>}
                      {sending ? ' Sending...' : 'Send Invite Link'}
                    </button>
                    <br />
                    <br />
                    <b>Link:</b>{' '}
                    <a
                      href={
                        baseUrl +
                        '/register/family/profile?login_hash=' +
                        user.login_hash +
                        '&email=' +
                        user.email
                      }
                    >
                      {baseUrl}
                      /register/family/profile?login_hash=
                      {user.login_hash}&email={user.email}
                    </a>
                  </div>
                </React.Fragment>
              )}
              <div className="divider"></div>
              <div className="align_center row">
                <button
                  type="button"
                  className="theme-btn blue large save-btn"
                  disabled={saving}
                  onClick={this.handleSubmit}
                >
                  {saving && <i className="fas fa-spinner fa-spin"></i>}
                  {saving ? ' Saving...' : 'Save'}
                </button>
                {creating === false && (
                  <button
                    type="button"
                    className="theme-btn red large save-btn"
                    disabled={deleting}
                    onClick={() => this.setState({ isOpen: true })}
                  >
                    {deleting && <i className="fas fa-spinner fa-spin"></i>}
                    {deleting ? ' Deleting...' : 'Delete'}
                  </button>
                )}
              </div>
            </div>
          </div>
          <ReactModal
            title="Confirm Delete"
            isOpen={isOpen}
            closeModal={this.closeModal}
            content={'Are you sure you want to delete this family member?'}
            primaryButton={deleting ? ' Deleting...' : 'Delete'}
            secondaryButton={'Close'}
            handleClick={this.deleteMember}
          />
          <ReactModal
            title="Confirm Subscription Cancellation"
            isOpen={showCancel}
            closeModal={this.toggleCancelSubscription}
            content={
              "Are you sure you want to cancel this user's subscription?"
            }
            primaryButton={
              cancelling ? ' Cancelling...' : 'Cancel Subscription'
            }
            secondaryButton={'Close'}
            handleClick={this.cancelSubscription}
          />
        </div>
      </main>
    );
  }
}

export default MembersEdit;
