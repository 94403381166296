import React from 'react';
import { connect } from 'react-redux';
import { Elements, StripeProvider } from 'react-stripe-elements';
import { stripeKey } from '../../../config';
import { mapAuthState } from '../../../helpers/mapAuthState';
import { FormStepTracker } from '../../shared';
import PaymentForm from './PaymentForm';
import trackerSteps from './trackerSteps';

const fontsObj = [
  {
    cssSrc: 'https://fonts.googleapis.com/css?family=Open+Sans&display=swap',
  },
];

class Payment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      //
    };
  }
  componentDidMount() {
    const { user } = this.props.auth;
    if (!user) {
      this.sendUserBack();
    }
  }
  componentDidUpdate(prevProps, prevState) {
    //
  }
  sendUserBack() {
    alert('You must sign up or log in to view this page.');
    window.open('/', '_self');
  }
  render() {
    const {
      //
    } = this.state;
    return (
      <div className="registration family step-5 payment">
        <div className="form">
          <div className="inner">
            <FormStepTracker steps={trackerSteps} currentStep={3} />
            <div className="form-header">
              <h2>Payment</h2>
            </div>
            <StripeProvider apiKey={stripeKey}>
              <Elements fonts={fontsObj}>
                <PaymentForm />
              </Elements>
            </StripeProvider>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapAuthState)(Payment);
