import moment from 'moment-timezone';
const displayAppointmentDuration = (appointment, useRequestedTime = false) => {
    const start = !!useRequestedTime
        ? appointment.requested_start_time
        : appointment.start_time;
    const end = !!useRequestedTime
        ? appointment.requested_end_time
        : appointment.end_time;
    const startTime = moment(start);
    const endTime = moment(end);
    const hours = moment.duration(endTime.diff(startTime)).asHours();
    return `${hours} hrs`;
};
export default displayAppointmentDuration;
