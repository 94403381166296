import React from 'react';
import Form from '../../shared/Form';
import Joi from 'joi';

import httpService from '../../../services/httpService';

const checkboxOptions = [{ label: 'Yes', value: true }];

class Signup extends Form {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        firstname: null,
        lastname: null,
        email: null,
        over_18: 0,
        has_transportation: 0,
        lives_within_service_area: 0,
      },
      errors: {},
      recaptchaAccepted: false,
      formSubmitted: false,
    };
  }
  schema = Joi.object({
    firstname: Joi.string().required().label('First Name'),
    lastname: Joi.string().required().label('Last Name'),
    email: Joi.string().required().label('Email'),
    over_18: Joi.number()
      .integer()
      .min(1)
      .max(1)
      .required()
      .messages({
        'number.greater': 'You must be over 18 to apply.',
        'number.lesser': 'You must be over 18 to apply.',
        'number.min': 'You must be over 18 to apply.',
        'number.max': 'You must be over 18 to apply.',
      })
      .label('Over 18'),
    has_transportation: Joi.number()
      .integer()
      .min(1)
      .max(1)
      .required()
      .messages({
        'number.greater': 'You must have a means of transportation to apply.',
        'number.lesser': 'You must have a means of transportation to apply.',
        'number.min': 'You must have a means of transportation to apply.',
        'number.max': 'You must have a means of transportation to apply.',
      })
      .label('Has Transportation'),
    lives_within_service_area: Joi.number()
      .integer()
      .min(1)
      .max(1)
      .required()
      .messages({
        'number.greater': 'You must acknowledge that you have reviewed the serviceable area to apply.',
        'number.lesser': 'You must acknowledge that you have reviewed the serviceable area to apply.',
        'number.min': 'You must acknowledge that you have reviewed the serviceable area to apply.',
        'number.max': 'You must acknowledge that you have reviewed the serviceable area to apply.',
      })
      .label('Reviewed Service Area'),
  });
  doSubmit = async (callback) => {
    if (!this.state.recaptchaAccepted) {
      alert('You must accept the ReCAPTCHA before proceeding.');
      callback();
      return;
    }
    const { data } = this.state;
    try {
      const response = await httpService.post('/api/signup/sitter', data);
      if (response.status === 200 && response.data.message === 'OK') {
        this.setState({
          formSubmitted: true,
        });
      } else {
        alert('Whoops! An error! Oh no!');
      }
      callback();
    } catch (e) {
      console.log('Error: ', e);
      callback();
    }
  };
  onChange = () => {
    this.setState({
      recaptchaAccepted: true,
    });
  };
  render() {
    return (
      <div className="registration sitter step-1 signup">
        <div className="form">
          {!this.state.formSubmitted && (
            <div className="inner">
              <div className="form-header">
                <h2>Sign up for SmartSitter</h2>
                <p>
                  A local online marketplace where families find
                  trusted and responsible Sitters.
                </p>
              </div>
              <form onSubmit={this.handleSubmit}>
                {this.renderInput('firstname', 'First Name', 'text', true)}
                {this.renderInput('lastname', 'Last Name', 'text', true)}
                {this.renderInput('email', 'Email', 'email', true)}
                {this.renderBoolean('over_18', 'I am 18 years or old', true)}
                {this.renderBoolean(
                  'has_transportation',
                  'I have transportation to get to appointments',
                  true
                )}
                {this.renderBoolean(
                  'lives_within_service_area',
                  'I have reviewed the service area',
                  true
                )}
                {this.renderRecaptcha(this.onChange)}
                {this.renderButton('Submit', 'Submitting...', 'theme-btn blue')}
              </form>
            </div>
          )}
          {this.state.formSubmitted && (
            <div className="inner">
              <div className="signup-completed">
                <h2>
                  Almost ready to
                  <br />
                  get started
                </h2>
                <p>
                  We just need to verify your email. Please check your inbox and
                  click the link within.
                </p>
              </div>
            </div>
          )}
        </div>
        <div className="image"></div>
      </div>
    );
  }
}

export default Signup;
