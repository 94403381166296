import {
    Grid,
    GridColumn,
  } from '@progress/kendo-react-grid';
  import React from 'react';
  import { findLinkGroupByTitle } from '../../../helpers';
  import sitterService from '../../../services/sitterService';
  import familyService from '../../../services/familyService';
  import Sidebar from '../Sidebar';
  import {
    ActiveCell,
    AvailabilityCell,
    ColumnMenu,
    ManageProvidersCell,
  } from '../Providers/KendoComponents';

  import {
    SitterActionCell,
  } from './KendoComponents';
  
  class ManageSittersGrid extends React.Component {
    state = {
      sitters: [],
      result: [],
      profileFamily: null,
      loading: true,
      showOnboardingKey: false,
      linkGroup: findLinkGroupByTitle('Providers'),
      dataState: {
        sort: [{ field: 'firstname', dir: 'asc' }],
        filter: {
          logic: 'and',
          filters: [],
        },
        filterOperators: {
          text: [
            {
              text: 'grid.filterContainsOperator',
              operator: 'contains',
            },
            { text: 'grid.filterEqOperator', operator: 'eq' },
          ],
        },
        skip: 0,
        take: 50,
        total: 0,
      },
    };
  
    componentDidMount = () => {
      this.getProviders();
      this.getFamily();
    };
    getProviders = async () => {
      try {
        const { dataState } = this.state;
        const response = await sitterService.list({
          skip: dataState.skip,
          take: dataState.take,
          sort: dataState.sort,
          filter: dataState.filter,
        });
        if (response.status === 200) {
          const { sitters, count } = response.data.data;
          this.setState({
            result: sitters,
            dataState: {
              ...dataState,
              total: count,
            },
            sitters,
            loading: false,
          });
        }
      } catch {
        this.setState({
          loading: false,
        });
      }
    };
    getFamily = async () => {
      try {
        const { id } = this.props.match.params;
        const response = await familyService.get(id);
        if (response.status === 200) {
          const {
            profileFamily,
          } = response.data.data;
          this.setState({
            profileFamily,
          });
        }
      } catch (e) {
        console.log(e);
      }
    };
    pageChange = (event) => {
      this.setState(
        {
          dataState: {
            ...this.state.dataState,
            skip: event.page.skip,
            take: event.page.take,
          },
        },
        () => {
          this.getProviders();
        }
      );
    };
    sortChange = (event) => {
      this.setState(
        {
          dataState: {
            ...this.state.dataState,
            sort: event.sort,
          },
        },
        () => {
          this.getProviders();
        }
      );
    };
    filterChange = (event) => {
      this.setState(
        {
          dataState: {
            ...this.state.dataState,
            filter: event.filter,
          },
        },
        () => {
          this.getProviders();
        }
      );
    };
    onActionClick = async (piid) => {
      const { id, type } = this.props.match.params;
      try {
        const response = await (type === 'block' ? familyService.blockSitter(id, piid, {
            excluded: 1,
          }) : familyService.favoriteSitter(id, piid, {
            favorite: 1,
          }));
        if (response.status === 200) {
          this.goBack();
        }
      } catch (e) {
        console.log(e);
      }
    };
    goBack () {
      this.props.history.goBack();
    }
    render() {
      const {
        type
      } = this.props.match.params;
      const { 
        loading, 
        linkGroup, 
        dataState, 
        profileFamily,
        result 
      } = this.state;
  
      if (!!loading) {
        return (
          <main className="admin admin-listing with-sidebar appointments">
            <div className="sidebar-wrapper">
              <Sidebar
                {...this.props}
                iconClass="fas fa-id-card"
                linkGroup={linkGroup}
              />
            </div>
            <div className="content">
              <h4 className="section-label">
                <i className="fas fa-calendar-star"></i>
                &nbsp;Loading Providers...
              </h4>
            </div>
          </main>
        );
      }
  
      return (
        <main className="admin admin-listing with-sidebar appointments">
          <div className="sidebar-wrapper">
            <Sidebar
              {...this.props}
              iconClass="fas fa-id-card"
              linkGroup={linkGroup}
            />
          </div>
          <div className="content">
            <h4 className="section-label">
              { !!profileFamily && (
                <>
                  <i className="fas fa-calendar-star"></i>&nbsp;{ type === 'block' ? 'Blocking' : 'Favoriting' }&nbsp;Sitters for { profileFamily.name } Family
                </>
              )}
              { !profileFamily && (
                <>
                  <i className="fas fa-calendar-star"></i>&nbsp;{ type === 'block' ? 'Blocking' : 'Favoriting' }&nbsp;Sitters
                </>
              )}
            </h4>
            <div className="kendo-table">
              <Grid
                data={result}
                pageable={true}
                sortable={true}
                {...dataState}
                onPageChange={this.pageChange}
                onSortChange={this.sortChange}
                onFilterChange={this.filterChange}
              >
                <GridColumn
                  title="First Name"
                  field="firstname"
                  columnMenu={ColumnMenu}
                />
                <GridColumn
                  title="Last Name"
                  field="lastname"
                  columnMenu={ColumnMenu}
                />
                <GridColumn
                  title="Status"
                  field="active"
                  width={100}
                  columnMenu={ColumnMenu}
                  cell={(props) => <ActiveCell {...props} {...this.props} />}
                />
                <GridColumn
                  title="Available Slots"
                  field="availability_count"
                  width={140}
                  columnMenu={ColumnMenu}
                  cell={(props) => (
                    <AvailabilityCell {...props} {...this.props} />
                  )}
                />
                <GridColumn
                  title="ID"
                  field="id"
                  width={60}
                  columnMenu={ColumnMenu}
                />
                <GridColumn
                  title=" "
                  field="piid"
                  width={125}
                  cell={(props) => (
                    <SitterActionCell onActionClick={this.onActionClick} type={type} {...props} {...this.props} />
                  )}
                />
                <GridColumn
                  title=" "
                  field="piid"
                  width={125}
                  cell={(props) => (
                    <ManageProvidersCell onActionClick={this.onActionClick} type={type} {...props} {...this.props} />
                  )}
                />
              </Grid>
            </div>
          </div>
        </main>
      );
    }
  }
  
  export default ManageSittersGrid;
  