import { findIndex } from 'lodash-es';
import moment from 'moment-timezone';
import React from 'react';
import { toast } from 'react-toastify';
import { makeTimeIncrements } from '../../../helpers';
import { Form, Modal } from '../../shared';

class AvailabilityRow extends Form {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        id: null,
        date: new Date(),
        timeslots: [],
        start_time: null,
        end_time: null,
      },
      errors: {},
      timeslots: [],
      showingRemoveAvail: false,
      showAddTimeSlot: false,
      showEditTimeSlot: false,
      timeslotIndex: 0,
      end_times: makeTimeIncrements(),
      start_times: makeTimeIncrements(4, 21),
    };
  }
  componentDidMount = () => {
    const { availability } = this.props;
    let { data } = this.state;
    data = { ...availability };
    this.setState({
      data: {
        id: data.id,
        date: data.date,
        timeslots: data.timeslots,
        start_time: null,
        end_time: null,
      },
      timeslots: data.timeslots,
    });
  };
  componentDidUpdate = (prevProps, prevState) => {
    let prevData = prevState.data;
    let { data } = this.state;

    if (prevData !== data) {
      const { idx } = this.props;
      let availabilityData = { ...data };
      this.props.onAvailabilityChangeData(idx, availabilityData);
    }

    if (
      data['start_time'] !== null &&
      prevData['start_time'] !== data['start_time']
    ) {
      this.filterEndTimes();
    }
  };
  filterEndTimes = () => {
    let newEndTimes = [...this.props.times];
    const { data } = this.state;
    const idx = findIndex(newEndTimes, (time) => {
      return time.value == data['start_time'];
    });
    const length = idx + 12;
    if (length === newEndTimes.length) {
      // if they select the very last time slot (11:45pm), then there are 0 options left
      // So we force there to be only 1 item
      newEndTimes.splice(0, length - 1);
    } else {
      newEndTimes.splice(0, length);
    }
    data['end_time'] =
      newEndTimes[0] !== undefined ? newEndTimes[0].value : '23:45';
    this.setState({
      end_times: newEndTimes,
      data,
    });
  };
  toggleRemoveAvailability = (e) => {
    if (e) e.preventDefault();
    this.setState({
      showingRemoveAvail: !this.state.showingRemoveAvail,
    });
  };
  toggleShowAddTimeSlot = (e) => {
    if (e) e.preventDefault();
    this.setState({
      showAddTimeSlot: !this.state.showAddTimeSlot,
    });
  };
  editTimeSlot = (timeslot, i) => {
    const { data } = this.state;
    let newData = { ...data };
    newData['start_time'] =
      timeslot.start_time.length > 5
        ? moment(timeslot.start_time, 'hh:mm:ss').format('hh:mm')
        : timeslot.start_time;
    newData['end_time'] =
      timeslot.end_time.length > 5
        ? moment(timeslot.end_time, 'hh:mm:ss').format('hh:mm')
        : timeslot.end_time;
    this.setState({
      data: newData,
      timeslotIndex: i,
      showEditTimeSlot: !this.state.showEditTimeSlot,
    });
  };
  removeAvailability = (e) => {
    e.preventDefault();
    this.setState(
      {
        showingRemoveAvail: !this.state.showingRemoveAvail,
      },
      () => {
        this.props.removeAvailability(this.props.idx);
      }
    );
  };
  saveTimeSlot = (edit = false) => {
    let { timeslots, data, timeslotIndex } = this.state;

    if (data.start_time >= data.end_time) {
      toast.error('Oops, end time must be after start time');
    } else {
      this.props.dirty();

      if (edit === true) {
        timeslots[timeslotIndex] = {
          start_time: data.start_time,
          end_time: data.end_time,
        };

        this.setState({
          showEditTimeSlot: !this.state.showEditTimeSlot,
          timeslots,
          data: {
            ...data,
            timeslots,
          },
        });
      } else {
        const selectedSlots = {
          start_time: data.start_time,
          end_time: data.end_time,
        };
        timeslots.push(selectedSlots);

        this.setState({
          showAddTimeSlot: !this.state.showAddTimeSlot,
          timeslots,
          data: {
            ...data,
            timeslots,
          },
        });
      }
    }
  };
  deleteTimeSlot = () => {
    let { timeslots, data, timeslotIndex } = this.state;
    timeslots.splice(timeslotIndex, 1);

    this.props.dirty();

    this.setState({
      showEditTimeSlot: !this.state.showEditTimeSlot,
      timeslots,
      data: {
        ...data,
        timeslots,
      },
    });
  };
  render() {
    const {
      data,
      showingRemoveAvail,
      showAddTimeSlot,
      showEditTimeSlot,
      start_times,
      end_times,
    } = this.state;
    const endTimeDisabled = !this.state.data.start_time;

    return (
      <div className="availability-row">
        <div className="form-field-wrapper date">
          {moment(data.date).format('MMM Do')}
        </div>
        {data.timeslots.length > 0 &&
          data.timeslots.map((timeslot, i) => {
            return (
              <div key={i} className="timeslot">
                <span className="header">Time Slot</span>
                <span className="slot">
                  {moment(timeslot.start_time, 'H:mm').format('h:mm a')} -{' '}
                  {moment(timeslot.end_time, 'H:mm').format('h:mm a')}
                </span>
                <i
                  className="fas fa-edit primary-green"
                  onClick={() => this.editTimeSlot(timeslot, i)}
                ></i>
              </div>
            );
          })}
        <div className="add-time-slot" onClick={this.toggleShowAddTimeSlot}>
          Add Time Slot
          <i className="fas fa-plus-circle primary-green"></i>
        </div>
        <div className="form-field-wrapper cancel">
          <a className="cancel-btn" onClick={this.toggleRemoveAvailability}>
            <span>X</span>
          </a>
        </div>
        <Modal
          title="Confirm Removal"
          isOpen={showingRemoveAvail}
          closeModal={this.toggleRemoveAvailability}
          content={
            'Are you sure you want to remove this availability? This action CANNOT be undone.'
          }
          primaryButton={'Remove'}
          secondaryButton={'Close'}
          handleClick={this.removeAvailability}
        />
        <Modal
          title="Add Time Slot"
          isOpen={showAddTimeSlot}
          closeModal={() =>
            this.setState({
              showAddTimeSlot: !this.state.showAddTimeSlot,
            })
          }
          content={
            <React.Fragment>
              <div>Enter your start and end times for this time slot</div>
              <div className="modal-time-slot">
                <div className="form-field-wrapper start_time">
                  {this.renderSelect(
                    'start_time',
                    'Start Time',
                    start_times,
                    true
                  )}
                </div>
                <div className="form-field-wrapper end_time">
                  {this.renderSelect('end_time', 'End Time', end_times, true)}
                </div>
              </div>
            </React.Fragment>
          }
          saveButton={'Add'}
          secondaryButton={'Close'}
          handleClick={() => this.saveTimeSlot(false)}
        />
        <Modal
          title="Edit Time Slot"
          isOpen={showEditTimeSlot}
          closeModal={() =>
            this.setState({
              showEditTimeSlot: !this.state.showEditTimeSlot,
            })
          }
          content={
            <React.Fragment>
              <div>Enter your start and end times for this time slot</div>
              <div className="modal-time-slot">
                <div className="form-field-wrapper start_time">
                  {this.renderSelect(
                    'start_time',
                    'Start Time',
                    start_times,
                    true
                  )}
                </div>
                <div className="form-field-wrapper end_time">
                  {this.renderSelect('end_time', 'End Time', end_times, true, {
                    disabled: endTimeDisabled,
                  })}
                </div>
              </div>
            </React.Fragment>
          }
          saveButton={'Save'}
          deleteButton={'Remove'}
          handleDelete={this.deleteTimeSlot}
          secondaryButton={'Close'}
          handleClick={() => this.saveTimeSlot(true)}
        />
      </div>
    );
  }
}

export default AvailabilityRow;
