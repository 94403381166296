import React from 'react';
import { displayProfileColor } from '@ss/helpers';
import clsx from 'clsx';
const Avatar = (props) => {
    const { imageSrc, className = 'default', profileColor = null } = props;
    return (React.createElement("div", { className: clsx('avatar', className) },
        React.createElement("div", { className: clsx('avatar-wrapper', displayProfileColor(profileColor, 'background')) },
            imageSrc && React.createElement("img", { src: imageSrc, alt: "" }),
            !imageSrc && React.createElement("i", { className: "far fa-camera" }))));
};
export default Avatar;
