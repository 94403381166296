import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { Modal } from '..';
import appointmentService from '../../../services/appointmentService';
export const AppointmentClaimSitConfirmation = (props) => {
    const { isOpen, onRequestClose, conflict, sitterPiid, appointmentId, onSuccess, } = props;
    const [isSubmitting, setIsSubmitting] = useState(false);
    const handleSubmit = async () => {
        setIsSubmitting(true);
        try {
            await appointmentService.createPendingListSitterSignup({
                sitter_piid: sitterPiid,
                appointment_id: appointmentId,
            });
            await onSuccess();
            toast.success('You have requested to sit for this this appointment');
            onRequestClose();
        }
        catch (error) {
            console.log(error);
            setIsSubmitting(false);
            toast.error('Oh no! We couldn’t process your request. Try again');
        }
    };
    const submitLabel = () => {
        if (isSubmitting) {
            return 'Submitting...';
        }
        if (conflict) {
            return 'Request Sit';
        }
        return 'Confirm';
    };
    return (React.createElement(Modal, { title: conflict ? 'Notice' : 'Confirmation', isOpen: isOpen, closeModal: onRequestClose, content: React.createElement(React.Fragment, null,
            conflict && (React.createElement("p", null, "You have selected to sit for this appointment. However, you have another appointment at or too close to this appointment. You can still request to sit and the admin will review.")),
            !conflict && (React.createElement("p", null, "You have selected to sit for this appointment. By Confirming you are assigned this appointment and are required to sit for it.")),
            React.createElement("p", { className: "mod-buttons" },
                React.createElement("button", { className: "theme-btn primary-green", onClick: handleSubmit },
                    !isSubmitting && React.createElement("i", { className: "fas fa-plus-circle" }),
                    isSubmitting && React.createElement("i", { className: "fas fa-spinner fa-spin" }),
                    ' ',
                    submitLabel()))), secondaryButton: 'Close' }));
};
